/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import bottle from "../../../common/images/bottlefill.gif";
import { stripslashes } from "../../Helpers/SettingHelper";
import { GET_BANNER_LIST } from "../../../actions";
var Parser = require("html-react-parser");

class HomeBanner extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.getBannerList();
  }
  render() {
    let bannerArr = this.props.banner;
    let bannerlist = [];
    let bannerimagesource = "";

    if (Object.keys(bannerArr).length > 0) {
      if (bannerArr[0].status === "ok") {
        bannerlist = bannerArr[0].result_set;
        bannerimagesource = bannerArr[0].common.image_source;
      }
    }

    var settingsGallery = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };

    return (
      <div className="home-banner">
        {Object.keys(bannerlist).length > 0 ? (
          <Slider {...settingsGallery}>
            {bannerlist.map((banner, index) => {
              return (
                <div key={index}>
                  <a
                    href={
                      banner.banner_link !== "" && banner.banner_link !== null
                        ? banner.banner_link
                        : void 0
                    }
                  >
                    <img
                      src={
                        banner.banner_image_mobile !== "" &&
                        banner.banner_image_mobile !== null
                          ? bannerimagesource + banner.banner_image_mobile
                          : bannerimagesource + banner.banner_image
                      }
                      alt="Banner"
                      className="desktop-banner"
                    />
                    <img
                      src={bannerimagesource + banner.banner_image}
                      alt="Banner"
                      className="mobile-banner"
                    />
                    {banner.banner_description != "" && (
                      <div className="banner-info">
                        <div className="homebanner-content-inner">
                          {Parser(stripslashes(banner.banner_description))}
                        </div>
                      </div>
                    )}
                  </a>
                </div>
              );
            })}
          </Slider>
        ) : (
          <div className="no-banner">
            {" "}
            <img src={bottle} />{" "}
          </div>
        )}
      </div>
      /*  <div className="loader-main-cls">
            <div className="loader-sub-div"></div>
          </div> */
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    banner: state.banner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBannerList: () => {
      dispatch({ type: GET_BANNER_LIST });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(HomeBanner);
