/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import update from "immutability-helper";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import Changepassword from "./Changepassword";
import { GET_ACTIVITYCOUNT, GET_CHANGEPASSWORD } from "../../actions";
import { language } from "../Helpers/Language";
import { appId } from "../Helpers/Config";
import { showAlert, showLoader, hideLoader } from "../Helpers/SettingHelper";

import mm1 from "../../common/images/mobile-myaccount1.png";
import mm2 from "../../common/images/mobile-myaccount2.png";
import mm3 from "../../common/images/mobile-myaccount3.png";
import mm4 from "../../common/images/mobile-myaccount4.png";
import promoimg from "../../common/images/mobile-myaccount5.png";
import mm6 from "../../common/images/mobile-myaccount6.png";
import mmlight1 from "../../common/images/mobile-myaccount-light1.png";
import mmlight2 from "../../common/images/mobile-myaccount-light2.png";
import mmlight3 from "../../common/images/mobile-myaccount-light3.png";
import mmlight4 from "../../common/images/mobile-myaccount-light4.png";
import promoimglight from "../../common/images/mobile-myaccount-light5.png";

import mmlight6 from "../../common/images/mobile-myaccount-light6.png";

import mm5 from "../../common/images/subscrpition1.png";
import mmlight5 from "../../common/images/subscrpition2.png";

var qs = require("qs");
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: this.props.pageName,
      deliveryTakeaway_orders: "",
      fieldschpassword: {
        oldpin: "",
        newpin: "",
        confirmpin: "",
      },
    };
    const inputKeys = ["deliveryTakeaway_orders"];
    this.props.getActivityCount(JSON.stringify(inputKeys));
    this.changepassword = this.changepassword.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    /*activity count -start */
    if (Object.keys(nextProps.activitycountNew).length > 0) {
      if (nextProps.activitycountNew !== this.props.activitycountNew) {
        if (
          nextProps.activitycountNew[0].status &&
          nextProps.activitycountNew[0].result_set
        ) {
          this.setState({
            deliveryTakeaway_orders:
              nextProps.activitycountNew[0].result_set.deliveryTakeaway_orders,
          });
        }
      }
    } else {
      this.setState({
        order_all: 0,
        overall_orders: 0,
        catering_orders: 0,
        deliveryTakeaway_orders: 0,
        reservation_orders: 0,
      });
    }

    if (nextProps.changepassword !== this.props.changepassword) {
      $(".old_password").val("");
      $(".new_password").val("");
      $(".confirm_password").val("");
      hideLoader("change-pass-sbmt", "class");
      if (nextProps.changepassword[0].status === "ok") {
        showAlert("Success", "PIN changed successfully!");
      } else {
        if (nextProps.changepassword[0].form_error) {
          showAlert("Success", nextProps.changepassword[0].form_error);
        } else {
          showAlert("Success", nextProps.changepassword[0].message);
        }
      }
      window.$.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  fieldChange = (field, value) => {
    this.setState(
      update(this.state, { fieldschpassword: { [field]: { $set: value } } })
    );
  };

  /* Change Password */
  changepassword() {
    var old_pin = $(".old_password").val();
    var new_pin = $(".new_password").val();
    var confirm_pin = $(".confirm_password").val();
    var postObject = {
      app_id: appId,
      type: "web",
      oldpassword: old_pin,
      password: new_pin,
      confirmpassword: confirm_pin,
      refrence: cookie.load("UserId"),
      access_token:
        cookie.load("access_token") !== "" &&
        typeof cookie.load("access_token") !== undefined &&
        typeof cookie.load("access_token") !== "undefined"
          ? cookie.load("access_token")
          : "",
      passwordtype: "PIN",
    };
    showLoader("change-pass-sbmt", "class");
    this.props.getChangePassword(qs.stringify(postObject));
  }

  render() {
    var initialSlide = 0;
    if (this.state.activePage === "myorders") {
      initialSlide = 1;
    } else if (this.state.activePage === "rewards") {
      initialSlide = 2;
    } else if (this.state.activePage === "mypromotions") {
      initialSlide = 3;
    }

    return (
      <>
        <div className="mainacc_menuout">
          <ul className="mainacc_menulist">
            <li
              className={this.state.activePage === "myaccount" ? "active" : ""}
            >
              <Link to="/myaccount" title={language.accountdetails}>
                <div className="desknav-img">
                  <img className="darker-version" src={mm1} />
                  <img className="lighter-version" src={mmlight1} />
                </div>
                <span>{language.accountdetails}</span>
              </Link>
            </li>
            <li
              className={this.state.activePage === "myorders" ? "active" : ""}
            >
              <Link to="/myorders" title={language.myorders}>
                <div className="desknav-img">
                  <img className="darker-version" src={mm2} />
                  <img className="lighter-version" src={mmlight2} />
                </div>
                <span>{language.myorders}</span>
                {parseFloat(this.state.overall_orders) > 0 && (
                  <span id="masterCount">{this.state.overall_orders}</span>
                )}
              </Link>
            </li>
            <li
              className={this.state.activePage === "buyagain" ? "active" : ""}
            >
              <Link to="/buyagain" title={language.buyagain}>
                <div className="desknav-img">
                  <img className="darker-version" src={mm2} />
                  <img className="lighter-version" src={mmlight2} />
                </div>
                <span>{language.buyagain}</span>
              </Link>
            </li>
            <li className={this.state.activePage === "rewards" ? "active" : ""}>
              <Link to="/rewards" title={language.reward}>
                <div className="desknav-img">
                  <img className="darker-version" src={mm3} />
                  <img className="lighter-version" src={mmlight3} />
                </div>
                <span>{language.reward}</span>
              </Link>
            </li>
            <li
              className={
                this.state.activePage === "subscription" ? "active" : ""
              }
            >
              <Link to="subscription" title={language.subscription}>
                <div className="desknav-img">
                  <img className="darker-version" src={mm5} />
                  <img className="lighter-version" src={mmlight5} />
                </div>
                <span>{language.subscription}</span>
              </Link>
            </li>
            <li
              className={
                this.state.activePage === "mypromotions" ? "active" : ""
              }
            >
              <Link to="/mypromotions" title={language.promotion}>
                <div className="desknav-img">
                  <img className="darker-version" src={promoimg} />
                  <img className="lighter-version" src={promoimglight} />
                </div>
                <span>{language.promotion}</span>
              </Link>
            </li>
            {/* <li>
              <a
                href="#change-password-popup"
                title="設定"
                className="open-popup-link"
              >
                <div className="desknav-img">
                  <img className="darker-version" src={mm5} />
                  <img className="lighter-version" src={mmlight5} />
                </div>
                <span>設定</span>
              </a>
            </li> */}
            <li>
              <Link to="/logout" title={language.logout}>
                <div className="desknav-img">
                  <img className="darker-version" src={mm6} />
                  <img className="lighter-version" src={mmlight6} />
                </div>
                <span>{language.logout}</span>
              </Link>
            </li>
          </ul>
        </div>

        <div
          id="change-password-popup"
          className="white-popup mfp-hide popup_sec changepw_popup"
        >
          <div className="pouup_in">
            <div id="form-msg"></div>
            <h3 className="title1 text-center">{language.changepassword}</h3>
            <Changepassword
              fields={this.state.fieldschpassword}
              onChange={this.fieldChange}
              onValid={this.changepassword}
              onInvalid={() => console.log("Form invalid!")}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activitycountNew: state.activitycount,
    changepassword: state.changepassword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
    getChangePassword: (formPayload) => {
      dispatch({ type: GET_CHANGEPASSWORD, formPayload });
    },
  };
};

Sidebar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
