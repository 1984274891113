/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import HomeBanner from "./HomeSubCompo/HomeBanner";
import MenuNavigation from "../Products/MenuNavigation";
import Partyproducts from "./HomeSubCompo/Partyproducts";
import Testimonial from "./HomeSubCompo/Testimonial";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import smartPhone from "../../common/images/smart-phone.png";

import member from "../../common/images/membership.svg";
import badge1 from "../../common/images/badge1.png";
import badge2 from "../../common/images/badge2.png";
import badge3 from "../../common/images/badge3.png";
import coocjapan1 from "../../common/images/cooc-japan1.jpg";
import coocjapan from "../../common/images/cooc-japan.jpg";
import medal from "../../common/images/medal.png";
import { language } from "../Helpers/Language";
import {
  appId,
  apiUrl,
  deliveryId,
  pickupId,
  headerconfig,
  cookieConfig,
} from "../Helpers/Config";
import {
  GET_GLOBAL_SETTINGS,
  GET_STATIC_BLOCK,
  GET_NORMAL_POPUP,
  GET_MENU_NAVIGATION,
  GET_PROMOTIONLIST,
} from "../../actions";
import {
  showLoader,
  hideLoader,
  validateEmailFun,
  showCustomAlert,
} from "../Helpers/SettingHelper";

import newletterImg from "../../common/images/newletter-bg.jpg";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import { lang } from "moment";
var Parser = require("html-react-parser");
const settingsJourney = {
  items: 1,
  autoplay: false,
  loop: true,
  dots: true,
  nav: false,
};
class Home extends Component {
  constructor(props) {
    super(props);

    var normalpopupFlg = "initial";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes" ||
      cookie.load("promoPopupTrigger") === "Yes"
    ) {
      normalpopupFlg = "trigger";
    }

    this.state = {
      users: [],
      defaultAvilablityId:
        cookie.load("defaultAvilablityId") !== "" &&
        typeof cookie.load("defaultAvilablityId") !== undefined &&
        typeof cookie.load("defaultAvilablityId") !== "undefined"
          ? cookie.load("defaultAvilablityId")
          : "",
      nextavail: "",
      cartTriggerFlg: "No",
      staticblacks: [],
      viewProductFlg: "no",
      viewProductSlug: "",
      normalpopuplist: [],
      normalpopupdata: [],
      normalpopup_status: "no",
      normalpopup_flg: normalpopupFlg,
      normalpopupstatus: "initial",
      promo_mail_id: "",
      displayType: "all",
      pickup_outlet: [],
      openOutletTime: false,
      promotions: [],
      display_Promo: "",

      featuredproducts: "",
      homeanimate: "",
      homeAbout: "",
      homeAboutImg: [],
      homeAboutImgPath: "",

      sojuparty: "",
      homeTestmonial: "",
      customerId:
        cookie.load("UserId") !== "" &&
        typeof cookie.load("UserId") !== "undefined" &&
        typeof cookie.load("UserId") !== undefined
          ? cookie.load("UserId")
          : "",
    };

    this.props.getSettings();
    this.props.getMenuNavigation();
    this.props.getNormalPopup();
    this.props.getStaticBlock();
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.staticblacks) {
      var homeAbout = "";
      var homeAboutImg = [];
      var homeAboutImgPath = "";
      var featuredproducts = "";
      var homeanimate = "";
      var sojuparty = "";
      var homeTestmonial = "";

      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data) => {
          if (data.staticblocks_slug === "home-about-us") {
            homeAbout = data.staticblocks_description;
            homeAboutImg = data.gallery_images;
            homeAboutImgPath = data.gallery_image_path;
            return "";
          }
          if (data.staticblocks_slug === "featured-products") {
            featuredproducts = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-animate") {
            homeanimate = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-soju-party-bundles") {
            sojuparty = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-testimonial") {
            homeTestmonial = data.staticblocks_description;
            return "";
          }
        });
      }
      homeAbout =
        homeAbout !== "" && homeAbout !== null ? Parser(homeAbout) : homeAbout;
      featuredproducts =
        featuredproducts !== "" && featuredproducts !== null
          ? Parser(featuredproducts)
          : featuredproducts;
      homeanimate =
        homeanimate !== "" && homeanimate !== null
          ? Parser(homeanimate)
          : homeanimate;

      sojuparty =
        sojuparty !== "" && sojuparty !== null ? Parser(sojuparty) : sojuparty;
      homeTestmonial =
        homeTestmonial !== "" && homeTestmonial !== null
          ? Parser(homeTestmonial)
          : homeTestmonial;

      this.setState(
        {
          staticblacks: PropsData.staticblack,
          featuredproducts: featuredproducts,
          homeAbout: homeAbout,
          homeAboutImg: homeAboutImg,
          homeAboutImgPath: homeAboutImgPath,
          homeanimate: homeanimate,
          sojuparty: sojuparty,
          homeTestmonial: homeTestmonial,
        },
        function () {
          if (
            cookie.load("UserId") !== "" &&
            typeof cookie.load("UserId") !== undefined &&
            typeof cookie.load("UserId") !== "undefined"
          ) {
            $(".premium-member-action").hide();
          }
        }
      );
    }

    if (
      PropsData.normalpopuplist !== this.state.normalpopuplist &&
      this.state.normalpopup_flg === "initial"
    ) {
      var normalpopupMain = PropsData.normalpopuplist,
        normalpopupdata = [],
        normalpopupStatus = "no";
      if (normalpopupMain !== "") {
        if (Object.keys(normalpopupMain).length > 0) {
          var normalpopupIds = cookie.load("normalpopupIds");
          var normalpopupIdArr =
            normalpopupIds != "" && normalpopupIds != undefined
              ? normalpopupIds.split("~~")
              : Array();
          if (
            $.inArray(normalpopupMain[0].normalpopup_id, normalpopupIdArr) ===
            -1
          ) {
            normalpopupdata = normalpopupMain[0];
            normalpopupStatus = "yes";
          }
          this.setState({
            normalpopuplist: normalpopupMain,
            normalpopupdata: normalpopupdata,
            normalpopup_status: normalpopupStatus,
            normalpopup_flg: "trigger",
          });
        }
      }
    }

    if (PropsData.normalpopupstatus !== this.state.normalpopupstatus) {
      this.setState({ normalpopupstatus: PropsData.normalpopupstatus });
    }

    if (PropsData.promotionlist !== this.props.promotionlist) {
      if (PropsData.promotionlist[0].status === "ok") {
        this.setState({ status: PropsData.promotionlist[0].status });
        this.setState(
          {
            promotions: PropsData.promotionlist[0].result_set.my_promo,
          },
          function () {
            this.displayPromo();
          }
        );
      }
    }
  }

  displayPromo() {
    if (this.state.promotions.length > 0) {
      let displayPromo = this.state.promotions.map(function (item, index) {
        return (
          <div className="notify-box" key={index}>
            <div className="left">
              <div className="notify-icon">
                <i className="icon"></i>
              </div>
            </div>
            <div className="right">
              <div className="notify-cnt">
                <h5>{item.promo_code}</h5>
                {item.promo_desc !== "" ? Parser(item.promo_desc) : ""}
              </div>
            </div>
          </div>
        );
      });
      this.setState({ display_Promo: displayPromo });
    }
  }

  chooseAvailability(availability) {
    var defaultAvilTy = cookie.load("defaultAvilablityId");
    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      popupIdtxt = "#delevery-popup";
    } else if (availability === pickupId) {
      popupIdtxt = "#takeaway-popup";
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  componentDidMount() {
    var customerParam = "";
    if (
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
    ) {
      var access_token =
        cookie.load("access_token") !== "" &&
        typeof cookie.load("access_token") !== undefined &&
        typeof cookie.load("access_token") !== "undefined"
          ? "&access_token=" + cookie.load("access_token")
          : "";
      customerParam = "&customer_id=" + cookie.load("UserId") + access_token;
    }

    this.props.getPromotionList(customerParam);

    if (
      cookie.load("triggerAvlPop") != "" &&
      cookie.load("triggerAvlPop") != undefined
    ) {
      var availabilityId = cookie.load("triggerAvlPop");
      cookie.remove("triggerAvlPop", cookieConfig);
      this.chooseAvailability(availabilityId);
    }

    if (cookie.load("orderPopuptrigger") === "Yes") {
      cookie.remove("orderPopuptrigger", cookieConfig);
      $.magnificPopup.open({
        items: {
          src: "#delevery-postcode-popup",
        },
        type: "inline",
      });
    }

    if (cookie.load("loginpopupTrigger") === "Yes") {
      cookie.save("loginpopupTrigger", "fromcheckout", cookieConfig);
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    var RctThis = this;
    $("body")
      .off("click", ".act_order_popup")
      .on("click", ".act_order_popup", function (e) {
        e.preventDefault();

        var odrType = $(this).attr("data-acturl");
        var availabilityId = "";
        if (odrType === "delivery") {
          availabilityId = deliveryId;
        } else if (odrType === "pickup") {
          availabilityId = pickupId;
        }

        if (availabilityId !== "") {
          RctThis.chooseAvailability(availabilityId);
        } else if (availabilityId === "" && odrType === "ordernow") {
          $.magnificPopup.open({
            items: {
              src: "#delevery-postcode-popup",
            },
            type: "inline",
          });
        }
      });
    $("body")
      .off("click", ".sign_up_popup")
      .on("click", ".sign_up_popup", function (e) {
        e.preventDefault();
        $.magnificPopup.open({
          items: {
            src: "#signup-popup",
          },
          type: "inline",
        });
      });
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    } else if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductFlg: "yes", viewProductSlug: value },
        function () {
          this.openProDetailPopup();
        }.bind(this)
      );
    } else if (field === "view_pro_upate" && value !== "") {
      this.setState({ viewProductFlg: value });
    } else if (field === "pickup_outlet" && value !== "") {
      this.setState({ pickup_outlet: value });
    } else if (field === "openOutletTime" && value !== "") {
      this.setState({ openOutletTime: value }, function () {});
    }
  };

  openProDetailPopup() {
    showLoader("comboPro-" + this.state.viewProductSlug, "Idtext");
    $("#ProductDetailMdl").modal({ backdrop: "static", keyboard: false });
  }

  triggerNormalPopup(trgType) {
    var otherPageActTrigger = "no";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes"
    ) {
      otherPageActTrigger = "yes";
    }

    if (
      trgType === "loading" &&
      otherPageActTrigger === "no" &&
      this.state.normalpopup_status === "yes" &&
      Object.keys(this.state.normalpopupdata).length > 0 &&
      cookie.load("promoPopupTrigger") !== "Yes"
    ) {
      var normalpopupIds = cookie.load("normalpopupIds");
      var normalpopupIdsNew =
        normalpopupIds != "" && normalpopupIds != undefined
          ? normalpopupIds + "~~" + this.state.normalpopupdata.normalpopup_id
          : this.state.normalpopupdata.normalpopup_id;
      var normalpopupIdArr = [];
      normalpopupIdArr["normalpopupids"] = normalpopupIdsNew;
      cookie.save("normalpopupIds", normalpopupIdsNew, cookieConfig);
      var $_this_rec = this;
      $.magnificPopup.open({
        items: {
          src: "#normal-popup",
        },
        type: "inline",
        midClick: true,
        closeOnBgClick: false,
        callbacks: {
          close: function () {
            $_this_rec.normalPopupUpdate();
          },
        },
      });
    }

    if (
      (cookie.load("promoPopupTrigger") === "Yes" ||
        (otherPageActTrigger === "no" &&
          this.state.normalpopup_status === "no" &&
          Object.keys(this.state.normalpopupdata).length === 0)) &&
      cookie.load("mailpopopuptrg") !== "yes"
    ) {
      cookie.save("mailpopopuptrg", "yes", cookieConfig);
      cookie.remove("promoPopupTrigger", cookieConfig);
    }
  }

  normalPopupUpdate() {
    if (cookie.load("mailpopopuptrg") !== "yes") {
      this.props.history.push("/refpage/promopopup");
    }
  }

  handleEmailChange(event) {
    this.setState({ promo_mail_id: event.target.value, promomail_error: "" });
  }

  sendPromoMailFun() {
    var promoEmail = this.state.promo_mail_id;

    var mailErrorTxt = "";
    if (promoEmail === "") {
      mailErrorTxt = language.emailrequired;
    } else if (!validateEmailFun(promoEmail)) {
      mailErrorTxt = language.invalidemail;
    }

    if (mailErrorTxt !== "") {
      this.setState({ promomail_error: mailErrorTxt });
      return false;
    } else {
      showLoader("promomailpopup-cls", "class");

      var qs = require("qs");
      var postObject = {
        app_id: appId,
        email_address: promoEmail,
      };

      axios
        .post(
          apiUrl + "promotion/user_promotion",
          qs.stringify(postObject),
          headerconfig
        )
        .then((response) => {
          hideLoader("promomailpopup-cls", "class");
          $.magnificPopup.close();
          if (response.data.status === "ok") {
            showCustomAlert("success", language.homecheckemail);
          } else {
            var errMsgtxt =
              response.data.message !== ""
                ? response.data.message
                : language.didnotusepromo;
            showCustomAlert("error", errMsgtxt);
          }
          return false;
        });
    }

    return false;
  }

  setdisplayType(displayType) {
    this.setState({ displayType: displayType });
  }

  changeAvailability(avilablity, event) {
    event.preventDefault();
    if (this.state.defaultAvilablityId !== avilablity) {
      $("#warning_avilablity").val(avilablity);
      $.magnificPopup.open({
        items: {
          src: "#warning-popup",
        },
        type: "inline",
      });
      return false;
    } else {
      this.props.history.push("/products");
    }
  }

  render() {
    let settingsArr = this.props.globalsettings;
    var showPromoPopup = "",
      showNormalPopup = "";
    if (Object.keys(settingsArr).length > 0) {
      if (Object.keys(settingsArr[0].result_set).length > 0) {
        if (settingsArr[0].result_set.client_promocode_enable === "1") {
          showNormalPopup =
            settingsArr[0].result_set.client_promo_code_normal_popup_enable ===
            "1"
              ? "yes"
              : "";
          showPromoPopup =
            settingsArr[0].result_set.client_promocode_options === "1"
              ? "yes"
              : "";
        }
      }
    }

    return (
      <div className="home-main-div">
        {/* Header section */}
        <Header
          homePageState={this.state}
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
        />
        {/* Home banner section */}
        <HomeBanner />

        <section className="product-home-category">
          <div className="container">
            <div className="normal-menu-nav">
              <MenuNavigation
                {...this.props}
                productState={this.state}
                sateValChange={this.sateValChange}
                includepage={"home"}
              />
            </div>
          </div>
        </section>
        <Partyproducts
          {...this.props}
          sateValChange={this.sateValChange}
          featuredproducts={this.state.featuredproducts}
        />
        {this.state.homeanimate}

        <section className="loyal-parent">
          <div className="container">
            <div className="loyalbar">
              <div className="loyal-round1"></div>
              <div className="loyal-round2"></div>
              <div className="loyal-round3"></div>
              <div className="loyal-round4"></div>
              <div className="loyalbar-top">
                <div className="loyalbar-lhs">
                  <img className="member-address" src={medal} alt="Member" />
                  <h3>{language.joinus}</h3>
                  <h2>{language.asamember}</h2>
                </div>

                <div className="loyalbar-hbtm textcenter">
                  <p>{language.earnrewarhome}</p>
                  {this.state.customerId !== "" ? (
                    <Link to={"/rewards"} className="gradient-btn">
                      {language.readmore}
                    </Link>
                  ) : (
                    <a
                      href="#login-popup"
                      data-effect="mfp-zoom-in"
                      className="gradient-btn open-popup-link"
                    >
                      {language.homesignupnow}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="home-our-journey">
          <div className="container">
            <div className="hoj-inner">
              {this.state.homeAbout}

              <div className="hoj-rhs">
                <OwlCarousel options={settingsJourney}>
                  {this.state.homeAboutImg.length > 0 ? (
                    this.state.homeAboutImg.map((item, index) => {
                      return (
                        <div key={index}>
                          {" "}
                          <img src={this.state.homeAboutImgPath + item} />
                        </div>
                      );
                    })
                  ) : (
                    <div>
                      <img src={coocjapan} />
                    </div>
                  )}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </section>
        {/* <Testimonial homeTestmonial={this.state.homeTestmonial} /> */}

        {/* Footer section */}
        <Footer />

        {/*Normal popup Start*/}
        <div
          id="normal-popup"
          className="white-popup mfp-hide popup_sec normal-popup"
        >
          <div className="normal_popup_alert">
            {this.state.normalpopup_status === "yes" &&
              Object.keys(this.state.normalpopupdata).length > 0 && (
                <div className="normal_popup_cont">
                  {Parser(this.state.normalpopupdata.normalpopup_description)}
                </div>
              )}
          </div>
        </div>
        {/*Normal popup - End*/}

        {/*Promo Check Email popup Start*/}
        <div
          id="promo-check-popup"
          className="white-popup mfp-hide popup_sec promo-check-popup"
        >
          <div className="promopopup-maindiv">
            <div className="promopopup-lhs">
              <div className="frm-action-div">
                <div className="frm-top-title">
                  <h3>{language.joinmaillist}</h3>
                  <p>{language.enteremailpromo}</p>
                </div>

                <div className="frm-inputbtn-div">
                  <div className="form-group">
                    <div className="focus-out">
                      <label>{language.email}</label>
                      <input
                        type="email"
                        className="form-control input-focus"
                        value={this.state.promo_mail_id}
                        onChange={this.handleEmailChange.bind(this)}
                      />
                      {this.state.promomail_error !== "" && (
                        <div id="promomail-error">
                          {this.state.promomail_error}
                        </div>
                      )}
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={this.sendPromoMailFun.bind(this)}
                    className="button promomailpopup-cls"
                  >
                    {language.subscribe}
                  </button>
                </div>

                <div className="frm-bottom-text">
                  <p>{language.limitedupdate}</p>
                </div>
              </div>
            </div>

            <div className="promopopup-rhs">
              <img src={newletterImg} />
            </div>
          </div>
        </div>
        {/*Promo Check Email popup - End*/}

        {this.state.normalpopupstatus !== "initial" &&
          (showNormalPopup === "yes" || showPromoPopup === "yes") &&
          this.triggerNormalPopup("loading")}

        <div
          id="vouchergift-popup"
          className="mfp-hide popup_sec vouchergift-popup"
        >
          <div className="pop-whole full-login-new">
            <div className="full-login-new-header">
              <h3>{language.buythis}</h3>
            </div>
            <div className="full-login-new-body">
              <div className="popup-footer voucher-popup-body">
                <Tabs>
                  <TabList>
                    <Tab>{language.forgift}</Tab>
                    <Tab>{language.forme}</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="voucher-popup-inner">
                      <h2>{language.giftrecipientinfo}</h2>
                      <div className="form-group">
                        <div className="focus-out">
                          <label>{language.name}</label>
                          <input
                            type="text"
                            className="form-control input-focus"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="focus-out">
                          <label>{language.entermobile}</label>
                          <input
                            type="tel"
                            className="form-control input-focus"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="focus-out">
                          <label>{language.email}</label>
                          <input
                            type="text"
                            className="form-control input-focus"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="focus-out">
                          <label>{language.mesage}</label>
                          <textarea
                            type="text"
                            className="form-control input-focus"
                          />
                        </div>
                      </div>

                      <div className="howtouse-gift-vouchar">
                        <img src={smartPhone} />
                        <h3>{language.howtouse}</h3>
                        <p>
                          {language.giftdesc}
                          {language.giftdesc1}
                          {language.giftdesc2}
                          {language.giftdesc3}{" "}
                        </p>
                      </div>

                      <div className="form-group gift-group">
                        <button type="button" className="button">
                          {language.addtocart}
                        </button>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="voucher-popup-inner">
                      <button type="button" className="button">
                        {language.addtocart}
                      </button>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  var normalPopupStatus = "initial";
  var normalPopupArr = Array();
  if (Object.keys(state.normalpopuplist).length > 0) {
    if (state.normalpopuplist[0].status === "ok") {
      normalPopupArr = state.normalpopuplist[0].result_set;
      normalPopupStatus = "ok";
    } else {
      normalPopupStatus = "error";
    }
  }

  var tempArr = Array();
  var navigateRst = Array();
  var navigateCmn = Array();
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
      }
    }
  }
  var promoSource = "";
  if (Object.keys(state.promotionlist).length > 0) {
    promoSource = state.promotionlist[0].common.promo_image_source;
  }
  return {
    globalsettings: state.settings,
    categorylist: state.categorylist,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    staticblack: blacksArr,
    normalpopuplist: normalPopupArr,
    normalpopupstatus: normalPopupStatus,
    promotionlist: state.promotionlist,
    promoSource: promoSource,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getPromotionList: (customerParam) => {
      dispatch({ type: GET_PROMOTIONLIST, customerParam });
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    getNormalPopup: () => {
      dispatch({ type: GET_NORMAL_POPUP });
    },
    getMenuNavigation: () => {
      dispatch({ type: GET_MENU_NAVIGATION });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Home);
