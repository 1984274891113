/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import cookie from "react-cookies";
import { connect } from "react-redux";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import Select from "react-select";
import { language } from "../Helpers/Language";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  headerconfig,
} from "../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showLoader,
  hideLoader,
  showCustomAlert,
  showAlert,
  removePromoCkValue,
  showPriceValueSub,
  emailValidate,
} from "../Helpers/SettingHelper";
import noimage from "../../common/images/no-img-product.png";
import smartPhone from "../../common/images/smart-phone.png";
import proVouchar from "../../common/images/pro-vouchar.png";
import cashVouchar from "../../common/images/cash-vouchar.png";
import cartLike from "../../common/images/cat-item-like-bw.svg";
import cartLikeActive from "../../common/images/cat-item-like-bw-active.svg";
import filter from "../../common/images/filter.svg";
import search from "../../common/images/loupe.svg";
import { GET_PRODUCT_LIST, GET_FAVOURITE } from "../../actions";
import { isNull } from "underscore";
var Parser = require("html-react-parser");
var qs = require("qs");
var settingsTestimonial = {
  items: 8,
  autoplay: false,
  nav: true,
  dots: false,
  margin: 10,
  responsive: {
    0: {
      items: 2,
    },
    360: {
      items: 3,
    },
    580: {
      items: 4,
    },
    980: {
      items: 6,
    },
    1280: {
      items: 8,
    },
  },
};
var settingsFilterDetails = {
  items: 5,
  autoplay: false,
  nav: true,
  dots: false,
  margin: 10,
  responsive: {
    0: {
      items: 2,
    },
    360: {
      items: 3,
    },
    500: {
      items: 3,
    },
    980: {
      items: 4,
    },
    1280: {
      items: 5,
    },
  },
};
class ProductList extends Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    const brand_filter = queryParams.get("brand_filter");
    var brandfilter = [];

    if (
      brand_filter !== "" &&
      brand_filter !== null &&
      typeof brand_filter !== undefined &&
      typeof brand_filter !== "undefined"
    ) {
      brandfilter = brand_filter.split(",");
    }
    console.log(brandfilter, "brand_filter");
    this.state = {
      selectedNavigation: "",
      selectedslugType: "",
      productSubcatlist: [],
      productCommon: [],
      productDetails: [],
      product_List: [],
      imagesource: "",
      viewProductSlug: "",
      OldupdateproductList: [],
      oldnavigateMenu: [],
      selProductEndSection: [],
      FinalDetailsProduct: [],
      voucherProductDetail: [],
      voucher_product_qty: "",
      voucher_name: "",
      voucher_mobile: "",
      voucher_email: "",
      voucher_message: "",
      error_voucher_name: "",
      error_voucher_email: "",
      error_voucher_message: "",
      error_voucher_mobile: "",
      voucherIndexFlag: "",
      favproductID: [],
      favdeleteproductID: [],
      loading: true,
      startFilter: "No",
      sort_By_PriceName: language.sortby,
      stopFilterPrice: "No",
      showShortByPrice: false,
      showShortByCategory: false,
      sort_By_categoryName: language.sortbycategory,
      tagList: [],
      tagdisplay: "",
      tagdisplayMain: "",
      brandList: [],
      branddisplay: "",
      branddisplayMain: "",
      brandimage_source: "",
      filterCategorySelected: [],
      filterCategory: "",
      filterCategorykey: "",
      filtersubCategory: [],
      filtersubCategoryslug: [],
      filtersubCategorykey: [],
      filter_Tag: [],
      filter_Brand: brandfilter,
      filter_min_price: "",
      filter_max_price: "",
      totalFilterApplied: 0,
      searchkeywords: "",
      customerId:
        cookie.load("UserId") !== "" &&
        typeof cookie.load("UserId") !== "undefined" &&
        typeof cookie.load("UserId") !== undefined
          ? cookie.load("UserId")
          : "",
    };
    this.loadTagList();
    this.loadBrandList();
  }

  componentWillReceiveProps(nextProps) {
    let selectedNavigation = nextProps.productState.selectedNavigation;
    let slugType = nextProps.productState.catslugType;
    if (selectedNavigation !== this.state.selectedNavigation) {
      if (nextProps.match.path !== "/products") {
        $("html, body").animate(
          { scrollTop: $(".productlist-main-div").offset().top - 30 },
          1000
        );
      }
      if (selectedNavigation !== "") {
        var catSlug = selectedNavigation;
        var subcatSlug = "";
        if (slugType === "subcategory") {
          catSlug = "";
          subcatSlug = selectedNavigation;
        }
        this.props.getProductList(catSlug, subcatSlug, "", "");
      }
      const queryParams1 = new URLSearchParams(window.location.search);
      const brand_filter1 = queryParams1.get("brand_filter");
      var brandfilter1 = [];

      if (
        brand_filter1 !== "" &&
        brand_filter1 !== null &&
        typeof brand_filter1 !== undefined &&
        typeof brand_filter1 !== "undefined"
      ) {
        brandfilter1 = brand_filter1.split(",");
      }
      this.setState(
        {
          selectedNavigation: selectedNavigation,
          sort_By_category: selectedNavigation,
          selectedslugType: slugType,

          startFilter: "Yes",
          filter_Brand: brandfilter1,
          selectedNavigationFilter: "",
          sort_By_PriceName: "並び替え",
          showShortByPrice: false,
        },
        function() {
          this.restFilter("no");
        }
      );
    }
    /*  if (nextProps.productState.setFiltetTag === "Yes") {
      this.setState(
        { filter_Tag: nextProps.productState.filterTag },
        function () {
          this.productsubcatlist();
          this.props.sateValChange("setFiltetTag", "No");
        }
      );
    } */

    if (this.state.productSubcatlist !== nextProps.productSubcatlist) {
      this.setState(
        { productSubcatlist: nextProps.productSubcatlist, loading: false },
        function() {
          $(".products-list-ulmain .addcart_row").hide();
          $(".products-list-ulmain .order_nowdiv").show();
          this.productsubcatlist();
        }
      );
    }
  }

  componentDidMount() {
    var currentthis = this;
    $(document).click(function(e) {
      if (!$(e.target).is(".filters-lhs, .filters-lhs * ")) {
        currentthis.setState({ showShortByPrice: false });
      }
      if (!$(e.target).is(".filters-rhs, .filters-rhs * ")) {
        currentthis.setState({ showShortByCategory: false });
      }
    });
    $(document).ready(function() {
      $(".tab_item").click(function() {
        $(".product_tab_item").removeClass("tab_item_active");
        $(
          ".product_tab_item[data-id='" + $(this).attr("data-id") + "']"
        ).addClass("tab_item_active");
        $(".tab_item").removeClass("active");
        $(this)
          .parent()
          .find(".tab_item")
          .addClass("active");
      });
    });
    var currentthis = this;
    $(document).click(function(e) {
      if (!$(e.target).is(".sort_by_price, .sort_by_price * ")) {
        currentthis.setState({ showShortByPrice: false });
      }
      if (!$(e.target).is(".sort_by_category, .sort_by_category * ")) {
        currentthis.setState({ showShortByCategory: false });
      }
    });
  }

  loadTagList() {
    Axios.get(
      apiUrl + "products/product_tags?app_id=" + appId,
      headerconfig
    ).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ tagList: res.data.result_set }, function() {
          this.displayTag();
        });
      }
    });
  }

  displayTag() {
    let tagList = "";
    let tagListMain = "";
    if (this.state.tagList.length > 0) {
      tagList = this.state.tagList.map((item, index) => {
        return (
          <li
            key={index}
            className={
              this.state.filter_Tag.indexOf(item.pro_tag_name) >= 0
                ? "active"
                : ""
            }
          >
            <a
              href={void 0}
              onClick={this.checkaddFilter.bind(
                this,
                "tag",
                item.pro_tag_name,
                "P"
              )}
            >
              {item.pro_tag_name}
            </a>
          </li>
        );
      });
      tagListMain = this.state.tagList.map((item, index) => {
        return (
          <li
            key={index}
            className={
              this.state.filter_Tag.indexOf(item.pro_tag_name) >= 0
                ? "active"
                : ""
            }
          >
            <a
              href={void 0}
              onClick={this.checkaddFilter.bind(
                this,
                "tag",
                item.pro_tag_name,
                "M"
              )}
            >
              {item.pro_tag_name}
            </a>
          </li>
        );
      });
    }
    this.setState({
      tagdisplay: tagList,
      tagdisplayMain: tagListMain,
    });
  }

  loadBrandList() {
    Axios.get(apiUrl + "products/brand_tag?app_id=" + appId, headerconfig).then(
      (res) => {
        if (res.data.status === "ok") {
          this.setState(
            {
              brandList: res.data.result_set,
              brandimage_source: res.data.common,
            },
            function() {
              this.displayBrand();
            }
          );
        }
      }
    );
  }

  displayBrand() {
    let navigateMenuArr = this.props.navigateMenu;
    let brandListMain = "";
    let brandList = "";
    navigateMenuArr.map((loaddata) => {
      var filterCategorySelected = this.state.filterCategorySelected;
      var selectedNavigation = this.state.selectedNavigation;
      if (
        filterCategorySelected.length > 0 &&
        filterCategorySelected !== "" &&
        typeof filterCategorySelected !== undefined &&
        typeof filterCategorySelected !== "undefined"
      ) {
        var splitfilterCategorySelected = filterCategorySelected[0].value.split(
          "~"
        );
        selectedNavigation = splitfilterCategorySelected[1];
      }
      console.log(this.state.filter_Brand, "this.state.filter_Brand");
      if (selectedNavigation === loaddata.pro_cate_slug) {
        if (loaddata.brands.length > 0) {
          brandListMain = loaddata.brands.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  this.state.filter_Brand.indexOf(item.pro_brand_tag_id) >= 0
                    ? "active"
                    : ""
                }
              >
                <a
                  href={void 0}
                  onClick={this.checkaddFilter.bind(
                    this,
                    "brand",
                    item.pro_brand_tag_id,
                    "M"
                  )}
                >
                  <img
                    src={
                      item.pro_brand_tag_image !== "" &&
                      item.pro_brand_tag_image !== null
                        ? this.state.brandimage_source.image_source +
                          "/" +
                          item.pro_brand_tag_image
                        : noimage
                    }
                  />
                </a>
              </div>
            );
          });
          brandList = loaddata.brands.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  this.state.filter_Brand.indexOf(item.pro_brand_tag_id) >= 0
                    ? "active"
                    : ""
                }
              >
                <a
                  href={void 0}
                  onClick={this.checkaddFilter.bind(
                    this,
                    "brand",
                    item.pro_brand_tag_id,
                    "P"
                  )}
                >
                  <img
                    src={
                      item.pro_brand_tag_image !== "" &&
                      item.pro_brand_tag_image !== null
                        ? this.state.brandimage_source.image_source +
                          "/" +
                          item.pro_brand_tag_image
                        : noimage
                    }
                  />
                </a>
              </div>
            );
          });
        }
      }
    });

    /*  if (this.state.brandList.length > 0) {
      brandList = this.state.brandList.map((item, index) => {
        return (
          <div
            key={index}
            className={
              this.state.filter_Brand.indexOf(item.pro_brand_tag_id) >= 0
                ? "active"
                : ""
            }
          >
            <a
              href={void 0}
              onClick={this.checkaddFilter.bind(
                this,
                "brand",
                item.pro_brand_tag_id,
                "P"
              )}
            >
              <img
                src={
                  item.pro_brand_tag_image !== "" &&
                  item.pro_brand_tag_image !== null
                    ? this.state.brandimage_source.image_source +
                      "/" +
                      item.pro_brand_tag_image
                    : noimage
                }
              />
            </a>
          </div>
        );
      }); */
    /*  brandListMain = this.state.brandList.map((item, index) => {
        return (
          <div
            key={index}
            className={
              this.state.filter_Brand.indexOf(item.pro_brand_tag_id) >= 0
                ? "active"
                : ""
            }
          >
            <a
              href={void 0}
              onClick={this.checkaddFilter.bind(
                this,
                "brand",
                item.pro_brand_tag_id,
                "M"
              )}
            >
              <img
                src={
                  item.pro_brand_tag_image !== "" &&
                  item.pro_brand_tag_image !== null
                    ? this.state.brandimage_source.image_source +
                      "/" +
                      item.pro_brand_tag_image
                    : noimage
                }
              />
            </a>
          </div>
        );
      }); 
    }*/
    this.setState(
      {
        branddisplay: brandList,
        branddisplayMain: brandListMain,
      },
      function() {
        setTimeout(function() {
          if (
            $(".filter-middle .owl-stage-outer .owl-stage .owl-item .owl-stage")
              .length > 0
          ) {
            $(
              ".filter-middle  .owl-stage-outer .owl-stage .owl-item .owl-stage"
            ).each(function() {
              $(this)
                .parents(".owl-item")
                .hide();
            });
          }
        }, 10);
      }
    );
  }

  loadBrandFilter() {
    let navigateMenuArr = this.props.navigateMenu;
    let brandList = "";
    navigateMenuArr.map((loaddata, index1) => {
      if (index1 === parseInt(this.state.filterCategorykey)) {
        if (loaddata.brands.length > 0) {
          brandList = loaddata.brands.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  this.state.filter_Brand.indexOf(item.pro_brand_tag_id) >= 0
                    ? "active"
                    : ""
                }
              >
                <a
                  href={void 0}
                  onClick={this.checkaddFilter.bind(
                    this,
                    "brand",
                    item.pro_brand_tag_id,
                    "P"
                  )}
                >
                  <img
                    src={
                      item.pro_brand_tag_image !== "" &&
                      item.pro_brand_tag_image !== null
                        ? this.state.brandimage_source.image_source +
                          "/" +
                          item.pro_brand_tag_image
                        : noimage
                    }
                  />
                </a>
              </div>
            );
          });
        }
      }
    });

    this.setState({
      branddisplay: brandList,
    });
  }

  productsubcatlist() {
    var subcatlistArr = this.props.productSubcatlist;
    if (Object.keys(subcatlistArr).length > 0) {
      $("#no_product_error").hide();
      const productDetails = (
        <div className="innerproduct">
          <ul className="products-list-ulmain">
            {subcatlistArr.map((categories) => {
              if (categories.products.length > 0) {
                return this.productList(
                  categories.products,
                  categories.pro_subcate_slug
                );
              }
            })}
          </ul>
        </div>
      );
      this.setState({ productDetails: productDetails }, function() {
        setTimeout(function() {
          hideLoader("productlist-main-div", "class");
        }, 300);
        var totalProducts = 0;
        if ($(".innerproduct").length > 0) {
          $(".innerproduct").map(function() {
            if ($(this).find(".products-list-ulmain li").length > 0) {
              totalProducts++;
            }
          });
          if (totalProducts > 0) {
            $("#no_product_error").hide();
            $(".innerproduct").show();
          } else {
            $("#no_product_error").show();
            $(".innerproduct").hide();
          }
        }
      });
      return productDetails;
    } else {
      if (this.props.setnoProduct === "Yes") {
        $("#no_product_error").show();
        hideLoader("productlist-main-div", "class");
        $(".innerproduct").hide();
      }
    }
  }

  chkProStockCls(proSlug, Stock, checkLeadTime) {
    var searchProVal = this.props.productState.searchProVal;
    var actClstxt = searchProVal === proSlug ? " active" : "";

    if (checkLeadTime !== "") {
      actClstxt += " current-not-available";
    }

    var returnText =
      "products-single-li no-stock-product " + proSlug + actClstxt;
    if (Stock > 0) {
      returnText = "products-single-li " + proSlug + actClstxt;
    }
    return returnText;
  }

  /* Products List */
  productList(list, pro_subcate_slug) {
    var imageSource = this.props.productCommon.product_image_source;
    const listProduct = list.map((productDetail, index) => {
      var description =
        productDetail.product_short_description !== null &&
        productDetail.product_short_description !== ""
          ? Parser(stripslashes(productDetail.product_short_description))
          : "";

      var prodivId = "proIndex-" + productDetail.product_primary_id;
      var comboProId = "comboPro-" + productDetail.product_slug;
      let filter = this.checkFilterTag(productDetail);
      let checkLeadTime = this.checkLeadTime(productDetail.product_lead_time);
      if (filter === 1) {
        return (
          <li
            className={this.chkProStockCls(
              productDetail.product_slug,
              productDetail.product_stock,
              checkLeadTime
            )}
            id={prodivId}
            key={index}
          >
            {this.state.customerId !== "" && (
              <div>
                {this.state.favdeleteproductID.indexOf(
                  productDetail.product_primary_id
                ) >= 0 ? (
                  <a
                    href={void 0}
                    className="like"
                    onClick={this.addFavourite.bind(
                      this,
                      productDetail.product_primary_id,
                      "Yes"
                    )}
                  >
                    <img src={cartLike} />
                    <img src={cartLikeActive} className="active-hart" />
                  </a>
                ) : (!isNull(productDetail.fav_product_primary_id) &&
                    typeof productDetail.fav_product_primary_id !== undefined &&
                    typeof productDetail.fav_product_primary_id !==
                      "undefined") ||
                  this.state.favproductID.indexOf(
                    productDetail.product_primary_id
                  ) >= 0 ? (
                  <a
                    href={void 0}
                    className="like active"
                    onClick={this.addFavourite.bind(
                      this,
                      productDetail.product_primary_id,
                      "No"
                    )}
                  >
                    <img src={cartLike} />
                    <img src={cartLikeActive} className="active-hart" />
                  </a>
                ) : (
                  <a
                    href={void 0}
                    className="like"
                    onClick={this.addFavourite.bind(
                      this,
                      productDetail.product_primary_id,
                      "Yes"
                    )}
                  >
                    <img src={cartLike} />
                    <img src={cartLikeActive} className="active-hart" />
                  </a>
                )}
              </div>
            )}

            {productDetail.product_tag_info !== "" &&
              productDetail.product_tag_info !== null && (
                <div className="ribbon">
                  <span>{productDetail.product_tag_info}</span>
                </div>
              )}

            <Link
              to={
                "/products/" +
                this.state.selectedNavigation +
                "/" +
                pro_subcate_slug +
                "/" +
                productDetail.product_slug
              }
              onClick={this.viewProDetail.bind(
                this,
                productDetail,
                pro_subcate_slug
              )}
            >
              <div className="products-image-div">
                {productDetail.product_thumbnail !== "" ? (
                  <img
                    src={imageSource + "/" + productDetail.product_thumbnail}
                  />
                ) : productDetail.product_type !== "5" ? (
                  <img src={noimage} />
                ) : productDetail.product_voucher === "f" ? (
                  <img src={proVouchar} />
                ) : (
                  <img src={cashVouchar} />
                )}
              </div>
            </Link>

            <div className="product-info-div">
              <div className="product-title-maindiv">
                <div className="product-title">
                  <h5>
                    {productDetail.product_alias !== ""
                      ? stripslashes(productDetail.product_alias)
                      : stripslashes(productDetail.product_name)}
                  </h5>
                </div>
              </div>
              {description !== "" && description !== null && (
                <div className="product-short-description">
                  <p>{description}</p>
                </div>
              )}

              <div className="products-ordernow-action">
                <div className="products-ordernow-inner">
                  <div className="product-price">
                    {productDetail.product_specialprice_applicable === "yes"
                      ? parseFloat(productDetail.product_price) > 0 && (
                          <span className="price_disc">
                            {showPriceValueSub(
                              productDetail.product_price,
                              "Y"
                            )}
                          </span>
                        )
                      : parseFloat(productDetail.product_cost) > 0 && (
                          <span className="price_disc">
                            {showPriceValueSub(productDetail.product_cost, "Y")}
                          </span>
                        )}
                    <h3>
                      {productDetail.product_specialprice_applicable === "yes"
                        ? showPriceValueSub(
                            productDetail.product_special_price,
                            "Y"
                          )
                        : showPriceValueSub(productDetail.product_price, "Y")}
                    </h3>
                  </div>
                  {checkLeadTime !== "" ? (
                    <a
                      className="button order_nowdiv smiple_product_lk disbl_href_action"
                      href={void 0}
                    >
                      {language.buynow}
                    </a>
                  ) : productDetail.product_stock > 0 ||
                    productDetail.product_stock === null ? (
                    productDetail.product_type === "1" ||
                    productDetail.product_type === "5" ? (
                      <a
                        className="button order_nowdiv smiple_product_lk disbl_href_action"
                        href={"/products/" + productDetail.product_slug}
                        onClick={this.addToCartSimple.bind(
                          this,
                          productDetail,
                          "initial"
                        )}
                      >
                        {language.buynow}
                      </a>
                    ) : (
                      <a
                        href={"/products/" + productDetail.product_slug}
                        onClick={this.viewProDetail.bind(
                          this,
                          productDetail,
                          pro_subcate_slug
                        )}
                        title="Product Details"
                        id={comboProId}
                        className="button order_nowdiv compo_product_lk"
                      >
                        {language.buynow}
                      </a>
                    )
                  ) : (
                    <a
                      className="button order_nowdiv disabled disbl_href_action"
                      href="/"
                    >
                      {language.soldout}
                    </a>
                  )}

                  <div className="addcart_row addcart_done_maindiv">
                    <div className="addcart-row-child">
                      <div className="qty_bx">
                        <span
                          className="qty_minus"
                          onClick={this.proQtyAction.bind(
                            this,
                            productDetail.product_primary_id,
                            "decr"
                          )}
                        >
                          -
                        </span>
                        <input
                          type="text"
                          className="proqty_input"
                          readOnly
                          value="1"
                        />
                        <span
                          className="qty_plus"
                          onClick={this.proQtyAction.bind(
                            this,
                            productDetail.product_primary_id,
                            "incr"
                          )}
                        >
                          +
                        </span>
                      </div>
                      <button
                        className="btn btn_black"
                        onClick={this.addToCartSimple.bind(
                          this,
                          productDetail,
                          "done"
                        )}
                      >
                        {language.updatebuynow}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {checkLeadTime !== "" && (
              <div className="next-avail-data">{checkLeadTime}</div>
            )}
          </li>
        );
      }
    });

    return listProduct;
  }

  checkLeadTime(product_lead_time) {
    if (
      product_lead_time !== "" &&
      parseInt(product_lead_time) > 0 &&
      cookie.load("orderDateTime") !== "" &&
      typeof cookie.load("orderDateTime") !== undefined &&
      typeof cookie.load("orderDateTime") !== "undefined"
    ) {
      var addedData = new Date(
        new Date().getTime() + product_lead_time * 24 * 60 * 60 * 1000
      );
      var orderDateTime = new Date(
        cookie
          .load("orderDateTime")
          .split('"')
          .join("")
      );
      var selectedDate =
        orderDateTime.getDate() +
        "-" +
        orderDateTime.getMonth() +
        "-" +
        orderDateTime.getFullYear();
      var leadDate =
        addedData.getDate() +
        "-" +
        addedData.getMonth() +
        "-" +
        addedData.getFullYear();
      if (selectedDate !== leadDate) {
        /*    var date1 = new Date('7/11/2010');
          var date2 = new Date('12/12/2010'); */
        var diffDays = addedData.getDate() - orderDateTime.getDate();
        var result = "";
        var avail = "";
        if (cookie.load("defaultAvilablityId") === deliveryId) {
          avail = "Delivery";
        } else {
          avail = "Pickup";
        }
        if (diffDays === 1) {
          // result = "This item is available for next day " + avail + " only";
        } else {
          /*  result =
            "This item is available for " + leadDate + " " + avail + " only"; */
        }
        return result;
      }
    } else {
      return "";
    }
  }

  addFavourite(productID, fav_flag) {
    if (this.state.customerId !== "") {
      var formData = {
        app_id: appId,
        product_id: productID,
        fav_flag: fav_flag,
        customer_id: this.state.customerId,
        access_token:
          cookie.load("access_token") !== "" &&
          typeof cookie.load("access_token") !== undefined &&
          typeof cookie.load("access_token") !== "undefined"
            ? cookie.load("access_token")
            : "",
      };
      var favproductID = this.state.favproductID;
      var favdeleteproductID = this.state.favdeleteproductID;
      if (fav_flag === "Yes") {
        favproductID.push(productID);
        const index1 = favdeleteproductID.indexOf(productID);
        if (index1 > -1) {
          favdeleteproductID.splice(index1, 1);
        }
      } else {
        const index = favproductID.indexOf(productID);
        if (index > -1) {
          favproductID.splice(index, 1);
        }
        favdeleteproductID.push(productID);
      }
      this.setState(
        { favproductID: favproductID, favdeleteproductID: favdeleteproductID },
        function() {
          this.productsubcatlist(
            this.state.OldupdateproductList,
            this.state.oldnavigateMenu
          );
        }
      );

      var postObject = qs.stringify(formData);
      this.props.getFavouriteproducts(postObject);
    } else {
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }
  }

  checkFilterTag(productFilter) {
    var proTag = productFilter.product_tag;
    var proBrand = productFilter.product_brand_tag;
    var current = this;
    var result = 1;
    if (
      this.state.filter_Tag !== "" &&
      typeof this.state.filter_Tag !== undefined &&
      typeof this.state.filter_Tag !== "undefined" &&
      this.state.filter_Tag.length > 0
    ) {
      if (Object.keys(proTag).length > 0) {
        let tagAvil = 0;
        proTag.map(function(item) {
          if (current.state.filter_Tag.indexOf(item.pro_tag_name) >= 0) {
            tagAvil++;
          }
        });
        if (tagAvil > 0) {
          result = 1;
        } else {
          result = 0;
        }
      } else {
        result = 0;
      }
    } else {
      result = 1;
    }
    if (result === 1) {
      if (
        this.state.filter_Brand !== "" &&
        typeof this.state.filter_Brand !== undefined &&
        typeof this.state.filter_Brand !== "undefined" &&
        this.state.filter_Brand.length > 0
      ) {
        if (Object.keys(proBrand).length > 0) {
          let tagAvil = 0;
          proBrand.map(function(item) {
            if (
              current.state.filter_Brand.indexOf(item.pro_brand_tag_id) >= 0
            ) {
              tagAvil++;
            }
          });
          if (tagAvil > 0) {
            result = 1;
          } else {
            result = 0;
          }
        } else {
          result = 0;
        }
      } else {
        result = 1;
      }
    }
    if (result === 1) {
      if (this.state.filter_min_price !== "") {
        if (
          parseFloat(this.state.filter_min_price) <=
          parseFloat(productFilter.product_price)
        ) {
          result = 1;
        } else {
          result = 0;
        }
      } else {
        result = 1;
      }

      if (result === 1) {
        if (this.state.filter_max_price !== "") {
          if (
            parseFloat(this.state.filter_max_price) >=
            parseFloat(productFilter.product_price)
          ) {
            result = 1;
          } else {
            result = 0;
          }
        } else {
          result = 1;
        }
      }
    }
    if (result === 1) {
      if (this.state.searchkeywords !== "") {
        var productName =
          productFilter.product_alias !== ""
            ? stripslashes(productFilter.product_alias)
            : stripslashes(productFilter.product_name);
        if (
          productName
            .toLowerCase()
            .indexOf(this.state.searchkeywords.toLowerCase()) >= 0
        ) {
          result = 1;
        } else {
          result = 0;
        }
      } else {
        result = 1;
      }
    }
    return result;
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();

    if (productDetail.product_type === "5") {
      var IndexFlg = productDetail.product_primary_id;

      var proqtyQty = $("#proIndex-" + IndexFlg)
        .find(".proqty_input")
        .val();

      $.magnificPopup.open({
        items: {
          src: "#vouchergift-popup",
        },
        type: "inline",
      });
      this.setState({
        voucherProductDetail: productDetail,
        voucher_product_qty: proqtyQty,
        voucherIndexFlag: IndexFlg,
      });
    } else {
      /* if (
        cookie.load("orderDateTime") === "" ||
        typeof cookie.load("orderDateTime") === undefined ||
        typeof cookie.load("orderDateTime") === "undefined"
      ) {
        if (this.props.productState.pickup_outlet.length == 0) {
          $.magnificPopup.open({
            items: {
              src: "#takeaway-popup",
            },
            type: "inline",
          });
        } else if (this.props.productState.pickup_outlet.length > 1) {
          $.magnificPopup.open({
            items: {
              src: "#takeaway-popup",
            },
            type: "inline",
          });
        } else {
          this.props.sateValChange("openOutletTime", true);
        }
        return false;
      } */

      var IndexFlg = productDetail.product_primary_id;

      if (actionFlg === "initial") {
        $("#proIndex-" + IndexFlg).addClass("active");
        $("#proIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .hide();
        $("#proIndex-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .show();
        return false;
      } else {
        showLoader("proIndex-" + IndexFlg, "Idtext");
        var availabilityId = cookie.load("defaultAvilablityId");
        /*var availabilityId = deliveryId;*/
        var availabilityName =
          availabilityId === deliveryId ? "Delivery" : "Pickup";
        var isAddonProduct = "No";
        var productId = productDetail.product_id;
        var customerId = this.state.customerId;
        var proqtyQty = $("#proIndex-" + IndexFlg)
          .find(".proqty_input")
          .val();
        var specialprice_applicable =
          productDetail.product_specialprice_applicable;
        var productCategoryId = productDetail.product_category_id;
        var productSubcategoryId = productDetail.product_subcategory_id;
        var postObject = {};
        postObject = {
          app_id: appId,
          product_id: productId,
          product_qty: proqtyQty,
          product_type: 1,
          availability_id: availabilityId,
          availability_name: availabilityName,
          isAddonProduct: isAddonProduct,
          specialprice_applicable: specialprice_applicable,
          reference_id: customerId === "" ? getReferenceID() : "",
          customer_id: customerId,
          category_id: productCategoryId,
          subcategory_id: productSubcategoryId,
          access_token:
            cookie.load("access_token") !== "" &&
            typeof cookie.load("access_token") !== undefined &&
            typeof cookie.load("access_token") !== "undefined"
              ? cookie.load("access_token")
              : "",
        };
        $(".hcartdd_trigger").removeClass("bounce");
        Axios.post(
          apiUrlV2 + "cart/simpleCartInsert",
          qs.stringify(postObject),
          headerconfig
        ).then((res) => {
          $("#proIndex-" + IndexFlg).removeClass("active");
          hideLoader("proIndex-" + IndexFlg, "Idtext");
          $("#proIndex-" + IndexFlg)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndex-" + IndexFlg)
            .find(".smiple_product_lk")
            .show();
          if (res.data.status === "ok") {
            this.props.sateValChange("cartflg", "yes");
            removePromoCkValue();
            $(".hcartdd_trigger").addClass("bounce");
            /* this.handleShowAlertFun(
              "成功",
              "偉大な選択肢！カートにアイテムが追加されました。"
            ); */
            /*  showCustomAlert(
              "success",
              "偉大な選択肢！カートにアイテムが追加されました。",
              "cart-success-msg"
            ); */
          } else if (res.data.status === "error") {
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : language.itemnotadded;
            showCustomAlert("error", errMsgtxt);
            this.handleShowAlertFun("エラー", errMsgtxt);
          }
          return false;
        });
      }
    }
  }

  addToCartVoucher() {
    var error = 0;
    if (this.state.voucher_name === null || this.state.voucher_name === "") {
      error++;
      this.setState({
        error_voucher_name: Parser(
          '<span class="error">' + language.thisfieldrequired + "</span>"
        ),
      });
    } else {
      this.setState({ error_voucher_name: "" });
    }

    if (
      this.state.voucher_mobile === null ||
      this.state.voucher_mobile === ""
    ) {
      error++;
      this.setState({
        error_voucher_mobile: Parser(
          '<span class="error">' + language.thisfieldrequired + "</span>"
        ),
      });
    } else {
      this.setState({ error_voucher_mobile: "" });
    }

    if (this.state.voucher_email === null || this.state.voucher_email === "") {
      error++;
      this.setState({
        error_voucher_email: Parser(
          '<span class="error">' + language.thisfieldrequired + "</span>"
        ),
      });
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        this.state.voucher_email
      )
    ) {
      error++;
      this.setState({
        error_voucher_email: Parser(
          '<span class="error">' + language.thisnotvalidemail + "</span>"
        ),
      });
    } else {
      this.setState({ error_voucher_email: "" });
    }

    if (
      this.state.voucher_message === null ||
      this.state.voucher_message === ""
    ) {
      error++;
      this.setState({
        error_voucher_message: Parser(
          '<span class="error">' + language.thisfieldrequired + "</span>"
        ),
      });
    } else {
      this.setState({ error_voucher_message: "" });
    }

    if (error == 0) {
      var availabilityId = cookie.load("defaultAvilablityId");
      /*var availabilityId = deliveryId;*/
      var availabilityName =
        availabilityId === deliveryId ? "Delivery" : "Pickup";
      var isAddonProduct = "No";
      var productId = this.state.voucherProductDetail.product_id;
      var IndexFlg = this.state.voucherProductDetail.product_primary_id;
      var customerId = this.state.customerId;

      var TotalPrice =
        this.state.voucherProductDetail.product_price *
        this.state.voucher_product_qty;

      var ImagePath = this.state.voucherProductDetail.product_thumbnail;
      if (ImagePath !== "") {
        var postImagePath =
          this.state.productimagePath +
          this.state.voucherProductDetail.product_thumbnail;
      } else {
        postImagePath = "";
      }

      var postObject = {};
      postObject = {
        app_id: appId,
        product_id: this.state.voucherProductDetail.product_id,
        product_qty: this.state.voucher_product_qty,
        product_name: this.state.voucherProductDetail.product_name,
        product_sku: this.state.voucherProductDetail.product_sku,
        product_total_price: TotalPrice,
        product_unit_price: this.state.voucherProductDetail.product_price,
        product_remarks: "",
        product_type: 5,
        modifiers: "",
        menu_set_component: "",
        availability_id: availabilityId,
        product_image: postImagePath,
        product_edit_enable: "No",
        reference_id: customerId === "" ? getReferenceID() : "",
        customer_id: customerId,
        category_id: this.state.voucherProductDetail.product_category_id,
        subcategory_id: this.state.voucherProductDetail.product_subcategory_id,
        access_token:
          cookie.load("access_token") !== "" &&
          typeof cookie.load("access_token") !== undefined &&
          typeof cookie.load("access_token") !== "undefined"
            ? cookie.load("access_token")
            : "",
        voucher_gift_name:
          this.state.voucher_name !== ""
            ? this.state.voucher_name
            : cookie.load("UserFname"),
        voucher_gift_mobile:
          this.state.voucher_mobile !== "" ? this.state.voucher_mobile : "",
        voucher_gift_email:
          this.state.voucher_email !== ""
            ? this.state.voucher_email
            : cookie.load("UserEmail"),
        voucher_gift_message:
          this.state.voucher_message !== "" ? this.state.voucher_message : "",
        product_voucher_expiry_date:
          this.state.voucherProductDetail.product_voucher_expiry_date !== ""
            ? this.state.voucherProductDetail.product_voucher_expiry_date
            : "",
      };
      Axios.post(
        apiUrl + "cart/insert",
        qs.stringify(postObject),
        headerconfig
      ).then((res) => {
        $("#proIndex-" + this.state.voucherIndexFlag).removeClass("active");
        hideLoader("proIndex-" + this.state.voucherIndexFlag, "Idtext");
        $("#proIndex-" + this.state.voucherIndexFlag)
          .find(".addcart_done_maindiv")
          .hide();
        $("#proIndex-" + this.state.voucherIndexFlag)
          .find(".smiple_product_lk")
          .show();

        if (res.data.status === "ok") {
          this.setState({
            voucher_name: "",
            error_voucher_name: "",
            voucher_mobile: "",
            error_voucher_mobile: "",
            voucher_email: "",
            error_voucher_email: "",
            voucher_message: "",
            error_voucher_message: "",
          });
          this.props.sateValChange("cartflg", "yes");
          hideLoader("productlist-main-div", "class");
          removePromoCkValue();
          /*  this.handleShowAlertFun(
            "成功",
            "偉大な選択肢！カートにアイテムが追加されました。"
          ); */
          this.setState({
            voucherProductDetail: "",
            voucher_product_qty: "",
          });
        } else if (res.data.status === "error") {
          var errMsgtxt =
            res.data.message !== "" ? res.data.message : itemnotadded;
          showCustomAlert("error", errMsgtxt);
          this.handleShowAlertFun("エラー", errMsgtxt);
          this.setState({
            voucherProductDetail: "",
            voucher_product_qty: "",
            voucherIndexFlag: "",
          });
        }

        return false;
      });
    }
  }

  addToCartVoucherMe() {
    var availabilityId = cookie.load("defaultAvilablityId");
    /*var availabilityId = deliveryId;*/
    var availabilityName =
      availabilityId === deliveryId ? "Delivery" : "Pickup";
    var isAddonProduct = "No";
    var productId = this.state.voucherProductDetail.product_id;
    var customerId = this.state.customerId;

    var TotalPrice =
      this.state.voucherProductDetail.product_price *
      this.state.voucher_product_qty;

    var postObject = {};
    postObject = {
      app_id: appId,
      product_id: this.state.voucherProductDetail.product_id,
      product_qty: this.state.voucher_product_qty,
      product_type: 5,
      availability_id: availabilityId,
      product_name: this.state.voucherProductDetail.product_name,
      product_sku: this.state.voucherProductDetail.product_sku,
      product_total_price: TotalPrice,
      product_unit_price: this.state.voucherProductDetail.product_price,
      product_remarks: "",
      product_type: 5,
      modifiers: "",
      menu_set_component: "",
      availability_id: availabilityId,
      product_image: this.state.voucherProductDetail.product_thumbnail,
      product_edit_enable: "No",
      reference_id: customerId === "" ? getReferenceID() : "",
      customer_id: customerId,
      category_id: this.state.voucherProductDetail.product_category_id,
      subcategory_id: this.state.voucherProductDetail.product_subcategory_id,
      access_token:
        cookie.load("access_token") !== "" &&
        typeof cookie.load("access_token") !== undefined &&
        typeof cookie.load("access_token") !== "undefined"
          ? cookie.load("access_token")
          : "",
      voucher_gift_name:
        typeof cookie.load("UserFname") === "undefined"
          ? ""
          : cookie.load("UserFname"),
      voucher_gift_mobile:
        typeof cookie.load("UserMobile") === "undefined"
          ? ""
          : cookie.load("UserMobile"),
      voucher_gift_email:
        typeof cookie.load("UserEmail") === "undefined"
          ? ""
          : cookie.load("UserEmail"),
      voucher_gift_message: "",
      product_voucher_expiry_date:
        this.state.voucherProductDetail.product_voucher_expiry_date !== ""
          ? this.state.voucherProductDetail.product_voucher_expiry_date
          : "",
    };

    Axios.post(
      apiUrl + "cart/insert",
      qs.stringify(postObject),
      headerconfig
    ).then((res) => {
      $("#proIndex-" + this.state.voucherIndexFlag).removeClass("active");
      hideLoader("proIndex-" + this.state.voucherIndexFlag, "Idtext");
      $("#proIndex-" + this.state.voucherIndexFlag)
        .find(".addcart_done_maindiv")
        .hide();
      $("#proIndex-" + this.state.voucherIndexFlag)
        .find(".smiple_product_lk")
        .show();

      if (res.data.status === "ok") {
        this.props.sateValChange("cartflg", "yes");
        hideLoader("productlist-main-div", "class");
        removePromoCkValue();
        /* this.handleShowAlertFun(
          "成功",
          "偉大な選択肢！カートにアイテムが追加されました。"
        ); */
        this.setState({
          voucherProductDetail: "",
          voucher_product_qty: "",
        });
      } else if (res.data.status === "error") {
        var errMsgtxt =
          res.data.message !== "" ? res.data.message : language.itemnotadded;
        showCustomAlert("error", errMsgtxt);
        this.handleShowAlertFun("エラー", errMsgtxt);
        this.setState({
          voucherProductDetail: "",
          voucher_product_qty: "",
          voucherIndexFlag: "",
        });
      }

      return false;
    });
  }

  viewProDetail(productDetail, pro_subcate_slug, event) {
    event.preventDefault();

    this.props.history.push(
      "/products/" +
        this.state.selectedNavigation +
        "/" +
        pro_subcate_slug +
        "/" +
        productDetail.product_slug
    );
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.props.sateValChange("cartflg", value);
    }
    if (field === "selProductEndSection") {
      this.setState({ selProductEndSection: "" }, function() {
        this.productsubcatlist(
          this.state.OldupdateproductList,
          this.state.oldnavigateMenu
        );
      });
    }
  };

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  handleChangeTxt = (item, event) => {
    if (item === "voucher_mobile") {
      const re = /^[0-9\b]+$/;
      if (event.target.value === "" || re.test(event.target.value)) {
        this.setState({ [item]: event.target.value });
      }
      this.setState({ ["error_" + item]: "" });
    } else if (item === "voucher_email") {
      if (emailValidate(event.target.value) === true) {
        this.setState({ ["error_" + item]: "" });
      } else {
        this.setState({
          ["error_" + item]: Parser(
            '<span class="error">' + language.thisnotvalidemail + "</span>"
          ),
        });
      }
      this.setState({ [item]: event.target.value });
    } else if (item === "filter_min_price") {
      this.setState({ filter_min_price: event.target.value });
    } else if (item === "filter_max_price") {
      this.setState({ filter_max_price: event.target.value });
    } else if (item === "product_search") {
      this.setState({ searchkeywords: event.target.value }, function() {
        this.productsubcatlist();
      });
    } else {
      this.setState({ [item]: event.target.value });
      this.setState({ ["error_" + item]: "" });
    }
  };
  handleChange = (item, value) => {
    if (item === "category") {
      var fcategory = value.value.split("~");
      this.setState(
        {
          filterCategorySelected: [{ value: value.value, label: value.label }],
          filterCategory: fcategory[1],
          filterCategorykey: fcategory[0],
          filtersubCategory: [],
          filtersubCategoryslug: [],
          filtersubCategorykey: [],
        },
        function() {
          this.loadBrandFilter();
        }
      );
    } else if (item === "subcategory") {
      var fsubcategory = value.value.split("~");
      var filtersubCategory = this.state.filtersubCategory;

      if (filtersubCategory.indexOf(fsubcategory[2]) >= 0) {
        const index = filtersubCategory.indexOf(fsubcategory[2]);
        filtersubCategory.splice(index, 1);
      } else {
        filtersubCategory.push(fsubcategory[2]);
      }
      this.setState({
        filtersubCategory: filtersubCategory,
        filtersubCategoryslug: fsubcategory[1],
        filtersubCategorykey: fsubcategory[0],
      });
    }
  };

  clearSubcat(currentsubcate) {
    this.setState(
      {
        filtersubCategory: [],
      },
      function() {
        this.loadBrandFilter();
      }
    );
  }

  checkaddFilter(type, value, displaySection) {
    if (type === "tag") {
      var filter_Tag = this.state.filter_Tag;
      if (filter_Tag.indexOf(value) >= 0) {
        const index = filter_Tag.indexOf(value);
        filter_Tag.splice(index, 1);
      } else {
        filter_Tag.push(value);
      }
      this.setState({ filter_Tag: filter_Tag }, function() {
        this.displayTag();
        this.checkTotalFilter();
        if (displaySection === "M") {
          this.productsubcatlist();
        }
      });
    } else if (type === "brand") {
      var filter_Brand = this.state.filter_Brand;
      if (filter_Brand.indexOf(value) >= 0) {
        const index = filter_Brand.indexOf(value);
        filter_Brand.splice(index, 1);
      } else {
        filter_Brand.push(value);
      }
      this.setState({ filter_Brand: filter_Brand }, function() {
        this.displayBrand();
        this.checkTotalFilter();
        if (displaySection === "M") {
          this.productsubcatlist();
        }
      });
    }
  }
  checkTotalFilter() {
    var totalFilter = 0;
    if (this.state.filter_Tag.length > 0) {
      totalFilter++;
    }
    if (this.state.filter_Brand.length > 0) {
      totalFilter++;
    }
    if (
      this.state.filter_max_price !== "" ||
      this.state.filter_min_price !== ""
    ) {
      totalFilter++;
    }
    if (this.state.filterCategory !== "") {
      totalFilter++;
    }
    if (this.state.filtersubCategory.length > 0) {
      totalFilter++;
    }
    this.setState({ totalFilterApplied: totalFilter });
  }
  sortByPrice(sort, event) {
    event.preventDefault();
    var sort_By_PriceName = "";
    var sortKey = "product_price";
    var sortValue = sort;
    if (sort === "ASC") {
      sort_By_PriceName = language.pricelowtohigh;
    } else if (sort === "RECOMEND") {
      sort_By_PriceName = language.recommended;
      sortKey = "recomend";
    } else {
      sort_By_PriceName = language.pricehightolow;
    }
    showLoader("productlist-main-div", "class");

    this.setState(
      {
        sort_By_Price: sort,
        sort_By_PriceName: sort_By_PriceName,
        stopFilterPrice: "Yes",
      },
      function() {
        this.props.getProductList(
          this.state.selectedNavigation,
          "",
          sortKey,
          sortValue
        );
      }
    );
  }
  showSortBy(type) {
    if (type === "price") {
      if (this.state.showShortByPrice === true) {
        this.setState({ showShortByPrice: false });
      } else {
        this.setState({
          showShortByPrice: true,
          showShortByCategory: false,
          showShortByTag: false,
        });
      }
    } else if (type === "category") {
      if (this.state.showShortByCategory === true) {
        this.setState({ showShortByCategory: false });
      } else {
        this.setState({
          showShortByCategory: true,
          showShortByPrice: false,
          showShortByTag: false,
        });
      }
    } else if (type === "tag") {
      if (this.state.showShortByTag === true) {
        this.setState({ showShortByTag: false });
      } else {
        this.setState({
          showShortByTag: true,
          showShortByCategory: false,
          showShortByPrice: false,
        });
      }
    } else if (type === "brandtag") {
      if (this.state.showShortByBrandTag === true) {
        this.setState({ showShortByBrandTag: false });
      } else {
        this.setState({
          showShortByBrandTag: true,
          showShortByTag: false,
          showShortByCategory: false,
          showShortByPrice: false,
        });
      }
    }
  }
  sortByCategories(categorySlug, categoryName, event) {
    event.preventDefault();
    if (this.state.selectedNavigation !== categorySlug) {
      this.setState(
        {
          sort_By_category: categorySlug,
          sort_By_categoryName: categoryName,
        },
        function() {
          this.props.history.push("/products" + "/category/" + categorySlug);
        }
      );
    }
  }

  morefilter() {
    $("#more-filter-popup").addClass("open");
    $("body").addClass("filter-open");
    /*  $.magnificPopup.open({
      items: {
        src: "#more-filter-popup",
      },
      type: "inline",
      midClick: true,
      closeOnBgClick: false,
    }); */
  }
  restFilter(resetTag = "") {
    this.setState(
      {
        filterCategorySelected: [],
        filterCategory: "",
        filterCategorykey: "",
        filtersubCategory: [],
        filtersubCategoryslug: [],
        filtersubCategorykey: [],
        filter_Tag: [],
      },
      function() {
        if (resetTag == "") {
          this.setState({ filter_Brand: [] });
        }

        this.displayTag();
        this.displayBrand();
        this.checkTotalFilter();
        $("#more-filter-popup").removeClass("open");
        $("body")
          .removeClass("filter-open")
          .css("overflow", "hidden auto");
        this.productsubcatlist();
      }
    );
  }

  applyFilter() {
    this.checkTotalFilter();
    if (this.state.filterCategory !== "") {
      var applyBrand = "";
      if (this.state.filter_Brand.length > 0) {
        applyBrand = "?brand_filter=" + this.state.filter_Brand.join(",");
      }
      this.props.history.push(
        "/products" + "/category/" + this.state.filterCategory + applyBrand
      );
    } else {
      this.productsubcatlist();
    }
    $("#more-filter-popup").removeClass("open");
    $("body")
      .removeClass("filter-open")
      .css("overflow", "hidden auto");
    //$.magnificPopup.close();
  }
  openSearch() {
    if ($(".filter-search.expand-search").length > 0) {
      $(".filter-search").removeClass("expand-search");
    } else {
      $(".filter-search").addClass("expand-search");
    }
  }
  render() {
    var categoryList = [];
    var subcategoryList = [];
    if (Object.keys(this.props.navigateMenu).length > 0) {
      this.props.navigateMenu.map((item, index) => {
        categoryList.push({
          value: index + "~" + item.pro_cate_slug,
          label: stripslashes(item.menu_custom_title),
        });
        if (item.pro_cate_slug === this.state.filterCategory) {
          if (Object.keys(item.subcat_list_arr).length > 0) {
            item.subcat_list_arr.sub_result_set.map((scitem, scindex) => {
              subcategoryList.push({
                value:
                  scindex +
                  "~" +
                  scitem.pro_subcate_slug +
                  "~" +
                  scitem.pro_subcate_name,
                label: stripslashes(scitem.pro_subcate_name),
              });
            });
          }
        }
      });
    }
    return (
      <div>
        <div className="mobile-filter">
          <div className="filter-top sorting-filter">
            {/* this.state.tagdisplay !== "" && (
              <ul>{this.state.tagdisplayMain}</ul>
            ) */}

            <div className="filters-lhs ">
              <span>{language.sortby}</span>
              <span
                className="filter-act"
                onClick={this.showSortBy.bind(this, "price")}
              >
                {this.state.sort_By_PriceName}
              </span>
              <ul
                className={this.state.showShortByPrice === true ? "active" : ""}
              >
                <li
                  className={this.state.sort_By_Price === "ASC" ? "active" : ""}
                >
                  <a href={void 0} onClick={this.sortByPrice.bind(this, "ASC")}>
                    {language.pricelowtohigh}
                  </a>
                </li>
                <li
                  className={
                    this.state.sort_By_Price === "DESC" ? "active" : ""
                  }
                >
                  <a
                    href={void 0}
                    onClick={this.sortByPrice.bind(this, "DESC")}
                  >
                    {language.pricehightolow}
                  </a>
                </li>
                <li
                  className={
                    this.state.sort_By_Price === "RECOMEND" ? "active" : ""
                  }
                >
                  <a
                    href={void 0}
                    onClick={this.sortByPrice.bind(this, "RECOMEND")}
                  >
                    {language.recommended}
                  </a>
                </li>
              </ul>
            </div>

            <div className="filter-icon">
              <div className="filter-search">
                <div className="input-sec">
                  <img
                    className="top-pos"
                    src={search}
                    onClick={this.openSearch.bind(this)}
                  />
                </div>
                <div className="mobile-search-txt">
                  <input
                    type="text"
                    className="productsearch"
                    placeholder="ここに入力して検索..."
                    autoComplete="off"
                    onChange={this.handleChangeTxt.bind(this, "product_search")}
                  />
                </div>
              </div>
              <a
                className="filter-icon-tag"
                href={void 0}
                onClick={this.morefilter.bind(this)}
              >
                <img src={filter} alt="" />
                {this.state.totalFilterApplied > 0 && (
                  <span>{this.state.totalFilterApplied}</span>
                )}
              </a>
            </div>
          </div>
          <div className="filter-middle">
            <OwlCarousel options={settingsTestimonial}>
              {this.state.branddisplayMain !== "" &&
                this.state.branddisplayMain}
            </OwlCarousel>
          </div>
        </div>
        <div className="productlist-main-div">
          {/* <div className="product-filters">
            <div className="filters-lhs sort_by_price">
              <span>価格で並べ替え</span>
              <span
                className="filter-act"
                onClick={this.showSortBy.bind(this, "price")}
              >
                {this.state.sort_By_PriceName}
              </span>
              <ul
                className={this.state.showShortByPrice === true ? "active" : ""}
              >
                <li
                  className={this.state.sort_By_Price === "ASC" ? "active" : ""}
                >
                  <a href={void 0} onClick={this.sortByPrice.bind(this, "ASC")}>
                    低価格から高価格
                  </a>
                </li>
                <li
                  className={
                    this.state.sort_By_Price === "DESC" ? "active" : ""
                  }
                >
                  <a
                    href={void 0}
                    onClick={this.sortByPrice.bind(this, "DESC")}
                  >
                    価格高から低
                  </a>
                </li>
              </ul>
            </div>
            <div className="filters-rhs sort_by_category">
              <span>カテゴリ別に購入</span>
              <span
                className="filter-act"
                onClick={this.showSortBy.bind(this, "category")}
              >
                {this.state.sort_By_categoryName}
              </span>
              {this.props.navigateMenu.length > 0 && (
                <ul
                  className={
                    this.state.showShortByCategory === true ? "active" : ""
                  }
                >
                  {this.props.navigateMenu.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          this.state.sort_By_category === item.pro_cate_slug
                            ? "active"
                            : ""
                        }
                      >
                        <a
                          href={void 0}
                          onClick={this.sortByCategories.bind(
                            this,
                            item.pro_cate_slug,
                            item.menu_custom_title
                          )}
                        >
                          {item.menu_custom_title}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div> */}

          <div className="innerproduct_row">
            {/* this.state.product_List */}
            {this.state.productDetails}
            <div
              className="no-product"
              id="no_product_error"
              style={{ display: "none" }}
            >
              <div className="container">
                <h2>{language.productnotfound}</h2>
                <p>{language.norpoductmsg}</p>
              </div>
            </div>
          </div>
          <div
            id="vouchergift-popup"
            className="mfp-hide popup_sec vouchergift-popup"
          >
            <div className="pop-whole full-login-new">
              <div className="full-login-new-header">
                <h3>{language.buythis}</h3>
              </div>
              <div className="full-login-new-body">
                <div className="voucher-popup-body">
                  <div className="tab-menu-container">
                    <ul>
                      <li>
                        <a
                          href={void 0}
                          className="tab_item active"
                          data-id="productItem-1"
                        >
                          {language.forgift}
                        </a>
                      </li>
                      <li>
                        <a
                          href={void 0}
                          className="tab_item"
                          data-id="productItem-2"
                        >
                          {language.forme}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="product_choosen_row_inner product_tab_item tab_item_active"
                    data-id="productItem-1"
                  >
                    <div className="voucher-popup-inner">
                      <h2>{language.giftrecipientinfo}</h2>
                      <div className="form-group">
                        <div
                          className={
                            this.state.voucher_name !== ""
                              ? "focus-out focused"
                              : "focus-out"
                          }
                        >
                          <label>{language.firstname}</label>
                          <input
                            type="text"
                            id="guest-name"
                            name="guest-name"
                            value={this.state.voucher_name}
                            onChange={this.handleChangeTxt.bind(
                              this,
                              "voucher_name"
                            )}
                            className="form-control input-focus"
                          />
                          {this.state.error_voucher_name}
                        </div>
                      </div>
                      <div className="form-group">
                        <div
                          className={
                            this.state.voucher_mobile !== ""
                              ? "focus-out focused"
                              : "focus-out"
                          }
                        >
                          <label>{language.entermobile}</label>
                          <input
                            type="tel"
                            id="guest-number"
                            name="guest-number"
                            value={this.state.voucher_mobile}
                            onChange={this.handleChangeTxt.bind(
                              this,
                              "voucher_mobile"
                            )}
                            className="form-control input-focus"
                          />
                          {this.state.error_voucher_mobile}
                        </div>
                      </div>
                      <div className="form-group">
                        <div
                          className={
                            this.state.voucher_email !== ""
                              ? "focus-out focused"
                              : "focus-out"
                          }
                        >
                          <label>{language.email}</label>
                          <input
                            type="text"
                            id="guest-email"
                            name="guest-email"
                            value={this.state.voucher_email}
                            onChange={this.handleChangeTxt.bind(
                              this,
                              "voucher_email"
                            )}
                            className="form-control input-focus"
                          />
                          {this.state.error_voucher_email}
                        </div>
                      </div>
                      <div className="form-group">
                        <div
                          className={
                            this.state.voucher_message !== ""
                              ? "focus-out focused"
                              : "focus-out"
                          }
                        >
                          <label>{language.message}</label>
                          <textarea
                            type="text"
                            id="guest-message"
                            name="guest-message"
                            value={this.state.voucher_message}
                            onChange={this.handleChangeTxt.bind(
                              this,
                              "voucher_message"
                            )}
                            className="form-control input-focus"
                          />
                          {this.state.error_voucher_message}
                        </div>
                      </div>

                      <div className="howtouse-gift-vouchar">
                        <img src={smartPhone} />
                        <h3>{language.howtouse}</h3>
                        <p>
                          {language.giftdesc}
                          {language.giftdesc1}
                          {language.giftdesc2}
                        </p>
                      </div>
                      <div className="form-group gift-group">
                        <button
                          type="button"
                          className="button"
                          onClick={this.addToCartVoucher.bind(this)}
                        >
                          {language.addtocart}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="product_choosen_row_inner product_tab_item"
                    data-id="productItem-2"
                  >
                    <div className="howtouse-gift-vouchar">
                      <img src={smartPhone} />
                      <h3>{language.howtouse}</h3>
                      <p>{language.youreciveemailnotification}</p>
                    </div>
                    <div className="voucher-popup-inner">
                      <button
                        type="button"
                        className="button"
                        onClick={this.addToCartVoucherMe.bind(this)}
                      >
                        {language.addtocart}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Filter popup - start */}
        <div id="more-filter-popup" className="filter-popup">
          <div className="order-body">
            <div className="order-body-flex">
              <div className="filter-popup-top">
                <h4>{language.filter}</h4>
                <div className="filter-icon">
                  <a href={void 0} className="filter-icon-tag">
                    <img src={filter} alt="" />
                    {this.state.totalFilterApplied > 0 && (
                      <span>{this.state.totalFilterApplied}</span>
                    )}
                  </a>
                </div>
              </div>
              <div className="filter-category">
                <div className="re_select">
                  <Select
                    options={categoryList}
                    placeholder={language.selectcategory}
                    value={this.state.filterCategorySelected}
                    onChange={this.handleChange.bind(this, "category")}
                  />
                </div>
              </div>
              <div className="filter-subcategory">
                <div className="re_select">
                  <Select
                    options={subcategoryList}
                    value=""
                    noOptionsMessage={() => language.nooption}
                    placeholder={language.selectsubcategory}
                    onChange={this.handleChange.bind(this, "subcategory")}
                  />
                </div>
              </div>
              <div className="filter-selected-item">
                <ul>
                  {this.state.filtersubCategory.map((item, index) => {
                    return (
                      <li key={index} className="active">
                        {item}{" "}
                        <a
                          className="selected-de"
                          href={void 0}
                          onClick={this.clearSubcat.bind(
                            this,
                            this.state.filtersubCategorykey[index]
                          )}
                        >
                          +
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {/* <div className="filter-top filter-pop-category">
                <h5>人気のタグ付け</h5>
                <ul>{this.state.tagdisplay}</ul>
              </div> */}
              {this.state.branddisplay !== "" && (
                <div className="filter-popup-slider">
                  <h5>ブランド</h5>
                  <OwlCarousel options={settingsFilterDetails}>
                    {this.state.branddisplay}
                  </OwlCarousel>
                </div>
              )}
              <div className="filter-bottom">
                <h5>{language.price}</h5>
                <div className="filter-bottom-inner">
                  <div className="min-max-input">
                    <div className="input-sec">
                      <input
                        type="text"
                        name="filter_min_price"
                        className="productsearch"
                        placeholder={language.min}
                        onChange={this.handleChangeTxt.bind(
                          this,
                          "filter_min_price"
                        )}
                        value={this.state.filter_min_price}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="min-max-input">
                    <div className="input-sec">
                      <input
                        type="text"
                        name="searchkey"
                        className="productsearch"
                        placeholder={language.max}
                        onChange={this.handleChangeTxt.bind(
                          this,
                          "filter_max_price"
                        )}
                        value={this.state.filter_max_price}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="filter-popup-button">
              <a
                href={void 0}
                onClick={this.restFilter.bind(this)}
                className="button"
              >
                {language.reset}
              </a>
              <a
                href={void 0}
                onClick={this.applyFilter.bind(this)}
                className="button"
              >
                {language.saveapply}
              </a>
            </div>
          </div>
        </div>
        {/* Filter popup - end */}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var productSubcat = Array();
  var productCmn = Array();
  var setnoProduct = "No";
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("productlist" in state.product[0])
      ? Array()
      : state.product[0].productlist;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        productSubcat = tempArr[0].result_set[0].subcategorie;
        productCmn = tempArr[0].common;
      } else {
        setnoProduct = "Yes";
      }
    }
  }

  return {
    productSubcatlist: productSubcat,
    productCommon: productCmn,
    setnoProduct: setnoProduct,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductList: (catslug, subcatslug, sortByField, sortByValue) => {
      dispatch({
        type: GET_PRODUCT_LIST,
        catslug,
        subcatslug,
        sortByField,
        sortByValue,
      });
    },
    getFavouriteproducts: (formPayload) => {
      dispatch({ type: GET_FAVOURITE, formPayload });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(ProductList);
