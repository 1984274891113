/* eslint-disable */
import React, { Component } from "react";
/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import { language } from "../Helpers/Language";
import {
  appId,
  apiUrl,
  deliveryId,
  headerconfig,
  siteURL,
  cookieConfig,
} from "../Helpers/Config";
import {
  addressFormat,
  stripslashes,
  showPrices,
  showCustomAlert,
  subscribeCycle,
  removePromoCkValue,
  showLoader,
  hideLoader,
} from "../Helpers/SettingHelper";
import { connect } from "react-redux";
import Moment, { lang } from "moment";
import {
  GET_CORDERDETAIL,
  GET_PORDERDETAIL,
  GET_PRINTORDER,
  GET_ORDERHISTORY,
  GET_ACTIVITYCOUNT,
} from "../../actions";
import Sidebar from "./Sidebar";
import Mobilesidebar from "./Mobilesidebar";

import cookie from "react-cookies";
import scotterImg from "../../common/images/scotter.png";
import mapImg from "../../common/images/map-icon.png";
import productImg from "../../common/images/noimg-400x400.jpg";
var Parser = require("html-react-parser");
var qs = require("qs");
class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      corderdetail: [],
      cartItems: [],
      cartData: [],
      cart_brktime_list: [],
      showitems: 10,
      showpitems: 10,
      showresvitems: 10,
      resviewmore: "none",
      order_all: 0,
      overall_orders: 0,
      deliveryTakeaway_orders: 0,
      currentTab: "C",
      orderID: "",
      order_dirver_rider_link: "",
      selectedFile: "",
    };

    if (cookie.load("UserId") === undefined) {
      cookie.save("loginpopupTrigger", "Yes", cookieConfig);
      props.history.push("/");
    }
  }

  componentDidMount() {
    /* delivery current - past orders */
    var access_token =
      cookie.load("access_token") !== "" &&
      typeof cookie.load("access_token") !== undefined &&
      typeof cookie.load("access_token") !== "undefined"
        ? "&access_token=" + cookie.load("access_token")
        : "";
    var deliveryparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes" +
      access_token;
    this.props.getCorderDetail(deliveryparams);

    var deliverypastparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes" +
      access_token;
    this.props.getPorderDetail(deliverypastparams);
    $("#dvLoading").fadeOut(2000);

    //Default Action
    $(".filter_tabsec").hide(); //Hide all content
    $("ul.web_order_typecls li:first")
      .addClass("active")
      .show(); //Activate first tab
    $(".filter_tabsec:first").show(); //Show first tab content

    //On Click Event
    $("ul.web_order_typecls li").click(function() {
      $("ul.web_order_typecls li").removeClass("active"); //Remove any "active" class
      $(this).addClass("active"); //Add "active" class to selected tab
      $(".filter_tabsec").hide(); //Hide all tab content
      var activeTab = $(this)
        .find("a")
        .attr("href"); //Find the rel attribute value to identify the active tab + content
      $(activeTab).fadeIn(); //Fade in the active content
      return false;
    });

    this.getActivityCounts();
  }

  /* for order load more  button */
  loadcurrentItems() {
    var pageNext = this.state.showitems + 10;
    this.setState({ showitems: pageNext }, function() {
      this.loadcitems();
    });
  }
  loadpastItems() {
    var pagepNext = this.state.showpitems + 10;
    this.setState({ showpitems: pagepNext }, function() {
      this.loadpitems();
    });
  }

  loadcitems() {
    $(".load_more_data").append('<b class="gloading_img"></b>');
    var access_token =
      cookie.load("access_token") !== "" &&
      typeof cookie.load("access_token") !== undefined &&
      typeof cookie.load("access_token") !== "undefined"
        ? "&access_token=" + cookie.load("access_token")
        : "";
    var deliveryparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&availabilityIDs=" +
      deliveryId +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes" +
      access_token;
    this.props.getCorderDetail(deliveryparams);
  }
  loadpitems() {
    var access_token =
      cookie.load("access_token") !== "" &&
      typeof cookie.load("access_token") !== undefined &&
      typeof cookie.load("access_token") !== "undefined"
        ? "&access_token=" + cookie.load("access_token")
        : "";
    $(".load_more_data1").append('<b class="gloading_img"></b>');
    var deliverypastparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&availabilityIDs=" +
      deliveryId +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes" +
      access_token;
    this.props.getPorderDetail(deliverypastparams);
  }

  getActivityCounts() {
    const inputKeys = [
      "order_all",
      "overall_orders",
      "deliveryTakeaway_orders",
    ];
    this.props.getActivityCount(JSON.stringify(inputKeys));
  }

  componentWillReceiveProps(nextProps) {
    /*activity count -start */
    if (Object.keys(nextProps.activitycount).length > 0) {
      if (nextProps.activitycount !== this.props.activitycount) {
        if (
          nextProps.activitycount[0].status &&
          nextProps.activitycount[0].result_set
        ) {
          this.setState({
            order_all: nextProps.activitycount[0].result_set.order_all,
            overall_orders:
              nextProps.activitycount[0].result_set.overall_orders,
            deliveryTakeaway_orders:
              nextProps.activitycount[0].result_set.deliveryTakeaway_orders,
          });
          $("#dvLoading").fadeOut(2000);
        }
      }
    } else {
      this.setState({
        order_all: 0,
        overall_orders: 0,
        deliveryTakeaway_orders: 0,
      });
    }

    /*current orders */
    if (nextProps.corderdetail !== this.props.corderdetail) {
      if (nextProps.corderdetail && nextProps.corderdetail[0].status == "ok") {
        $("b").removeClass("gloading_img");
        $("#dvLoading").fadeOut(2000);
        this.setState({ corderdetail: nextProps.corderdetail[0].result_set });
        if (nextProps.corderdetail[0].common.total_records) {
          this.setState({
            totalcount: nextProps.corderdetail[0].common.total_records,
          });
        }
        if (
          this.state.showitems > nextProps.corderdetail[0].common.total_records
        ) {
          $(".load_more_data").hide();
        } else {
          $(".load_more_data").show();
        }
      } else {
        $(".load_more_data").hide();
      }
    }

    if (nextProps.porderdetail !== this.props.porderdetail) {
      if (nextProps.porderdetail && nextProps.porderdetail[0].status == "ok") {
        $("b").removeClass("gloading_img");
        $("#dvLoading").fadeOut(2000);
        this.setState({ porderdetail: nextProps.porderdetail[0].result_set });
        if (
          nextProps.porderdetail[0].common.total_records &&
          this.state.showpitems > nextProps.porderdetail[0].common.total_records
        ) {
          $(".load_more_data1").hide();
        } else {
          $(".load_more_data1").show();
        }
      } else {
        $(".load_more_data1").hide();
      }
    }

    if (nextProps.orderhistory !== this.props.orderhistory) {
      if (nextProps.orderhistory[0].status === "ok") {
        this.setState({
          CartItems: nextProps.orderhistory[0].result_set[0]["items"],
          ReceiptDetails: nextProps.orderhistory[0].result_set[0],
        });

        $("#dvLoading").fadeOut(5000);
        setTimeout(function() {
          $.magnificPopup.open({
            items: {
              src: ".receipt_popup",
            },
            type: "inline",
          });
        }, 1000);
      } else {
        this.setState({ ReceiptDetails: [] });
      }
    }

    if (nextProps.printorder !== this.props.printorder) {
      if (nextProps.printorder[0].status === "ok") {
        $("#dvLoading").fadeOut(2000);
        window.open(nextProps.printorder[0].pdf_url, "_blank");
      }
    }
  }

  getReceipt(orderId) {
    $("#dvLoading").fadeIn();
    var access_token =
      cookie.load("access_token") !== "" &&
      typeof cookie.load("access_token") !== undefined &&
      typeof cookie.load("access_token") !== "undefined"
        ? "&access_token=" + cookie.load("access_token")
        : "";
    var params =
      "&customer_id=" +
      cookie.load("UserId") +
      "&order_id=" +
      orderId +
      access_token;
    this.props.getOrderHistory(params);
  }

  printReceipt(orderId, availabilityId) {
    $("#dvLoading").fadeIn();
    this.props.getPrintOrder(orderId, deliveryId);
  }

  /* Advanced Slot */
  showOrdTimeSlot(orderDetail) {
    var ordTmSlt = Moment(orderDetail.order_date).format("hh:mm A");
    if (orderDetail.order_is_timeslot === "Yes") {
      var slotTime1 =
        orderDetail.order_pickup_time_slot_from !== ""
          ? orderDetail.order_pickup_time_slot_from.split(":")
          : Array();
      var slotTime2 =
        orderDetail.order_pickup_time_slot_to !== ""
          ? orderDetail.order_pickup_time_slot_to.split(":")
          : Array();
      if (
        Object.keys(slotTime1).length > 0 &&
        Object.keys(slotTime2).length > 0
      ) {
        var startTimeVal = parseInt(slotTime1[0]);
        var startMinitVal = parseInt(slotTime1[1]);
        var strdatevalobj = new Date();
        strdatevalobj.setHours(startTimeVal);
        strdatevalobj.setMinutes(startMinitVal);

        var endTimeVal = parseInt(slotTime2[0]);
        var endMinitVal = parseInt(slotTime2[1]);
        var enddatevalobj = new Date();
        enddatevalobj.setHours(endTimeVal);
        enddatevalobj.setMinutes(endMinitVal);

        ordTmSlt =
          format(strdatevalobj, "p") + " - " + format(enddatevalobj, "p");
      }
    }

    return ordTmSlt;
  }

  openDriverDetails(rider_link) {
    if (rider_link !== "") {
      this.setState({ order_dirver_rider_link: rider_link });
      $.magnificPopup.open({
        items: {
          src: "#rider_details",
        },
        type: "inline",
      });
    }
  }

  openImportPopup() {
    $.magnificPopup.open({
      items: {
        src: "#import-popup",
      },
      type: "inline",
    });
  }
  handleFile(e) {
    // Getting the files from the input
    this.setState({
      selectedFile: e.target.files[0],
    });
  }
  handleUpload(e) {
    e.preventDefault();
    if (!this.state.selectedFile) {
      showCustomAlert("error", "Please select The file");
      return false;
    }
    showLoader("bulk-import", "class");
    var qs = require("qs");
    var customerId =
      typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");
    const data = new FormData();
    data.append("app_id", appId);
    data.append("customer_id", customerId);
    data.append("availability_id", deliveryId);
    data.append("availability_name", "Delivery");
    data.append(
      "access_token",
      cookie.load("access_token") !== "" &&
        typeof cookie.load("access_token") !== undefined &&
        typeof cookie.load("access_token") !== "undefined"
        ? cookie.load("access_token")
        : ""
    );
    data.append("file", this.state.selectedFile);
    axios
      .post(apiUrl + "exportproduct/import_product", data, headerconfig)
      .then((res) => {
        if (res.data.status === "ok") {
          document.getElementById("importFile").value = null;
          $.magnificPopup.close();
          this.setState({ cartTriggerFlg: "yes" });
          hideLoader("bulk-import", "class");
          removePromoCkValue();
          setTimeout(function() {
            $(".hcartdd_trigger").addClass("bounce");
          }, 1000);
        } else if (res.data.status === "error") {
          var errMsgtxt =
            res.data.message !== "" ? res.data.message : language.itemnotadded;
          showCustomAlert("error", errMsgtxt);
          this.handleShowAlertFun("エラー", errMsgtxt);
        }
        return false;
      });
  }
  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  getOrderItemData = (dataProp) => {
    var dataProp = dataProp !== undefined ? dataProp : Array();
    if (Object.keys(dataProp).length > 0) {
      return dataProp.map((item) => {
        const orderDate = Moment(item.order_date).format("YYYY/MM/DD");
        /* Advanced Slot */
        const orderTime = this.showOrdTimeSlot(item);
        return (
          <div key={item.order_id} className="current_order">
            <div className="myacc_head_sec">
              <div className="head_left">
                <div className="head-group">
                  <h4>
                    {language.ordernumber} - <span>{item.order_local_no}</span>
                  </h4>
                </div>
              </div>
              <div className="head_right">
                <div className="head-group">
                  <h4>
                    {(() => {
                      if (item.status_name === "Received") {
                        return "受取り済";
                      } else if (item.status_name === "Processing") {
                        return "処理中";
                      } else if (item.status_name === "Delivering") {
                        return "納品済み";
                      } else if (item.status_name === "Completed") {
                        return "完了";
                      } else {
                        return item.status_name;
                      }
                    })()}
                  </h4>
                </div>
              </div>
            </div>

            <div className="order_details_body">
              <div className="reorder">
                <a
                  href={void 0}
                  onClick={this.orderAgain.bind(this, item.order_id)}
                >
                  {language.reorder}
                </a>
              </div>

              <div className="reorder hidden">
                <a
                  href={void 0}
                  onClick={this.openDriverDetails.bind(
                    this,
                    item.lo_order_tracklink
                  )}
                >
                  {language.derivedetails}
                </a>
              </div>

              <div className="order_no_deatails">
                {language.yourorder} :{" "}
                <strong>
                  {" "}
                  {Moment(item.order_created_on).format("YYYY/MM/DD") +
                    " " +
                    Moment(item.order_created_on).format("h:mm a")}
                </strong>
                <span>
                  {language.paymentmethod} :{" "}
                  {(() => {
                    if (item.order_method_name === "Credit card/Debit card") {
                      return language.criditordebitcard;
                    } else if (item.order_method_name === "Cash") {
                      return language.cash;
                    } else if (item.order_method_name === "Promotion") {
                      return language.promotion;
                    } else if (item.order_method_name === "Corporate Wallet") {
                      return language.corporatewallet;
                    } else {
                      return item.order_method_name;
                    }
                  })()}
                </span>
              </div>
              {item.order_availability_id === deliveryId ? (
                <div className="delivery_total delivery_total_text">
                  <div className="delivery_total_left">
                    <img src={scotterImg} />
                    <h3>{language.orderprocessing}</h3>
                    <span>
                      {item.outlet_name !== "" && item.outlet_name !== null
                        ? stripslashes(item.outlet_name)
                        : ""}
                    </span>
                    <span>
                      {item.outlet_name !== "" &&
                        item.outlet_name !== null &&
                        addressFormat(
                          item.outlet_unit_number1,
                          item.outlet_unit_number2,
                          item.outlet_address_line1,
                          item.outlet_address_line2,
                          item.outlet_postal_code
                        )}
                    </span>
                  </div>
                  <div className="delivery_total_left delivery_total_right">
                    <img src={mapImg} />
                    <h3>{language.deliverylocation}</h3>
                    <span>
                      {addressFormat(
                        item.order_customer_unit_no1,
                        item.order_customer_unit_no2,
                        item.order_customer_address_line1,
                        item.order_customer_address_line2,
                        item.order_customer_postal_code
                      )}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="delivery_total delivery_total_text pickup-order-div">
                  <div className="delivery_total_left">
                    <h3>{language.pickuplocation}</h3>
                    <span>
                      {item.outlet_name !== "" && item.outlet_name !== undefined
                        ? stripslashes(item.outlet_name)
                        : ""}
                    </span>
                    <span>
                      {addressFormat(
                        item.outlet_unit_number1,
                        item.outlet_unit_number2,
                        item.outlet_address_line1,
                        item.outlet_address_line2,
                        item.outlet_postal_code
                      )}
                    </span>
                  </div>
                </div>
              )}
              <div className="delivery_total delivery_total_number">
                <div className="delivery_total_left">
                  <h2>
                    {item.order_availability_id === deliveryId
                      ? language.deliverydate
                      : "Pickup"}{" "}
                  </h2>
                  <h4 className="checkoutDate">{orderDate}</h4>
                </div>
                <div className="delivery_total_left delivery_total_right">
                  <h2>
                    {item.order_availability_id === deliveryId
                      ? language.time
                      : "Pickup"}{" "}
                  </h2>
                  <h4 className="checkoutTime">{orderTime}</h4>
                </div>
              </div>
            </div>

            <div className="order_details_footer">
              <div className="order_amt">
                <div className="order_amt-left">
                  <h3>{language.total}</h3>
                </div>
                <div className="order_amt-right">
                  <h3 className="text-right">
                    {showPrices(item.order_total_amount, "Y")}
                  </h3>
                </div>
              </div>
              <div className="order_btns">
                <a
                  href={void 0}
                  onClick={this.printReceipt.bind(this, item.order_primary_id)}
                  className="button print_invoice"
                  title=""
                >
                  {language.printinvoice}
                </a>
                <a
                  href={void 0}
                  onClick={this.getReceipt.bind(this, item.order_id)}
                  className="button view_recipt"
                  title=""
                >
                  {language.viewreceipt}
                </a>
              </div>
              {/* )} */}
            </div>
          </div>
        );
      });
    } else {
      return <div className="no-recrds-found">{language.norecordfound}</div>;
    }
  };

  reservStatus(statusTxt) {
    var returnTxt = "Pending";
    statusTxt = statusTxt !== "" ? parseInt(statusTxt) : 0;
    if (statusTxt === 2) {
      returnTxt = "Cancel";
    } else if (statusTxt === 1) {
      returnTxt = "Assigned";
    } else if (statusTxt === 3) {
      returnTxt = "Arrived";
    } else if (statusTxt === 4) {
      returnTxt = "Not Arrived";
    } else if (statusTxt === 5) {
      returnTxt = "Completed";
    }

    return returnTxt;
  }

  showNoOfPax(adultCount, childCount) {
    var adultTxt =
      parseInt(adultCount) == 0
        ? ""
        : parseInt(adultCount) == 1
        ? adultCount + " Adult"
        : adultCount + " Adults";
    var childTxt =
      parseInt(childCount) == 0
        ? ""
        : parseInt(childCount) == 1
        ? childCount + " Child"
        : childCount + " Children";
    var paxText = adultTxt + " " + childTxt;

    return paxText;
  }
  openActiveTab(currentTab) {
    this.setState({ currentTab: currentTab });
  }

  orderAgain(orderID, event) {
    event.preventDefault();
    this.setState({ orderID: orderID }, function() {
      if (this.state.existCart === true) {
        $.magnificPopup.open({
          items: {
            src: "#warningmyaccount-popup",
          },
          type: "inline",
        });
      } else {
        this.conformorderagian();
      }
    });
  }

  /* For Order Again Start */
  conformorderagian(event) {
    if (
      event !== "" &&
      typeof event !== undefined &&
      typeof event !== "undefined"
    ) {
      event.preventDefault();
    }

    var orderID = this.state.orderID;
    var customerId =
      typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");

    var postObject = {
      app_id: appId,
      order_id: orderID,
      customer_id: customerId,
      access_token:
        cookie.load("access_token") !== "" &&
        typeof cookie.load("access_token") !== undefined &&
        typeof cookie.load("access_token") !== "undefined"
          ? cookie.load("access_token")
          : "",
    };

    axios
      .post(apiUrl + "cart/order_again", qs.stringify(postObject), headerconfig)
      .then((res) => {
        if (res.data.status === "ok") {
          location.href = siteURL + "checkout";
        } else if (res.data.status === "error") {
          var errMsgtxt =
            res.data.message !== ""
              ? res.data.message
              : "ごめん！再度注文するとカートを追加できません。";
          showCustomAlert("error", errMsgtxt);
        }
        return false;
      });
  }
  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
  };
  render() {
    return (
      <div className="myacc-main-div">
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
        />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <div className="container">
              <h4>{language.myaccount}</h4>
              <p>{language.myaccoutinfo}</p>
            </div>
          </div>
        </div>
        <Mobilesidebar pageName={"myorders"} />
        <div className="innersection_wrap myadmin_wrap">
          {/* container div - start */}
          <div className="container">
            <div className="reorder" style={{ textAlign: "right" }}>
              <a
                href={void 0}
                onClick={this.openImportPopup.bind(this)}
                style={{ marginRight: "10px" }}
                className=""
              >
                <span>{language.bulk_import}</span>
              </a>
            </div>
            <div className="mainacc_menusec">
              <Sidebar pageName={"myorders"} />
              <div className="mainacc_menucontent">
                <div className="main_tabsec">
                  <div className="order-tab-section">
                    <div className="mainacc_mobrow">
                      <div className="tab_sec main_tabsec_inner">
                        <div className="myacc_filter">
                          <div className="tab_sec filter_tabsec" id="ordertab1">
                            <ul className="nav nav-tabs text-center">
                              <li
                                className={
                                  this.state.currentTab === "C" ? "active" : ""
                                }
                              >
                                <a
                                  data-toggle="tab"
                                  href={void 0}
                                  onClick={this.openActiveTab.bind(this, "C")}
                                  aria-expanded="true"
                                >
                                  <span>{language.currentorder}</span>
                                </a>
                              </li>
                              <li
                                className={
                                  this.state.currentTab === "P" ? "active" : ""
                                }
                              >
                                <a
                                  data-toggle="tab"
                                  href={void 0}
                                  onClick={this.openActiveTab.bind(this, "P")}
                                  aria-expanded="false"
                                >
                                  <span>{language.pastorder}</span>
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content">
                              {this.state.currentTab === "C" && (
                                <div
                                  id="tab-id-inn5"
                                  className="tab-pane fade active in"
                                >
                                  <h4 className="tab_mobtrigger inner_tab_border active">
                                    {language.currentorder}
                                    <i></i>
                                  </h4>
                                  <div className="tab_mobrow filter_tabin">
                                    <div className="order-delivery">
                                      <div className="ord-body">
                                        <div className="cur-order-body">
                                          <div className="myacc_order_details">
                                            {this.getOrderItemData(
                                              this.state.corderdetail
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="load_more_div">
                                    <button
                                      className="load_more_data"
                                      onClick={this.loadcurrentItems.bind(this)}
                                      style={{ display: "none" }}
                                    >
                                      {language.loadmore}
                                    </button>
                                  </div>
                                </div>
                              )}
                              {this.state.currentTab === "P" && (
                                <div
                                  id="tab-id-inn6"
                                  className="tab-pane fade active in"
                                >
                                  <h4 className="tab_mobtrigger inner_tab_border active">
                                    {language.pastorder}
                                    <i></i>
                                  </h4>
                                  <div className="tab_mobrow filter_tabin">
                                    <div className="pst-order-body">
                                      <div className="myacc_order_details">
                                        {this.getOrderItemData(
                                          this.state.porderdetail
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="load_more_div">
                                    <button
                                      className="load_more_data1"
                                      style={{ display: "none" }}
                                      onClick={this.loadpastItems.bind(this)}
                                    >
                                      {language.loadmore}
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          {/* next tab */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* container div - end */}
        </div>

        <Footer />

        <div
          id="receipt-popup"
          className="white-popup mfp-hide popup_sec receipt_popup"
        >
          <div className="pouup_in">
            <Viewreceipt
              {...this.props}
              details={this.state.ReceiptDetails}
              cartItems={this.state.CartItems}
            />
          </div>
        </div>
        <div
          id="rider_details"
          className="white-popup mfp-hide popup_sec receipt_popup"
        >
          {this.state.order_dirver_rider_link !== "" &&
          this.state.order_dirver_rider_link !== undefined &&
          this.state.order_dirver_rider_link !== null ? (
            <div className="pouup_in">
              <iframe
                height="400"
                src={this.state.order_dirver_rider_link}
                frameBorder="0"
                allow="clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          ) : (
            <div className="redeem_bansec_rider hidden">
              <h6>
                ドライバーが割り当てられていません。ドライバーが割り当てられると配達詳細が表示されます
              </h6>
            </div>
          )}
        </div>

        <div id="import-popup" className="mfp-hide popup_sec login-popup">
          <div className="pop-whole full-login-new">
            <div className="full-login-new-header">
              <h3>{language.bulk_import}</h3>
              <p>{Parser(language.import_message)}</p>
            </div>
            <div className="full-login-new-body">
              <div className="form-group import-browse">
                <div className="focus-out">
                  <input
                    type="file"
                    name="file"
                    className=""
                    id="importFile"
                    onChange={(e) => this.handleFile(e)}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="focus-out"></div>
              </div>
              <div className="form-group import-btn">
                <a
                  href={void 0}
                  onClick={() =>
                    window.open(apiUrl + "exportproduct/?app_id=" + appId)
                  }
                  className="button export-btn"
                >
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i>{" "}
                  {language.export_txt}
                </a>
                <button
                  className="btn btn_black bulk-import"
                  onClick={(e) => this.handleUpload(e)}
                >
                  {language.submit}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div id="dvLoading"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    corderdetail: state.corderdetail,
    porderdetail: state.porderdetail,
    printorder: state.printorder,
    orderhistory: state.orderhistory,
    activitycount: state.activitycount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCorderDetail: (deliveryparams) => {
      dispatch({ type: GET_CORDERDETAIL, deliveryparams });
    },
    getPorderDetail: (deliverypastparams) => {
      dispatch({ type: GET_PORDERDETAIL, deliverypastparams });
    },
    getPrintOrder: (orderprimaryId, availabilityId) => {
      dispatch({ type: GET_PRINTORDER, orderprimaryId, availabilityId });
    },
    getOrderHistory: (params) => {
      dispatch({ type: GET_ORDERHISTORY, params });
    },
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
  };
};

Orders.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Orders));

class Viewreceipt extends Component {
  constructor(props) {
    super(props);
  }

  getCartDetList(cartItems) {
    if (cartItems.length > 0) {
      return cartItems.map((item, index) => (
        <div className="cart_row product-details oreder_itm_row" key={index}>
          <div className="row-replace">
            <div className="col-sm-cls cart_left text-left">
              <div className="cart_img">
                {item.item_image !== "" ? (
                  <img src={item.item_image} alt="" />
                ) : (
                  <img src={productImg} alt="" />
                )}
              </div>
              <div className="cart_info">
                <h4>
                  {stripslashes(item.item_name)} X {item.item_qty}
                </h4>
                {Object.keys(item.voucher_details).length > 0 && (
                  <div className="cart_extrainfo">
                    <p>
                      <b>{language.giftdetails}</b>
                    </p>
                    {item.voucher_details.voucher_gift_name !== "" &&
                      item.voucher_details.voucher_gift_name !== null && (
                        <p>
                          {language.name}:{" "}
                          {item.voucher_details.voucher_gift_name}
                        </p>
                      )}
                    {item.voucher_details.order_item_voucher_mobile !== "" &&
                      item.voucher_details.order_item_voucher_mobile !==
                        null && (
                        <p>
                          {language.mobile}:{" "}
                          {item.voucher_details.order_item_voucher_mobile}
                        </p>
                      )}
                    {item.voucher_details.order_item_voucher_email !== "" &&
                      item.voucher_details.order_item_voucher_email !==
                        null && (
                        <p>
                          {language.email}:{" "}
                          {item.voucher_details.order_item_voucher_email}
                        </p>
                      )}
                    {item.voucher_details.order_item_voucher_message !== "" &&
                      item.voucher_details.order_item_voucher_message !==
                        null && (
                        <p>
                          {language.mesage}:{" "}
                          {item.voucher_details.order_item_voucher_message}
                        </p>
                      )}
                  </div>
                )}
                {this.loadModifierItems(
                  "Component",
                  item.modifiers,
                  item.set_menu_component
                )}

                {item.item_revel_id !== "" && item.item_revel_id !== null && (
                  <p>SKU:{item.item_revel_id}</p>
                )}
                {item.subscription === "Yes" &&
                  item.subscription_started === "Yes" && (
                    <>
                      <p>
                        {language.subscription}:
                        {subscribeCycle(item.subscription_cycle)}
                      </p>

                      {item.subscription_start_date !== "" &&
                        item.subscription_start_date !== null &&
                        item.subscription_start_date !== "0000-00-00" && (
                          <p>
                            {language.startdate}:{item.subscription_start_date}
                          </p>
                        )}
                      {item.subscription_end_date !== "" &&
                        item.subscription_end_date !== null &&
                        item.subscription_end_date !== "0000-00-00" && (
                          <p>
                            {language.enddate}:{item.subscription_end_date}
                          </p>
                        )}
                    </>
                  )}
              </div>

              {item.item_specification !== "" && (
                <p className="help-block">
                  {stripslashes(item.item_specification)}
                </p>
              )}
              {/*} 
				 <p className ="help-block">Special instruction for individual product items ...</p>
				 {*/}
            </div>
            <div className="col-sm-cls cart_right text-right">
              <div className="cart_price">
                <p>
                  {showPrices(parseFloat(item.item_total_amount).toFixed(2))}
                </p>
              </div>
            </div>
          </div>
        </div>
      ));
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo">';
    if (len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        html +=
          "<p><b>" +
          stripslashes(modName) +
          ":</b></p><p> " +
          stripslashes(modval) +
          "</p>";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);
        html +=
          "<p><b>" +
          comboName +
          ":</b></p><p> " +
          comboVal +
          " " +
          this.showComboModifiers(combo[i]["product_details"][0]["modifiers"]) +
          "</p>";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        var comboPro = combos[r]["menu_product_name"];
        var comboQty = combos[r]["menu_product_qty"];
        var comboPrice = combos[r]["menu_product_price"];
        if (parseFloat(comboPrice) > 0) {
          var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
          /* + newPrice */
          if (parseInt(comboQty) > 1) {
            comboQty = comboQty + " X ";
          } else {
            comboQty = "";
          }
          html += "<p>" + comboQty + comboPro + " </p>";
        }
      }
      return html;
    }
    return "";
  }
  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "";
    if (lenMod > 0) {
      html = "<div > ";
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["order_modifier_price"];
        if (parseFloat(modValPrice) > 0) {
          var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : "";
          /*  newModValPrice + */
          html += "<p><b>" + modName + ":</b></p><p> " + modval + "</p>";
        }
      }
      html += "</div>";
    }
    return html;
  }

  /* Advanced Slot */
  showOrdTimeSlot(orderDetail) {
    var ordTmSlt = Moment(orderDetail.order_date).format("hh:mm a");
    if (orderDetail.order_is_timeslot === "Yes") {
      var slotTime1 =
        orderDetail.order_pickup_time_slot_from !== ""
          ? orderDetail.order_pickup_time_slot_from.split(":")
          : Array();
      var slotTime2 =
        orderDetail.order_pickup_time_slot_to !== ""
          ? orderDetail.order_pickup_time_slot_to.split(":")
          : Array();
      if (
        Object.keys(slotTime1).length > 0 &&
        Object.keys(slotTime2).length > 0
      ) {
        var startTimeVal = parseInt(slotTime1[0]);
        var startMinitVal = parseInt(slotTime1[1]);
        var strdatevalobj = new Date();
        strdatevalobj.setHours(startTimeVal);
        strdatevalobj.setMinutes(startMinitVal);

        var endTimeVal = parseInt(slotTime2[0]);
        var endMinitVal = parseInt(slotTime2[1]);
        var enddatevalobj = new Date();
        enddatevalobj.setHours(endTimeVal);
        enddatevalobj.setMinutes(endMinitVal);

        ordTmSlt =
          format(strdatevalobj, "p") + " - " + format(enddatevalobj, "p");
      }
    }

    return ordTmSlt;
  }

  orderAgain(orderID, event) {
    event.preventDefault();
    this.setState({ orderID: orderID }, function() {
      if (this.state.existCart === true) {
        $.magnificPopup.open({
          items: {
            src: "#warningmyaccount-popup",
          },
          type: "inline",
        });
      } else {
        this.conformorderagian();
      }
    });
  }

  /* For Order Again Start */
  conformorderagian(event) {
    if (
      event !== "" &&
      typeof event !== undefined &&
      typeof event !== "undefined"
    ) {
      event.preventDefault();
    }

    var orderID = this.state.orderID;
    var customerId =
      typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");
    console.log(orderID, "orderIDorderID");

    var postObject = {
      app_id: appId,
      order_id: orderID,
      customer_id: customerId,
      access_token:
        cookie.load("access_token") !== "" &&
        typeof cookie.load("access_token") !== undefined &&
        typeof cookie.load("access_token") !== "undefined"
          ? cookie.load("access_token")
          : "",
    };

    axios
      .post(apiUrl + "cart/order_again", qs.stringify(postObject), headerconfig)
      .then((res) => {
        $.magnificPopup.close();
        if (res.data.status === "ok") {
          location.href = siteURL + "checkout";
        } else if (res.data.status === "error") {
          var errMsgtxt =
            res.data.message !== ""
              ? res.data.message
              : "ごめん！再度注文するとカートを追加できません。";
          showCustomAlert("error", errMsgtxt);
        }
        return false;
      });
  }

  render() {
    const { details, cartItems } = this.props;
    if (details !== undefined) {
      return (
        <div className="Viewreceipt">
          <div className="tnk-you">
            <div className="tnk-header">
              <div className="tnk-detail">
                <div className="tnk-order">
                  <h3>
                    {language.ordernumber} - {details.order_local_no}
                  </h3>
                  <p>
                    {language.orderat} :{" "}
                    {Moment(details.order_created_on).format(
                      "YYYY/MM/DD hh:mm a"
                    )}
                    <br></br>
                    {language.paymentmethod} :{" "}
                    {(() => {
                      if (
                        details.order_method_name === "Credit card/Debit card"
                      ) {
                        return language.criditordebitcard;
                      } else if (details.order_method_name === "Cash") {
                        return language.cash;
                      } else {
                        return details.order_method_name;
                      }
                    })()}
                  </p>
                </div>
                <h2>{language.orderdetails}</h2>
              </div>
            </div>
            <div className="tnk-delivery">
              {details.order_availability_id === deliveryId ? (
                <div className="delivery_total">
                  <div className="delivery_total_left">
                    <img src={scotterImg} />
                    <h3>{language.orderprocessing}</h3>
                    <span>
                      {details.outlet_name !== "" &&
                      details.outlet_name !== undefined
                        ? stripslashes(details.outlet_name)
                        : ""}
                    </span>
                    <span>
                      {addressFormat(
                        details.outlet_unit_number1,
                        details.outlet_unit_number2,
                        details.outlet_address_line1,
                        details.outlet_address_line2,
                        details.outlet_postal_code
                      )}
                    </span>
                  </div>
                  <div className="delivery_total_left delivery_total_right">
                    <img src={mapImg} />
                    <h3>{language.deliverylocation}</h3>
                    <span>{details.order_customer_address_line1}</span>{" "}
                    <span>
                      {details.order_customer_unit_no1 != "" &&
                      details.order_customer_unit_no2 != ""
                        ? details.order_customer_unit_no1 +
                          "-" +
                          details.order_customer_unit_no2
                        : ""}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="delivery_total pickup-order-div">
                  <div className="delivery_total_left">
                    <h3>Pickup Location</h3>
                    <span>
                      {details.outlet_name !== "" &&
                      details.outlet_name !== undefined
                        ? stripslashes(details.outlet_name)
                        : ""}
                    </span>
                    <span>
                      {addressFormat(
                        details.outlet_unit_number1,
                        details.outlet_unit_number2,
                        details.outlet_address_line1,
                        details.outlet_address_line2,
                        details.outlet_postal_code
                      )}
                    </span>
                  </div>
                </div>
              )}
              <div className="delivery_total delivery_total_number">
                <div className="delivery_total_left">
                  <h2>
                    {details.order_availability_id === deliveryId
                      ? language.deliverydate
                      : "Pickup"}{" "}
                  </h2>
                  <h4 className="checkoutDate">
                    {Moment(details.order_date).format("YYYY/MM/DD")}
                  </h4>
                </div>
                <div className="delivery_total_left delivery_total_right">
                  <h2>
                    {details.order_availability_id === deliveryId
                      ? language.time
                      : "Pickup"}{" "}
                  </h2>
                  {/* Advanced Slot */}
                  <h4 className="checkoutTime">
                    {this.showOrdTimeSlot(details)}
                  </h4>
                </div>
              </div>

              <div className="order-items-maindiv">
                {this.getCartDetList(cartItems)}
                {details.order_remarks !== "" && (
                  <>
                    <div className="remark_notesec text-left">
                      <h4>{language.remarks}</h4>
                      <p>{details.order_remarks}</p>
                    </div>
                    <br />
                  </>
                )}

                {details.order_customer_send_gift === "Yes" && (
                  <>
                    {details.order_recipient_name !== "" &&
                      details.order_recipient_name !== null && (
                        <div className="remark_notesec text-left">
                          <h4>{language.recipientname}</h4>
                          <p>{details.order_recipient_name}</p>
                        </div>
                      )}
                    {details.order_recipient_contact_no !== "" &&
                      details.order_recipient_contact_no !== null && (
                        <div className="remark_notesec text-left">
                          <h4>{language.recipientnumber}</h4>
                          <p>{details.order_recipient_contact_no}</p>
                        </div>
                      )}
                    {details.order_gift_message !== "" &&
                      details.order_gift_message !== null && (
                        <div className="remark_notesec text-left">
                          <h4>{language.giftmessage}</h4>
                          <p>{details.order_gift_message}</p>
                        </div>
                      )}
                    <br />
                  </>
                )}
              </div>

              <div className="cart_footer tnk_cart_footer">
                <div className="cart_row">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <p className="text-uppercase">{language.subtotal}</p>
                    </div>
                    <div className="col-sm-cls text-right">
                      <span>{showPrices(details.order_sub_total)}</span>
                    </div>
                  </div>
                </div>
                {parseFloat(details.order_tax_charge_inclusive) > 0 &&
                  parseFloat(details.order_tax_calculate_amount_inclusive) >
                    0 && (
                    <p className="inclisive-gst">
                      {language.inclusivegst} (
                      {details.order_tax_charge_inclusive}%):
                      {showPrices(
                        parseFloat(
                          details.order_tax_calculate_amount_inclusive
                        ).toFixed(2)
                      )}
                    </p>
                  )}
                {parseFloat(details.order_discount_amount) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          {details.order_discount_type === "redeem"
                            ? language.dicount + " (-)"
                            : language.promocode + " (-)"}
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(details.order_discount_amount).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {parseFloat(details.order_redeemed_points) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          {language.rewardpoints}
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(details.order_redeemed_points).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {parseFloat(details.order_cpcustomer_discount_amount) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          {language.corporatepromotion}(-)
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(
                              details.order_cpcustomer_discount_amount
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {parseFloat(details.order_corporate_wallet_amount) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          {language.corporatewallet}
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(
                              details.order_corporate_wallet_amount
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {parseFloat(details.order_voucher_discount_amount) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          {language.voucherdiscount1}(-)
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(
                              details.order_voucher_discount_amount
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(details.order_delivery_charge) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">{language.delvieryfee}</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(details.order_delivery_charge).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(details.order_additional_delivery) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          {language.additionaldelvieryfee}
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(
                              details.order_additional_delivery
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {details.order_tax_calculate_amount > 0 &&
                  parseFloat(details.order_tax_charge_inclusive) === 0 && (
                    <div className="cart_row gst-row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-uppercase">
                            {" "}
                            {language.gst}{" "}
                            {/* parseFloat(details.order_tax_charge) > 0 && (
                              <>({parseFloat(details.order_tax_charge)}%)</>
                            ) */}
                          </p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            {showPrices(details.order_tax_calculate_amount)}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                {details.connect_platform_fee != "undefined" &&
                  parseFloat(details.connect_platform_fee) > 0 && (
                    <div className="cart_row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-uppercase">
                            {language.platformfee}
                          </p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            {showPrices(details.connect_platform_fee)}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                <div className="cart_row cart_footer_totrow grant-total-cls">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <p className="text-uppercase">{language.total}</p>
                    </div>
                    <div className="col-sm-cls text-right">
                      <span>{showPrices(details.order_total_amount, "Y")}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="reorder-btn">
                <a
                  class="button reorder"
                  href={void 0}
                  onClick={this.orderAgain.bind(this, details.order_id)}
                >
                  {language.reorder}
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
