/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { GET_ACTIVITYCOUNT } from "../../actions";
import { language } from "../Helpers/Language";

import mm1 from "../../common/images/mobile-myaccount1.png";
import mm2 from "../../common/images/mobile-myaccount2.png";
import mm3 from "../../common/images/mobile-myaccount3.png";

import mm5 from "../../common/images/mobile-myaccount5.png";
import mmlight1 from "../../common/images/mobile-myaccount-light1.png";
import mmlight2 from "../../common/images/mobile-myaccount-light2.png";
import mmlight3 from "../../common/images/mobile-myaccount-light3.png";

import mmlight5 from "../../common/images/mobile-myaccount-light5.png";

import mm4 from "../../common/images/subscrpition1.png";
import mmlight4 from "../../common/images/subscrpition2.png";
var qs = require("qs");
class Mobilesidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: this.props.pageName,
      deliveryTakeaway_orders: "",
      fieldschpassword: {
        oldpin: "",
        newpin: "",
        confirmpin: "",
      },
    };
    const inputKeys = ["deliveryTakeaway_orders"];
    this.props.getActivityCount(JSON.stringify(inputKeys));
  }
  componentWillReceiveProps(nextProps) {
    /*activity count -start */
    if (Object.keys(nextProps.activitycountNew).length > 0) {
      if (nextProps.activitycountNew !== this.props.activitycountNew) {
        if (
          nextProps.activitycountNew[0].status &&
          nextProps.activitycountNew[0].result_set
        ) {
          this.setState({
            deliveryTakeaway_orders:
              nextProps.activitycountNew[0].result_set.deliveryTakeaway_orders,
          });
        }
      }
    } else {
      this.setState({
        order_all: 0,
        overall_orders: 0,
        catering_orders: 0,
        deliveryTakeaway_orders: 0,
        reservation_orders: 0,
      });
    }
  }

  render() {
    return (
      <div className="mobile-single-nav">
        <div className="container">
          <ul>
            <li
              className={this.state.activePage === "myaccount" ? "active" : ""}
            >
              <Link to="/myaccount">
                <div className="singlenav-img">
                  <img className="darker-version" src={mm1} />
                  <img className="lighter-version" src={mmlight1} />
                </div>
                <p>{language.account}</p>
              </Link>
            </li>
            <li
              className={this.state.activePage === "myorders" ? "active" : ""}
            >
              <Link to="/myorders">
                <div className="singlenav-img">
                  <img className="darker-version" src={mm2} />
                  <img className="lighter-version" src={mmlight2} />
                </div>
                <p>{language.myaccount}</p>
              </Link>
            </li>
            <li className={this.state.activePage === "rewards" ? "active" : ""}>
              <Link to="/rewards">
                <div className="singlenav-img">
                  <img className="darker-version" src={mm3} />
                  <img className="lighter-version" src={mmlight3} />
                </div>
                <p>{language.reward}</p>
              </Link>
            </li>
            <li
              className={
                this.state.activePage === "subscription" ? "active" : ""
              }
            >
              <Link to="/subscription">
                <div className="singlenav-img">
                  <img className="darker-version" src={mm4} />
                  <img className="lighter-version" src={mmlight4} />
                </div>
                <p>{language.subscription}</p>
              </Link>
            </li>
            <li
              className={
                this.state.activePage === "mypromotions" ? "active" : ""
              }
            >
              <Link to="/mypromotions">
                <div className="singlenav-img">
                  <img className="darker-version" src={mm5} />
                  <img className="lighter-version" src={mmlight5} />
                </div>
                <p>{language.promotion}</p>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activitycountNew: state.activitycount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
  };
};

Mobilesidebar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Mobilesidebar)
);
