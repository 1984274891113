/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import {
  GET_MENU_NAVIGATION,
  SET_MENU_NAVIGATION,
  GET_PRODUCT_LIST,
  SET_PRODUCT,
} from "../actions";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  defaultoutlet,
  headerconfig,
  cookieConfig
} from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";
import { format } from "date-fns";
export const watchGetMenuNavigation = function* () {
  yield takeEvery(GET_MENU_NAVIGATION, workerGetMenuNavigation);
};

export const watchGetProducts = function* () {
  yield takeEvery(GET_PRODUCT_LIST, workerGetProducts);
};

function* workerGetMenuNavigation() {
  try {
    var availabilityId =
      cookie.load("defaultAvilablityId") === undefined ||
      cookie.load("defaultAvilablityId") == ""
        ? deliveryId
        : cookie.load("defaultAvilablityId");

    var orderOutletId =
      cookie.load("orderOutletId") === undefined ||
      cookie.load("orderOutletId") == ""
        ? defaultoutlet
        : cookie.load("orderOutletId");

    const uri =
      apiUrlV2 +
      "products/getMenuNavigation?app_id=" +
      appId +
      "&availability=" +
      availabilityId +
      "&outletId=" +
      orderOutletId;
    const result = yield call(Axios.get, uri, headerconfig);
    var resultArr = [];
    resultArr.push(result.data);
    if (result.data.status == "ok") {
      cookie.save("firstNavigation", result.data.result_set[0].pro_cate_slug, cookieConfig);
    }
    yield put({ type: SET_MENU_NAVIGATION, value: resultArr });
  } catch {
    console.log("Get Products Failed");
  }
}

function* workerGetProducts({ catslug, subcatslug, sortByField, sortByValue }) {
  try {
    var availabilityId =
      cookie.load("defaultAvilablityId") === undefined ||
      cookie.load("defaultAvilablityId") == ""
        ? deliveryId
        : cookie.load("defaultAvilablityId");

    var orderOutletId =
      cookie.load("orderOutletId") === undefined ||
      cookie.load("orderOutletId") == ""
        ? defaultoutlet
        : cookie.load("orderOutletId");
    var fav_cust_id =
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== "undefined" &&
      typeof cookie.load("UserId") !== undefined
        ? "&fav_cust_id=" + cookie.load("UserId")
        : "";
    var access_token = (cookie.load('access_token')!=="" && typeof cookie.load('access_token')!==undefined && typeof cookie.load('access_token')!=="undefined")?'&access_token='+cookie.load('access_token'):'';

    var sortBy = "";
    if (
      sortByField !== "" &&
      typeof sortByField !== undefined &&
      typeof sortByField !== "undefined" &&
      sortByValue !== "" &&
      typeof sortByValue !== undefined &&
      typeof sortByValue !== "undefined"
    ) {
      if (sortByField === "recomend") {
        // sortBy = "&recomend=Yes";
      } else {
        sortBy =
          "&order_by_field=" + sortByField + "&order_by_type=" + sortByValue;
      }
    }
    var deliveryDate =
      cookie.load("deliveryDate") == undefined ||
      cookie.load("deliveryDate") == ""
        ? ""
        : cookie.load("deliveryDate").split("/");
    var delivery_Date = "";
    if (deliveryDate !== "") {
      delivery_Date =
        deliveryDate[2] + "-" + deliveryDate[1] + "-" + deliveryDate[0];
    } else {
      delivery_Date = format(new Date(), "yyyy-MM-dd");
    }

    var spicelPride =
      "&specialprice_applicable=orderdate&order_datetxt=" + delivery_Date;
    const uri =
      apiUrlV2 +
      "products/getAllProducts?app_id=" +
      appId +
      "&availability=" +
      availabilityId +
      "&category_slug=" +
      catslug +
      "&subcate_slug=" +
      subcatslug +
      "&outletId=" +
      orderOutletId +
      fav_cust_id +
      access_token +
      sortBy +
      spicelPride;
    const result = yield call(Axios.get, uri, headerconfig);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_PRODUCT, value: resultArr });
  } catch {
    console.log("Get Products Failed");
  }
}
