/* eslint-disable */
import React, { Component } from "react";
import { validated } from "react-custom-validation";
import { language } from "../Helpers/Language";
const isEmpty = (value) => (value === "" ? language.thisfieldrequired : null);
const isChecked = (terms) => (terms ? null : language.thisfieldrequired);
function validationConfig(props) {
  const { email, pin, terms } = props.fields;

  return {
    fields: ["email", "pin", "terms"],

    validations: {
      email: [[isEmpty, email]],
      pin: [[isEmpty, pin]],
      terms: [[isChecked, terms, 1]],
    },
  };
}

class Login extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      fields,
      onChange,
      onValid,
      onInvalid,
      $field,
      $validation,
    } = this.props;
    let errMsgEmail, errMsgPin, errMsgTerms;

    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show && (
        <span className="error">{$validation.email.error.reason}</span>
      );
    }
    if ($validation.pin.error.reason !== undefined) {
      errMsgPin = $validation.pin.show && (
        <span className="error">{$validation.pin.error.reason}</span>
      );
    }
    if ($validation.terms.error.reason !== undefined) {
      errMsgTerms = $validation.terms.show && (
        <span className="error">{$validation.terms.error.reason}</span>
      );
    }

    return (
      <div className="popup-body">
        <span className="spn-error"></span>
        <div className="form-group">
          <div className="focus-out focused">
            <label>{language.email}</label>
            <input
              type="text"
              className="form-control input-focus"
              value={fields.email}
              {...$field("email", (e) => onChange("email", e.target.value))}
            />
            {errMsgEmail}
          </div>
        </div>
        <div className="form-group">
          <div className="focus-out focused">
            <label>{language.password}</label>

            <input
              type="password"
              className="form-control input-focus"
              value={fields.pin}
              {...$field("pin", (e) => onChange("pin", e.target.value))}
            />
            {errMsgPin}
          </div>
        </div>
        <div className="form-group login-checkbox-parent">
          <div className="login-checkbox custom_checkbox_content">
            <input
              type="checkbox"
              id="login_terms"
              checked={fields.terms}
              {...$field(
                "terms",
                (e) => onChange("terms", e.target.value),
                null,
                false
              )}
            />
            <div className="sign_reg">
              <span>
                本サイトは、飲食店向けにお酒の販売を行うサイトとなります。
                <br />
                20歳未満の飲酒は日本の法律により固く禁じられています。
                <br />
                20歳未満の方へのお酒の販売は行いませんので、20歳未満の方は本サイトを利用できません。
                <br />
                お客様が20歳以上の場合は、このチェックボックスをクリックしてサイトにお入りください。
              </span>
            </div>
          </div>
          {errMsgTerms}
        </div>
        <div className="form-group">
          <div className="login_pop_sub">
            <button
              className="btn btn_black btn_minwid login_submit"
              onClick={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
              }}
            >
              {" "}
              {language.loginbtn}{" "}
            </button>
            <div id="login-error" style={{ display: "none" }}>
              <span className="error"></span>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="controls two-links">
            {/* <a
              href="#signup-popup"
              data-effect="mfp-zoom-in"
              className="open-popup-link"
            >
              {" "}
              パスワードをお忘れですか ？
            </a> */}
            <span className="spilter"> </span>
            <a href="#forgot-password-popup" className="open-popup-link">
              {language.haveforgotpin}
            </a>{" "}
          </div>
        </div>
      </div>
    );
  }
}
Login = validated(validationConfig)(Login);

export default Login;
