import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import pageNotFnd from "./common/images/404.png";
import mainLogo from "./common/images/logo.svg";
import MenuNavigation from "./components/Products/MenuNavigation";
import { GET_MENU_NAVIGATION } from "./actions";
import { projectTitle } from "./components/Helpers/Config";
class Page404 extends Component {
  constructor(props) {
    super(props);
    this.state = { errorStatus: "" };
    this.props.getMenuNavigation();
  }

  render() {
    return (
      <>
     <header>
          <div className="header-top-cls">
            <div className="container">
              <div className="logo-main-section">
                <div className="logo">
                  <Link to={"/"} title={projectTitle}>
                    <img src={mainLogo} alt="Logo" />
                  </Link>
                </div>

             
              </div>
            </div>
          </div>
      
          <div className="mbl-menu-overly"></div>
        </header>
      <div className="container page404-main-div">
        <div className="page404-inner-main">
          <div className="page404-lhs">
            <img src={pageNotFnd} alt="page not found" />
          </div>
          <div className="page404-rhs">
            <h1>Oops!</h1>
            <p>Have a drink here</p>
          </div>
        </div>
        <section className="product-home-category">
          <div className="container">
            <div className="normal-menu-nav">
              <MenuNavigation
                {...this.props}
                productState={this.state}
                sateValChange={this.sateValChange}
                includepage={"home"}
              />
            </div>
          </div>
        </section>
      </div>
      </>
    );
  }
}

const mapStateTopProps = (state) => {

  var navigateRst =[];
  var navigateCmn = [];
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? []
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
      }
    }
  }
 
  return {
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMenuNavigation: () => {
      dispatch({ type: GET_MENU_NAVIGATION });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Page404);