import { SET_STRIPE_KEY } from '../actions';

const stripekey = (state = [], action) => {
  switch (action.type) {
    case SET_STRIPE_KEY:
      return [...action.value];
    default: return state; 
  } 
} 

export default stripekey;