/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { SET_STRIPE_KEY, GET_STRIPE_KEY} from '../actions';
import { apiUrl,headerconfig } from "../components/Helpers/Config";

import {   getHeaderAuth } from "../components/Helpers/SettingHelper";
import Axios from 'axios';
 


/* Stripe key authorize */

export const watchGetStripeKey = function* () {
  yield takeEvery(GET_STRIPE_KEY, workerGetStripeKey);
}

function* workerGetStripeKey({ stripedata }) {
   

  try {
    const result = yield call(GetStripeKey, stripedata);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_STRIPE_KEY, value: resultArr });
  } 
  catch(e) {
    console.log('Stripe Key  failed sdgdfg'+e); 
  } 
}  
  

function GetStripeKey(stripedata) {  
  return Axios.post(apiUrl+'payment/stripeClientTokenGenerateNew', stripedata,headerconfig);
} 

 