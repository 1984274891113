/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { stripslashes } from "../Helpers/SettingHelper";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import categoryimg from "../../common/images/badge1.png";
class MenuNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateMenu: [],
      selectedNavigation: "",
    };
  }

  componentWillReceiveProps(pdtProps) {
    if (
      this.state.selectedNavigation !== pdtProps.productState.selectedNavigation
    ) {
      this.setState({
        selectedNavigation: pdtProps.productState.selectedNavigation,
      });
    }
  }

  navigateMenuList() {
    let navigateMenuArr = this.props.navigateMenu;
    let navigateCommon = this.props.navigateCommon;
    var selectedNav = this.state.selectedNavigation;

    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
        if (loaddata.menu_type == "main") {
          if (
            selectedNav === loaddata.pro_cate_slug &&
            this.props.productState.startcategory === true
          ) {
            var categorydesc =
              loaddata.pro_cate_description !== ""
                ? loaddata.pro_cate_description
                : loaddata.pro_cate_short_description !== ""
                ? loaddata.pro_cate_short_description
                : "";
            this.props.sateValChange("categorydesc", categorydesc);
            this.props.sateValChange(
              "categorytitle",
              loaddata.menu_custom_title
            );
          }
          return (
            <div
              key={index + 1}
              className={
                selectedNav === loaddata.pro_cate_slug
                  ? "menu-list-intvl active"
                  : "menu-list-intvl"
              }
            >
              <Link
                to={"/products/category/" + loaddata.pro_cate_slug}
                onClick={this.goCategoryProducts.bind(
                  this,
                  loaddata.pro_cate_slug
                )}
                className="menu-title-link"
                title={loaddata.menu_custom_title}
              >
                <div>
                  <img
                    src={
                      loaddata.pro_cate_image !== "" &&
                      loaddata.pro_cate_image !== null
                        ? navigateCommon.category_image_url +
                          "/" +
                          loaddata.pro_cate_image
                        : categoryimg
                    }
                    title={stripslashes(
                      loaddata.menu_custom_title.toLowerCase()
                    )}
                    alt=""
                  />{" "}
                </div>
                {stripslashes(loaddata.menu_custom_title.toLowerCase())}
              </Link>
            </div>
          );
        } else if (loaddata.menu_type == "sub") {
          return (
            <div
              key={index + 1}
              className={
                selectedNav === loaddata.pro_subcate_slug
                  ? "menu-list-intvl active"
                  : "menu-list-intvl"
              }
            >
              <Link
                to={"/"}
                className="menu-title-link"
                title={loaddata.menu_custom_title}
              >
                <div>
                  <img
                    src={categoryimg}
                    title={stripslashes(
                      loaddata.menu_custom_title.toLowerCase()
                    )}
                    alt=""
                  />{" "}
                </div>
                {stripslashes(loaddata.menu_custom_title.toLowerCase())}
              </Link>
            </div>
          );
        }
      });
      return mainMenu;
    } else {
      return;
    }
  }

  goCategoryProducts(pro_cate_slug, event) {
    if (pro_cate_slug === this.state.selectedNavigation) {
      event.preventDefault();
    } else {
      $(".more-menu-parent, .more_categor_info").removeClass("active");
      $(".more_categor_info").hide();
    }
  }

  render() {
    if (this.props.includepage === "home") {
      var settingsMenu = {
        items: 10,
        autoplay: false,
        nav: true,
        dots: false,
        margin: 10,
        responsive: {
          0: {
            items: 3,
            margin: 5,
          },
          370: {
            items: 4,
            margin: 5,
          },
          600: {
            items: 5,
          },
          900: {
            items: 7,
          },
          1200: {
            items: 10,
          },
        },
      };
    } else {
      var settingsMenu = {
        startPosition: 1,
        items: 6,
        autoplay: false,
        margin: 10,
        nav: true,
        dots: false,
        responsive: {
          0: {
            items: 3,
            margin: 5,
          },
          370: {
            items: 4,
            margin: 5,
          },
          600: {
            items: 5,
          },
          900: {
            items: 6,
          },
          1200: {
            items: 8,
          },
        },
      };
    }

    return (
      <div className="menu-section-left">
        <div className="menu-section-left-inner product-menu">
          <OwlCarousel options={settingsMenu}>
            {Object.keys(this.props.navigateMenu).length > 0 ? (
              this.navigateMenuList()
            ) : (
              <div></div>
            )}
          </OwlCarousel>
        </div>
      </div>
    );
  }
}

export default MenuNavigation;
