/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_ADDONPRODUCT, SET_ADDONPRODUCT } from "../actions";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  headerconfig,
} from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";
import { format } from "date-fns";
export const watchGetAddonPro = function* () {
  yield takeEvery(GET_ADDONPRODUCT, workerGetAddonPro);
};

function* workerGetAddonPro({ outletId }) {
  try {
    var availabilityId =
      cookie.load("defaultAvilablityId") === undefined ||
      cookie.load("defaultAvilablityId") == ""
        ? deliveryId
        : cookie.load("defaultAvilablityId");
    var deliveryDate =
      cookie.load("deliveryDate") == undefined ||
      cookie.load("deliveryDate") == ""
        ? ""
        : cookie.load("deliveryDate").split("/");
    var delivery_Date = "";
    if (deliveryDate !== "") {
      delivery_Date =
        deliveryDate[2] + "-" + deliveryDate[1] + "-" + deliveryDate[0];
    } else {
      delivery_Date = format(new Date(), "yyyy-MM-dd");
    }
    var spicelPride =
      "&specialprice_applicable=orderdate&order_datetxt=" + delivery_Date;
    const uri =
      apiUrlV2 +
      "products/getAllProducts?app_id=" +
      appId +
      "&apply_addon=Yes&availability=" +
      availabilityId +
      "&outletId=" +
      outletId +
      spicelPride;
    const result = yield call(Axios.get, uri, headerconfig);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_ADDONPRODUCT, value: resultArr });
  } catch {
    console.log("Get Addon Product Failed");
  }
}
