/* eslint-disable */
import React, { useEffect, useState } from "react";
import {useStripe, useElements, PaymentElement,CardElement} from '@stripe/react-stripe-js';
import cookie from "react-cookies";

import CardSection from './CardSection';
import { language } from "../Helpers/Language";
import { apiUrl,appId,headerconfig,siteURL,stripeReference } from "../Helpers/Config";
import $ from 'jquery'; 
import Axios from 'axios';
import { ReactSession }  from 'react-client-session';
import {
  showLoader,
  hideLoader,
} from "../Helpers/SettingHelper";

var qs = require('qs');

export default function CheckoutForm() {

  
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }
  }, [stripe]);
   
  const handleSubmit = async (event) => {
    
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    showLoader("spinner", "Idtext");

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: siteURL+"placeorder",
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }  
    setIsLoading(false);
    hideLoader("spinner", "Idtext");
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button disabled={isLoading || !stripe || !elements} id="submit" className="btn btn-block btn_pink">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner">{language.fullpayment}</div> : language.fullpayment}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
} 