/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import footerLogo from "../../common/images/f-logo.svg";
import { projectTitle } from "../Helpers/Config";
import { language } from "../Helpers/Language";
import { GET_STATIC_BLOCK, GET_GLOBAL_SETTINGS } from "../../actions";
var Parser = require("html-react-parser");
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { footerBlocks: "", footerlink: "", globalsettings: [] };
    this.props.getGlobalSettings();
  }

  componentDidMount() {
    this.props.getStaticBlock();

    var btn = $("#scrollbutton");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 300) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    btn.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.footerBlocks) {
      var footerPas = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "footer-contents") {
            footerPas = data.staticblocks_description;
            return "";
          }
        });
      }
      footerPas = footerPas !== "" ? Parser(footerPas) : footerPas;
      this.setState({
        footerBlocks: PropsData.staticblack,
        footerlink: footerPas,
      });
    }
    if (this.state.globalsettings !== PropsData.globalsettings) {
      if (PropsData.globalsettings.length > 0) {
        this.setState({
          globalsettings: PropsData.globalsettings[0].result_set,
        });
      }
    }
  }
  render() {
    var todayTimeSp = new Date();
    var yearSp = todayTimeSp.getFullYear();

    return (
      <footer className="footer-main">
        <div className="footer-logo">
          <Link className="" to={"/"} title={projectTitle}>
            <img src={footerLogo} alt="footer logo" />
          </Link>
        </div>
        {this.state.footerlink}

        <div className="copyright-section">
          <p>
            {language.footercpy}
          </p>
        </div>
        <div className="scroll_top_parent">
          <div className="scrolltop" id="scrollbutton">
            <a href="/" className="disbl_href_action">
              <span>
                <i className="fa fa-angle-double-up ars"></i>
                <i className="fa fa-angle-up ars1"></i>
                <i className="fa fa-angle-up ars2"></i>
              </span>
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  return {
    staticblack: blacksArr,
    globalsettings: state.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Footer);
