/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import { connect } from "react-redux";
import Slider from "react-slick";
import StripeCheckout from "react-stripe-checkout";
import moment, { lang } from "moment";
import axios from "axios";
import Select from "react-select";
import { format } from "date-fns";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
//import OrderdatetimeSlot from "../Layout/OrderdatetimeSlot";
import OrderAdvancedDatetimeSlot from "../Layout/OrderAdvancedDatetimeSlot";
import { language } from "../Helpers/Language";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  pickupId,
  CountryTxt,
  stripeReference,
  stripeCurrency,
  stripeImage,
  stripeCompany,
  currencySymbol,
  projectTitle,
  headerconfig,
  defaultoutlet,
  cookieConfig,
} from "../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  showLoader,
  hideLoader,
  getCalculatedAmount,
  showAlert,
  showCustomAlert,
  getPromoCkValue,
  getreedemPointValue,
  getCCPromoValue,
  removePromoCkValue,
  smoothScroll,
  showPrices,
  showPoint,
  subscribeCycle,
  showPoint_total,
  getCorporateWalletValue,
  showRewardPoints,
} from "../Helpers/SettingHelper";
import {
  GET_CART_DETAIL,
  UPDATE_CART_DETAIL,
  DELETE_CART_DETAIL,
  DESTROY_CART_DETAIL,
  GET_ACTIVITYCOUNT,
  GET_ADDONPRODUCT,
  GET_STRIPE_KEY,
} from "../../actions";

import { ReactSession } from "react-client-session";

import noimage from "../../common/images/no-img-product.png";
import loadingImage from "../../common/images/loading_popup.gif";
import tickImage from "../../common/images/tick_popup.png";
import crossImg from "../../common/images/cross1.png";
import warningImg from "../../common/images/warning.svg";
import paymentImage from "../../common/images/payment.jpg";
import productImg from "../../common/images/noimg-400x400.jpg";
import go from "../../common/images/checkout-go.png";
import locationpin from "../../common/images/location-pin.png";
import royality from "../../common/images/royality.png";
import speaker from "../../common/images/promo-speaker.png";
import paybystripe from "../../common/images/pay-by-stripe.png";
import cashImage from "../../common/images/pay-by-cod.png";
import payment_cards from "../../common/images/pay-by-stripe.png";
import warningplus from "../../common/images/18plus.png";

var qs = require("qs");
var Parser = require("html-react-parser");
var stripePromise = "";

class Checkout extends Component {
  constructor(props) {
    super(props);
    /* Rounding to the nearest 15 minute interval */
    const start = moment();
    const remainder = 15 - (start.minute() % 15);
    const currentTime = moment(start).add(remainder, "minutes");
    /* To change the date after 2 PM */

    var CurrentDate = new Date();

    var avilablityId =
      cookie.load("defaultAvilablityId") != "" &&
      cookie.load("defaultAvilablityId") != undefined
        ? cookie.load("defaultAvilablityId")
        : deliveryId;
    var orderOutletId =
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined
        ? cookie.load("orderOutletId")
        : defaultoutlet;
    var userregisterType =
      cookie.load("Userregister_type") != "" &&
      cookie.load("Userregister_type") != undefined
        ? cookie.load("Userregister_type")
        : "";
    var orderPostalCode =
      typeof cookie.load("orderPostalCode") === "undefined"
        ? ""
        : cookie.load("orderPostalCode");
    var orderDeliveryAddress =
      typeof cookie.load("orderDeliveryAddress") === "undefined"
        ? ""
        : cookie.load("orderDeliveryAddress");
    var unitNoOne =
      typeof cookie.load("unitNoOne") === "undefined"
        ? ""
        : cookie.load("unitNoOne");
    var unitNoTwo =
      typeof cookie.load("unitNoTwo") === "undefined"
        ? ""
        : cookie.load("unitNoTwo");

    var billingPostalCode =
      typeof cookie.load("billingPostalCode") === "undefined"
        ? ""
        : cookie.load("billingPostalCode");
    var billingDeliveryAddress =
      typeof cookie.load("billingDeliveryAddress") === "undefined"
        ? ""
        : cookie.load("billingDeliveryAddress");
    var billunitNoOne =
      typeof cookie.load("billunitNoOne") === "undefined"
        ? ""
        : cookie.load("billunitNoOne");
    var billunitNoTwo =
      typeof cookie.load("billunitNoTwo") === "undefined"
        ? ""
        : cookie.load("billunitNoTwo");

    var orderDateTime =
      typeof cookie.load("orderDateTime") === "undefined"
        ? ""
        : cookie.load("orderDateTime");
    var orderTAT =
      typeof cookie.load("orderTAT") === "undefined"
        ? 60
        : cookie.load("orderTAT");

    /* For Advanced Slot */
    var isAdvanced =
      typeof cookie.load("isAdvanced") === "undefined"
        ? ""
        : cookie.load("isAdvanced");
    var slotType =
      typeof cookie.load("slotType") === "undefined"
        ? ""
        : cookie.load("slotType");
    var orderDeliveryAddress =
      cookie.load("orderDeliveryAddress") != "" &&
      cookie.load("orderDeliveryAddress") != undefined
        ? cookie.load("orderDeliveryAddress")
        : "";
    var setDeliveryAddress = [];
    if (orderDeliveryAddress !== "") {
      setDeliveryAddress = {
        value: orderDeliveryAddress,
        label: orderDeliveryAddress,
      };
    }

    var corporateCustomerOrder =
      userregisterType === "Corporate_customer" ? "Yes" : "No";

    var getPromoCkVal = getPromoCkValue();
    var getReedemPointCkVal = getreedemPointValue();
    var getCCPromoCkVal = getCCPromoValue();
    var getCCPromoVal = getCCPromoValue();
    var getWalletAmount = getCorporateWalletValue();

    var Maxdate = new Date();
    Maxdate.setFullYear(Maxdate.getFullYear() - 10);
    this.state = {
      globalSettings: [],
      Maxdate: Maxdate,
      staticblacks: [],
      overAllcart: [],
      cartItems: [],
      cartDetails: [],
      cartStatus: "",
      cartTotalItmCount: 0,
      order_postcode: orderPostalCode,
      order_delivery_address: orderDeliveryAddress,
      unitnumber1: unitNoOne,
      unitnumber2: unitNoTwo,
      billing_postcode: billingPostalCode,
      billing_delivery_address: billingDeliveryAddress,
      billunitnumber1: billunitNoOne,
      billunitnumber2: billunitNoTwo,
      billing_addrs_sameas: "yes",
      order_specil_note: "",

      /* For Advanced Slot */
      getDateTimeFlg: "yes",
      seleted_ord_date: "",
      seleted_ord_time: "",
      seleted_ord_slot: "",
      seleted_ord_slotTxt: "",
      seleted_ord_slot_str: "",
      seleted_ord_slot_end: "",
      isAdvanced: isAdvanced,
      slotType: slotType,

      activitycount_arr: [],
      promotion_count: 0,
      reward_point_count: 0,

      order_reward_sub_total: getReedemPointCkVal["rewardSubTotal"],
      used_reward_point: getReedemPointCkVal["usedPoints"],
      reward_point_val: getReedemPointCkVal["reedemPointVal"],
      randompoint_applied: getReedemPointCkVal["promotionApplied"],
      randompoint_amount: getReedemPointCkVal["promotionAmount"],

      order_promo_sub_total: getPromoCkVal["promoSubTotal"],
      promo_code_val: getPromoCkVal["promoCodeVal"],
      promotion_applied: getPromoCkVal["promotionApplied"],
      promotion_type: getPromoCkVal["promotionType"],
      promotion_amount: getPromoCkVal["promotionAmount"],
      promotion_source: getPromoCkVal["promotionSource"],
      promoIs_delivery: getPromoCkVal["promoIsDelivery"],

      paymentmodevalue: "Stripe",
      validateimage: loadingImage,
      placingorderimage: loadingImage,
      completingpaymentimage: loadingImage,
      subscriptionCaptureLoading: loadingImage,
      orderValidFail: 0,
      cod_payment_enable: 0,
      stripe_payment_enable: 0,
      postorder_triggered: "no",
      chk_tarms_contn: "No",
      termsAndConditions: "",
      seletedAvilablityId: avilablityId,
      seletedOutletId: orderOutletId,
      order_tat_time: orderTAT,
      viewProductSlug: "",
      cust_birthdate_update: "no",
      cust_birthdate: "",
      cust_gender: "",
      omise_payment_enable: 0,
      omise_log_id: "",
      omisecodeerror: "",
      omiseyearerror: "",
      omisemontherror: "",
      omisecardrror: "",
      omisenameerror: "",
      holdername: "",
      cardNumber: "",
      expiration_month: "",
      expiration_year: "",
      security_code: "",
      cardImage: "",
      payment_ref_id: "",
      omise_tokken_response: "",
      omise_tokken_card_id: "",
      omise_tokken_id: "",
      updateCartResponse: [],
      startMsg: 0,
      recipient_name: "",
      recipient_contact_no: "",
      gift_message: "",
      addAddress: "No",
      secondaryaddress: [],
      addressList: [],
      setDeliveryAddress: setDeliveryAddress,

      userregister_type: userregisterType,
      corporate_customer_order: corporateCustomerOrder,
      corporate_company_balance: 0,
      corporate_promo_avbl: "no",

      corporate_promo_code: getCCPromoCkVal["ccpromoCodeVal"],
      order_cpcustomer_promo_sub_total: getCCPromoCkVal["ccpromoSubTotal"],
      cpcustomer_coupon_applied: getCCPromoCkVal["promotionApplied"],
      cpcustomer_coupon_amount: getCCPromoCkVal["promotionAmount"],
      cpcustomer_promo_code: getCCPromoCkVal["ccpromoCodeVal"],
      cpcustomer_promotion_delivery_charge_applied:
        getCCPromoCkVal["promoIsDelivery"],

      corporate_customer_wallet_input: getWalletAmount["usedWalletAmount"],
      corporate_customer_wallet_pay_amount: getWalletAmount["usedWalletAmount"],
      corporate_wallet_pay: getWalletAmount["corporate_wallet_pay"],
      corporate_wallet_type: "notpay",

      order_discount_both: 1,
      cardList: [],
      displayCard: "",
      payCard: "",
      clientSecret: "",
      selectCard: "new",
      saveMyCard: "No",
      cardID: "",
      outlet_platformfee: "",
    };

    this.getActivityCounts();
    this.props.getAddonProList(orderOutletId);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.loadCustomerCard();
  }

  /* Convert Timezone */
  calcTime(city, offset) {
    var d = new Date();
    var utc = d.getTime() + d.getTimezoneOffset() * 60000;
    var nd = new Date(utc + 3600000 * offset);
    return nd;
  }

  changeBillingAddrChk() {
    var isSame = this.state.billing_addrs_sameas;
    if (isSame === "yes") {
      var tempvlu = "";
      this.setState({
        billing_postcode: tempvlu,
        billing_delivery_address: tempvlu,
        billunitnumber1: tempvlu,
        billunitnumber2: tempvlu,
        billing_addrs_sameas: "no",
      });
    } else {
      this.setState(
        { billing_addrs_sameas: "yes" },
        function() {
          this.setBillingAddrs();
        }.bind(this)
      );
    }
  }

  setBillingAddrs() {
    var isSame = this.state.billing_addrs_sameas;
    if (isSame === "yes") {
      var postalCode = this.state.order_postcode;
      var custAddress = this.state.order_delivery_address;
      var floorNo = this.state.unitnumber1;
      var unitNo = this.state.unitnumber2;
      this.setState({
        billing_postcode: postalCode,
        billing_delivery_address: custAddress,
        billunitnumber1: floorNo,
        billunitnumber2: unitNo,
      });
    }
  }

  CheckBillingAddrChk(type) {
    var isSame = this.state.billing_addrs_sameas;
    var chkBox = false;
    var diplayTxt = "block";
    if (isSame === "yes") {
      chkBox = true;
      diplayTxt = "none";
    }

    return type === "checkbox" ? chkBox : diplayTxt;
  }

  handleAddrChange(event) {
    if (event.target.name === "unit_no1") {
      cookie.save("unitNoOne", event.target.value, cookieConfig);
      this.setState(
        { unitnumber1: event.target.value },
        function() {
          this.setBillingAddrs();
        }.bind(this)
      );
    } else if (event.target.name === "unit_no2") {
      cookie.save("unitNoTwo", event.target.value, cookieConfig);
      this.setState(
        { unitnumber2: event.target.value },
        function() {
          this.setBillingAddrs();
        }.bind(this)
      );
    } else if (event.target.name === "specil_note") {
      this.setState({ order_specil_note: event.target.value });
    } else if (event.target.name === "reward_point") {
      var reward_amount_value = "";
      if (event.target.value > 0) {
        reward_amount_value = showPoint_total(Math.ceil(event.target.value));
      }
      this.setState({ reward_point_val: reward_amount_value });
    } else if (event.target.name === "promo_code") {
      this.setState({ promo_code_val: event.target.value });
    } else if (event.target.name === "bill_unit_no1") {
      this.setState({ billunitnumber1: event.target.value });
    } else if (event.target.name === "bill_unit_no2") {
      this.setState({ billunitnumber2: event.target.value });
    } else if (event.target.name === "recipient_name") {
      this.setState({ recipient_name: event.target.value });
    } else if (event.target.name === "recipient_contact_no") {
      this.setState({ recipient_contact_no: event.target.value });
    } else if (event.target.name === "gift_message") {
      this.setState({ gift_message: event.target.value });
    } else if (event.target.name === "wallet_amount") {
      var wallet_amount_value = "";
      if (event.target.value > 0) {
        wallet_amount_value = showPoint_total(Math.ceil(event.target.value));
      }
      this.setState({ corporate_customer_wallet_input: wallet_amount_value });
    }
  }

  /* Validate Float Value */
  validateFloatval(e) {
    const re = /[0-9.]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  /* Validate Int Value */
  validateIntval(e) {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  /* post code */
  changePostalCode(isFrm, event) {
    var postalCode = event.target.value;
    var postCdLth = postalCode !== "" ? postalCode.length : 0;

    if (postalCode !== "" && parseInt(postCdLth) === 6) {
      var urlShringTxt =
        apiUrl +
        "outlets/find_outlet?app_id=" +
        appId +
        "&availability_id=" +
        deliveryId +
        "&postal_code=" +
        postalCode;

      axios.get(urlShringTxt, headerconfig).then((res) => {
        var custAddress = "";
        if (res.data.status === "ok") {
          var outltResulSet = res.data.result_set;
          if (Object.keys(outltResulSet.postal_code_information).length > 0) {
            var outletAddress =
              outltResulSet.postal_code_information.zip_buno != ""
                ? outltResulSet.postal_code_information.zip_buno +
                  ", " +
                  outltResulSet.postal_code_information.zip_sname
                : outltResulSet.postal_code_information.zip_sname;

            custAddress = outletAddress + " " + CountryTxt;
          }
        }

        this.setState({
          billing_postcode: postalCode,
          billing_delivery_address: custAddress,
        });
      });
    } else {
      var temparrs = "";
      this.setState({
        billing_postcode: postalCode,
        billing_delivery_address: temparrs,
      });
    }
  }

  /* Get Redeem Points Count History Details */
  getActivityCounts() {
    const inputKeys = ["promotionwithoutuqc", "reward", "testtxt"];
    this.props.getActivityCount(JSON.stringify(inputKeys));
  }

  /* Get Redeem Points Count History Details */
  getActivityCountsInitial() {
    const inputKeys = ["promotionwithoutuqc", "reward", "testtxt"];
    var access_token =
      cookie.load("access_token") !== "" &&
      typeof cookie.load("access_token") !== undefined &&
      typeof cookie.load("access_token") !== "undefined"
        ? "&access_token=" + cookie.load("access_token")
        : "";
    var customerParam =
      "&status=A&customer_id=" +
      cookie.load("UserId") +
      access_token +
      "&availability_id=" +
      cookie.load("defaultAvilablityId");
    var actArrParam = "&act_arr=" + JSON.stringify(inputKeys);
    const urlShringTxt =
      apiUrl +
      "reports/activity_counts1?app_id=" +
      appId +
      actArrParam +
      customerParam;
    axios.get(urlShringTxt, headerconfig).then((res) => {
      var corporateCompanyBalance = 0;
      var promo_apply = "No";
      var promo_code = "";
      if (res.data.status === "ok") {
        var activityResulSet = res.data.result_set;
        if (Object.keys(activityResulSet).length > 0) {
          corporateCompanyBalance =
            activityResulSet.corporate_company_balance != ""
              ? activityResulSet.corporate_company_balance
              : 0;
          if (
            Object.keys(activityResulSet.corporate_promo).length > 0 &&
            activityResulSet.corporate_promo.promo_apply === "Yes"
          ) {
            promo_apply = "Yes";
            promo_code = activityResulSet.corporate_promo.promo_code;
          }
        }
      }

      if (
        cookie.load("randompointApplied") !== "Yes" &&
        cookie.load("promotionApplied") !== "Yes"
      ) {
        this.setState(
          {
            corporate_company_balance: corporateCompanyBalance,
            corporate_promo_avbl: promo_apply,
            corporate_promo_code: promo_code,
          },
          function() {
            this.applyCorporatePromo();
          }
        );
      } else {
        this.setState({
          corporate_company_balance: corporateCompanyBalance,
          corporate_promo_avbl: promo_apply,
        });
      }
    });
  }

  applyCorporatePromo() {
    var corporate_promo_avbl = this.state.corporate_promo_avbl;
    var corporate_promo_code = this.state.corporate_promo_code;
    if (corporate_promo_avbl === "Yes" && corporate_promo_code !== "") {
      var cpcustomerPromoSubTotal = this.state.order_cpcustomer_promo_sub_total;
      var cpcustomerCouponApplied = this.state.cpcustomer_coupon_applied;
      var cpcustomerCouponAmount = this.state.cpcustomer_coupon_amount;
      var cpcustomerPromoCode = this.state.cpcustomer_promo_code;
      var cpcustomerPromoDeliveryApplied = this.state
        .cpcustomer_promotion_delivery_charge_applied;

      var cartDetailsArr = this.state.cartDetails;
      var cartItemsSateArr = this.state.cartItems;

      var subTotal =
        Object.keys(cartDetailsArr).length > 0
          ? cartDetailsArr.cart_sub_total
          : 0;
      var totalItems =
        Object.keys(cartDetailsArr).length > 0
          ? cartDetailsArr.cart_total_items
          : 0;
      var categoryIdsDet = this.getProductIdsDet(cartItemsSateArr);
      var avilablityId = cookie.load("defaultAvilablityId");

      var postObject = {
        app_id: appId,
        reference_id: cookie.load("UserId"),
        access_token:
          cookie.load("access_token") !== "" &&
          typeof cookie.load("access_token") !== undefined &&
          typeof cookie.load("access_token") !== "undefined"
            ? cookie.load("access_token")
            : "",
        promo_code: corporate_promo_code,
        cart_amount: subTotal,
        cart_quantity: totalItems,
        category_id: categoryIdsDet,
        availability_id: avilablityId,
        outlet_id: cookie.load("orderOutletId"),
        corporate_customer_order: "Yes",
      };

      axios
        .post(
          apiUrl + "promotion_api_v2/apply_promotion",
          qs.stringify(postObject),
          headerconfig
        )
        .then((res) => {
          if (res.data.status === "success") {
            var pointDet = res.data.result_set;
            var IsDelivery =
              pointDet.promotion_delivery_charge_applied == "Yes"
                ? "Yes"
                : "No";
            cpcustomerCouponApplied = "Yes";
            cpcustomerCouponAmount = pointDet.promotion_amount;
            cpcustomerPromoCode = corporate_promo_code;
            cpcustomerPromoDeliveryApplied = IsDelivery;
            cpcustomerPromoSubTotal = subTotal;
          } else {
            cpcustomerCouponApplied = "No";
            cpcustomerCouponAmount = 0;
            cpcustomerPromoCode = "";
            cpcustomerPromoDeliveryApplied = "";
            cpcustomerPromoSubTotal = 0;
          }

          this.setState(
            {
              order_cpcustomer_promo_sub_total: cpcustomerPromoSubTotal,
              cpcustomer_coupon_applied: cpcustomerCouponApplied,
              cpcustomer_coupon_amount: cpcustomerCouponAmount,
              cpcustomer_promo_code: cpcustomerPromoCode,
              cpcustomer_promotion_delivery_charge_applied: cpcustomerPromoDeliveryApplied,
            },
            function() {
              cookie.save(
                "ccpromoSubTotal",
                cpcustomerPromoSubTotal,
                cookieConfig
              );
              cookie.save("ccpromoCodeVal", cpcustomerPromoCode, cookieConfig);
              cookie.save(
                "ccpromotionApplied",
                cpcustomerCouponApplied,
                cookieConfig
              );
              cookie.save(
                "ccpromotionAmount",
                cpcustomerCouponAmount,
                cookieConfig
              );
              cookie.save(
                "ccpromoIsDelivery",
                cpcustomerPromoDeliveryApplied,
                cookieConfig
              );
            }
          );
        });
    }
  }

  loadCustomerCard() {
    var access_token =
      cookie.load("access_token") !== "" &&
      typeof cookie.load("access_token") !== undefined &&
      typeof cookie.load("access_token") !== "undefined"
        ? "&access_token=" + cookie.load("access_token")
        : "";
    axios
      .get(
        apiUrl +
          "subscription/getCustomerCard?app_id=" +
          appId +
          "&customer_id=" +
          cookie.load("UserId") +
          access_token,
        headerconfig
      )
      .then((response) => {
        if (response.data.status === "ok") {
          this.setState({ cardList: response.data.result_set }, function() {
            this.loadCardList();
          });
        }
      });
  }
  loadCardList() {
    var displayCard = "";
    if (this.state.cardList.length > 0) {
      displayCard = this.state.cardList.map((item, index) => {
        var bransName = item.brand.toLowerCase();
        var image_name = "";
        if (bransName === "visa") {
          image_name = "visa.svg";
        } else if (bransName === "mastercard") {
          image_name = "mastercard.svg";
        } else if (bransName === "unionpay") {
          image_name = "union.svg";
        } else if (bransName === "american express") {
          image_name = "american-express.svg";
        } else if (bransName === "diners_club") {
          image_name = "diners-club-international.svg";
        } else if (bransName === "discover") {
          image_name = "discover.svg";
        } else if (bransName === "jcb") {
          image_name = "jcb.svg";
        }

        return (
          <li
            key={index}
            className={this.state.selectCard === item.c_id ? "active" : ""}
            onClick={this.selectCard.bind(this, item.c_id)}
          >
            <div className="card-brand-parent">
              <div className="card-brand">
                <img src={"./img/cards/" + image_name} alt={item.brand} />
              </div>
              <div className="card-no">
                <div className="card-common-no">
                  <div className="card-brand-action">
                    <a href="#" className="edit-card hidden">
                      <i
                        className="fa fa-pencil-square-o"
                        aria-hidden="true"
                      ></i>
                    </a>
                    <a
                      href={void 0}
                      className="delete-card"
                      onClick={this.deleteCard.bind(this, item.c_id)}
                    >
                      <i className="fa fa-trash-o" aria-hidden="true"></i>
                    </a>
                  </div>
                  <div className="card-heading">
                    <h4>{item.brand}</h4>
                  </div>
                </div>
                **** **** **** {item.last4}
              </div>
            </div>

            <div className="card-holder-merge">
              <div className="card-holder">
                <span>{language.cardholder}</span>
                <p>{item.name}</p>
              </div>
              <div className="card-expiry">
                <span>{language.expirydate}</span>
                <p>
                  {item.exp_month}/{item.exp_year}
                </p>
              </div>
            </div>
          </li>
        );
      });
    }
    this.setState({ displayCard: displayCard });
  }
  selectCard(cardID) {
    this.setState({ selectCard: cardID }, function() {
      this.loadCardList();
    });
    setTimeout(function() {
      $("html, body").animate(
        {
          scrollTop: $(".savedcardsubmit").offset().top - 100,
        },
        1000
      );
    }, 500);
  }

  deleteCard(cardID) {
    this.setState(
      {
        cardID: cardID,
      },
      function() {
        $.magnificPopup.open({
          items: {
            src: "#cancellsubscription-popup",
          },
          type: "inline",
        });
      }
    );
  }

  proceeddeleteCard(e) {
    e.preventDefault();
    showLoader("cancel_proceed", "Idtext");
    var postObject = {
      app_id: appId,
      customer_id: cookie.load("UserId"),
      access_token:
        cookie.load("access_token") !== "" &&
        typeof cookie.load("access_token") !== undefined &&
        typeof cookie.load("access_token") !== "undefined"
          ? cookie.load("access_token")
          : "",
      payment_reference: stripeReference,
      card_id: this.state.cardID,
    };
    axios
      .post(
        apiUrl + "payment/deleteCard",
        qs.stringify(postObject),
        headerconfig
      )
      .then((res) => {
        hideLoader("cancel_proceed", "Idtext");
        if (res.data.status === "ok") {
          this.handleShowAlertFunct(language.success, language.deletedcard);
          setTimeout(function() {
            window.location.reload();
          }, 1000);
        } else {
          this.handleShowAlertFunct(language.error, res.data.message);
        }
      });
  }

  componentWillMount() {
    /*this.props.getCartDetail();*/
  }

  componentWillReceiveProps(nextProps) {
    $("body").removeClass("cart-items-open");
    $(".hcartdd_trigger").removeClass("active");
    $(".hcart_dropdown").removeClass("open");

    if (this.state.globalSettings !== nextProps.settingsArr) {
      var tampStArr = nextProps.settingsArr;
      var paymentmode = "Stripe";
      var codPayment = 0;
      var stripePayment = 0;
      var omisePayment = 0;
      if (Object.keys(tampStArr).length > 0) {
        if (
          tampStArr.client_cod_enable == 1 &&
          tampStArr.client_cod_availability == 1
        ) {
          codPayment = 1;
          paymentmode = "Cash";
        }

        if (
          tampStArr.client_stripe_enable == 1 &&
          tampStArr.client_stripe_availability == 1
        ) {
          stripePayment = 1;
        }

        if (
          tampStArr.client_omise_enable == 1 &&
          tampStArr.client_omise_availability == 1
        ) {
          omisePayment = 1;
          if (paymentmode !== "Cash") {
            paymentmode = "Omise";
          }
        }
      }

      this.setState({
        globalSettings: nextProps.settingsArr,
        cod_payment_enable: codPayment,
        stripe_payment_enable: stripePayment,
        omise_payment_enable: omisePayment,
        paymentmodevalue: paymentmode,
      });
    }

    if (this.state.activitycount_arr !== nextProps.activitycountArr) {
      this.setState({
        activitycount_arr: nextProps.activitycountArr,
        promotion_count: nextProps.activitycountArr.promotionwithoutuqc,
        reward_point_count: nextProps.activitycountArr.reward_ponits,
      });
    }

    if (this.state.overAllcart !== nextProps.overAllcart) {
      this.setState({
        overAllcart: nextProps.overAllcart,
        cartItems: nextProps.cartItems,
        cartDetails: nextProps.cartDetails,
        cartStatus: nextProps.cartStatus,
        cartTotalItmCount: nextProps.cartTotalItmCount,
      });
      ReactSession.set("subscriptionavailability", 0);
      window.sessionStorage.setItem("subscriptionavailability", 0);
      var row = nextProps.cartItems.map(
        function(item, i) {
          if (
            item.cart_item_subscription_start_date !== "" &&
            item.cart_item_subscription_start_date !== "0000-00-00" &&
            item.cart_item_subscription_start_date !== null
          ) {
            ReactSession.set("subscriptionavailability", 1);
            window.sessionStorage.setItem("subscriptionavailability", 1);
          }
        }.bind(this)
      );
    }

    if (nextProps.staticblack !== this.state.staticblacks) {
      var termsConditions = "";
      if (Object.keys(nextProps.staticblack).length > 0) {
        nextProps.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "terms-and-conditions") {
            termsConditions = data.staticblocks_description;
            return "";
          }
        });
      }
      termsConditions =
        termsConditions !== "" ? Parser(termsConditions) : termsConditions;
      this.setState({
        staticblacks: nextProps.staticblack,
        termsAndConditions: termsConditions,
      });

      setTimeout(function() {
        $(".checkout-terms-and-condition").mCustomScrollbar();
      }, 800);
    }

    if (
      nextProps.stripekey !== undefined &&
      nextProps.stripekey !== this.state.stripekey
    ) {
      this.setState({
        stripekey: nextProps.stripekey,
        clientSecret: nextProps.stripekey.client_secret,
      });
      cookie.save(
        "clientSecret",
        nextProps.stripekey.client_secret,
        cookieConfig
      );
      cookie.save("paymentIntent", nextProps.stripekey.intent, cookieConfig);
      cookie.save(
        "stripe_account_id",
        nextProps.stripeaccountId.stripe_account_id,
        cookieConfig
      );
      cookie.save(
        "connect_total_amount",
        nextProps.stripeaccountId.amount,
        cookieConfig
      );
      cookie.save(
        "platform_fee",
        nextProps.stripeaccountId.platform_fee,
        cookieConfig
      );
      cookie.save(
        "stripe_fee_percent",
        nextProps.stripeaccountId.stripe_fee_percent,
        cookieConfig
      );

      if (nextProps.stripekey.stripe_status == "succeeded") {
        $("#dvLoading").fadeOut(1000);
        $.magnificPopup.open({
          items: {
            src: ".processing",
          },
          type: "inline",
          showCloseBtn: false,
          midClick: true,
          closeOnBgClick: false,
        });
        this.postOrder(
          3,
          "",
          nextProps.stripekey.intent,
          "StripeConnect",
          "",
          "",
          JSON.stringify(nextProps.stripe_response)
        );
      } else if (
        nextProps.stripekey.stripe_status == "requires_action" ||
        nextProps.stripekey.stripe_status == "requires_confirmation"
      ) {
        $("#dvLoading").fadeIn();
        var stripe = Stripe(atob(this.state.globalSettings.stripe_public_key));
        stripe
          .confirmCardPayment(nextProps.stripekey.client_secret, {
            payment_method: nextProps.stripekey.payment_method,
          })
          .then(
            function(result) {
              if (result.error) {
                $("#dvLoading").fadeOut(1000);
                hideLoader("savedcardsubmit", "class");
                showAlert(language.error, result.error.message);
                $.magnificPopup.open({
                  items: {
                    src: ".alert_popup",
                  },
                  type: "inline",
                });
                return false;
              } else {
                if (result.paymentIntent.status === "succeeded") {
                  hideLoader("savedcardsubmit", "class");
                  $("#dvLoading").fadeIn();
                  $.magnificPopup.open({
                    items: {
                      src: ".processing",
                    },
                    type: "inline",
                    showCloseBtn: false,
                    midClick: true,
                    closeOnBgClick: false,
                  });
                  this.postOrder(
                    3,
                    "",
                    nextProps.stripekey.intent,
                    "StripeConnect",
                    "",
                    "",
                    JSON.stringify(nextProps.stripe_response)
                  );
                }
              }
            }.bind(this)
          );
      } else if (
        nextProps.stripekey.stripe_status == "requires_payment_method"
      ) {
        var popupIdTxt = "#pay-conf-popup";

        $.magnificPopup.open({
          items: {
            src: popupIdTxt,
          },
          type: "inline",
          closeOnBgClick: false,
        });
        $("#dvLoading").fadeOut(2000);
      }
    }

    if (this.state.updateCartResponse !== nextProps.updateCartResponse) {
      if (Object.keys(nextProps.updateCartResponse).length > 0) {
        this.setState(
          { updateCartResponse: nextProps.updateCartResponse },
          function() {
            var Response = nextProps.updateCartResponse;
            if (Object.keys(Response).length > 0) {
              if (Response[0].status === "error") {
                if (this.state.startMsg === 1) {
                  this.handleShowAlertFunct(
                    language.error,
                    Response[0].message
                  );
                  this.setState({ startMsg: 0 });
                }
              }
            }
          }
        );
      }
    }
    if (nextProps.secondaryaddress !== this.state.secondaryaddress) {
      if (nextProps.secondaryaddress.length > 0) {
        this.setState(
          {
            secondaryaddress: nextProps.secondaryaddress,
          },
          function() {
            this.loadAddressList();
          }
        );
      }
    }
  }

  loadAddressList() {
    var addressList = [];
    if (this.state.secondaryaddress.length > 0) {
      this.state.secondaryaddress.map((item) => {
        if (
          item.address_latitude !== "" &&
          item.address_latitude !== null &&
          item.address_longitude !== "" &&
          item.address_longitude !== null
        ) {
          addressList.push({
            value:
              item.address_latitude +
              "~" +
              item.address_longitude +
              "~" +
              item.address,
            label: item.address,
          });
          if (
            this.state.order_delivery_address === "" ||
            this.state.order_delivery_address === "undefined" ||
            typeof this.state.order_delivery_address === undefined ||
            typeof this.state.order_delivery_address === "undefined"
          ) {
            cookie.save("order_delivery_address", item.address, cookieConfig);
            this.setState({
              order_delivery_address: addressList,
              setDeliveryAddress: {
                value: addressList,
                label: addressList,
              },
            });
            this.handleChangeSelect(
              item.address_latitude +
                "~" +
                item.address_longitude +
                "~" +
                item.address
            );
          }
        }
      });
    }
    this.setState({ addressList: addressList });
  }

  handleShowAlertFunct(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  componentDidMount() {
    window.sessionStorage.removeItem("subscriptionavailability");
    window.sessionStorage.removeItem("postObject");
    window.sessionStorage.removeItem("saveMyCard");
    ReactSession.set("subscriptionavailability", 0);
    const script = document.createElement("script");
    script.src = "https://cdn.omise.co/omise.js";
    script.async = true;
    document.body.appendChild(script);
    smoothScroll(100, 70);

    $(document).ready(function() {
      $(window).on("scroll", function() {
        if ($(".chk-payment-main").length > 0) {
          if (
            $(window).scrollTop() >=
            $(".chk-payment-main").offset().top +
              $(".chk-payment-main").outerHeight() -
              window.innerHeight -
              100
          ) {
            $(".chk-payment-btn").addClass("checkout-sticky");
          } else {
            $(".chk-payment-btn").removeClass("checkout-sticky");
          }
        }
      });
    });

    if (this.state.corporate_customer_order === "Yes") {
      this.getActivityCountsInitial();
    }
  }

  componentDidUpdate() {
    this.checkOutAuthentication();
    var modevalue = this.state.paymentmodevalue;
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var randomPointArr = Array();
    randomPointArr["promotionApplied"] = this.state.randompoint_applied;
    randomPointArr["promotionAmount"] = this.state.randompoint_amount;
    var ccPromoTionArr = Array();
    ccPromoTionArr["promotionApplied"] = this.state.cpcustomer_coupon_applied;
    ccPromoTionArr["promotionAmount"] = this.state.cpcustomer_coupon_amount;
    ccPromoTionArr[
      "promoIsDelivery"
    ] = this.state.cpcustomer_promotion_delivery_charge_applied;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      this.props.cartItems,
      promoTionArr,
      randomPointArr,
      ccPromoTionArr,
      this.state.corporate_customer_wallet_pay_amount
    );
    var subTotalAmount = parseFloat(calculatedAmount["cartSubTotalAmount"]);
    var grandTotalAmount = parseFloat(calculatedAmount["grandTotalAmount"]);
    if (
      subTotalAmount > 0 &&
      grandTotalAmount === 0 &&
      this.props.availabileSubscription === "No"
    ) {
      modevalue = "promotion";
    }
    if (this.state.paymentmodevalue !== modevalue) {
      this.setState({ paymentmodevalue: modevalue });
    }

    var cartDetailsArr = this.state.cartDetails;
    var settingsArr = this.state.globalSettings;
    if (
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined &&
      cookie.load("UserMobile") != "" &&
      cookie.load("UserMobile") != undefined &&
      Object.keys(cartDetailsArr).length > 0 &&
      Object.keys(settingsArr).length > 0 &&
      this.state.postorder_triggered === "no"
    ) {
      this.setState(
        { postorder_triggered: "yes", getDateTimeFlg: "yes" },
        function() {
          setTimeout(
            function() {
              //this.postOrder("initial", "Yes");
            }.bind(this),
            600
          );
        }.bind(this)
      );
    }

    var sltdOrdDate = this.state.seleted_ord_date;
    var sltdOrdTime = this.state.seleted_ord_time;
    if (sltdOrdDate !== "" && sltdOrdTime !== "") {
      this.updateOrderDateTimeCookie();
    }
  }

  /* For Advanced Slot */
  updateOrderDateTimeCookie() {
    var sltdOrdDate = this.state.seleted_ord_date;
    var sltdOrdTime = this.state.seleted_ord_time;
    var OrdDate = format(sltdOrdDate, "yyyy-MM-dd");
    var OrdHours = sltdOrdTime.getHours();
    var OrdMunts = sltdOrdTime.getMinutes();
    var OrdSecnd = sltdOrdTime.getSeconds();
    var ordDateTime = new Date(OrdDate);
    ordDateTime.setHours(OrdHours);
    ordDateTime.setMinutes(OrdMunts);
    ordDateTime.setSeconds(OrdSecnd);

    var deliveryDate = format(sltdOrdDate, "dd/MM/yyyy");
    var deliveryTime =
      this.pad(OrdHours) + ":" + this.pad(OrdMunts) + ":" + this.pad(OrdSecnd);

    cookie.save("orderDateTime", ordDateTime, cookieConfig);
    cookie.save("deliveryDate", deliveryDate, cookieConfig);
    cookie.save("deliveryTime", deliveryTime, cookieConfig);

    var isAdvanced = this.state.isAdvanced;
    var slotType = this.state.slotType;
    var orderSlotVal = "",
      orderSlotTxt = "",
      orderSlotStrTime = "",
      orderSlotEndTime = "";
    if (slotType === "2") {
      orderSlotVal = this.state.seleted_ord_slot;
      orderSlotTxt = this.state.seleted_ord_slotTxt;
      orderSlotStrTime = this.state.seleted_ord_slot_str;
      orderSlotEndTime = this.state.seleted_ord_slot_end;
    }

    cookie.save("orderSlotVal", orderSlotVal, cookieConfig);
    cookie.save("orderSlotTxt", orderSlotTxt, cookieConfig);
    cookie.save("orderSlotStrTime", orderSlotStrTime, cookieConfig);
    cookie.save("orderSlotEndTime", orderSlotEndTime, cookieConfig);
  }

  pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  checkOutAuthentication() {
    var orderOutletId =
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined
        ? cookie.load("orderOutletId")
        : "";
    var avilablityId =
      cookie.load("defaultAvilablityId") != "" &&
      cookie.load("defaultAvilablityId") != undefined
        ? cookie.load("defaultAvilablityId")
        : "";
    var zoneIdTxt =
      typeof cookie.load("orderZoneId") === "undefined"
        ? ""
        : cookie.load("orderZoneId");

    /* if (orderOutletId === "" || avilablityId === "") {
      showAlert("エラー", "ごめん！。注文アウトレットの詳細は空です。");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      this.props.history.push("/products");
      return false;
    } */

    /* if (avilablityId === deliveryId && zoneIdTxt === "") {
      showAlert("エラー", "ごめん！。注文ゾーンの詳細は空です。");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      this.props.history.push("/");
      return false;
    } */

    var cartDetailsArr = this.state.cartDetails;
    var settingsArr = this.state.globalSettings;
    var zonedetailsArr = this.props.zonedetails;

    if (this.state.cartStatus === "failure") {
      showAlert(language.error, language.yourcartempty);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      this.props.history.push("/products");
      return false;
    }

    if (
      this.state.cartStatus === "success" &&
      Object.keys(cartDetailsArr).length > 0 &&
      Object.keys(zonedetailsArr).length > 0
    ) {
      /*var cartMinAmount = (settingsArr.callcnter_min_amount !== '') ? parseFloat(settingsArr.callcnter_min_amount) : 0;*/

      var promoTionArr = Array();
      promoTionArr["promotionApplied"] = this.state.promotion_applied;
      promoTionArr["promotionAmount"] = this.state.promotion_amount;
      promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
      var randomPointArr = Array();
      randomPointArr["promotionApplied"] = this.state.randompoint_applied;
      randomPointArr["promotionAmount"] = this.state.randompoint_amount;
      var ccPromoTionArr = Array();
      ccPromoTionArr["promotionApplied"] = this.state.cpcustomer_coupon_applied;
      ccPromoTionArr["promotionAmount"] = this.state.cpcustomer_coupon_amount;
      ccPromoTionArr[
        "promoIsDelivery"
      ] = this.state.cpcustomer_promotion_delivery_charge_applied;
      var calculatedAmount = getCalculatedAmount(
        this.state.globalSettings,
        this.props.zonedetails,
        cartDetailsArr,
        this.props.cartItems,
        promoTionArr,
        randomPointArr,
        ccPromoTionArr,
        this.state.corporate_customer_wallet_pay_amount
      );
      /* var cartMinAmount =
        zonedetailsArr[0].zone_min_amount !== ""
          ? parseFloat(zonedetailsArr[0].zone_min_amount)
          : 0; */
      var cartMinAmount = calculatedAmount.minOrderAmnt;

      var cartSubTotal = parseFloat(cartDetailsArr.cart_sub_total);

      var errorMsg =
        language.mintoorder +
        currencySymbol +
        cartMinAmount +
        language.totalorderamt +
        currencySymbol +
        cartSubTotal +
        language.is;

      if (
        cookie.load("defaultAvilablityId") === deliveryId &&
        cartSubTotal < cartMinAmount
      ) {
        /*showCustomAlert('error',errorMsg);*/
        showAlert(language.error, errorMsg);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
        this.props.history.push("/products");
        return false;
      }
    }

    var UserId =
      cookie.load("UserId") != "" && cookie.load("UserId") != undefined
        ? cookie.load("UserId")
        : "";
    if (UserId === "") {
      cookie.save("loginpopupTrigger", "Yes", cookieConfig);
      this.props.history.push("/");
      return false;
    }

    var UserMobile =
      cookie.load("UserMobile") != "" && cookie.load("UserMobile") != undefined
        ? cookie.load("UserMobile")
        : "";
    if (UserId !== "" && UserMobile === "") {
      showAlert(language.error, language.updatemobile);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      this.props.history.push("/myaccount");
      return false;
    }
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductSlug: value },
        function() {
          this.openProDetailPopup();
        }.bind(this)
      );
    }
  };

  openProDetailPopup() {
    showLoader("comboPro-" + this.state.viewProductSlug, "Idtext");
    $("#ProductDetailMdl").modal({ backdrop: "static", keyboard: false });
  }

  loadCartOverallData() {
    var cartDetailsArr = this.state.cartDetails;

    if (Object.keys(cartDetailsArr).length > 0) {
      $("#dvLoading").fadeOut(1000);

      var promoTionArr = Array();
      promoTionArr["promotionApplied"] = this.state.promotion_applied;
      promoTionArr["promotionAmount"] = this.state.promotion_amount;
      promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
      var randomPointArr = Array();
      randomPointArr["promotionApplied"] = this.state.randompoint_applied;
      randomPointArr["promotionAmount"] = this.state.randompoint_amount;
      var ccPromoTionArr = Array();
      ccPromoTionArr["promotionApplied"] = this.state.cpcustomer_coupon_applied;
      ccPromoTionArr["promotionAmount"] = this.state.cpcustomer_coupon_amount;
      ccPromoTionArr[
        "promoIsDelivery"
      ] = this.state.cpcustomer_promotion_delivery_charge_applied;
      var calculatedAmount = getCalculatedAmount(
        this.state.globalSettings,
        this.props.zonedetails,
        cartDetailsArr,
        this.props.cartItems,
        promoTionArr,
        randomPointArr,
        ccPromoTionArr,
        this.state.corporate_customer_wallet_pay_amount
      );
      var promotionType = this.state.promotion_type;

      return (
        <div className="checkout-right-body-section">
          <div className="cart_table">
            <div className="cart_body">
              <div className="overall-parent">
                <div className="order-details-with-clear">
                  <h5>{language.productdetail}</h5>
                  <a
                    href="/"
                    onClick={this.clearAllCartItm.bind(this)}
                    className="hclear_cart"
                    title={language.emptycart}
                  >
                    {language.emptycart}
                  </a>
                </div>
                <div className="product-details-parent">
                  {this.loadCartList()}
                </div>
              </div>
            </div>

            <div className="cart_footer">
              <div className="cart_footer-bg">
                <div className="cart_row">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <p className="text-uppercase">{language.subtotal}</p>
                    </div>
                    <div className="col-sm-cls text-right">
                      <span>
                        {showPrices(calculatedAmount["cartSubTotalAmount"])}
                      </span>
                    </div>
                  </div>
                </div>
                {parseFloat(calculatedAmount["orderGstAmount"]) > 0 &&
                  calculatedAmount["inclusiveGST"] === "Yes" && (
                    <p className="inclisive-gst">
                      {language.inclusivegst} (
                      {calculatedAmount["orderDisplayGst"]}
                      %):
                      {showPrices(
                        parseFloat(calculatedAmount["orderGstAmount"]).toFixed(
                          2
                        )
                      )}
                    </p>
                  )}
                {parseFloat(calculatedAmount["deliveryCharge"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">{language.delivery}</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(
                              calculatedAmount["deliveryCharge"]
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(calculatedAmount["additionalDelivery"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          {language.additionaldelivery}
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(
                              calculatedAmount["additionalDelivery"]
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {parseFloat(calculatedAmount["orderGstAmount"]) > 0 &&
                  calculatedAmount["inclusiveGST"] === "No" && (
                    <div className="cart_row gst-row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-uppercase">
                            {language.gst}{" "}
                            {calculatedAmount["orderProductDisplayGst"] !==
                              1 && (
                              <> ({calculatedAmount["orderDisplayGst"]} %)</>
                            )}
                          </p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            {showPrices(
                              parseFloat(
                                calculatedAmount["orderGstAmount"]
                              ).toFixed(2)
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                {parseFloat(
                  this.state.globalSettings.stripe_express_platform_fee
                ) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">{language.platformfee}</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            this.state.globalSettings
                              .stripe_express_platform_fee
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(calculatedAmount["ccpromotionAmount"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          {language.corporatediscount}(-)
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          (
                          {showPrices(
                            parseFloat(
                              calculatedAmount["ccpromotionAmount"]
                            ).toFixed(2)
                          )}
                          )
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(calculatedAmount["randomPointAmount"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">{language.dicount}(-)</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          (
                          {showPrices(
                            parseFloat(
                              calculatedAmount["randomPointAmount"]
                            ).toFixed(2)
                          )}
                          )
                        </span>
                      </div>
                    </div>
                    <a
                      href="/"
                      onClick={this.removePointsAndPromo.bind(
                        this,
                        "points",
                        "fromclk"
                      )}
                      className="cart_remove"
                    ></a>
                  </div>
                )}
                {parseFloat(calculatedAmount["promotionAmount"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          {promotionType === "points"
                            ? language.dicount + "(-)"
                            : language.promocode + "(-)"}
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          (
                          {showPrices(
                            parseFloat(
                              calculatedAmount["promotionAmount"]
                            ).toFixed(2)
                          )}
                          )
                        </span>
                      </div>
                    </div>
                    <a
                      href="/"
                      onClick={this.removePointsAndPromo.bind(
                        this,
                        "promoCode",
                        "fromclk"
                      )}
                      className="cart_remove"
                    ></a>
                  </div>
                )}
                {parseFloat(calculatedAmount["voucherDiscountAmount"]) > 0 && (
                  <div className="cart_row gst-row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          {language.voucherdiscount}
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          -
                          {showPrices(
                            parseFloat(
                              calculatedAmount["voucherDiscountAmount"]
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {parseFloat(this.state.corporate_customer_wallet_pay_amount) >
                  0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          {language.walletdicount}(-)
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          (
                          {showPrices(
                            parseFloat(
                              this.state.corporate_customer_wallet_pay_amount
                            ).toFixed(2)
                          )}
                          )
                        </span>
                      </div>
                    </div>
                    <a
                      href="/"
                      onClick={this.removeCorporateWallet.bind(this, "fromclk")}
                      className="cart_remove"
                    ></a>
                  </div>
                )}

                <div className="cart_row grant-total-cls">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <p className="text-uppercase">{language.total}</p>
                    </div>
                    <div className="col-sm-cls text-right">
                      <span>
                        {showPrices(calculatedAmount["grandTotalAmount"], "Y")}
                      </span>
                    </div>
                  </div>
                  {/* parseInt(calculatedAmount["roundoff"]) > 0 && (
                    <div className="member-discount-total">
                      * 四捨五入 00.{calculatedAmount["roundoff"]}
                    </div>
                  ) */}
                  {parseFloat(cartDetailsArr.cart_special_discount) > 0 && (
                    <div className="member-discount-total">
                      * {cartDetailsArr.cart_special_discount_type}
                      {showPrices(cartDetailsArr.cart_special_discount)}{" "}
                      {language.apply}
                    </div>
                  )}
                </div>

                {cookie.load("defaultAvilablityId") === deliveryId &&
                  this.loadDeliveryPercentageBar()}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  /* this  function used to load delivery percentage */
  loadDeliveryPercentageBar() {
    var freeDeliveryAmnt = 0;
    var DeliveryAmnt = 0;
    var remainAmnt = 0;
    var delPercentage = 0;

    var cartDetailsArr = this.state.cartDetails;

    if (Object.keys(cartDetailsArr).length > 0) {
      var promoTionArr = Array();
      promoTionArr["promotionApplied"] = this.state.promotion_applied;
      promoTionArr["promotionAmount"] = this.state.promotion_amount;
      promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
      var randomPointArr = Array();
      randomPointArr["promotionApplied"] = this.state.randompoint_applied;
      randomPointArr["promotionAmount"] = this.state.randompoint_amount;
      var ccPromoTionArr = Array();
      ccPromoTionArr["promotionApplied"] = this.state.cpcustomer_coupon_applied;
      ccPromoTionArr["promotionAmount"] = this.state.cpcustomer_coupon_amount;
      ccPromoTionArr[
        "promoIsDelivery"
      ] = this.state.cpcustomer_promotion_delivery_charge_applied;

      var calculatedAmount = getCalculatedAmount(
        this.state.globalSettings,
        this.props.zonedetails,
        cartDetailsArr,
        this.props.cartItems,
        promoTionArr,
        randomPointArr,
        ccPromoTionArr,
        this.state.corporate_customer_wallet_pay_amount
      );

      freeDeliveryAmnt = parseFloat(calculatedAmount["freeDeliveryAmnt"]);
      DeliveryAmnt = parseFloat(calculatedAmount["deliveryCharge"]);
      var subTotal = parseFloat(calculatedAmount["cartSubTotalAmount"]);
      var percentage = (subTotal * 100) / freeDeliveryAmnt;
      percentage = Math.round(percentage);
      delPercentage = percentage;
      remainAmnt = parseFloat(freeDeliveryAmnt - subTotal).toFixed(2);
    }

    if (DeliveryAmnt > 0 && freeDeliveryAmnt > 0 && remainAmnt > 0) {
      return (
        <div className="cart_row progress_bar_div">
          <div className="indication">
            <div className="indication_progress">
              <span
                className="progress_bar"
                style={{ width: delPercentage + "%" }}
              />
            </div>
            <p className="help-block">
              {showPrices(remainAmnt)} {language.freedelivery}
            </p>
          </div>
        </div>
      );
    }
  }

  loadCartList() {
    var cartItemsArr = this.state.cartItems;
    var cartDetails = this.state.cartDetails;
    if (Object.keys(cartItemsArr).length > 0) {
      return cartItemsArr.map((product, index) => {
        var pointPercentage = product.cart_item_point_percentage;
        if (parseFloat(pointPercentage) > 0) {
          var addedPointsSplit = pointPercentage.split(".");
          if (addedPointsSplit[1] === "00") {
            pointPercentage = addedPointsSplit[0];
          }
        }
        var rewardpoint_val = parseInt(this.state.reward_point_val);

        var crditPoints = 0;
        if (rewardpoint_val > 0 && this.state.randompoint_applied === "Yes") {
          crditPoints = showRewardPoints(
            this.props.cartItems,
            rewardpoint_val,
            cartDetails.cart_sub_total,
            product.cart_item_id
          );
        } else {
          crditPoints = product.cart_item_points;
        }
        return (
          <div
            className="cart_row product-details"
            id={"rowcartid-" + product.cart_item_id}
            key={index}
          >
            <div className="row-replace">
              <div className="col-sm-cls cart_left">
                <div className="cart_img">
                  {product.cart_item_product_image !== "" ? (
                    <img src={product.cart_item_product_image} alt="" />
                  ) : (
                    <img src={productImg} alt="" />
                  )}
                </div>
                <div className="cart_info text-left">
                  <h4>{stripslashes(product.cart_item_product_name)}</h4>

                  {product.cart_item_voucher_id !== "" &&
                    product.cart_item_voucher_id != null && (
                      <span className="voucher-discount-applied">
                        {language.discountapplied}
                      </span>
                    )}
                  {product.cart_item_product_type === "5" &&
                    product.cart_item_product_voucher_gift_email !== "" &&
                    product.cart_item_product_voucher_gift_email !==
                      cookie.load("UserEmail") &&
                    product.cart_item_product_voucher_gift_mobile !== "" &&
                    (product.cart_voucher_order_item_id === "" ||
                      product.cart_voucher_order_item_id === null) && (
                      <div className="cart_extrainfo">
                        <p>
                          <b>{language.giftdetails}</b>
                        </p>
                        <p>
                          {language.name}:{" "}
                          {product.cart_item_product_voucher_gift_name}
                        </p>
                        <p>
                          {language.mobile}:{" "}
                          {product.cart_item_product_voucher_gift_mobile}
                        </p>
                        <p>
                          {language.email}:{" "}
                          {product.cart_item_product_voucher_gift_email}
                        </p>
                        <p>
                          {language.mesage}:{" "}
                          {product.cart_item_product_voucher_gift_message}
                        </p>
                      </div>
                    )}
                  {this.loadModifierItems(
                    product.cart_item_type,
                    product.modifiers,
                    product.set_menu_component
                  )}
                  {product.product_revel_id !== "" &&
                    product.product_revel_id !== null && (
                      <p>
                        {language.sku}:{product.product_revel_id}
                      </p>
                    )}
                  {product.cart_item_subscription !== "" &&
                    product.cart_item_subscription !== "0" &&
                    product.cart_item_subscription !== null && (
                      <>
                        <p>
                          {language.subscription}:
                          {subscribeCycle(product.cart_item_subscription_cycle)}
                        </p>

                        {product.cart_item_subscription_start_date !== "" &&
                          product.cart_item_subscription_start_date !== null &&
                          product.cart_item_subscription_start_date !==
                            "0000-00-00" && (
                            <p>
                              {language.startdate}:
                              {format(
                                new Date(
                                  product.cart_item_subscription_start_date
                                ),
                                "yyyy/MM/dd"
                              )}
                            </p>
                          )}
                      </>
                    )}
                  {product.cart_item_special_notes !== "" && (
                    <p className="help-block">
                      {stripslashes(product.cart_item_special_notes)}
                    </p>
                  )}

                  {parseFloat(product.cart_item_promotion_discount) > 0 && (
                    <span className="member-discount-desc">
                      {showPrices(product.cart_item_promotion_discount)}{" "}
                      {product.cart_item_promotion_type}{" "}
                      {language.discountapplied}
                    </span>
                  )}
                  {parseFloat(product.cart_product_tax) > 0 &&
                    parseFloat(product.cart_product_tax_percentage) > 0 && (
                      <p>
                        {language.gst}(
                        {parseFloat(
                          product.cart_product_tax_percentage
                        ).toFixed(0)}
                        %)
                      </p>
                    )}
                  {parseFloat(product.cart_item_point_percentage) > 0 &&
                    parseInt(crditPoints) > 0 && (
                      <p className="cart-earning-points">
                        {pointPercentage}%のポイント：
                        {showPoint(crditPoints)}ポイント
                      </p>
                    )}
                </div>
              </div>
              <div className="col-sm-cls cart_right text-right">
                <div className="cart_price">
                  <p>{showPrices(product.cart_item_total_price)}</p>
                </div>

                {product.cart_item_voucher_product_free != 1 ? (
                  <div className="qty_bx">
                    <span
                      className="qty_minus"
                      onClick={this.updateCartQty.bind(this, product, "decr")}
                    >
                      -
                    </span>
                    <input type="text" value={product.cart_item_qty} readOnly />
                    <span
                      className="qty_plus"
                      onClick={this.updateCartQty.bind(this, product, "incr")}
                    >
                      +
                    </span>
                  </div>
                ) : (
                  <div className="qty_bx free_product">
                    <span className="qty_minus">-</span>
                    <input type="text" value={product.cart_item_qty} readOnly />
                    <span className="qty_plus">+</span>
                  </div>
                )}
              </div>
            </div>
            <a
              href="/"
              onClick={this.deleteCartItm.bind(this, product)}
              className="cart_remove"
            >
              <img src={crossImg} alt="" />
            </a>
          </div>
        );
      });
    }
  }

  updateCartQty(itemArr, type) {
    var productId = itemArr.cart_item_product_id;
    var cartItemId = itemArr.cart_item_id;
    var cartQty = itemArr.cart_item_qty;
    var totalItmCount = this.props.cartTotalItmCount;
    var orderVoucherId = itemArr.cart_voucher_order_item_id;
    showLoader("rowcartid-" + cartItemId, "Idtext");

    if (type === "decr") {
      cartQty = parseInt(cartQty) - 1;
      if (parseInt(totalItmCount) === 0) {
      } else if (parseInt(cartQty) === 0) {
        this.props.deleteCartDetail(cartItemId);
      } else {
        this.props.updateCartDetail(
          productId,
          cartItemId,
          cartQty,
          orderVoucherId
        );
      }
    } else {
      cartQty = parseInt(cartQty) + 1;
      this.props.updateCartDetail(
        productId,
        cartItemId,
        cartQty,
        orderVoucherId
      );
    }
    this.setState({ startMsg: 1 });
    this.removePointsAndPromo("all", "frmFunct");
  }

  deleteCartItm(itemArr, event) {
    event.preventDefault();
    var cartItemId = itemArr.cart_item_id;
    showLoader("rowcartid-" + cartItemId, "Idtext");
    this.props.deleteCartDetail(cartItemId);
    this.removePointsAndPromo("all", "frmFunct");
  }
  clearAllCartItm(event) {
    event.preventDefault();
    $.magnificPopup.open({
      items: {
        src: "#warning-delete-popup",
      },
      type: "inline",
      showCloseBtn: false,
      midClick: true,
      closeOnBgClick: false,
    });
  }
  clearCartItm(event) {
    event.preventDefault();
    showLoader("cart_body", "class");
    this.props.destroyCartDetail();
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo">';

    var temp_html = "";

    if (itemType === "Modifier" && len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modVlPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModVlPrice =
          modVlPrice > 0
            ? " <b>(+" + currencySymbol + modVlPrice + ")</b>"
            : "";
        /*  + newModVlPrice */
        temp_html += "<p><b>" + modName + ": </b>" + modval + "</b></p> ";
      }

      html += temp_html + "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (itemType === "Component" && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);

        if (combo[i]["product_details"][0]["modifiers"].length) {
          html +=
            "<p><b>" +
            comboName +
            ":</b> </p><p>" +
            comboVal +
            "  " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        } else {
          html +=
            "<p><b>" +
            comboName +
            ":</b> </p><p>" +
            comboVal +
            " " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        }
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        if (parseInt(combos[r]["cart_menu_component_product_qty"]) > 0) {
          var comboPro = combos[r]["cart_menu_component_product_name"];
          var comboQty = combos[r]["cart_menu_component_product_qty"];
          var comboPrice = combos[r]["cart_menu_component_product_price"];
          var newPrice =
            comboPrice > 0
              ? " <b>(+" + currencySymbol + comboPrice + ")</b>"
              : "";
          /* + newPrice  */
          if (parseInt(comboQty) > 1) {
            comboQty = comboQty + " X ";
          } else {
            comboQty = "";
          }
          html += "<p>" + comboQty + comboPro + " </p> ";
        }
      }
      return html;
    }
    return "";
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "<div >";
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModValPrice =
          modValPrice > 0
            ? " <b>(+" + currencySymbol + modValPrice + ")</b>"
            : "";
        /* newModValPrice + */
        html += "<p><b>" + modName + ":</b> </p><p> " + modval + "</p> ";
      }
      html += "</div>";

      return html;
    }

    return "";
  }

  applyCorporateWallet(payAmount) {
    var walletInputVal = this.state.corporate_customer_wallet_input;
    var walletPayAmount = this.state.corporate_customer_wallet_pay_amount;
    var walletPay = this.state.corporate_wallet_pay;
    var walletType = this.state.corporate_wallet_type;
    var walletBalance = this.state.corporate_company_balance;

    var totalPayAmount = payAmount !== "" ? parseFloat(payAmount) : 0;
    walletInputVal = walletInputVal !== "" ? parseFloat(walletInputVal) : 0;
    walletBalance = walletBalance !== "" ? parseFloat(walletBalance) : 0;
    var flgAct = "";
    var flgMsg = "";
    if (walletInputVal > 0 && walletBalance >= walletInputVal) {
      if (walletInputVal >= totalPayAmount) {
        walletInputVal = totalPayAmount;
        walletPayAmount = totalPayAmount;
        walletPay = "Yes";
        walletType = "fully";
      } else {
        walletPayAmount = walletInputVal;
        walletPay = "Yes";
        walletType = "partially";
      }
      flgAct = "success";
      flgMsg = language.walletappliedsucc;
    } else {
      flgAct = "error";
      flgMsg = language.entervalidamt;
      walletPay = "No";
      walletType = "notpay";
      walletPayAmount = 0;
    }
    var wallet_amount_value = showPoint_total(parseFloat(walletInputVal));
    var new_grand_total_value = 0;

    if (
      ReactSession.get("subscriptionavailability") === 1 &&
      ReactSession.get("subscriptionavailability") !== undefined
    ) {
      if (walletInputVal >= totalPayAmount) {
        wallet_amount_value = wallet_amount_value - 100;
        walletPayAmount = walletPayAmount - 100;
      } else if (walletInputVal < totalPayAmount) {
        new_grand_total_value = totalPayAmount - walletInputVal;
        if (new_grand_total_value < 100) {
          wallet_amount_value = "";
          walletPayAmount = "";
          flgAct = "error";
          flgMsg = language.entervalidamt;
          walletPay = "No";
          walletType = "notpay";
          showAlert(language.error, language.minstirpeamt);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      }
    }

    this.setState(
      {
        corporate_customer_wallet_input: wallet_amount_value,
        corporate_customer_wallet_pay_amount: walletPayAmount,
        corporate_wallet_pay: walletPay,
        corporate_wallet_type: walletType,
      },
      function() {
        cookie.save(
          "corporate_customer_wallet_pay_amount",
          walletPayAmount,
          cookieConfig
        );
        showCustomAlert(flgAct, flgMsg);
      }
    );
  }

  applyPointsAndPromo(type) {
    ///  this.removeCorporateWallet("action");
    var promotionApplied = this.state.promotion_applied;
    var promotionType = this.state.promotion_type;
    var promotionAmount = this.state.promotion_amount;
    var promotionSource = this.state.promotion_source;
    var promoIsDelivery = this.state.promoIs_delivery;
    var reedemPointVal = this.state.reward_point_val;
    var promoCodeVal = this.state.promo_code_val;
    var usedPoints = this.state.used_reward_point;
    var randompointApplied = this.state.randompoint_applied;
    var randompointAmount = this.state.randompoint_amount;
    var cpcustomerCouponAmount = this.state.cpcustomer_coupon_amount;

    var promoSubTotal = this.state.order_promo_sub_total;
    var rewardSubTotal = this.state.order_reward_sub_total;
    var cpcustomerPromoSubTotal = this.state.order_cpcustomer_promo_sub_total;

    promotionAmount = promotionAmount !== "" ? parseFloat(promotionAmount) : 0;
    randompointAmount =
      randompointAmount !== "" ? parseFloat(randompointAmount) : 0;
    cpcustomerCouponAmount =
      cpcustomerCouponAmount !== "" ? parseFloat(cpcustomerCouponAmount) : 0;

    var cartDetailsArr = this.state.cartDetails;
    var cartItemsSateArr = this.state.cartItems;

    var subTotal =
      Object.keys(cartDetailsArr).length > 0
        ? cartDetailsArr.cart_sub_total
        : 0;
    var totalItems =
      Object.keys(cartDetailsArr).length > 0
        ? cartDetailsArr.cart_total_items
        : 0;

    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var randomPointArr = Array();
    randomPointArr["promotionApplied"] = this.state.randompoint_applied;
    randomPointArr["promotionAmount"] = this.state.randompoint_amount;
    var ccPromoTionArr = Array();
    ccPromoTionArr["promotionApplied"] = this.state.cpcustomer_coupon_applied;
    ccPromoTionArr["promotionAmount"] = this.state.cpcustomer_coupon_amount;
    ccPromoTionArr[
      "promoIsDelivery"
    ] = this.state.cpcustomer_promotion_delivery_charge_applied;

    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      this.props.cartItems,
      promoTionArr,
      randomPointArr,
      ccPromoTionArr,
      this.state.corporate_customer_wallet_pay_amount
    );

    if (
      type === "points" &&
      reedemPointVal > 0 &&
      calculatedAmount["grandTotalAmount"] > 0
    ) {
      var new_grand_total_value = "";
      if (
        ReactSession.get("subscriptionavailability") === 1 &&
        ReactSession.get("subscriptionavailability") !== undefined
      ) {
        if (
          reedemPointVal >= parseFloat(calculatedAmount["grandTotalAmount"])
        ) {
          if (
            reedemPointVal > parseFloat(calculatedAmount["grandTotalAmount"])
          ) {
            reedemPointVal =
              parseFloat(calculatedAmount["grandTotalAmount"]) - 100;
          } else {
            reedemPointVal = reedemPointVal - 100;
          }
        } else if (
          reedemPointVal < parseFloat(calculatedAmount["grandTotalAmount"])
        ) {
          new_grand_total_value =
            parseFloat(calculatedAmount["grandTotalAmount"]) - reedemPointVal;
          if (new_grand_total_value < 100) {
            reedemPointVal = "";
            showAlert(language.error, language.minstirpeamt);
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
            return false;
          }
        }
      }

      showLoader("applypoints_cls", "class");

      var pointsSubTotal =
        parseFloat(subTotal) - cpcustomerCouponAmount - promotionAmount;

      var postObject = {
        app_id: appId,
        reference_id: cookie.load("UserId"),
        access_token:
          cookie.load("access_token") !== "" &&
          typeof cookie.load("access_token") !== undefined &&
          typeof cookie.load("access_token") !== "undefined"
            ? cookie.load("access_token")
            : "",
        redeem_point: reedemPointVal,
        cart_amount: pointsSubTotal,
        grand_total_amount: parseFloat(calculatedAmount["grandTotalAmount"]),
      };

      axios
        .post(
          apiUrl + "loyalty/apply_loyalityv1",
          qs.stringify(postObject),
          headerconfig
        )
        .then((res) => {
          if (res.data.status === "success") {
            var pointDet = res.data.result_set;
            randompointApplied = "Yes";
            randompointAmount = pointDet.points_amount;
            usedPoints = reedemPointVal;
            rewardSubTotal = pointsSubTotal;
            showCustomAlert("success", language.rewardappliedsuc);
          } else {
            randompointApplied = "No";
            randompointAmount = 0;
            usedPoints = 0;
            rewardSubTotal = 0;
            showCustomAlert("error", language.rewardnotapply);
          }

          hideLoader("applypoints_cls", "class");
          cookie.save("rewardSubTotal", rewardSubTotal, cookieConfig);
          cookie.save("reedemPointVal", reedemPointVal, cookieConfig);
          cookie.save("randompointApplied", randompointApplied, cookieConfig);
          cookie.save("randompointAmount", randompointAmount, cookieConfig);
          cookie.save("usedPoints", pointDet.points_amount, cookieConfig);

          this.setState({
            order_reward_sub_total: rewardSubTotal,
            reward_point_val: pointDet.points_amount,
            randompoint_applied: randompointApplied,
            randompoint_amount: randompointAmount,
            used_reward_point: usedPoints,
          });
        });
    }

    if (type === "promoCode" && calculatedAmount["grandTotalAmount"] > 0) {
      if (
        ReactSession.get("subscriptionavailability") === 1 &&
        ReactSession.get("subscriptionavailability") !== undefined
      ) {
        if (calculatedAmount["grandTotalAmount"] < 100) {
          showAlert(language.error, language.minstirpeamt);

          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
          return false;
        }
      }

      showLoader("applypromo_cls", "class");

      var categoryIdsDet = this.getProductIdsDet(cartItemsSateArr);
      var avilablityId = cookie.load("defaultAvilablityId");

      var promoCodeSubTotal =
        parseFloat(subTotal) - cpcustomerCouponAmount - randompointAmount;

      if (promoCodeSubTotal > 0) {
        promoCodeSubTotal = promoCodeSubTotal;
      } else {
        promoCodeSubTotal = 0;
      }

      var postObject = {
        app_id: appId,
        reference_id: cookie.load("UserId"),
        access_token:
          cookie.load("access_token") !== "" &&
          typeof cookie.load("access_token") !== undefined &&
          typeof cookie.load("access_token") !== "undefined"
            ? cookie.load("access_token")
            : "",
        promo_code: promoCodeVal,
        cart_amount: promoCodeSubTotal,
        cart_quantity: totalItems,
        category_id: categoryIdsDet,
        availability_id: avilablityId,
        outlet_id: cookie.load("orderOutletId"),
      };

      axios
        .post(
          apiUrl + "promotion_api_v2/apply_promotion",
          qs.stringify(postObject),
          headerconfig
        )
        .then((res) => {
          if (res.data.status === "success") {
            if (
              ReactSession.get("subscriptionavailability") === 1 &&
              ReactSession.get("subscriptionavailability") !== undefined
            ) {
              var pointDet = res.data.result_set;
              promotionAmount = pointDet.promotion_amount;
              var calculate_grand_total =
                calculatedAmount["grandTotalAmount"] - promotionAmount;

              if (calculate_grand_total > 100) {
                var IsDelivery =
                  pointDet.promotion_delivery_charge_applied == "Yes"
                    ? "Yes"
                    : "No";
                promotionApplied = "Yes";
                promotionType = "promoCode";
                promotionAmount = pointDet.promotion_amount;
                promotionSource = promoCodeVal;
                promoIsDelivery = IsDelivery;
                promoSubTotal = promoCodeSubTotal;
                showCustomAlert("success", language.promoappliedsucc);
              } else {
                promotionApplied = "";
                promotionType = "";
                promotionAmount = "";
                promotionSource = "";
                promoIsDelivery = "";
                promoSubTotal = 0;

                hideLoader("applypromo_cls", "class");
                showAlert(language.error, language.minstirpeamt);
                $.magnificPopup.open({
                  items: {
                    src: ".alert_popup",
                  },
                  type: "inline",
                });
                return false;
              }
            } else {
              var pointDet = res.data.result_set;
              var IsDelivery =
                pointDet.promotion_delivery_charge_applied == "Yes"
                  ? "Yes"
                  : "No";
              promotionApplied = "Yes";
              promotionType = "promoCode";
              promotionAmount = pointDet.promotion_amount;
              promotionSource = promoCodeVal;
              promoIsDelivery = IsDelivery;
              promoSubTotal = promoCodeSubTotal;
              showCustomAlert("success", language.promoappliedsucc);
            }
          } else {
            var msgTxt =
              res.data.message !== ""
                ? res.data.message
                : language.promonotapply;

            promotionApplied = "";
            promotionType = "";
            promotionAmount = "";
            promotionSource = "";
            promoIsDelivery = "";
            promoSubTotal = 0;
            showCustomAlert("error", msgTxt);
          }

          hideLoader("applypromo_cls", "class");
          cookie.save("promoSubTotal", promoSubTotal, cookieConfig);
          cookie.save("promoCodeVal", promoCodeVal, cookieConfig);
          cookie.save("promotionApplied", promotionApplied, cookieConfig);
          cookie.save("promotionType", promotionType, cookieConfig);
          cookie.save("promotionAmount", promotionAmount, cookieConfig);
          cookie.save("promotionSource", promotionSource, cookieConfig);
          cookie.save("promoIsDelivery", promoIsDelivery, cookieConfig);

          this.setState({
            order_promo_sub_total: promoSubTotal,
            promo_code_val: promoCodeVal,
            promotion_applied: promotionApplied,
            promotion_type: promotionType,
            promotion_amount: promotionAmount,
            promotion_source: promotionSource,
            promoIs_delivery: promoIsDelivery,
          });
        });
    }
  }

  removeCorporateWallet(flag, event) {
    if (flag === "fromclk") {
      event.preventDefault();
    }

    var paymentmode = this.state.paymentmodevalue;
    if (paymentmode === "promotion") {
      var settingsArr = this.state.globalSettings;
      var paymentmode = "Stripe";
      if (Object.keys(settingsArr).length > 0) {
        if (
          settingsArr.client_cod_enable == 1 &&
          settingsArr.client_cod_availability == 1
        ) {
          paymentmode = "Cash";
        }

        if (
          settingsArr.client_omise_enable == 1 &&
          settingsArr.client_omise_availability == 1 &&
          paymentmode !== "Cash"
        ) {
          paymentmode = "Omise";
        }
      }
    }

    this.setState(
      {
        corporate_customer_wallet_input: "",
        corporate_customer_wallet_pay_amount: 0,
        corporate_wallet_pay: "No",
        corporate_wallet_type: "notpay",
        paymentmodevalue: paymentmode,
      },
      function() {
        cookie.remove("corporate_customer_wallet_pay_amount", cookieConfig);
      }
    );
  }

  removePointsAndPromo(typetxt, flag, event) {
    //this.removeCorporateWallet("action");
    if (flag === "fromclk") {
      event.preventDefault();
    }
    var paymentmode = this.state.paymentmodevalue;
    if (paymentmode === "promotion") {
      var settingsArr = this.state.globalSettings;
      var paymentmode = "Stripe";
      if (Object.keys(settingsArr).length > 0) {
        if (
          settingsArr.client_cod_enable == 1 &&
          settingsArr.client_cod_availability == 1
        ) {
          paymentmode = "Cash";
        }

        if (
          settingsArr.client_omise_enable == 1 &&
          settingsArr.client_omise_availability == 1 &&
          paymentmode !== "Cash"
        ) {
          paymentmode = "Omise";
        }
      }
    }

    if (typetxt === "all") {
      removePromoCkValue();
    }

    if (typetxt === "points") {
      this.setState(
        {
          reward_point_val: "",
          randompoint_applied: "No",
          randompoint_amount: 0,
          used_reward_point: 0,
          order_reward_sub_total: 0,
          paymentmodevalue: paymentmode,
        },
        function() {
          cookie.remove("rewardSubTotal", cookieConfig);
          cookie.remove("reedemPointVal", cookieConfig);
          cookie.remove("randompointApplied", cookieConfig);
          cookie.remove("randompointAmount", cookieConfig);
          cookie.remove("usedPoints", cookieConfig);
        }
      );
    }

    if (typetxt === "promoCode") {
      this.setState(
        {
          promo_code_val: "",
          promotion_applied: "",
          promotion_type: "",
          promotion_amount: "",
          promotion_source: "",
          promoIs_delivery: "",
          order_promo_sub_total: 0,
          paymentmodevalue: paymentmode,
        },
        function() {
          cookie.remove("promoSubTotal", cookieConfig);
          cookie.remove("promoCodeVal", cookieConfig);
          cookie.remove("promotionApplied", cookieConfig);
          cookie.remove("promotionType", cookieConfig);
          cookie.remove("promotionAmount", cookieConfig);
          cookie.remove("promotionSource", cookieConfig);
          cookie.remove("promoIsDelivery", cookieConfig);
        }
      );
    }
  }

  getProductIdsDet(cartItems) {
    var product_cartid = "";
    if (Object.keys(cartItems).length > 0) {
      for (var key in cartItems) {
        if (
          product_cartid !== "" &&
          cartItems[key].cart_item_product_id !== ""
        ) {
          product_cartid += ";";
        }
        product_cartid +=
          cartItems[key].cart_item_product_id +
          "|" +
          cartItems[key].cart_item_total_price +
          "|" +
          cartItems[key].cart_item_qty;
      }
    }

    return product_cartid;
  }

  payCash(event) {
    event.preventDefault();
    $.magnificPopup.open({
      items: {
        src: ".processing",
      },
      type: "inline",
      showCloseBtn: false,
      midClick: true,
      closeOnBgClick: false,
    });

    this.postOrder(1);
  }

  postPromotionOrder(event) {
    event.preventDefault();
    $.magnificPopup.open({
      items: {
        src: ".processing",
      },
      type: "inline",
      showCloseBtn: false,
      midClick: true,
      closeOnBgClick: false,
    });
    this.postOrder(4);
  }

  /* show online payment mode loading */
  onlinePaymentLoading() {
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var randomPointArr = Array();
    randomPointArr["promotionApplied"] = this.state.randompoint_applied;
    randomPointArr["promotionAmount"] = this.state.randompoint_amount;
    var ccPromoTionArr = Array();
    ccPromoTionArr["promotionApplied"] = this.state.cpcustomer_coupon_applied;
    ccPromoTionArr["promotionAmount"] = this.state.cpcustomer_coupon_amount;
    ccPromoTionArr[
      "promoIsDelivery"
    ] = this.state.cpcustomer_promotion_delivery_charge_applied;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      this.props.cartItems,
      promoTionArr,
      randomPointArr,
      ccPromoTionArr,
      this.state.corporate_customer_wallet_pay_amount
    );
    var grandTotal = parseFloat(calculatedAmount["grandTotalAmount"]);

    if (this.state.paymentmodevalue !== "Cash" && grandTotal > 0) {
      return (
        <div className="process_col">
          <div className="process_left">
            <img src={this.state.validateimage} />
          </div>
          <div className="process_right">
            <h5>{language.process}</h5>
            <p>{language.verifycard}</p>
          </div>
        </div>
      );
    }
  }
  /**/

  /* show online payment mode loading */
  orderBCLoading() {
    return (
      <div className="process_col">
        <div className="process_left">
          <img src={this.state.placingorderimage} />
        </div>
        <div className="process_right">
          <h5>{language.process}</h5>
          <p>{language.veryifyload}</p>
        </div>
      </div>
    );
  }
  /**/

  /* show online payment mode loading */
  amountCaptureLoading() {
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var randomPointArr = Array();
    randomPointArr["promotionApplied"] = this.state.randompoint_applied;
    randomPointArr["promotionAmount"] = this.state.randompoint_amount;
    var ccPromoTionArr = Array();
    ccPromoTionArr["promotionApplied"] = this.state.cpcustomer_coupon_applied;
    ccPromoTionArr["promotionAmount"] = this.state.cpcustomer_coupon_amount;
    ccPromoTionArr[
      "promoIsDelivery"
    ] = this.state.cpcustomer_promotion_delivery_charge_applied;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      this.props.cartItems,
      promoTionArr,
      randomPointArr,
      ccPromoTionArr,
      this.state.corporate_customer_wallet_pay_amount
    );
    var grandTotal = parseFloat(calculatedAmount["grandTotalAmount"]);

    if (this.state.paymentmodevalue !== "Cash" && grandTotal > 0) {
      return (
        <div className="process_col">
          <div className="process_left">
            <img src={this.state.completingpaymentimage} />
          </div>
          <div className="process_right">
            <h5>{language.process}</h5>
            <p>{language.completepaymet}</p>
          </div>
        </div>
      );
    }
  }
  /**/

  subscriptionCaptureLoading() {
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var randomPointArr = Array();
    randomPointArr["promotionApplied"] = this.state.randompoint_applied;
    randomPointArr["promotionAmount"] = this.state.randompoint_amount;
    var ccPromoTionArr = Array();
    ccPromoTionArr["promotionApplied"] = this.state.cpcustomer_coupon_applied;
    ccPromoTionArr["promotionAmount"] = this.state.cpcustomer_coupon_amount;
    ccPromoTionArr[
      "promoIsDelivery"
    ] = this.state.cpcustomer_promotion_delivery_charge_applied;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      this.props.cartItems,
      promoTionArr,
      randomPointArr,
      ccPromoTionArr,
      this.state.corporate_customer_wallet_pay_amount
    );
    var grandTotal = parseFloat(calculatedAmount["grandTotalAmount"]);

    if (this.state.paymentmodevalue !== "Cash" && grandTotal > 0) {
      return (
        <div className="process_col">
          <div className="process_left">
            <img src={this.state.subscriptionCaptureLoading} />
          </div>
          <div className="process_right">
            <h5>{language.process}</h5>
            <p>{language.completsubscription}</p>
          </div>
        </div>
      );
    }
  }

  /* this fuction used to post order to biz panel */
  postOrder(
    paymentMode,
    validation = "No",
    captureID = "",
    payGetWayType = "",
    Paymentpop = "No",
    startstrip = "No",
    stripeconnectres = ""
  ) {
    if (cookie.load("UserId") == "" || cookie.load("UserId") == undefined) {
      cookie.save("loginpopupTrigger", "Yes", cookieConfig);
      this.props.history.push("/");
      return false;
    }

    if (
      cookie.load("defaultAvilablityId") == deliveryId &&
      paymentMode !== "initial"
    ) {
      var unitNoOne = this.state.unitnumber1;
      var unitNoTwo = this.state.unitnumber2;

      var billingAddrsSameas = this.state.billing_addrs_sameas;
      var billingPostcode = this.state.billing_postcode;
      var billingAddrss = this.state.billing_delivery_address;
      if (
        billingAddrsSameas === "no" &&
        (billingPostcode === "" || billingAddrss === "")
      ) {
        $("#dvLoading").fadeOut(1000);
        hideLoader("savedcardsubmit", "class");
        showAlert(language.error, language.enterdefaultaddress);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
        return false;
      }
    }

    var finalcartItems = this.state.cartItems;
    var subscriptionTotalAmt = 0;
    var subscriptionavail = 0;
    var products = [];
    var row = this.state.cartItems.map(
      function(item, i) {
        var modifierdata = [];
        var comboData = [];
        var ComboLen = item.set_menu_component.length;
        var modifierLen = item.modifiers.length;
        if (item.cart_item_type === "Modifier" && modifierLen > 0) {
          for (var l = 0; l < modifierLen; l++) {
            var modifierVal = [];
            modifierVal.push({
              modifier_value_id:
                item.modifiers[l].modifiers_values[0].cart_modifier_id,
              modifier_value_qty:
                item.modifiers[l].modifiers_values[0].cart_modifier_qty,
              modifier_value_name:
                item.modifiers[l].modifiers_values[0].cart_modifier_name,
              modifier_value_price:
                item.modifiers[l].modifiers_values[0].cart_modifier_price,
            });
            modifierdata.push({
              modifier_id: item.modifiers[l].cart_modifier_id,
              modifier_name: item.modifiers[l].cart_modifier_name,
              modifiers_values: modifierVal,
            });
          }
        } else if (item.cart_item_type === "Component" && ComboLen > 0) {
          for (var m = 0; m < ComboLen; m++) {
            var combomodifierdata = [];
            /* build  modifier array */
            var comboModifier =
              item.set_menu_component[m].product_details[0].modifiers;

            var combomodifierdata = [];
            if (comboModifier.length > 0) {
              for (var p = 0; p < comboModifier.length; p++) {
                var comboModifierVal = [];
                comboModifierVal.push({
                  modifier_value_id:
                    comboModifier[p].modifiers_values[0].cart_modifier_id,
                  modifier_value_qty:
                    comboModifier[p].modifiers_values[0].cart_modifier_qty,
                  modifier_value_name:
                    comboModifier[p].modifiers_values[0].cart_modifier_name,
                  modifier_value_price:
                    comboModifier[p].modifiers_values[0].cart_modifier_price,
                });
                combomodifierdata.push({
                  modifier_id: comboModifier[p].cart_modifier_id,
                  modifier_name: comboModifier[p].cart_modifier_name,
                  modifiers_values: comboModifierVal,
                });
              }
            }

            var comborVal = [];
            var comboProductDetailslen =
              item.set_menu_component[m].product_details.length;
            if (comboProductDetailslen > 0) {
              for (
                var j = 0, lengthComboPro = comboProductDetailslen;
                j < lengthComboPro;
                j++
              ) {
                comborVal.push({
                  product_id:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_id,
                  product_name:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_name,
                  product_sku:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_sku,
                  product_qty:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_qty,
                  product_price:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_price,
                  product_selection:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_selection,
                  modifiers: combomodifierdata,
                });
              }
            }

            comboData.push({
              menu_component_id: item.set_menu_component[m].menu_component_id,
              menu_component_name:
                item.set_menu_component[m].menu_component_name,
              product_details: comborVal,
            });
          }
        }

        products.push({
          product_item_id: item.cart_item_id,
          product_name: item.cart_item_product_name,
          product_unit_price: item.cart_item_unit_price,
          product_actual_unit_price: item.cart_item_actual_unit_price,
          product_total_amount: item.cart_item_total_price,
          product_sku: item.cart_item_product_sku,
          product_revel_id: item.cart_item_product_revel_id,
          product_dimension: item.cart_item_dimension,
          product_image: item.cart_item_product_image,
          product_id: item.cart_item_product_id,
          product_qty: item.cart_item_qty,
          product_subscription: item.cart_item_subscription,
          product_subscription_cycle: item.cart_item_subscription_cycle,
          product_subscription_start_date:
            item.cart_item_subscription_start_date !== "" &&
            item.cart_item_subscription_start_date !== null &&
            item.cart_item_subscription_start_date
              ? item.cart_item_subscription_start_date
              : "",
          product_cate_id: item.cart_item_category_id,
          product_subcate_id: item.cart_item_sub_category_id,
          condiments: "",
          modifiers: modifierdata,
          bakers_modifiers: "",
          menu_set_components: comboData,
          baby_pack: "",
          product_special_notes: item.cart_item_special_notes,
          voucher_gift_name: item.cart_item_product_voucher_gift_name,
          voucher_gift_mobile: item.cart_item_product_voucher_gift_mobile,
          voucher_gift_email: item.cart_item_product_voucher_gift_email,
          voucher_gift_message: item.cart_item_product_voucher_gift_message,
          order_item_id:
            item.cart_voucher_order_item_id != "0"
              ? item.cart_voucher_order_item_id
              : "",
          voucher_free_product: item.cart_item_voucher_product_free,
          order_voucher_id: item.cart_item_voucher_id,
        });
        if (
          item.cart_item_subscription_start_date !== "" &&
          item.cart_item_subscription_start_date !== "0000-00-00" &&
          item.cart_item_subscription_start_date !== null
        ) {
          ReactSession.set("subscriptionavailability", 1);
          window.sessionStorage.setItem("subscriptionavailability", 1);
          subscriptionavail = 1;
          subscriptionTotalAmt += parseFloat(item.cart_item_total_price);
        }
        return products;
      }.bind(this)
    );

    /* if  merge order date */
    var orderDate = "";

    var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;

    /* For Advanced Slot */
    var order_is_timeslot = "No",
      ordSlotStrTm = "",
      ordSlotEndTm = "";
    if (seletedOrdDate !== "" && seletedOrdTime !== "") {
      var formatedDate = format(seletedOrdDate, "yyyy-MM-dd");
      var OrderHours = seletedOrdTime.getHours();
      var OrderMunts = seletedOrdTime.getMinutes();
      var OrderSecnd = seletedOrdTime.getSeconds();

      var orderTime = this.pad(OrderHours) + ":" + this.pad(OrderMunts);

      orderDate = formatedDate + " " + orderTime;

      if (this.state.isAdvanced === "yes" && this.state.slotType === "2") {
        order_is_timeslot = "Yes";
        ordSlotStrTm = this.state.seleted_ord_slot_str;
        ordSlotEndTm = this.state.seleted_ord_slot_end;
      }
    }

    if (seletedOrdDate !== "" && seletedOrdTime !== "") {
      var formatedDate = format(seletedOrdDate, "yyyy-MM-dd");
      var OrderHours = seletedOrdTime.getHours();
      var OrderMunts = seletedOrdTime.getMinutes();
      var OrderSecnd = seletedOrdTime.getSeconds();

      var orderTime = this.pad(OrderHours) + ":" + this.pad(OrderMunts);

      orderDate = formatedDate + " " + orderTime;
    }

    if (orderDate === "" && paymentMode !== "initial") {
      $("#dvLoading").fadeOut(1000);
      hideLoader("savedcardsubmit", "class");
      showAlert(language.error, language.selectdatetime);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      return false;
    }

    if (
      this.state.chk_tarms_contn !== "Yes" &&
      this.state.termsAndConditions !== "" &&
      paymentMode !== "initial"
    ) {
      $(".tarms_chkbox_div").addClass("err_tarms_chk");
      window.scrollTo(0, 1000);
      return false;
    }

    if (paymentMode === "initial") {
      paymentMode = 2;
    }

    /*orderDate = '2019-07-10 10:15';*/

    /* insert customner details */
    if (validation !== "Yes") {
      var addressObject = {};
      var customerAddressId = 0;
      addressObject = {
        app_id: appId,
        customer_first_name: cookie.load("UserFname"),
        customer_last_name: cookie.load("UserLname"),
        customer_phone: cookie.load("UserMobile"),
        customer_email: cookie.load("UserEmail"),
        customer_address_line1: cookie.load("orderDeliveryAddress"),
        customer_postal_code: cookie.load("orderPostalCode"),
        customer_address_name: this.state.unitnumber1,
        customer_address_name2: this.state.unitnumber2,
        refrence: cookie.load("UserId"),
        access_token:
          cookie.load("access_token") !== "" &&
          typeof cookie.load("access_token") !== undefined &&
          typeof cookie.load("access_token") !== "undefined"
            ? cookie.load("access_token")
            : "",
        customer_status: "A",
        customer_order_status: "order",
      };
      /* if (cookie.load("defaultAvilablityId") === deliveryId) {
        axios
          .post(
            apiUrl + "customer/secondary_address_add",
            qs.stringify(addressObject),
            headerconfig
          )
          .then((res) => {
            if (res.data.status === "ok") {
              customerAddressId = res.data.insert_id;
            }
          });
      } */
    }

    var promotionApplied = this.state.promotion_applied;
    var promotionType = this.state.promotion_type;
    var promotionAmount = this.state.promotion_amount;
    var promotionSource = this.state.promotion_source;
    var promoIsDelivery = this.state.promoIs_delivery;

    var cartDetailsArr = this.state.cartDetails;
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var randomPointArr = Array();
    randomPointArr["promotionApplied"] = this.state.randompoint_applied;
    randomPointArr["promotionAmount"] = this.state.randompoint_amount;
    var ccPromoTionArr = Array();
    ccPromoTionArr["promotionApplied"] = this.state.cpcustomer_coupon_applied;
    ccPromoTionArr["promotionAmount"] = this.state.cpcustomer_coupon_amount;
    ccPromoTionArr[
      "promoIsDelivery"
    ] = this.state.cpcustomer_promotion_delivery_charge_applied;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      cartDetailsArr,
      this.props.cartItems,
      promoTionArr,
      randomPointArr,
      ccPromoTionArr,
      this.state.corporate_customer_wallet_pay_amount
    );

    var BillingAddress = this.state.billing_delivery_address;
    var BillingPostalCode = this.state.billing_postcode;
    var BillingUnitNo1 = this.state.billunitnumber1;
    var BillingUnitNo2 = this.state.billunitnumber2;

    if (this.state.billing_addrs_sameas === "yes") {
      BillingAddress = cookie.load("orderDeliveryAddress");
      BillingPostalCode = cookie.load("orderPostalCode");
      BillingUnitNo1 = this.state.unitnumber1;
      BillingUnitNo2 = this.state.unitnumber2;
    }

    var custBirthdateUpdate = "",
      custBirthdate = "",
      custGender = "";
    if (this.state.cust_birthdate_update === "yes") {
      custBirthdateUpdate = this.state.cust_birthdate_update;
      var birthdateTxt = this.state.cust_birthdate;
      custBirthdate = format(birthdateTxt, "yyyy-MM-dd");
      custGender = this.state.cust_gender;
    }

    var zoneIdTxt =
      typeof cookie.load("orderZoneId") === "undefined"
        ? ""
        : cookie.load("orderZoneId");

    if (
      paymentMode === 4 &&
      this.state.corporate_wallet_pay === "Yes" &&
      parseFloat(this.state.corporate_customer_wallet_pay_amount) > 0
    ) {
      paymentMode = 12;
    }

    var order_discount_applied = "No";

    if (parseFloat(this.state.corporate_customer_wallet_pay_amount) > 0) {
      order_discount_applied = "No";
    }

    if (promotionApplied === "Yes" && parseFloat(promotionAmount) > 0) {
      order_discount_applied = "Yes";
    }
    /* if (startstrip === "Yes") { 
      
    }
    */

    if (
      parseFloat(calculatedAmount["grandTotalAmount"]) < 100 &&
      parseFloat(calculatedAmount["grandTotalAmount"]) > 0
    ) {
      $("#dvLoading").fadeOut(1000);
      hideLoader("savedcardsubmit", "class");
      showAlert(language.error, language.minstirpeamt);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      return false;
    }

    if (
      ReactSession.get("subscriptionavailability") == 1 &&
      parseFloat(subscriptionTotalAmt) > 0 &&
      parseFloat(subscriptionTotalAmt) < 900
    ) {
      $("#dvLoading").fadeOut(1000);
      hideLoader("savedcardsubmit", "class");
      showAlert(language.error, language.minsubscriptionamt);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      return false;
    }

    if (
      cookie.load("orderOutletId") == "" &&
      typeof cookie.load("orderOutletId") === "undefined"
    ) {
      hideLoader("savedcardsubmit", "class");
      showAlert(language.error, "アウトレットIDがありません");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      return false;
    }

    var corporate_wallet_type_name = this.state.corporate_wallet_type;

    if (
      getCorporateWalletValue["usedWalletAmount"] > 0 &&
      calculatedAmount["grandTotalAmount"] >=
        getCorporateWalletValue["usedWalletAmount"]
    ) {
      if (
        calculatedAmount["grandTotalAmount"] >=
        getCorporateWalletValue["usedWalletAmount"]
      ) {
        corporate_wallet_type_name = "fully";
      } else {
        corporate_wallet_type_name = "partially";
      }
    }

    var postObject = {};
    postObject = {
      /* cart details */
      app_id: appId,
      availability_id: cookie.load("defaultAvilablityId"),
      sub_total:
        showPoint_total(parseFloat(calculatedAmount["cartSubTotalAmount"])) +
        ".00",
      grand_total:
        showPoint_total(parseFloat(calculatedAmount["grandTotalAmount"])) +
        ".00",
      order_voucher_discount_amount:
        parseFloat(calculatedAmount["voucherDiscountAmount"]) > 0
          ? calculatedAmount["voucherDiscountAmount"]
          : "0",
      outlet_id:
        cookie.load("orderOutletId") === undefined ||
        cookie.load("orderOutletId") === ""
          ? defaultoutlet
          : cookie.load("orderOutletId"),
      zone_id: zoneIdTxt,
      table_number: "",
      order_status: 1,
      order_source: "Web",
      order_date: orderDate,
      /* For Advanced Slot */
      order_is_timeslot: order_is_timeslot,
      order_pickup_time_slot_from: ordSlotStrTm,
      order_pickup_time_slot_to: ordSlotEndTm,
      order_remarks: this.state.order_specil_note,
      products: JSON.stringify(products),

      /* customer  Details */
      customer_id: cookie.load("UserId"),
      access_token:
        cookie.load("access_token") !== "" &&
        typeof cookie.load("access_token") !== undefined &&
        typeof cookie.load("access_token") !== "undefined"
          ? cookie.load("access_token")
          : "",
      customer_address_id: customerAddressId,
      customer_fname: cookie.load("UserFname"),
      customer_lname: cookie.load("UserLname"),
      customer_mobile_no: cookie.load("UserMobile"),
      customer_email: cookie.load("UserEmail"),
      customer_address_line1: cookie.load("orderDeliveryAddress"),
      customer_address_line2: "",
      customer_postal_code: cookie.load("orderPostalCode"),
      customer_unit_no1: this.state.unitnumber1,
      customer_unit_no2: this.state.unitnumber2,

      customer_birthdate_update: custBirthdateUpdate,
      customer_birthdate: custBirthdate,
      customer_gender: custGender,

      billing_address_line1: BillingAddress,
      billing_postal_code: BillingPostalCode,
      billing_unit_no1: BillingUnitNo1,
      billing_unit_no2: BillingUnitNo2,

      /* Payment */
      payment_mode: paymentMode /*paymentMode, */,
      payment_reference: stripeReference,
      stripe_envir: "test",
      payment_type: payGetWayType,
      order_capture_token: captureID,
      order_payment_getway_type: "",
      order_payment_getway_status: "Yes",

      /* additional paras */
      delivery_charge: parseFloat(calculatedAmount["deliveryCharge"]).toFixed(
        2
      ),
      additional_delivery: parseFloat(
        calculatedAmount["additionalDelivery"]
      ).toFixed(2),
      order_tat_time:
        typeof cookie.load("orderTAT") === "undefined"
          ? ""
          : cookie.load("orderTAT"),
      tax_charge: calculatedAmount["orderDisplayGst"],
      order_tax_calculate_amount:
        showPoint_total(
          parseFloat(calculatedAmount["orderGstAmount"]).toFixed(2)
        ) + ".00",
      order_data_encode: "Yes",
      /* discount */
      order_discount_applied: order_discount_applied,
      order_discount_amount: "",
      order_discount_type: "",

      order_special_discount_amount: cartDetailsArr.cart_special_discount,
      order_special_discount_type: cartDetailsArr.cart_special_discount_type,
      order_recipient_name: this.state.recipient_name,
      order_recipient_contact_no: this.state.recipient_contact_no,
      order_gift_message: this.state.gift_message,
    };
    /* check  validation */

    if (payGetWayType === "StripeConnect") {
      postObject["order_status"] = "1";
      postObject["payment_type"] = "Stripe";
    } else {
      postObject["order_status"] = "1";
      postObject["payment_type"] = payGetWayType;
    }

    /*  promo code object - start */
    if (
      this.state.cpcustomer_coupon_applied === "Yes" &&
      parseFloat(this.state.cpcustomer_coupon_amount) > 0
    ) {
      var categoryIdsDet = this.getProductIdsDet(finalcartItems);

      postObject["cpcustomer_coupon_applied"] = "Yes";
      postObject["cpcustomer_promo_code"] = this.state.cpcustomer_promo_code;
      postObject["cart_quantity"] = cartDetailsArr.cart_total_items;
      postObject["category_id"] = categoryIdsDet;
      postObject[
        "cpcustomer_coupon_amount"
      ] = this.state.cpcustomer_coupon_amount;
      postObject[
        "order_cpcustomer_promo_sub_total"
      ] = this.state.order_cpcustomer_promo_sub_total;
      postObject[
        "cpcustomer_promotion_delivery_charge_applied"
      ] = this.state.cpcustomer_promotion_delivery_charge_applied;
    }

    /*  promo code object - start */
    if (
      (promotionApplied === "Yes" && parseFloat(promotionAmount) > 0) ||
      (this.state.randompoint_applied === "Yes" &&
        parseFloat(this.state.randompoint_amount) > 0)
    ) {
      postObject["discount_applied"] = "Yes";
      postObject["discount_amount"] = promotionAmount;

      if (promotionApplied === "Yes" && parseFloat(promotionAmount) > 0) {
        if (this.state.cpcustomer_coupon_applied !== "Yes") {
          var categoryIdsDet = this.getProductIdsDet(finalcartItems);
          postObject["cart_quantity"] = cartDetailsArr.cart_total_items;
          postObject["category_id"] = categoryIdsDet;
        }

        postObject["coupon_applied"] = "Yes";
        postObject["promo_code"] = promotionSource;
        postObject["coupon_amount"] = promotionAmount;
        postObject["promotion_delivery_charge_applied"] = promoIsDelivery;
        postObject["order_promo_sub_total"] = this.state.order_promo_sub_total;
      }

      if (
        this.state.randompoint_applied === "Yes" &&
        parseFloat(this.state.randompoint_amount) > 0
      ) {
        postObject["redeem_applied"] = "Yes";
        postObject["redeem_point"] = this.state.used_reward_point;
        postObject["redeem_amount"] = this.state.randompoint_amount;
        postObject[
          "order_reward_sub_total"
        ] = this.state.order_reward_sub_total;
        postObject["overAllgrandTotalAmount"] =
          showPoint_total(
            parseFloat(calculatedAmount["overAllgrandTotalAmount"])
          ) + ".00";
      }
    }

    postObject[
      "corporate_customer_wallet_pay_amount"
    ] = this.state.corporate_customer_wallet_pay_amount;
    postObject["corporate_wallet_pay"] = this.state.corporate_wallet_pay;
    postObject["corporate_wallet_type"] = corporate_wallet_type_name;

    postObject["order_discount_both"] = this.state.order_discount_both;
    postObject[
      "corporate_customer_order"
    ] = this.state.corporate_customer_order;
    postObject["reward_point_status"] = "Yes";
    postObject["validate_product_specialprice"] = "Yes";
    postObject["connect_platform_fee"] =
      showPoint_total(
        parseFloat(this.state.globalSettings.stripe_express_platform_fee)
      ) + ".00";

    if (
      cookie.load("stripe_account_id") != "undefined" &&
      cookie.load("stripe_account_id") != ""
    ) {
      postObject["connect_stripe_account_id"] = cookie.load(
        "stripe_account_id"
      );
      postObject["connect_total_amount"] = cookie.load("connect_total_amount");
    }

    ReactSession.set("postObject", qs.stringify(postObject));
    window.sessionStorage.setItem("postObject", qs.stringify(postObject));
    if (validation === "Yes") {
      postObject["validation_only"] = "Yes";
    }
    var post_outlet_id = postObject["outlet_id"];

    axios
      .post(
        apiUrl + "ordersv1/submit_order",
        qs.stringify(postObject),
        headerconfig
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ orderValidFail: 0 });

          if (validation !== "Yes") {
            var localOrderNo = res.data.common.local_order_no;
            var subscription = res.data.common.subscription;
            this.setState({ placingorderimage: tickImage });
            /* capture payemnt */
            if (payGetWayType === "Stripe") {
              this.captureAmount(
                captureID,
                res.data.common.order_primary_id,
                localOrderNo,
                subscription
              );
            } else if (payGetWayType === "StripeConnect") {
              this.captureAmountStripeConnect(
                captureID,
                res.data.common.order_id,
                localOrderNo,
                subscription,
                stripeconnectres,
                res.data.common.order_primary_id
              );
            } else if (payGetWayType === "Omise") {
              this.captureOmiseAmount(
                captureID,
                res.data.common.order_primary_id,
                localOrderNo
              );
            } else {
              this.showSuccessPage(localOrderNo);
            }
          } else {
            if (startstrip === "Yes" && payGetWayType != "StripeConnect") {
              this.getStripToken();
            } else if (payGetWayType == "StripeConnect") {
              var grandTotalAmount =
                showPoint_total(
                  parseFloat(calculatedAmount["grandTotalAmount"])
                ) + ".00";
              var postObject = {
                app_id: appId,
                customer_id: cookie.load("UserId"),
                access_token:
                  cookie.load("access_token") !== "" &&
                  typeof cookie.load("access_token") !== undefined &&
                  typeof cookie.load("access_token") !== "undefined"
                    ? cookie.load("access_token")
                    : "",
                payment_reference: stripeReference,
                paid_amount: parseFloat(grandTotalAmount).toFixed(2),
                availability_id: cookie.load("defaultAvilablityId"),
                orderOutlet_Id: post_outlet_id,
                card_id: this.state.selectCard,
                saveMyCard: this.state.saveMyCard,
                customer_fname: cookie.load("UserFname"),
                customer_email: cookie.load("UserEmail"),
              };
              this.props.getStripeKey(qs.stringify(postObject));
            } else {
              if (Paymentpop === "Yes") {
                var popupIdTxt =
                  payGetWayType === "Omise"
                    ? "#pay-omiseconf-popup"
                    : "#pay-conf-popup";

                $.magnificPopup.open({
                  items: {
                    src: popupIdTxt,
                  },
                  type: "inline",
                  closeOnBgClick: false,
                });
              }
            }
            return true;
          }
        } else if (res.data.status === "error") {
          $("#dvLoading").fadeOut(1000);
          hideLoader("pay-conf-popup", "Idtext");
          // This is used for stripe Auth Capture
          this.setState({ orderValidFail: 1 });

          if (res.data.form_error !== undefined) {
            showAlert("Error", res.data.form_error);
          } else {
            showAlert("Error", res.data.message);
          }
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });

          if (
            res.data.clear_offer === "Yes" ||
            res.data.offer_changed === "Yes"
          ) {
            removePromoCkValue();
            this.props.history.push("/refpage/gocheckout");
          }
        }
      })
      .catch((error) => {
        $.magnificPopup.close();
        this.paymentFail(language.error, language.error1004);
        $.magnificPopup.open({
          items: {
            src: ".warining_popup",
          },
          type: "inline",
        });
        return false;
      });
  }

  pageReload = () => {
    setTimeout(function() {
      //  window.location.reload();
    }, 15000);
  };

  paymentFail(header, message) {
    $(".warining_popup").remove();
    $("body").append(
      '<div class="white-popup mfp-hide popup_sec warining_popup"><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
        header +
        '</div><div class="alert_body"><p>' +
        message +
        '</p><div class="alt_btns"><a href="javascript:void(0);" onClick="' +
        this.pageReload() +
        '"class="btn btn-sm btn_yellow popup-modal-dismiss">' +
        language.allright +
        "</a></div></div></div></div></div></div>"
    );
  }

  /* Capture amount */
  captureAmount(captureID, orderPrimaryId, localOrderNo, subscription) {
    var cabtureObjects = {};
    var captureParams = captureID + "~" + orderPrimaryId + "~" + localOrderNo;
    cabtureObjects = {
      payment_reference: stripeReference,
      stripe_envir: this.state.globalSettings.stripe_envir,
      customer_id: cookie.load("UserId"),
      access_token:
        cookie.load("access_token") !== "" &&
        typeof cookie.load("access_token") !== undefined &&
        typeof cookie.load("access_token") !== "undefined"
          ? cookie.load("access_token")
          : "",
      app_id: appId,
      token: captureID,
      order_id: orderPrimaryId,
    };
    axios
      .post(
        apiUrl + "paymentconnect/captureAmount",
        qs.stringify(cabtureObjects),
        headerconfig
      )
      .then((captureRes) => {
        if (captureRes.data.status === "ok") {
          this.setState({ completingpaymentimage: tickImage });
          if (subscription === "Yes") {
            this.createSubscription(orderPrimaryId, localOrderNo, subscription);
          } else {
            this.showSuccessPage(localOrderNo);
          }
        } else if (captureRes.data.status === "error") {
          this.paymentFail(language.error, language.orderunsuccess);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        } else {
          this.paymentFail(language.error, language.orderunsuccess);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      })
      .catch((error) => {});
  }

  /* Capture amount */
  captureAmountStripeConnect(
    captureID,
    orderID,
    localOrderNo,
    subscription,
    stripeconnectres,
    orderPrimaryId
  ) {
    var customerId =
      typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");
    var postObject = {};
    postObject = {
      app_id: appId,
      order_id: orderID,
      payment_intent: cookie.load("paymentIntent"),
      customerId: customerId,
      access_token:
        cookie.load("access_token") !== "" &&
        typeof cookie.load("access_token") !== undefined &&
        typeof cookie.load("access_token") !== "undefined"
          ? cookie.load("access_token")
          : "",
    };

    axios
      .post(
        apiUrl + "orders/statusUpdate",
        qs.stringify(postObject),
        headerconfig
      )
      .then((captureRes) => {
        if (captureRes.data.status === "ok") {
          this.setState({ completingpaymentimage: tickImage });
          if (subscription === "Yes") {
            this.createSubscription(orderPrimaryId, localOrderNo, subscription);
          } else {
            this.showSuccessPage(localOrderNo);
          }
        } else if (captureRes.data.status === "error") {
          this.paymentFail(language.error, language.orderunsuccess);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        } else {
          this.paymentFail(language.error, language.orderunsuccess);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      })
      .catch((error) => {});
  }

  createSubscription(orderPrimaryId, localOrderNo, subscription) {
    var subscribeObjects = {};
    subscribeObjects = {
      app_id: appId,
      order_primary_id: orderPrimaryId,
      payment_reference: stripeReference,
      customer_id: cookie.load("UserId"),
      access_token:
        cookie.load("access_token") !== "" &&
        typeof cookie.load("access_token") !== undefined &&
        typeof cookie.load("access_token") !== "undefined"
          ? cookie.load("access_token")
          : "",
      payment_intent: cookie.load("paymentIntent"),
      stripe_account_id:
        typeof cookie.load("stripe_account_id") === "undefined"
          ? ""
          : cookie.load("stripe_account_id"),
      stripe_fee_percent:
        typeof cookie.load("stripe_fee_percent") === "undefined"
          ? ""
          : cookie.load("stripe_fee_percent"),
    };

    axios
      .post(
        apiUrl + "subscription/createSubscription",
        qs.stringify(subscribeObjects),
        headerconfig
      )
      .then((captureRes) => {
        if (captureRes.data.status === "ok") {
          this.setState({ subscriptionCaptureLoading: tickImage });
          this.showSuccessPage(localOrderNo);
        } else if (captureRes.data.status === "error") {
          this.paymentFail(language.error, language.orderunsuccess);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        } else {
          this.paymentFail(language.error, language.orderunsuccess);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      });
  }

  /* Omise Capture Payment */
  captureOmiseAmount(captureID, orderPrimaryId, localOrderNo) {
    var cabtureObjects = {};
    cabtureObjects = {
      payment_reference: stripeReference,
      customer_id: cookie.load("UserId"),
      access_token:
        cookie.load("access_token") !== "" &&
        typeof cookie.load("access_token") !== undefined &&
        typeof cookie.load("access_token") !== "undefined"
          ? cookie.load("access_token")
          : "",
      app_id: appId,
      token: captureID,
      order_id: orderPrimaryId,
      log_id: this.state.omise_log_id,
      outlet_id: cookie.load("orderOutletId"),
    };
    axios
      .post(
        apiUrl + "paymentv1/captureAmountOmise",
        qs.stringify(cabtureObjects),
        headerconfig
      )
      .then((captureRes) => {
        if (captureRes.data.status === "ok") {
          this.setState({ completingpaymentimage: tickImage });

          this.setState(
            { stop_authentication: "yes" },
            function() {
              this.showSuccessPage(localOrderNo);
            }.bind(this)
          );
        } else if (captureRes.data.status === "pending") {
          this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId);
        } else if (captureRes.data.status === "error") {
          var magnfPopup = $.magnificPopup.instance;
          var omsMsgTxt =
            captureRes.data.message != ""
              ? captureRes.data.message
              : language.orderunsuccess;
          showAlert("Error", omsMsgTxt, magnfPopup);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        } else {
          /*var magnfPopup = $.magnificPopup.instance;
        showAlert('Error', 'Your order was not successful.', magnfPopup);
                $.magnificPopup.open({
                    items: {
                        src: '.alert_popup'
                    },
                    type: 'inline'
                });*/

          /*Get Status of Charge ID*/
          this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId);
        }
      })
      .catch((error) => {
        this.captureOmiseAmount(captureID, orderPrimaryId, localOrderNo);
      });
  }

  retrieve_charge_details = (captureID, localOrderNo, orderPrimaryId) => {
    var orderOutlet_Id = cookie.load("orderOutletId");
    var postObject = {};
    postObject = {
      charge_id: captureID,
      app_id: appId,
      order_primary_id: orderPrimaryId,
      outlet_id: orderOutlet_Id,
    };

    axios
      .post(
        apiUrl + "paymentv1/retrieve_charge_details",
        qs.stringify(postObject),
        headerconfig
      )
      .then((res) => {
        if (
          res.data.status === "ok" &&
          res.data.payment_status === "successful"
        ) {
          this.setState(
            { stop_authentication: "yes" },
            function() {
              this.showSuccessPage(localOrderNo);
            }.bind(this)
          );
        } else if (
          res.data.status === "ok" &&
          res.data.payment_status === "pending"
        ) {
          this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId);
        } else {
          var magnfPopup = $.magnificPopup.instance;
          var msgTxt =
            res.data.message != "" ? res.data.message : language.orderunsuccess;
          showAlert(language.error, msgTxt, magnfPopup);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      });
  };

  /* sucess page */
  showSuccessPage(localOrderNo) {
    this.props.destroyCartDetail();
    cookie.remove("corporate_customer_wallet_pay_amount", cookieConfig);
    cookie.save("ChkOrderid", localOrderNo, cookieConfig);
    const { history } = this.props;
    setTimeout(function() {
      $.magnificPopup.close();
      history.push("/thankyou/" + localOrderNo);
    }, 450);
  }
  /* this function used to  delete all cookie values */
  deleteOrderCookie() {
    removePromoCkValue();

    cookie.remove("orderPaymentMode", cookieConfig);
    cookie.remove("orderTableNo", cookieConfig);
    cookie.remove("product_remarks", cookieConfig);
    cookie.remove("orderOutletName", cookieConfig);
    //cookie.remove('orderOutletId');
    cookie.remove("carttotalitems", cookieConfig);
    cookie.remove("cartsubtotal", cookieConfig);
    cookie.remove("cartid", cookieConfig);

    /* Delivery avilablity */
    cookie.remove("deliveryDate", cookieConfig);
    cookie.remove("deliveryTime", cookieConfig);
    /* cookie.remove("unitNoOne", cookieConfig);
    cookie.remove("unitNoTwo", cookieConfig); */
    cookie.remove("firstNavigation", cookieConfig);

    cookie.remove("promotion_id", cookieConfig);
    cookie.remove("promotion_applied", cookieConfig);
    cookie.remove("promotion_code", cookieConfig);
    cookie.remove("promotion_delivery_charge_applied", cookieConfig);
    cookie.remove("promotion_amount", cookieConfig);
    cookie.remove("promotion_category", cookieConfig);
    cookie.remove("prmo_type", cookieConfig);

    /*Remove voucher*/
    cookie.remove("voucher_applied", cookieConfig);
    cookie.remove("voucher_code", cookieConfig);
    cookie.remove("voucher_amount", cookieConfig);

    cookie.remove("points_redeemed", cookieConfig);
    cookie.remove("points_used", cookieConfig);
    cookie.remove("points_amount", cookieConfig);
    cookie.remove("prmo_type", cookieConfig);
  }

  choosePayment(paymentMode) {
    if (paymentMode === "Cash") {
      this.setState({ paymentmodevalue: "Cash" });
      this.postOrder(2, "Yes");
    } else if (paymentMode === "Stripe") {
      this.setState({ paymentmodevalue: "Stripe" });
      this.postOrder(2, "Yes");
    } else if (paymentMode === "StripeConnect") {
      $("#dvLoading").fadeIn();
      this.setState({ paymentmodevalue: "StripeConnect" });
      this.setState({ clientSecret: "" });
      this.setState({ selectCard: "new" });
      this.postOrder(2, "Yes", "", "StripeConnect", "");
    } else if (paymentMode === "Omise") {
      this.setState({ paymentmodevalue: "Omise" });
      this.postOrder(2, "Yes");
    } else {
      this.setState({ paymentmodevalue: "promotion" });
      this.postOrder(2, "Yes");
    }
  }

  /* stripe description  */
  stripeDescription() {
    //userName
    if (
      typeof cookie.load("UserLname") !== "undefined" &&
      cookie.load("UserLname") !== ""
    ) {
      return cookie.load("UserFname") + " " + cookie.load("UserLname");
    } else {
      return cookie.load("UserLname");
    }
  }
  validateBeforeSumbit() {
    this.setState({ selectCard: "new" });
    showLoader("pay-conf-popup", "Idtext");
    this.postOrder(2, "Yes", "", "StripeConnect", "");
  }
  validateBeforeSumbitSaved() {
    showLoader("savedcardsubmit", "class");
    this.postOrder(2, "Yes", "", "StripeConnect", "");
  }
  getStripToken = () => {
    var errorFlg = 0,
      omisenameerror = "",
      omisecardrror = "",
      omisemontherror = "",
      omiseyearerror = "",
      omisecodeerror = "";
    if (this.state.selectCard === "new") {
      if (this.state.holdername == "") {
        errorFlg++;
        omisenameerror = "omise-form-error";
      }
      if (this.state.cardNumber == "") {
        errorFlg++;
        omisecardrror = "omise-form-error";
      }
      if (this.state.expiration_month == "") {
        errorFlg++;
        omisemontherror = "omise-form-error";
      }
      if (this.state.expiration_year == "") {
        errorFlg++;
        omiseyearerror = "omise-form-error";
      }
      if (this.state.security_code == "") {
        errorFlg++;
        omisecodeerror = "omise-form-error";
      }

      this.setState({
        omisenameerror: omisenameerror,
        omisecardrror: omisecardrror,
        omisemontherror: omisemontherror,
        omiseyearerror: omiseyearerror,
        omisecodeerror: omisecodeerror,
      });

      if (errorFlg === 0) {
        showLoader("omise-pay-btn", "class");

        var postObject = {};

        postObject = {
          app_id: appId,
          card_number: this.state.cardNumber,
          expiry_month: this.state.expiration_month,
          expiry_year: this.state.expiration_year,
          cvv_number: this.state.security_code,
          stripe_envir: this.state.globalSettings.stripe_envir,
          payment_reference: stripeReference,
        };

        axios
          .post(
            apiUrl + "payment/stripeGetToken",
            qs.stringify(postObject),
            headerconfig
          )
          .then((res) => {
            if (res.data.status === "ok") {
              this.onToken(res.data.result_set);
            } else if (res.data.status === "error") {
              $.magnificPopup.close();
              var msgTxt =
                res.data.message != "" ? res.data.message : language.error1002;
              this.paymentFail(language.error, msgTxt);

              $.magnificPopup.open({
                items: {
                  src: ".warining_popup",
                },
                type: "inline",
              });
              return false;
            }
          })
          .catch((error) => {
            $.magnificPopup.close();
            this.paymentFail(language.error, language.error1002);
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });
            return false;
          });
      }
    } else {
      this.onToken("old");
    }
  };

  stripConnectConfirm = () => {
    var errorFlg = 0,
      omisenameerror = "",
      omisecardrror = "",
      omisemontherror = "",
      omiseyearerror = "",
      omisecodeerror = "";
    if (this.state.holdername == "") {
      errorFlg++;
      omisenameerror = "omise-form-error";
    }
    if (this.state.cardNumber == "") {
      errorFlg++;
      omisecardrror = "omise-form-error";
    }
    if (this.state.expiration_month == "") {
      errorFlg++;
      omisemontherror = "omise-form-error";
    }
    if (this.state.expiration_year == "") {
      errorFlg++;
      omiseyearerror = "omise-form-error";
    }
    if (this.state.security_code == "") {
      errorFlg++;
      omisecodeerror = "omise-form-error";
    }

    this.setState({
      omisenameerror: omisenameerror,
      omisecardrror: omisecardrror,
      omisemontherror: omisemontherror,
      omiseyearerror: omiseyearerror,
      omisecodeerror: omisecodeerror,
    });

    if (errorFlg === 0) {
      showLoader("omise-pay-btn", "class");

      var postObject = {};

      postObject = {
        app_id: appId,
        card_number: this.state.cardNumber,
        expiry_month: this.state.expiration_month,
        expiry_year: this.state.expiration_year,
        cvv_number: this.state.security_code,
        stripe_envir: this.state.globalSettings.stripe_envir,
        payment_reference: stripeReference,
        payment_intent: cookie.load("paymentIntent"),
      };

      axios
        .post(
          apiUrl + "payment/stripeConnectConfirm",
          qs.stringify(postObject),
          headerconfig
        )
        .then((res) => {
          if (res.data.status === "ok") {
            this.postOrder(
              3,
              "",
              res.data.result_set.id,
              "StripeConnect",
              "",
              "",
              JSON.stringify(res.data.result_set)
            );
          } else if (res.data.status === "error") {
            $.magnificPopup.close();
            var msgTxt =
              res.data.message != "" ? res.data.message : language.error1002;
            this.paymentFail(language.error, msgTxt);

            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });
            return false;
          }
        })
        .catch((error) => {
          $.magnificPopup.close();
          this.paymentFail(language.error, language.error1002);
          $.magnificPopup.open({
            items: {
              src: ".warining_popup",
            },
            type: "inline",
          });
          return false;
        });
    }
  };

  /* post stripe account */
  onToken = (token) => {
    $.magnificPopup.open({
      items: {
        src: ".processing",
      },
      type: "inline",
      midClick: true,
      closeOnBgClick: false,
    });

    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var randomPointArr = Array();
    randomPointArr["promotionApplied"] = this.state.randompoint_applied;
    randomPointArr["promotionAmount"] = this.state.randompoint_amount;
    var ccPromoTionArr = Array();
    ccPromoTionArr["promotionApplied"] = this.state.cpcustomer_coupon_applied;
    ccPromoTionArr["promotionAmount"] = this.state.cpcustomer_coupon_amount;
    ccPromoTionArr[
      "promoIsDelivery"
    ] = this.state.cpcustomer_promotion_delivery_charge_applied;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      this.props.cartItems,
      promoTionArr,
      randomPointArr,
      ccPromoTionArr,
      this.state.corporate_customer_wallet_pay_amount
    );

    var payAmount = parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2);

    /*  load process html */
    var postObject = {};
    postObject = {
      app_id: appId,
      stripe_envir: this.state.globalSettings.stripe_envir,
      stripe_key: atob(this.state.globalSettings.stripe_public_key),
      customer_id: cookie.load("UserId"),
      access_token:
        cookie.load("access_token") !== "" &&
        typeof cookie.load("access_token") !== undefined &&
        typeof cookie.load("access_token") !== "undefined"
          ? cookie.load("access_token")
          : "",
      paid_amount: payAmount,
      outlet_name:
        typeof cookie.load("orderOutletName") !== "undefined"
          ? cookie.load("orderOutletName")
          : "",
      payment_reference: stripeReference,
      create_customer: this.state.saveMyCard === true ? "yes" : "no",
      /*  account_id:typeof cookie.load("stripe_account_id") !== "undefined" && typeof cookie.load("stripe_account_id") !== undefined && cookie.load("stripe_account_id")!==""
      ? cookie.load("stripe_account_id")
      : "", */
      account_id: "acct_1KuxKER2q6Qcq7Ba",
      platform_fee: "354",
      /* platform_fee:typeof cookie.load("platform_fee") !== "undefined" && typeof cookie.load("platform_fee") !== undefined && cookie.load("platform_fee")!==""
      ? cookie.load("platform_fee")
      : "", */
    };
    if (this.state.selectCard === "new") {
      postObject["token"] = token.id;
      postObject["card_type"] = "new";
    } else {
      postObject["token"] = this.state.selectCard;
      postObject["card_type"] = "old";
    }

    this.postOrder(2, "Yes"); // To validate the order

    if (this.state.orderValidFail === 0) {
      axios
        .post(
          apiUrl + "paymentconnect/stripeTokenPay",
          qs.stringify(postObject),
          headerconfig
        )
        .then((res) => {
          if (res.data.status === "ok") {
            var captureID = res.data.result_set.payment_reference_1;
            /*    console.log(captureID, 'captureIDcaptureID')
            return false; */
            if (this.state.saveMyCard === true) {
              this.storeCard(captureID);
            } else {
              this.setState({ validateimage: tickImage });
              this.postOrder(3, "", captureID, "Stripe");
            }
          } else if (res.data.status === "error") {
            $.magnificPopup.close();
            this.paymentFail(language.error, language.error1001);
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });
            return false;
          }
        })
        .catch((error) => {
          $.magnificPopup.close();
          this.paymentFail(language.error, language.error1002);
          $.magnificPopup.open({
            items: {
              src: ".warining_popup",
            },
            type: "inline",
          });
          return false;
        });
    } else {
      $.magnificPopup.close();
      this.paymentFail(language.error, language.error1003);
      $.magnificPopup.open({
        items: {
          src: ".warining_popup",
        },
        type: "inline",
      });

      return false;
    }
  };

  storeCard(captureID) {
    this.setState({
      validateimage: tickImage,
      paymentmodevalue: "StripeConnect",
    });
    this.postOrder(3, "", captureID, "StripeConnect");
  }

  orderPayValidaion(payGetWayType, event) {
    event.preventDefault();
    if (payGetWayType === "StripeConnect") {
      $("html, body").animate(
        {
          scrollTop: $(".chk-payment-main").offset().top - 100,
        },
        1000
      );
      // this.postOrder(2, "No", "","StripeConnect","Yes");
      // $("#stripe_payment_frm").fadeIn();
      //$("#stripe_btn").hide();
    }
    if (payGetWayType === "Stripe") {
      $("html, body").animate(
        {
          scrollTop: $(".chk-payment-main").offset().top - 100,
        },
        1000
      );
      this.postOrder(2, "Yes", "");
      // $("#stripe_payment_frm").fadeIn();
      //$("#stripe_btn").hide();
    }
    return false;
  }

  changeAddrrFun(event) {
    event.preventDefault();
    this.setState({ addAddress: "Yes" });
    $.magnificPopup.open({
      items: {
        src: "#delevery-postcode-popup",
      },
      type: "inline",
    });
    return false;
  }

  CheckTarmsContnChk() {
    var tarmsContn = this.state.chk_tarms_contn;
    var chkBox = false;
    if (tarmsContn === "Yes") {
      chkBox = true;
    }
    return chkBox;
  }

  changeTarmsContnChk() {
    var tarmsContn = this.state.chk_tarms_contn;
    if (tarmsContn === "Yes") {
      this.setState({ chk_tarms_contn: "No" });
    } else {
      this.setState({ chk_tarms_contn: "Yes" });
    }
    $(".tarms_chkbox_div").removeClass("err_tarms_chk");
  }

  changeSavecard() {
    var saveMyCard = this.state.saveMyCard;
    if (saveMyCard === "Yes") {
      this.setState({ saveMyCard: "No" });
    } else {
      if (this.state.cardList.length >= 3) {
        $("#payment-message-save").show();
        $("#payment-message-save").fadeOut(3000);
        $(".savecardcheck").prop("checked", false);
      } else {
        this.setState({ saveMyCard: "Yes" });
      }
    }
  }

  CheckSaveCard() {
    var saveMyCard = this.state.saveMyCard;
    window.sessionStorage.setItem("saveMyCard", this.state.saveMyCard);
    var chkBox = false;
    if (saveMyCard === "Yes") {
      chkBox = true;
    }
    return chkBox;
  }

  setdateTimeFlg = (field, value) => {
    if (field == "tmflg") {
      this.setState({ getDateTimeFlg: value });
    } else if (field == "ordDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordTime") {
      var tmSltArr = value;
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
      var OrdDateTimeArr = Array();
      OrdDateTimeArr["OrdDate"] = tmSltArr["sldorddate"];
      OrdDateTimeArr["OrdTime"] = tmSltArr["sldordtime"];
    } else if (field == "ordSlotDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotTime") {
      var tmSltArr = value;
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: tmSltArr["ordSlotVal"],
        seleted_ord_slotTxt: tmSltArr["ordSlotLbl"],
        seleted_ord_slot_str: tmSltArr["ordSlotStr"],
        seleted_ord_slot_end: tmSltArr["ordSlotEnd"],
      });
      var OrdDateTimeArr = Array();
      OrdDateTimeArr["OrdDate"] = tmSltArr["sldorddate"];
      OrdDateTimeArr["OrdTime"] = tmSltArr["sldordtime"];
    }
  };

  chkProStockCls(proSlug, Stock) {
    var returnText = "products-single-div no-stock-product " + proSlug;
    if (Stock > 0) {
      returnText = "products-single-div " + proSlug;
    }

    return returnText;
  }

  /* Addon product Listing */
  addonProductListing() {
    var addonProductlst = this.props.addonproductlist;
    var clientProductStock = "";
    if (Object.keys(this.state.globalSettings).length > 0) {
      clientProductStock = this.state.globalSettings.client_product_stock;
    }
    if (Object.keys(addonProductlst).length > 0) {
      if (Object.keys(addonProductlst[0].subcategorie).length > 0) {
        var slugType = "category";
        var slugValue = addonProductlst[0].subcategorie[0].pro_cate_slug;
        var addonProlstOnly = addonProductlst[0].subcategorie[0].products;
        var addonCommonImg = this.props.addonproductcommon.product_image_source;
        var tagImageSource = this.props.addonproductcommon.tag_image_source;
        const Phtml = addonProlstOnly.map((product, index) => (
          <div
            className={this.chkProStockCls(
              product.product_slug,
              product.product_stock,
              clientProductStock
            )}
            id={"proIndex-" + product.product_primary_id}
            key={index}
          >
            <div className="products-image-div">
              {product.product_thumbnail !== "" ? (
                <img src={addonCommonImg + "/" + product.product_thumbnail} />
              ) : (
                <img src={noimage} />
              )}
            </div>

            <div className="product-info-div">
              <div className="product-title-maindiv">
                <div className="product-title">
                  <h3>
                    {product.product_alias !== ""
                      ? stripslashes(product.product_alias)
                      : stripslashes(product.product_name)}
                  </h3>
                </div>
                {Object.keys(product.product_tag).length > 0 ? (
                  <div className="product-tag-list">
                    <ul>
                      {product.product_tag.map((producttag, index1) => {
                        return (
                          <li key={index1}>
                            {producttag.pro_tag_image !== "" ? (
                              <img
                                src={tagImageSource + producttag.pro_tag_image}
                              />
                            ) : (
                              ""
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="product-short-description">
                <p>
                  {product.product_short_description !== null &&
                  product.product_short_description !== ""
                    ? Parser(stripslashes(product.product_short_description))
                    : Parser("&nbsp;")}
                </p>
              </div>

              <div className="product-price">
                <h3>{showPriceValue(product.product_price)}</h3>
              </div>

              <div className="products-ordernow-action">
                {product.product_stock > 0 || product.product_stock === null ? (
                  product.product_type === "1" ? (
                    <a
                      className="button order_nowdiv smiple_product_lk disbl_href_action"
                      href="/"
                      onClick={this.addToCartSimple.bind(
                        this,
                        product,
                        "initial"
                      )}
                    >
                      {language.addtocart}
                    </a>
                  ) : (
                    <a
                      href="/"
                      onClick={this.viewProDetail.bind(this, product)}
                      title="Product Details"
                      id={"comboPro-" + product.product_slug}
                      className="button order_nowdiv compo_product_lk"
                    >
                      {language.ordernow}
                    </a>
                  )
                ) : (
                  <a
                    className="button order_nowdiv disabled disbl_href_action"
                    href="/"
                  >
                    {language.soldout}
                  </a>
                )}

                <div className="addcart_row addcart_done_maindiv">
                  <div className="qty_bx">
                    <span
                      className="qty_minus"
                      onClick={this.proQtyAction.bind(
                        this,
                        product.product_primary_id,
                        "decr"
                      )}
                    >
                      -
                    </span>
                    <input
                      type="text"
                      className="proqty_input"
                      readOnly
                      value="1"
                    />
                    <span
                      className="qty_plus"
                      onClick={this.proQtyAction.bind(
                        this,
                        product.product_primary_id,
                        "incr"
                      )}
                    >
                      +
                    </span>
                  </div>
                  <button
                    className="btn btn_black"
                    onClick={this.addToCartSimple.bind(this, product, "done")}
                  >
                    {language.end}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ));

        return Phtml;
      }
    } else {
      return false;
    }
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();
    var IndexFlg = productDetail.product_primary_id;

    if (actionFlg === "initial") {
      $("#proIndex-" + IndexFlg).addClass("active");
      $("#proIndex-" + IndexFlg)
        .find(".smiple_product_lk")
        .hide();
      $("#proIndex-" + IndexFlg)
        .find(".addcart_done_maindiv")
        .show();
      return false;
    } else {
      showLoader("proIndex-" + IndexFlg, "Idtext");
      var availabilityId = cookie.load("defaultAvilablityId");
      /*var availabilityId = deliveryId;*/
      var availabilityName =
        availabilityId === deliveryId ? "Delivery" : "Pickup";
      var isAddonProduct = "No";
      var productId = productDetail.product_id;
      var customerId =
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId");
      var proqtyQty = $("#proIndex-" + IndexFlg)
        .find(".proqty_input")
        .val();
      var productCategoryId = productDetail.product_category_id;
      var productSubcategoryId = productDetail.product_subcategory_id;

      var postObject = {};
      postObject = {
        app_id: appId,
        product_id: productId,
        product_qty: proqtyQty,
        product_type: 1,
        availability_id: availabilityId,
        availability_name: availabilityName,
        isAddonProduct: isAddonProduct,
        reference_id: customerId === "" ? getReferenceID() : "",
        customer_id: customerId,
        category_id: productCategoryId,
        subcategory_id: productSubcategoryId,
        access_token:
          cookie.load("access_token") !== "" &&
          typeof cookie.load("access_token") !== undefined &&
          typeof cookie.load("access_token") !== "undefined"
            ? cookie.load("access_token")
            : "",
      };

      axios
        .post(
          apiUrlV2 + "cart/simpleCartInsert",
          qs.stringify(postObject),
          headerconfig
        )
        .then((res) => {
          $("#proIndex-" + IndexFlg).removeClass("active");
          hideLoader("proIndex-" + IndexFlg, "Idtext");
          $("#proIndex-" + IndexFlg)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndex-" + IndexFlg)
            .find(".smiple_product_lk")
            .show();
          if (res.data.status === "ok") {
            this.sateValChange("cartflg", "yes");
            removePromoCkValue();
            this.removeCorporateWallet("action");
            showCustomAlert("success", language.itemaddedsucc);
            this.handleShowAlertFun(language.success, language.itemaddedsucc);
          } else if (res.data.status === "error") {
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : language.itemnotadded;
            showCustomAlert("error", errMsgtxt);
            this.handleShowAlertFun("Error", errMsgtxt);
          }

          return false;
        });
    }
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  viewProDetail(productDetail, event) {
    event.preventDefault();
    var productSlug = productDetail.product_slug;
    if (productSlug !== "") {
      $("#proIndex-" + productDetail.product_primary_id).addClass("active");
      this.sateValChange("view_pro_data", productSlug);
    }
    return false;
  }

  handleChangeDate(datevalue) {
    var dateval = new Date(datevalue);
    dateval = format(dateval, "dd/MM/yyyy");
    this.setState({ cust_birthdate_update: "yes", cust_birthdate: datevalue });
  }

  onGenderChange(event) {
    this.setState({ cust_gender: event.target.value });
  }

  /* Omise Payment Gateway Start */
  handleChangeTxt = (item, event) => {
    const re = /^[0-9 \b]+$/;
    if (item == "cardNumber") {
      if (event.target.value === "" || re.test(event.target.value)) {
        var cardnumber = this.space(event.target.value);
        var cardnumberLenght = cardnumber.length;
        if (cardnumberLenght <= 19) {
          this.setState({ [item]: cardnumber });
          this.cardValidation(cardnumber);
        }
      }
    } else if (
      item == "expiration_month" ||
      item == "expiration_year" ||
      item == "security_code"
    ) {
      if (event.target.value === "" || re.test(event.target.value)) {
        this.setState({ [item]: event.target.value });
      }
    } else {
      this.setState({ [item]: event.target.value });
    }

    this.setState({
      omisenameerror: "",
      omisecardrror: "",
      omisemontherror: "",
      omiseyearerror: "",
      omisecodeerror: "",
    });
  };

  space(el) {
    var numbes = el.replace(/ /g, "");
    return numbes.replace(/(\d{4})/g, "$1 ").replace(/(^\s+|\s+$)/, "");
  }

  cardValidation(carnumber) {
    var imagename = "";
    if (carnumber != "") {
      var single = carnumber.substring(0, 1);
      var double = carnumber.substring(0, 2);

      if (single == 4) {
        imagename = "visa";
      } else if (double == 34 || double == 37) {
        imagename = "american";
      } else if (double >= 51 && double <= 55) {
        imagename = "master";
      } else if (double == 60 || double == 64 || double == 65) {
        imagename = "discover";
      }
    }

    this.setState({ cardImage: imagename });
  }

  getOmiseToken() {
    var errorFlg = 0,
      omisenameerror = "",
      omisecardrror = "",
      omisemontherror = "",
      omiseyearerror = "",
      omisecodeerror = "";
    if (this.state.holdername == "") {
      errorFlg++;
      omisenameerror = "omise-form-error";
    }
    if (this.state.cardNumber == "") {
      errorFlg++;
      omisecardrror = "omise-form-error";
    }
    if (this.state.expiration_month == "") {
      errorFlg++;
      omisemontherror = "omise-form-error";
    }
    if (this.state.expiration_year == "") {
      errorFlg++;
      omiseyearerror = "omise-form-error";
    }
    if (this.state.security_code == "") {
      errorFlg++;
      omisecodeerror = "omise-form-error";
    }

    this.setState({
      omisenameerror: omisenameerror,
      omisecardrror: omisecardrror,
      omisemontherror: omisemontherror,
      omiseyearerror: omiseyearerror,
      omisecodeerror: omisecodeerror,
    });

    if (errorFlg === 0) {
      showLoader("omise-pay-btn", "class");
      Omise.setPublicKey(this.state.globalSettings.omise_public_key);
      var current = this;
      var card = {
        name: this.state.holdername,
        number: this.state.cardNumber,
        expiration_month: this.state.expiration_month,
        expiration_year: this.state.expiration_year,
        security_code: this.state.security_code,
        livemode: false,
      };
      Omise.createToken("card", card, function(statusCode, response) {
        hideLoader("omise-pay-btn", "class");
        if (statusCode === 200) {
          if (
            response.object == "error" ||
            !response.card.security_code_check
          ) {
            var msgArr = Array();
            msgArr["message"] =
              response.object == "error"
                ? response.message
                : language.invalidcard;
            current.showOmiselert(msgArr);
          } else {
            $.magnificPopup.close();
            /*current.onProcessOmiseToken(response);*/
            current.setState(
              {
                omise_tokken_response: response,
                omise_tokken_card_id: response.card.id,
                omise_tokken_id: response.id,
              },
              () => {
                current.onProcessOmiseToken(response);
              }
            );
          }
        } else {
          var msgArr = Array();
          msgArr["message"] =
            response.message !== "" ? response.message : language.omiseerror;
          current.showOmiselert(msgArr);
        }
      });
    } else {
      return false;
    }
  }

  showOmiselert(msgArr) {
    var dataTimeoutId = $(".omise-error-info-div").attr("data-timeoutid");
    clearTimeout(dataTimeoutId);
    $(".omise-error-info-div").hide();
    $(".omise-error-msg").html(msgArr["message"]);
    $("#omise-error-info-div").fadeIn();
    var timeoutid = setTimeout(function() {
      $(".omise-error-info-div").hide();
    }, 6000);
    $(".omise-error-info-div").attr("data-timeoutid", timeoutid);
  }

  /* post stripe account */
  onProcessOmiseToken = (token) => {
    $.magnificPopup.open({
      items: {
        src: ".processing",
      },
      type: "inline",
      showCloseBtn: false,
      midClick: true,
      closeOnBgClick: false,
    });

    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var randomPointArr = Array();
    randomPointArr["promotionApplied"] = this.state.randompoint_applied;
    randomPointArr["promotionAmount"] = this.state.randompoint_amount;
    var ccPromoTionArr = Array();
    ccPromoTionArr["promotionApplied"] = this.state.cpcustomer_coupon_applied;
    ccPromoTionArr["promotionAmount"] = this.state.cpcustomer_coupon_amount;
    ccPromoTionArr[
      "promoIsDelivery"
    ] = this.state.cpcustomer_promotion_delivery_charge_applied;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      this.props.cartItems,
      promoTionArr,
      randomPointArr,
      ccPromoTionArr,
      this.state.corporate_customer_wallet_pay_amount
    );

    var payAmount = parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2);

    var requestType =
      "Cococola-" + cookie.load("orderOutletId") + "-" + cookie.load("UserId");

    axios
      .get(
        apiUrl + "paymentv1/getPaymentReferenceId?app_id=" + appId,
        headerconfig
      )
      .then((topRes) => {
        if (topRes.data.status === "ok") {
          var paymentRef = topRes.data.payment_ref_id;
          this.setState({ payment_ref_id: paymentRef });

          /*  load process html */
          var postObject = {};
          postObject = {
            app_id: appId,
            token: token.id,
            customer_id: cookie.load("UserId"),
            access_token:
              cookie.load("access_token") !== "" &&
              typeof cookie.load("access_token") !== undefined &&
              typeof cookie.load("access_token") !== "undefined"
                ? cookie.load("access_token")
                : "",
            outlet_id: cookie.load("orderOutletId"),
            paid_amount: payAmount,
            request_type: requestType,
            outlet_name:
              typeof cookie.load("orderOutletName") !== "undefined" &&
              typeof cookie.load("orderOutletName") !== undefined
                ? cookie.load("orderOutletName") + "-" + paymentRef
                : "",
          };

          this.postOrder(2, "Yes"); // To validate the order

          if (this.state.orderValidFail === 0) {
            axios
              .post(
                apiUrl + "paymentv1/authOmise",
                qs.stringify(postObject),
                headerconfig
              )
              .then((res) => {
                if (res.data.status === "ok") {
                  var captureID = res.data.result_set.payment_reference_1;
                  var omiseLogId = res.data.result_set.log_id;
                  this.setState(
                    { validateimage: tickImage, omise_log_id: omiseLogId },
                    function() {
                      this.postOrder(3, "", captureID, "Omise");
                    }.bind(this)
                  );
                } else if (res.data.status === "error") {
                  if (res.data.message == "token was already used") {
                    this.omise_search_history();
                  } else {
                    $.magnificPopup.close();
                    var errerTxt =
                      res.data.message !== ""
                        ? res.data.message
                        : language.omiseerror1001;
                    this.paymentFail(language.error, errerTxt);
                    $.magnificPopup.open({
                      items: {
                        src: ".warining_popup",
                      },
                      type: "inline",
                    });
                    return false;
                  }
                } else {
                  var currents = this;
                  setTimeout(
                    function() {
                      currents.omise_search_history();
                    }.bind(this),
                    2000
                  );
                }
              })
              .catch((error) => {
                /*$.magnificPopup.close();
        this.paymentFail('Error', "Error code: 1002 Oops! Unable to connect to server. Please try again.");
                $.magnificPopup.open({
                    items: {
                        src: '.warining_popup'
                    },
                    type: 'inline'
                });
                return false;*/

                var currentcatch = this;
                setTimeout(
                  function() {
                    currentcatch.omise_search_history();
                  }.bind(this),
                  2000
                );
              });
          } else {
            $.magnificPopup.close();
            this.paymentFail(language.error, language.omiseerror1003);
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });

            return false;
          }
        } else {
          $.magnificPopup.close();
          this.paymentFail(language.error, language.omiseerror1003);
          $.magnificPopup.open({
            items: {
              src: ".warining_popup",
            },
            type: "inline",
          });

          return false;
        }
      })
      .catch((error) => {
        $.magnificPopup.close();
        this.paymentFail(language.error, language.omiseerror1002);
        $.magnificPopup.open({
          items: {
            src: ".warining_popup",
          },
          type: "inline",
        });
        return false;
      });
  };

  omise_search_history = () => {
    var orderOutlet_Id = cookie.load("orderOutletId");
    var postObject = {};
    postObject = {
      app_id: appId,
      card_id: this.state.omise_tokken_card_id,
      token_id: this.state.omise_tokken_id,
      outlet_id: orderOutlet_Id,
    };

    axios
      .post(
        apiUrl + "paymentv1/omise_search_details",
        qs.stringify(postObject),
        headerconfig
      )
      .then((res) => {
        if (res.data.status === "ok") {
          var captureID = res.data.captureID;
          var omiseLogId = res.data.log_id;
          this.setState(
            { validateimage: tickImage, omise_log_id: omiseLogId },
            function() {
              this.postOrder(3, "", captureID, "Omise");
            }.bind(this)
          );
        } else if (res.data.status === "error") {
          /* Reset poup message -  start */
          this.onProcessOmiseToken(this.state.omise_tokken_response);
        } else {
          this.onProcessOmiseToken(this.state.omise_tokken_response);
        }
      });
  };
  handleChangeSelect(field, event) {
    if (
      event !== "" &&
      typeof event !== undefined &&
      typeof event !== "undefined"
    ) {
      var splitValue = event.value.split("~");
    } else {
      var splitValue = field.split("~");
    }
    showLoader("delivery_submit_cls", "class");
    var availability = deliveryId;
    axios
      .all([
        axios.get(
          apiUrlV2 +
            "outlets/findOutletZone?app_id=" +
            appId +
            "&skip_timing=Yes&availability_id=" +
            availability +
            "&postalcode_basedoutlet=yes&address_based=yes&latitude=" +
            splitValue[0] +
            "&longitude=" +
            splitValue[1],
          headerconfig
        ),
      ])
      .then(
        axios.spread((res, timeslt) => {
          var deliveryInfo = [];
          /* Success response */
          if (res.data.status === "ok") {
            cookie.save("outletchosen", availability, cookieConfig);

            var additionalTatTime =
              res.data.result_set.zone_additional_tat_time !== ""
                ? res.data.result_set.zone_additional_tat_time
                : 0;
            var outletDeliveryTiming =
              res.data.result_set.outlet_delivery_timing !== ""
                ? res.data.result_set.outlet_delivery_timing
                : 0;
            var outletDeliveryTaT =
              parseInt(outletDeliveryTiming) + parseInt(additionalTatTime);

            var orderDeliveryAddress = splitValue[2];
            var orderHandled =
              stripslashes(res.data.result_set.outlet_name) +
              ", Crew will be seeing you in " +
              outletDeliveryTaT +
              " Minutes";
            deliveryInfo["orderZoneId"] = res.data.result_set.zone_id;
            deliveryInfo["orderOutletId"] = res.data.result_set.outlet_id;
            deliveryInfo["orderOutletName"] = stripslashes(
              res.data.result_set.outlet_name
            );
            deliveryInfo["orderPostalCode"] = "";
            deliveryInfo["orderTAT"] = outletDeliveryTaT;
            deliveryInfo["orderDeliveryAddress"] = orderDeliveryAddress;
            deliveryInfo["orderHandled"] = orderHandled;
            deliveryInfo["defaultAvilablityId"] = availability;
            deliveryInfo["address_latitude"] = splitValue[0];
            deliveryInfo["address_longitude"] = splitValue[1];

            var unitNum = this.showUnitNum(
              res.data.result_set.outlet_unit_number1,
              res.data.result_set.outlet_unit_number2
            );
            var outlet_address_line2 =
              res.data.result_set.outlet_address_line2 !== ""
                ? " " + res.data.result_set.outlet_address_line2
                : "";
            var orderHandledText =
              res.data.result_set.outlet_address_line1 +
              outlet_address_line2 +
              ", " +
              unitNum;

            deliveryInfo["orderHandledByText"] = orderHandledText;

            this.setState(
              {
                deliveryInfo: deliveryInfo,
                seletedAvilablityId: availability,
                seletedOutletId: res.data.result_set.outlet_id,
                outlet_platformfee:
                  res.data.result_set.stripe_express_platform_fee,
                order_tat_time: outletDeliveryTaT,
                orderHandled: orderHandled,
                orderDeliveryAddress: orderDeliveryAddress,
              },
              function() {
                axios
                  .get(
                    apiUrlV2 +
                      "settings/chkTimeslotIsAvaiable?app_id=" +
                      appId +
                      "&availability_id=" +
                      availability +
                      "&outletId=" +
                      res.data.result_set.outlet_id,
                    headerconfig
                  )
                  .then((timeslt) => {
                    hideLoader("delivery_submit_cls", "class");
                    $.magnificPopup.close();

                    if (timeslt.data.status === "success") {
                      ///For Advanced Slot
                      var isAdvanced = timeslt.data.isAdvanced,
                        slotType = "0";
                      if (isAdvanced === "yes") {
                        slotType = timeslt.data.slotType;
                      }
                      this.setState({
                        getDateTimeFlg: "yes",
                        isAdvanced: isAdvanced,
                        slotType: slotType,
                      });

                      removePromoCkValue();
                      this.removeCorporateWallet("action");
                      this.setOrderOutletDateTimeData();
                    } else {
                      /* $.magnificPopup.open({
                        items: {
                          src: "#outlet-error-popup",
                        },
                        type: "inline",
                      }); */
                    }
                  });
              }
            );
          }
          /* Error response */
          if (res.data.status === "error") {
            this.setState({ deliveryInfo: deliveryInfo });
            hideLoader("delivery_submit_cls", "class");
            $.magnificPopup.close();
            $.magnificPopup.open({
              items: {
                src: "#error-checkout-postal-popup",
              },
              type: "inline",
            });
            var first = "";
            if (first === 0) {
              var mgsTxt =
                res.data.message !== ""
                  ? res.data.message
                  : language.enteryourpostal1;
              $(".postal_error").html(
                '<span class="error">' + mgsTxt + "</span>"
              );
            }
          }
        })
      );
  }

  showUnitNum(unit1, unit2) {
    unit1 = typeof unit1 !== "undefined" ? unit1 : "";
    unit2 = typeof unit2 !== "undefined" ? unit2 : "";

    if (unit1 !== "") {
      var unitNo = unit2 !== "" ? unit1 + " - " + unit2 : unit1;
    } else {
      var unitNo = unit2;
    }

    return unitNo !== "" ? "#" + unitNo : "";
  }

  setOrderOutletDateTimeData() {
    /* var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;
    if (
      seletedOrdDate !== "" &&
      seletedOrdTime !== "" &&
      seletedOrdDate !== null &&
      seletedOrdTime !== null
    ) { */
    var orderInfoData = this.state.deliveryInfo;
    if (Object.keys(orderInfoData).length > 0) {
      /* var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
       
        var OrderHours = seletedOrdTime.getHours();
        var OrderMunts = seletedOrdTime.getMinutes();
        var OrderSecnd = seletedOrdTime.getSeconds();
        var orderDateTime = new Date(OrderDate);
        orderDateTime.setHours(OrderHours);
        orderDateTime.setMinutes(OrderMunts);
        orderDateTime.setSeconds(OrderSecnd);

        var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
        var deliveryTime =
          this.convPad(OrderHours) +
          ":" +
          this.convPad(OrderMunts) +
          ":" +
          this.convPad(OrderSecnd);
        cookie.save("orderDateTime", orderDateTime, cookieConfig);
        cookie.save("deliveryDate", deliveryDate, cookieConfig);
        cookie.save("deliveryTime", deliveryTime, cookieConfig); */

      /* For Advanced Slot */
      var isAdvanced = this.state.isAdvanced;
      var slotType = this.state.slotType;
      var orderSlotVal = "",
        orderSlotTxt = "",
        orderSlotStrTime = "",
        orderSlotEndTime = "";
      if (slotType === "2") {
        orderSlotVal = this.state.seleted_ord_slot;
        orderSlotTxt = this.state.seleted_ord_slotTxt;
        orderSlotStrTime = this.state.seleted_ord_slot_str;
        orderSlotEndTime = this.state.seleted_ord_slot_end;
      }
      cookie.save("isAdvanced", isAdvanced, cookieConfig);
      cookie.save("slotType", slotType, cookieConfig);
      cookie.save("orderSlotVal", orderSlotVal, cookieConfig);
      cookie.save("orderSlotTxt", orderSlotTxt, cookieConfig);
      /* cookie.save("orderSlotStrTime", orderSlotStrTime, cookieConfig);
        cookie.save("orderSlotEndTime", orderSlotEndTime, cookieConfig); */
      /* For Advanced Slot End */

      if (this.state.seletedAvilablityId === deliveryId) {
        cookie.save("orderZoneId", orderInfoData["orderZoneId"], cookieConfig);
        cookie.save(
          "orderDeliveryAddress",
          orderInfoData["orderDeliveryAddress"],
          cookieConfig
        );
        cookie.save(
          "latitude",
          orderInfoData["address_latitude"],
          cookieConfig
        );
        cookie.save(
          "longitude",
          orderInfoData["address_longitude"],
          cookieConfig
        );
      }

      cookie.save(
        "orderOutletId",
        orderInfoData["orderOutletId"],
        cookieConfig
      );
      cookie.save(
        "orderOutletName",
        orderInfoData["orderOutletName"],
        cookieConfig
      );
      cookie.save(
        "orderPostalCode",
        orderInfoData["orderPostalCode"],
        cookieConfig
      );
      cookie.save("orderTAT", orderInfoData["orderTAT"], cookieConfig);
      cookie.save("orderHandled", orderInfoData["orderHandled"], cookieConfig);
      cookie.save(
        "defaultAvilablityId",
        orderInfoData["defaultAvilablityId"],
        cookieConfig
      );
      cookie.save(
        "orderHandledByText",
        orderInfoData["orderHandledByText"],
        cookieConfig
      );
      cookie.save(
        "outletchosen",
        orderInfoData["defaultAvilablityId"],
        cookieConfig
      );

      this.props.history.push("/refpage/gocheckout");
    } else {
      $.magnificPopup.open({
        items: {
          src: "#outlet-error-popup",
        },
        type: "inline",
      });
    }
    /* } else {
      $(".ordrdatetime_error").html(
        '<span class="error"> Please select order date and time.</span>'
      );
    } */
  }

  render() {
    if (Object.keys(this.state.globalSettings).length > 0) {
      stripePromise = loadStripe(
        atob(this.state.globalSettings.stripe_public_key)
      );
      if (
        this.state.globalSettings.stripe_user_id &&
        this.state.globalSettings.stripe_public_key
      ) {
        stripePromise = loadStripe(
          atob(this.state.globalSettings.stripe_public_key)
        );
      }
    }
    var clientSecret = this.state.clientSecret;
    const options = {
      clientSecret,
    };

    var showRdmPoints =
      parseFloat(this.state.reward_point_count) -
      parseFloat(this.state.used_reward_point);
    showRdmPoints = showRdmPoints.toFixed(2);
    var InptRdmPoints =
      parseFloat(this.state.reward_point_count) > 0
        ? this.state.reward_point_count
        : 0;
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var randomPointArr = Array();
    randomPointArr["promotionApplied"] = this.state.randompoint_applied;
    randomPointArr["promotionAmount"] = this.state.randompoint_amount;
    var ccPromoTionArr = Array();
    ccPromoTionArr["promotionApplied"] = this.state.cpcustomer_coupon_applied;
    ccPromoTionArr["promotionAmount"] = this.state.cpcustomer_coupon_amount;
    ccPromoTionArr[
      "promoIsDelivery"
    ] = this.state.cpcustomer_promotion_delivery_charge_applied;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      this.props.cartItems,
      promoTionArr,
      randomPointArr,
      ccPromoTionArr,
      this.state.corporate_customer_wallet_pay_amount
    );

    var subTotalAmount = parseFloat(calculatedAmount["cartSubTotalAmount"]);
    var payAmount = parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2);
    /* For Advanced Slot */
    var advancedTimeslotEnable = "0";
    if (Object.keys(this.state.globalSettings).length > 0) {
      advancedTimeslotEnable = this.state.globalSettings
        .client_advanced_timeslot_enable;
    }

    var settingsAddons = {
      infinite: false,
      dots: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
          },
        },
        {
          breakpoint: 850,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    var birthdate = this.state.cust_birthdate;
    var gender = this.state.cust_gender;
    var genderHtml =
      "<option value=''> Your Gender </option><option value='M'> Male </option><option value='F'> Female </option><option value='O'> Unspecified </option>";
    var genderDropDown = Parser(genderHtml);

    var userBirthdate =
      cookie.load("UserBirthdate") != "" &&
      cookie.load("UserBirthdate") != undefined
        ? cookie.load("UserBirthdate")
        : "";
    if (userBirthdate === "") {
      userBirthdate =
        cookie.load("birthdate") != "" && cookie.load("birthdate") != undefined
          ? cookie.load("birthdate")
          : "";
    }
    var rewardpointval = 0;
    if (parseInt(this.state.reward_point_val) > 0) {
      rewardpointval = parseInt(this.state.reward_point_val);
    }

    var totalEarnPoints = this.state.cartDetails.total_earn_points;
    if (this.state.randompoint_applied === "Yes") {
      totalEarnPoints = showRewardPoints(
        this.props.cartItems,
        rewardpointval,
        parseInt(calculatedAmount["cartSubTotalAmount"])
      );
    }
    return (
      <div className="checkout-main-div">
        {/* Header start */}
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
        />
        {/* Header End */}

        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <div className="container">
              <h4>{language.finalconfirmorder}</h4>
              <p>{language.recivedyourorder}</p>
            </div>
          </div>
        </div>

        {/* container - start */}
        <div className="bo-container">
          <div className="container">
            {/* addon products main div - start */}
            {Object.keys(this.props.addonproductlist).length > 0 && (
              <div className="addonpro-slider-top">
                <div className="chk-addonpro-title">
                  <h3>{language.chooseaddon}</h3>
                </div>
                <div className="detail-pg-slider">
                  <Slider {...settingsAddons}>
                    {this.addonProductListing()}
                  </Slider>
                </div>
              </div>
            )}
            {/* addon products main div - end */}

            {/* check_out_fourcommon - start */}
            <div className="check_out_fourcommon">
              <div className="container-one">
                {/* checkoutpage_form_outer - start */}
                <div className="checkoutpage_form_outer">
                  <form
                    id="checkoutpage_form"
                    className=" form_sec checkout-total"
                    method="post"
                    acceptCharset="utf-8"
                  >
                    <div className="cdd-details">
                      {/* cdd-details-lhs - start */}
                      <div className="cdd-details-lhs fl">
                        <div className="checkout-body-section">
                          <div className="checkout-control-group-middle">
                            <label className="chk_hea pickup-datetime">
                              <b className="pdate">{language.checkoutdate}</b>
                              <b className="ptime">{language.time}</b>
                            </label>
                            <div className="form-group1234">
                              <OrderAdvancedDatetimeSlot
                                {...this.props}
                                hdrState={this.state}
                                setdateTimeFlg={this.setdateTimeFlg}
                              />
                              <div className="ordrdatetime_error"></div>
                            </div>
                          </div>
                          {cookie.load("defaultAvilablityId") ===
                            deliveryId && (
                            <div className="checkout-control-group-top">
                              <label className="chk_hea">
                                {language.deliverylocation}
                              </label>
                              <div className="form-group">
                                <div
                                  className={
                                    this.state.order_delivery_address != ""
                                      ? "focus-out controls-single checkout-location focused"
                                      : "focus-out controls-single checkout-location"
                                  }
                                >
                                  <img src={locationpin} alt="" />
                                  <Select
                                    defaultValue={this.state.setDeliveryAddress}
                                    onChange={this.handleChangeSelect.bind(
                                      this,
                                      "address"
                                    )}
                                    options={this.state.addressList}
                                    placeholder={"選択"}
                                  />
                                  <input
                                    type="hidden"
                                    readOnly
                                    className="form-control input-focus"
                                    value={this.state.order_delivery_address}
                                  />
                                </div>
                              </div>
                              <div className="form-group controls-two">
                                <div
                                  className={
                                    this.state.unitnumber1 != ""
                                      ? "focus-out focused"
                                      : "focus-out"
                                  }
                                >
                                  <label className="unit-num">
                                    {language.unitno}
                                  </label>
                                  <input
                                    type="text"
                                    id="unit_no1_id"
                                    name="unit_no1"
                                    readOnly={true}
                                    value={this.state.unitnumber1}
                                    className="form-control input-focus"
                                    onChange={this.handleAddrChange.bind(this)}
                                  />
                                </div>

                                <div
                                  className={
                                    this.state.unitnumber2 != ""
                                      ? "focus-out focused"
                                      : "focus-out"
                                  }
                                >
                                  <label className="unit-num">
                                    {language.buildingname}
                                  </label>
                                  <input
                                    type="text"
                                    name="unit_no2"
                                    readOnly={true}
                                    value={this.state.unitnumber2}
                                    className="form-control input-focus"
                                    onChange={this.handleAddrChange.bind(this)}
                                  />
                                </div>
                              </div>
                              {this.state.addressList.length === 0 && (
                                <div className="link-center">
                                  <a
                                    href="/"
                                    onClick={this.changeAddrrFun.bind(this)}
                                    className=""
                                  >
                                    + {language.addnewaddress}
                                  </a>
                                </div>
                              )}
                            </div>
                          )}

                          {cookie.load("defaultAvilablityId") === pickupId && (
                            <div className="checkout-control-group-top">
                              <label className="chk_hea">
                                {language.pickuplocation}
                              </label>
                              <div className="col-xs-cls">
                                <p>
                                  <b>{cookie.load("orderOutletName")}</b>
                                </p>
                                <p>{cookie.load("orderHandledByText")}</p>
                              </div>
                              {/*<a
                              href="/"
                               onClick={this.changeAddrrFun.bind(this)}
                               className="change-pickup-location"
                             >
                               Change Pickup Location
                             </a> */}
                            </div>
                          )}

                          <div
                            className="checkout-control-group-bottom"
                            style={{ display: "none" }}
                          >
                            <label className="chk_hea">
                              {language.specialmessage}
                            </label>
                            <textarea
                              placeholder={language.enterspecialmessage}
                              name="specil_note"
                              value={this.state.order_specil_note}
                              onChange={this.handleAddrChange.bind(this)}
                              id="orderRemarks"
                            ></textarea>

                            {this.state.termsAndConditions !== "" && (
                              <div>
                                <div className="checkout-terms-and-condition">
                                  {this.state.termsAndConditions}
                                </div>
                                <div className="custom_checkbox tarms_chkbox_div">
                                  <input
                                    type="checkbox"
                                    name="tarms_chk"
                                    onClick={this.changeTarmsContnChk.bind(
                                      this
                                    )}
                                    defaultChecked={this.CheckTarmsContnChk()}
                                  />
                                  <span>{language.checkoutrearterms}</span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* cdd-details-lhs - end */}

                      {/* cdd-details-rhs - start */}
                      <div className="cdd-details-rhs fl">
                        <div className="chekout_cart_bar">
                          {this.loadCartOverallData()}
                        </div>
                      </div>
                      {/* cdd-details-rhs - end */}
                    </div>
                  </form>
                </div>
                {/* checkoutpage_form_outer - end */}

                {/* redeem div - start */}
                <div className="redeem">
                  <div className="chk-payment-title">
                    <h3>{language.benifitpromo}</h3>
                    <p>{language.checkoutavailpromo}</p>
                  </div>
                  <div className="redeem-row">
                    <div className="redeem-col">
                      <div className="redeem-item">
                        <div className="redeem-item-hea">
                          <div className="redeem-item-hea-inner">
                            <img src={royality} />
                            <h4>{language.use}</h4>
                            <span>{language.usepointhear}</span>
                          </div>
                          {parseFloat(this.state.reward_point_count) > 0 ? (
                            <Link to={"/rewards"} className="points">
                              {showPoint(showRdmPoints)}
                              {language.thereispoint}
                            </Link>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="redeem_apply focus-out applypoints_cls">
                          <div
                            className={
                              this.state.used_reward_point != ""
                                ? "focus-out focused"
                                : "focus-out"
                            }
                          >
                            <input
                              placeholder={
                                showPoint(InptRdmPoints) + language.usepoint
                              }
                              type="text"
                              name="reward_point"
                              value={this.state.reward_point_val}
                              onKeyPress={(e) => this.validateFloatval(e)}
                              onChange={this.handleAddrChange.bind(this)}
                              id="pointValue"
                              className="form-control input-focus"
                            />
                          </div>
                          {this.state.randompoint_applied === "Yes" ? (
                            <button
                              className="btn btn_minwid applyPoints"
                              onClick={this.removePointsAndPromo.bind(
                                this,
                                "points",
                                "fromclk"
                              )}
                            >
                              {language.remove}
                            </button>
                          ) : (
                            <button
                              className="btn btn_minwid applyPoints"
                              onClick={this.applyPointsAndPromo.bind(
                                this,
                                "points"
                              )}
                            >
                              {language.use}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="redeem-col redeem-col-right">
                      <div className="redeem-item">
                        <div className="redeem-item-hea">
                          <div className="redeem-item-hea-inner">
                            <img src={speaker} />
                            <h4>{language.promocode}</h4>
                            <span>{language.applypromohere}</span>
                          </div>
                          {parseFloat(this.state.promotion_count) > 0 ? (
                            <Link to={"/mypromotions"} className="points">
                              {this.state.promotion_count}
                              {language.twopromo}
                              <br />
                              {language.youcanuse}
                            </Link>
                          ) : (
                            <Link to={"/mypromotions"} className="points">
                              0{language.twopromo}
                              <br />
                              {language.youcanuse}
                            </Link>
                          )}
                        </div>
                        <div className="redeem_apply focus-out applypromo_cls">
                          <div
                            className={
                              this.state.promo_code_val != ""
                                ? "focus-out focused"
                                : "focus-out"
                            }
                          >
                            {/* <label>
                              ここにプロモーションコードを追加します
                            </label> */}
                            <input
                              placeholder={language.enterpromo}
                              name="promo_code"
                              value={this.state.promo_code_val}
                              id="promocodeValue"
                              type="text"
                              className="form-control input-focus"
                              onChange={this.handleAddrChange.bind(this)}
                            />
                          </div>
                          {this.state.promotion_applied === "Yes" &&
                          this.state.promotion_type === "promoCode" ? (
                            <button
                              className="btn btn_minwid promobtn applyPromo"
                              onClick={this.removePointsAndPromo.bind(
                                this,
                                "promoCode",
                                "fromclk"
                              )}
                            >
                              {language.remove}
                            </button>
                          ) : (
                            <button
                              className="btn btn_minwid promobtn applyPromo"
                              onClick={this.applyPointsAndPromo.bind(
                                this,
                                "promoCode"
                              )}
                            >
                              {language.use}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* redeem div - end */}

                {/* userBirthdate === "" && (
                 
                    <div className="birthday-info-middle">
                      <p>
                        私たちがあなたに連絡するために以下を共有してください
                        プロモーションとオファー！
                      </p>
                    </div>
                    <div className="birthday-info-bottom">
                      <div className="birthday-inpt-act">
                        <div className="left-input-div">
                          <div className="react_datepicker">
                            <DatePicker
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control"
                              selected={birthdate}
                              maxDate={this.state.Maxdate}
                              placeholderText="Your Birthday"
                              dateFormat="dd/MM/yyyy"
                              onChange={this.handleChangeDate}
                            />
                          </div>
                        </div>
                        <div className="right-input-div">
                          <div className="narml_select">
                            <select
                              value={this.state.cust_gender}
                              name="cust_gender"
                              className="form-control"
                              onChange={this.onGenderChange.bind(this)}
                            >
                              {genderDropDown}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
               
                ) */}

                {/* chk-payment div - start */}
                <div className="chk-payment chk-payment-main">
                  <div className="chk-payment-row">
                    <div className="chk-payment-title">
                      <h3>{language.selectpaymentmethod}</h3>
                      <p>{language.cantchangeorderafterpay}</p>
                    </div>

                    {/* <Stripe></Stripe> */}

                    <div className="chk-payment-col">
                      {this.state.paymentmodevalue !== "promotion" &&
                        (parseFloat(payAmount) > 0 ||
                          this.props.availabileSubscription === "Yes") && (
                          <ul className="chk-payment-col-radio">
                            {this.state.cod_payment_enable === 1 && (
                              <li
                                className={
                                  this.state.paymentmodevalue == "Cash"
                                    ? "active "
                                    : " "
                                }
                              >
                                <input
                                  type="radio"
                                  defaultChecked={
                                    this.state.paymentmodevalue == "Cash"
                                      ? "checked"
                                      : ""
                                  }
                                  id="cash"
                                  onClick={this.choosePayment.bind(
                                    this,
                                    "Cash"
                                  )}
                                  name="radio-group"
                                />

                                <label className="radio_con" htmlFor="cash">
                                  <img src={cashImage} />
                                </label>
                              </li>
                            )}

                            {this.state.globalSettings.stripe_public_key &&
                              !this.state.globalSettings.stripe_user_id && (
                                <li
                                  className={
                                    this.state.paymentmodevalue == "Stripe"
                                      ? "active "
                                      : " "
                                  }
                                  id="stripe_payment_frm"
                                >
                                  {this.state.globalSettings
                                    .stripe_public_key &&
                                    !this.state.globalSettings
                                      .stripe_user_id && (
                                      <>
                                        <div className="checkout-payment-lhs">
                                          {/* <p>{language.productdetail}</p> */}
                                          <div className="cart_row grant-total-cls">
                                            <div className="row-replace">
                                              <div className="col-sm-cls text-left">
                                                <p className="text-uppercase">
                                                  {language.total}
                                                </p>
                                              </div>
                                              <div className="col-sm-cls text-right">
                                                <span>
                                                  {" "}
                                                  {showPrices(
                                                    calculatedAmount[
                                                      "grandTotalAmount"
                                                    ],
                                                    "Y"
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          {/* <div className="send-quote">
                                              <span>{language.quote}</span>
                                              <p>{language.emailquote}</p>
                                            </div> */}
                                          <Link to={"/products"}>
                                            {language.continueshopping}
                                          </Link>
                                        </div>
                                        <div className="checkout-payment-rhs">
                                          <div className="omise_pay_body">
                                            {this.state
                                              .corporate_customer_order ===
                                              "Yes" &&
                                              parseFloat(
                                                this.state
                                                  .corporate_company_balance
                                              ) > 0 && (
                                                <>
                                                  <h4>
                                                    コーポレートウォレットバランス
                                                    (
                                                    {showPoint(
                                                      this.state
                                                        .corporate_company_balance
                                                    )}
                                                    )
                                                  </h4>
                                                  <div className="redeem_apply focus-out applypoints_cls123">
                                                    <div
                                                      className={
                                                        this.state
                                                          .corporate_customer_wallet_input !=
                                                        ""
                                                          ? "focus-out focused"
                                                          : "focus-out"
                                                      }
                                                    >
                                                      <input
                                                        placeholder={
                                                          showPoint(
                                                            this.state
                                                              .corporate_company_balance
                                                          ) +
                                                          " " +
                                                          language.usewallet
                                                        }
                                                        type="text"
                                                        name="wallet_amount"
                                                        value={
                                                          this.state
                                                            .corporate_customer_wallet_input
                                                        }
                                                        onKeyPress={(e) =>
                                                          this.validateFloatval(
                                                            e
                                                          )
                                                        }
                                                        onChange={this.handleAddrChange.bind(
                                                          this
                                                        )}
                                                        id="walletValue"
                                                        className="form-control input-focus"
                                                      />
                                                    </div>
                                                    {this.state
                                                      .corporate_wallet_pay ===
                                                    "Yes" ? (
                                                      <button
                                                        className="btn btn_minwid applyPoints"
                                                        onClick={this.removeCorporateWallet.bind(
                                                          this,
                                                          "fromclk"
                                                        )}
                                                      >
                                                        {language.remove}
                                                      </button>
                                                    ) : (
                                                      <button
                                                        className="btn btn_minwid applyPoints"
                                                        onClick={this.applyCorporateWallet.bind(
                                                          this,
                                                          payAmount
                                                        )}
                                                      >
                                                        {language.use}
                                                      </button>
                                                    )}
                                                  </div>
                                                </>
                                              )}
                                            <div className="omisepop_in">
                                              <div className="form_sec">
                                                <div
                                                  className={
                                                    "uname-box " +
                                                    this.state.omisenameerror
                                                  }
                                                >
                                                  <input
                                                    type="text"
                                                    id="stripe_holdername"
                                                    className="form-control"
                                                    placeholder={
                                                      language.cardholdername
                                                    }
                                                    value={
                                                      this.state.holdername
                                                    }
                                                    onChange={this.handleChangeTxt.bind(
                                                      this,
                                                      "holdername"
                                                    )}
                                                  />
                                                </div>

                                                <div
                                                  className={
                                                    "card-numbr-div " +
                                                    this.state.omisecardrror
                                                  }
                                                >
                                                  <input
                                                    type="text"
                                                    className={
                                                      "form-control " +
                                                      this.state.cardImage
                                                    }
                                                    placeholder={
                                                      language.cardnumber
                                                    }
                                                    value={
                                                      this.state.cardNumber
                                                    }
                                                    id="stripe_card_number"
                                                    onChange={this.handleChangeTxt.bind(
                                                      this,
                                                      "cardNumber"
                                                    )}
                                                  />
                                                  <span className="card"></span>
                                                </div>

                                                <div className="form_expire_row">
                                                  <div className="expire_row_inner">
                                                    <div
                                                      className={
                                                        "expire_left " +
                                                        this.state
                                                          .omisemontherror
                                                      }
                                                    >
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        maxLength="2"
                                                        id="stripe_expiration_month"
                                                        placeholder={
                                                          language.year
                                                        }
                                                        value={
                                                          this.state
                                                            .expiration_month
                                                        }
                                                        onChange={this.handleChangeTxt.bind(
                                                          this,
                                                          "expiration_month"
                                                        )}
                                                      />
                                                    </div>

                                                    <div
                                                      className={
                                                        "expire_mdl " +
                                                        this.state
                                                          .omiseyearerror
                                                      }
                                                    >
                                                      <input
                                                        type="text"
                                                        placeholder={
                                                          language.year
                                                        }
                                                        className="form-control"
                                                        maxLength="4"
                                                        id="stripe_expiration_year"
                                                        value={
                                                          this.state
                                                            .expiration_year
                                                        }
                                                        onChange={this.handleChangeTxt.bind(
                                                          this,
                                                          "expiration_year"
                                                        )}
                                                      />
                                                    </div>

                                                    <div
                                                      className={
                                                        "expire_right " +
                                                        this.state
                                                          .omisecodeerror
                                                      }
                                                    >
                                                      <input
                                                        type="password"
                                                        maxLength="3"
                                                        className="form-control"
                                                        id="stripe_security_code"
                                                        placeholder={
                                                          language.cvv
                                                        }
                                                        value={
                                                          this.state
                                                            .security_code
                                                        }
                                                        onChange={this.handleChangeTxt.bind(
                                                          this,
                                                          "security_code"
                                                        )}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="alt_btns">
                                                  {this.state.globalSettings
                                                    .stripe_public_key &&
                                                    cookie.load("UserMobile") !=
                                                      "" &&
                                                    this.state
                                                      .paymentmodevalue ==
                                                      "Stripe" &&
                                                    this.state
                                                      .stripe_payment_enable ===
                                                      1 && (
                                                      <button
                                                        onClick={this.validateBeforeSumbit.bind(
                                                          this
                                                        )}
                                                        className="button omise-pay-btn"
                                                      >
                                                        {language.payment}{" "}
                                                        {showPrices(
                                                          parseFloat(payAmount)
                                                        )}
                                                      </button>
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}

                                  {/*<input
                                    type="radio"
                                    defaultChecked={
                                      this.state.paymentmodevalue == "StripeConnect"
                                        ? "checked"
                                        : ""
                                    }
                                    id="card"
                                    onClick={this.choosePayment.bind(
                                      this,
                                      "StripeConnect"
                                    )}
                                    name="radio-group"
                                  />
                                  <label className="radio_con" htmlFor="card">
                                    <img src={paybystripe} />
                                  </label> */}
                                </li>
                              )}
                            {this.state.globalSettings.stripe_public_key &&
                              this.state.globalSettings.stripe_user_id && (
                                <li
                                  className={
                                    this.state.paymentmodevalue ==
                                    "StripeConnect"
                                      ? "active "
                                      : " "
                                  }
                                  id="stripe_payment_frm"
                                >
                                  {this.state.globalSettings
                                    .stripe_public_key &&
                                    this.state.globalSettings
                                      .stripe_user_id && (
                                      <>
                                        <div className="checkout-payment-lhs">
                                          {/*  <p>{language.productdetail}</p> */}
                                          <div className="cart_row grant-total-cls">
                                            <div className="row-replace">
                                              <div className="col-sm-cls text-left">
                                                <p className="text-uppercase">
                                                  {language.total}
                                                </p>
                                              </div>
                                              <div className="col-sm-cls text-right">
                                                <span>
                                                  {" "}
                                                  {showPrices(
                                                    calculatedAmount[
                                                      "grandTotalAmount"
                                                    ],
                                                    "Y"
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                            {totalEarnPoints > 0 && (
                                              <div className="cart_row checkout-earning cart-total-earning">
                                                <div className="row-replace">
                                                  <div className="col-sm-cls text-left">
                                                    <p>
                                                      {language.pointsearning}
                                                    </p>
                                                  </div>
                                                  <div className="col-sm-cls text-right">
                                                    <span>
                                                      {this.state
                                                        .randompoint_applied ===
                                                        "Yes" &&
                                                      parseFloat(
                                                        totalEarnPoints
                                                      ) > 0
                                                        ? showPoint(
                                                            totalEarnPoints
                                                          )
                                                        : showPoint(
                                                            this.state
                                                              .cartDetails
                                                              .total_earn_points
                                                          )}{" "}
                                                      {language.points}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </div>

                                          {/* <div className="send-quote">
                                              <span>{language.quote}</span>
                                              <p>{language.emailquote}</p>
                                            </div> */}
                                          <Link to={"/products"}>
                                            {language.continueshopping}
                                          </Link>
                                        </div>
                                        <div className="checkout-payment-rhs">
                                          <div className="omise_pay_body">
                                            {this.state
                                              .corporate_customer_order ===
                                              "Yes" &&
                                              parseFloat(
                                                this.state
                                                  .corporate_company_balance
                                              ) > 0 && (
                                                <>
                                                  <h4>
                                                    {
                                                      language.corporatewalletbalance
                                                    }{" "}
                                                    (
                                                    {showPrices(
                                                      this.state
                                                        .corporate_company_balance,
                                                      "Y"
                                                    )}
                                                    )
                                                  </h4>
                                                  <div className="redeem_apply focus-out applypoints_cls123">
                                                    <div
                                                      className={
                                                        this.state
                                                          .corporate_customer_wallet_input !=
                                                        ""
                                                          ? "focus-out focused"
                                                          : "focus-out"
                                                      }
                                                    >
                                                      <input
                                                        placeholder={
                                                          currencySymbol +
                                                          showPoint(
                                                            this.state
                                                              .corporate_company_balance
                                                          ) +
                                                          " " +
                                                          language.usewallet
                                                        }
                                                        type="text"
                                                        name="wallet_amount"
                                                        value={
                                                          this.state
                                                            .corporate_customer_wallet_input
                                                        }
                                                        onKeyPress={(e) =>
                                                          this.validateFloatval(
                                                            e
                                                          )
                                                        }
                                                        onChange={this.handleAddrChange.bind(
                                                          this
                                                        )}
                                                        id="walletValue"
                                                        className="form-control input-focus"
                                                      />
                                                    </div>
                                                    {this.state
                                                      .corporate_wallet_pay ===
                                                    "Yes" ? (
                                                      <button
                                                        className="btn btn_minwid applyPoints"
                                                        onClick={this.removeCorporateWallet.bind(
                                                          this,
                                                          "fromclk"
                                                        )}
                                                      >
                                                        {language.remove}
                                                      </button>
                                                    ) : (
                                                      <button
                                                        className="btn btn_minwid applyPoints"
                                                        onClick={this.applyCorporateWallet.bind(
                                                          this,
                                                          payAmount
                                                        )}
                                                      >
                                                        {language.use}
                                                      </button>
                                                    )}
                                                  </div>
                                                  <h4 className="strip-payment-heading">
                                                    {language.paymentdetails}
                                                  </h4>
                                                  <div className="chk-card-payment">
                                                    <ul className="paymeny-listul">
                                                      {this.state.displayCard}
                                                    </ul>
                                                  </div>
                                                  {this.state.selectCard !=
                                                    "new" && (
                                                    <button
                                                      onClick={this.validateBeforeSumbitSaved.bind(
                                                        this
                                                      )}
                                                      className="button omise-pay-btn savedcardsubmit"
                                                    >
                                                      支払い{" "}
                                                      {showPrices(
                                                        parseFloat(payAmount)
                                                      )}
                                                    </button>
                                                  )}
                                                  {this.state.displayCard !=
                                                    "" && (
                                                    <h2>
                                                      {language.ornewpayment}
                                                    </h2>
                                                  )}
                                                  <div className="strip-payment strip-connect-pay">
                                                    <div className="strip-connect-inner">
                                                      <input
                                                        type="radio"
                                                        defaultChecked={
                                                          this.state
                                                            .paymentmodevalue ==
                                                          "StripeConnect"
                                                            ? "checked"
                                                            : ""
                                                        }
                                                        id="card"
                                                        onClick={this.choosePayment.bind(
                                                          this,
                                                          "StripeConnect"
                                                        )}
                                                        name="radio-group"
                                                      />
                                                      <label
                                                        className="radio_con"
                                                        htmlFor="card"
                                                      >
                                                        <img
                                                          src={paybystripe}
                                                        />
                                                      </label>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                          </div>
                                        </div>
                                      </>
                                    )}
                                </li>
                              )}
                            {this.state.omise_payment_enable === 1 && (
                              <li
                                className={
                                  this.state.paymentmodevalue == "Omise"
                                    ? "active"
                                    : ""
                                }
                              >
                                <input
                                  type="radio"
                                  defaultChecked={
                                    this.state.paymentmodevalue == "Omise"
                                      ? "checked"
                                      : ""
                                  }
                                  id="omscard"
                                  onClick={this.choosePayment.bind(
                                    this,
                                    "Omise"
                                  )}
                                  name="radio-group"
                                />
                                <label className="radio_con" htmlFor="omscard">
                                  <span>{language.criditordebitcard}</span>
                                </label>
                              </li>
                            )}
                          </ul>
                        )}

                      {this.state.paymentmodevalue === "promotion" &&
                        parseFloat(payAmount) === 0 &&
                        subTotalAmount > 0 && (
                          <div className="chk-promotion-pay">
                            {this.state.corporate_wallet_pay === "Yes" &&
                              parseFloat(
                                this.state.corporate_customer_wallet_input
                              ) > 0 && (
                                <>
                                  <h4>
                                    {language.corporatewalletbalance} (
                                    {showPoint(
                                      this.state.corporate_company_balance
                                    )}
                                    )
                                  </h4>
                                  <div className="redeem_apply focus-out applypoints_cls123">
                                    <div
                                      className={
                                        this.state
                                          .corporate_customer_wallet_input != ""
                                          ? "focus-out focused"
                                          : "focus-out"
                                      }
                                    >
                                      <input
                                        placeholder={
                                          this.state.corporate_company_balance +
                                          " " +
                                          language.usewallet
                                        }
                                        type="text"
                                        name="wallet_amount"
                                        value={
                                          this.state
                                            .corporate_customer_wallet_input
                                        }
                                        onKeyPress={(e) =>
                                          this.validateFloatval(e)
                                        }
                                        onChange={this.handleAddrChange.bind(
                                          this
                                        )}
                                        id="walletValue"
                                        className="form-control input-focus"
                                      />
                                    </div>
                                    {this.state.corporate_wallet_pay ===
                                    "Yes" ? (
                                      <button
                                        className="btn btn_minwid applyPoints"
                                        onClick={this.removeCorporateWallet.bind(
                                          this,
                                          "fromclk"
                                        )}
                                      >
                                        {language.remove}
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn_minwid applyPoints"
                                        onClick={this.applyCorporateWallet.bind(
                                          this,
                                          payAmount
                                        )}
                                      >
                                        {language.use}
                                      </button>
                                    )}
                                  </div>
                                </>
                              )}

                            <div className="chk-payment-btn-row">
                              <a
                                href="/"
                                className="gradient-btn"
                                title={language.proceedpurchase}
                                onClick={this.postPromotionOrder.bind(this)}
                              >
                                {language.proceedpurchase}{" "}
                                <img src={go} alt="" />
                              </a>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                  <div
                    className="alert alert-danger alert_default show_cart_error_msg"
                    style={{ display: "none" }}
                  ></div>
                  {/* <div className="chk-payment-text">
                    <p>
                      あなたの個人データはあなたの注文を処理し、あなたの経験をサポー
                      トするために使用されます
                      このウェブサイト全体、およびプライバシー
                      <br />
                      ポリシーに記載されているその他の目的のため。
                    </p>
                  </div> */}
                  <div className="chk-payment-btn">
                    {this.state.paymentmodevalue !== "promotion" &&
                      parseFloat(payAmount) > 0 && (
                        <div className="chk-payment-btn-row">
                          {this.state.paymentmodevalue == "Cash" &&
                            this.state.cod_payment_enable === 1 && (
                              <a
                                href="/"
                                className="gradient-btn"
                                title={language.proceedpurchase}
                                onClick={this.payCash.bind(this)}
                              >
                                {language.proceedpurchase}{" "}
                                <img src={go} alt="" />
                              </a>
                            )}
                          {this.state.globalSettings.stripe_user_id &&
                            cookie.load("UserMobile") != "" &&
                            this.state.stripe_payment_enable === 1 &&
                            this.state.selectCard == "new" && (
                              <a
                                href="/"
                                onClick={this.orderPayValidaion.bind(
                                  this,
                                  "StripeConnect"
                                )}
                                id="stripe_btn"
                                className="gradient-btn"
                                title={language.proceedpurchase}
                              >
                                {language.proceedpurchase}{" "}
                                <img src={go} alt="" />
                              </a>
                            )}
                          {this.state.globalSettings.stripe_public_key &&
                            !this.state.globalSettings.stripe_user_id &&
                            cookie.load("UserMobile") != "" &&
                            this.state.paymentmodevalue == "Stripe" &&
                            this.state.stripe_payment_enable === 1 && (
                              <a
                                href="/"
                                onClick={this.orderPayValidaion.bind(
                                  this,
                                  "Stripe"
                                )}
                                id="stripe_btn"
                                className="gradient-btn"
                                title={language.proceedpurchase}
                              >
                                {language.proceedpurchase}{" "}
                                <img src={go} alt="" />
                              </a>
                            )}

                          {this.state.globalSettings.omise_public_key &&
                            cookie.load("UserMobile") != "" &&
                            this.state.paymentmodevalue == "Omise" &&
                            this.state.omise_payment_enable === 1 && (
                              <a
                                href={void 0}
                                onClick={this.orderPayValidaion.bind(
                                  this,
                                  "Omise"
                                )}
                                className="gradient-btn"
                                title={language.proceedpurchase}
                              >
                                {language.proceedpurchase}{" "}
                                <img src={go} alt="" />
                              </a>
                            )}
                        </div>
                      )}

                    {this.state.paymentmodevalue === "promotion" &&
                      parseFloat(payAmount) === 0 &&
                      subTotalAmount > 0 && (
                        <div className="chk-payment-btn-row">
                          <a
                            href="/"
                            className="gradient-btn"
                            title={language.proceedpurchase}
                            onClick={this.postPromotionOrder.bind(this)}
                          >
                            {language.proceedpurchase} <img src={go} alt="" />
                          </a>
                        </div>
                      )}
                  </div>
                </div>
                {/* chk-payment div - end */}
              </div>
            </div>
            {/* check_out_fourcommon - end */}
          </div>
        </div>
        {/* container - end */}

        {/*Payment confirm popup Start*/}
        <div
          id="pay-conf-popup"
          className="white-popup mfp-hide popup_sec pay-conf-popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_header">{language.alert}</div>
                <div className="alert_body">
                  <p>{language.getready}</p>
                  <div className="alt_btns">
                    <div className="paymentsection cccard_paysec">
                      <div className="we-accept">
                        {/*  <span>{language.accept}</span> */}
                        <img src={payment_cards} />
                      </div>
                      <div className="stripe_from">
                        <label>{language.criditordebitcarddetails}</label>

                        {this.state.globalSettings.stripe_public_key &&
                          cookie.load("UserMobile") != "" &&
                          this.state.paymentmodevalue == "StripeConnect" &&
                          this.state.stripe_payment_enable === 1 &&
                          parseFloat(payAmount) > 0 &&
                          typeof this.state.clientSecret != "undefined" &&
                          this.state.clientSecret != "" && (
                            <>
                              <Elements
                                options={options}
                                stripe={stripePromise}
                              >
                                <CheckoutForm />
                              </Elements>
                              <div id="payment-message-save">
                                {language.savecardlimit}
                              </div>
                              <div className="custom_checkbox tarms_chkbox_div">
                                <input
                                  type="checkbox"
                                  name="tarms_chk"
                                  className="savecardcheck"
                                  onClick={this.changeSavecard.bind(this)}
                                  defaultChecked={this.CheckSaveCard()}
                                />
                                <span className="saveforfuture">
                                  {language.savecardforfuture}
                                </span>
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                    <div
                      className="paymentloading loading"
                      style={{ display: "none" }}
                    >
                      <img src={loadingImage} />{" "}
                      <span className="loading_text">
                        {language.waitcompletepayment}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*payment confirm popup - End*/}

        {/*Payment omise confirm popup Start*/}
        <div
          id="pay-omiseconf-popup"
          className="white-popup mfp-hide popup_sec pay-omiseconf-popup"
        >
          <div
            className="omise-error-info-div"
            id="omise-error-info-div"
            data-timeoutid=""
            style={{ display: "none" }}
          >
            <div className="container">
              <span className="omise-error-icon">
                <i
                  className="fa fa-exclamation-triangle"
                  aria-hidden="true"
                ></i>
              </span>
              <a
                href="#"
                className="omise_alert_close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={(evt) => {
                  evt.preventDefault();
                }}
              >
                {" "}
                <span aria-hidden="true">×</span>{" "}
              </a>
              <p className="omise-error-msg">{language.somthingwrong}</p>
            </div>
          </div>

          {this.state.globalSettings.omise_envir === "dev" && (
            <div className="omisepay-mode-option">{language.testmode}</div>
          )}

          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="omise_pay_header">
                  <span className="omise-pay-img">
                    <img className="makisan-logo-img" src={stripeImage} />
                  </span>
                  <span className="omise-pay-title">{projectTitle}</span>
                </div>
                <div className="omise_pay_body">
                  <div className="omisepop_in">
                    <div className="form_sec">
                      <div className={"uname-box " + this.state.omisenameerror}>
                        <i className="fa fa-user" data-unicode="f007"></i>
                        <input
                          type="text"
                          id="holdername"
                          className="form-control"
                          placeholder={language.cardholdername}
                          value={this.state.holdername}
                          onChange={this.handleChangeTxt.bind(
                            this,
                            "holdername"
                          )}
                        />
                      </div>

                      <div
                        className={"card-numbr-div " + this.state.omisecardrror}
                      >
                        <i
                          className="fa fa-credit-card"
                          data-unicode="f09d"
                        ></i>
                        <input
                          type="text"
                          className={"form-control " + this.state.cardImage}
                          placeholder={language.cardnumber}
                          value={this.state.cardNumber}
                          id="omise_card_number"
                          onChange={this.handleChangeTxt.bind(
                            this,
                            "cardNumber"
                          )}
                        />
                        <span className="card"></span>
                      </div>

                      <div className="form_expire_row">
                        <div className="expire_row_inner">
                          <div
                            className={
                              "expire_left " + this.state.omisemontherror
                            }
                          >
                            <i
                              className="fa fa-calendar-o"
                              data-unicode="f133"
                            ></i>
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              id="expiration_month"
                              placeholder={language.month}
                              value={this.state.expiration_month}
                              onChange={this.handleChangeTxt.bind(
                                this,
                                "expiration_month"
                              )}
                            />
                          </div>

                          <div
                            className={
                              "expire_mdl " + this.state.omiseyearerror
                            }
                          >
                            <input
                              type="text"
                              placeholder={language.year}
                              className="form-control"
                              maxLength="4"
                              id="expiration_year"
                              value={this.state.expiration_year}
                              onChange={this.handleChangeTxt.bind(
                                this,
                                "expiration_year"
                              )}
                            />
                          </div>

                          <div
                            className={
                              "expire_right " + this.state.omisecodeerror
                            }
                          >
                            <i className="fa fa-lock" data-unicode="f023"></i>
                            <input
                              type="password"
                              maxLength="3"
                              className="form-control"
                              id="security_code"
                              placeholder={language.cvv}
                              value={this.state.security_code}
                              onChange={this.handleChangeTxt.bind(
                                this,
                                "security_code"
                              )}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="alt_btns">
                        {this.state.globalSettings.omise_public_key &&
                          cookie.load("UserMobile") != "" &&
                          this.state.paymentmodevalue == "Omise" &&
                          this.state.omise_payment_enable === 1 && (
                            <button
                              onClick={this.getOmiseToken.bind(this)}
                              className="button omise-pay-btn"
                            >
                              {language.payment} {stripeCurrency}{" "}
                              {parseFloat(payAmount).toFixed(2)}
                            </button>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*payment omise confirm popup - End*/}

        <div
          id="processing-popup"
          className="white-popup mfp-hide popup_sec processing"
        >
          <div className="pouup_in">
            <h3 className="title1 text-center">{language.processingorder}</h3>
            <div className="process_inner">
              {this.onlinePaymentLoading()}
              {this.orderBCLoading()}
              {this.amountCaptureLoading()}
              {this.props.availabileSubscription === "Yes" &&
                this.subscriptionCaptureLoading()}
            </div>
          </div>
        </div>

        {/* Warning Popup - start */}
        <div
          id="warning-delete-popup"
          className="white-popup mfp-hide popup_sec warning_popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_header">{language.warning}</div>
                <div className="alert_body">
                  <img className="warning-popup-img" src={warningImg} />
                  <p>{language.wantdeleteitem}</p>
                  <div className="alt_btns">
                    <a
                      href="/"
                      className="popup-modal-dismiss button button-left disbl_href_action"
                    >
                      {language.no}
                    </a>
                    <a
                      href="/"
                      onClick={this.clearCartItm.bind(this)}
                      className="button button-right popup-modal-dismiss disbl_href_action"
                    >
                      {language.yes}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Warning Popup - end */}

        <div id="dvLoading"></div>
        <Footer />

        <div
          id="cancellsubscription-popup"
          className="white-popup mfp-hide popup_sec cancellsubscription_popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_header">{language.warning}</div>
                <div className="alert_body">
                  <img className="warning-popup-img" src={warningplus} />
                  <p>{language.deletecardconfirm}</p>
                  <div className="alt_btns">
                    <a
                      href="/"
                      className="popup-modal-dismiss button button-left disbl_href_action"
                    >
                      {language.no}
                    </a>
                    <a
                      href="/"
                      onClick={this.proceeddeleteCard.bind(this)}
                      id="cancel_proceed"
                      className="button button-right"
                    >
                      {language.yes}
                    </a>
                    <input type="hidden" id="warning_avilablity" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="error-checkout-postal-popup"
          className="white-popup mfp-hide popup_sec warning_popup outlet_error_popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_body">
                  <img className="warning-popup-img" src={warningImg} />
                  <h2 className="text-uppercase">{language.sorry}</h2>
                  <p>We Can't delivery your address</p>
                  <p>Please contact admin</p>
                  <div className="alt_btns">
                    <a
                      href={void 0}
                      className="button button-right popup-modal-dismiss disbl_href_action"
                    >
                      {language.ok}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer section */}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var overAllcart = Array();
  var cartDetails = Array();
  var cartItems = Array();
  var updateCartResponse = Array();
  var cartTotalItmCount = 0;
  var cartStatus = "";
  var stripekey = Array();
  var stripeaccountId = Array();
  var stripe_response = Array();
  var availabile_subscription = "No";
  if (Object.keys(state.cartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cartlistdetail[0])
      ? Array()
      : state.cartlistdetail[0].result_set;
    if (
      state.cartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      overAllcart = resultSetArr;
      cartDetails = resultSetArr.cart_details;
      cartItems = resultSetArr.cart_items;
      cartTotalItmCount = resultSetArr.cart_details.cart_total_items;
      availabile_subscription = resultSetArr.availabile_subscription;
      cartStatus = "success";
    } else {
      cartStatus = "failure";
    }
  }

  var globalSettings = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }

  var zonedetailArr = Array();
  if (Object.keys(state.zonedetail).length > 0) {
    if (state.zonedetail[0].status === "ok") {
      zonedetailArr = state.zonedetail[0].result_set;
    }
  }

  var activityCountArr = Array();
  if (Object.keys(state.activitycount).length > 0) {
    if (state.activitycount[0].status === "ok") {
      activityCountArr = state.activitycount[0].result_set;
    }
  }

  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  var addonProArr = Array();
  var addonCommonArr = Array();
  if (Object.keys(state.addonproduct).length > 0) {
    if (
      state.addonproduct[0].status === "ok" &&
      Object.keys(state.addonproduct[0].result_set).length > 0
    ) {
      addonProArr = state.addonproduct[0].result_set;
      addonCommonArr = state.addonproduct[0].common;
    }
  }

  if (Object.keys(state.updatecartdetail).length > 0) {
    if (state.updatecartdetail[0].status === "error") {
      updateCartResponse = state.updatecartdetail;
    }
  }
  var secondaryaddress = Array();
  if (Object.keys(state.secondaryaddress).length > 0) {
    if (state.secondaryaddress[0].status === "ok") {
      secondaryaddress = state.secondaryaddress[0].result_set;
    }
  }
  if (Object.keys(state.stripekey).length > 0) {
    if (state.stripekey[0].status === "ok") {
      stripekey = state.stripekey[0].result_set;
      stripe_response = state.stripekey[0].stripe_response;
      stripeaccountId = state.stripekey[0].payment_info;
    }
  }

  return {
    activitycountArr: activityCountArr,
    settingsArr: globalSettings,
    staticblack: blacksArr,
    zonedetails: zonedetailArr,
    overAllcart: overAllcart,
    cartDetails: cartDetails,
    cartItems: cartItems,
    cartTotalItmCount: cartTotalItmCount,
    cartStatus: cartStatus,
    availabileSubscription: availabile_subscription,
    addonproductlist: addonProArr,
    addonproductcommon: addonCommonArr,
    updateCartResponse: updateCartResponse,
    secondaryaddress: secondaryaddress,
    stripekey: stripekey,
    stripeaccountId: stripeaccountId,
    stripe_response: stripe_response,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCartDetail: () => {
      dispatch({ type: GET_CART_DETAIL });
    },
    updateCartDetail: (productId, cartItemId, cartQty, orderVoucherId) => {
      dispatch({
        type: UPDATE_CART_DETAIL,
        productId,
        cartItemId,
        cartQty,
        orderVoucherId,
      });
    },
    deleteCartDetail: (cartItemId) => {
      dispatch({ type: DELETE_CART_DETAIL, cartItemId });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
    getAddonProList: (outletId) => {
      dispatch({ type: GET_ADDONPRODUCT, outletId });
    },
    getStripeKey: (stripedata) => {
      dispatch({ type: GET_STRIPE_KEY, stripedata });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Checkout);
