/* eslint-disable */
import React, { Component } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import { connect } from "react-redux";
import { language } from "../Helpers/Language";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  headerconfig,
} from "../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showLoader,
  hideLoader,
  showCustomAlert,
  showAlert,
  removePromoCkValue,
  showPriceValueSub,
  emailValidate,
} from "../Helpers/SettingHelper";
import noimage from "../../common/images/no-img-product.png";
import smartPhone from "../../common/images/smart-phone.png";
import proVouchar from "../../common/images/pro-vouchar.png";
import cashVouchar from "../../common/images/cash-vouchar.png";
import cartLike from "../../common/images/cat-item-like-bw.svg";
import cartLikeActive from "../../common/images/cat-item-like-bw-active.svg";
import go from "../../common/images/checkout-go.png";
import { GET_FEATUREPRO } from "../../actions";
import { isNull } from "underscore";
var Parser = require("html-react-parser");
var qs = require("qs");

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedNavigation: "",
      selectedslugType: "",
      productSubcatlist: [],
      productCommon: [],
      productDetails: [],
      product_List: [],
      imagesource: "",
      viewProductSlug: "",
      OldupdateproductList: [],
      oldnavigateMenu: [],
      selProductEndSection: [],
      FinalDetailsProduct: [],
      voucherProductDetail: [],
      voucher_product_qty: "",
      voucher_name: "",
      voucher_mobile: "",
      voucher_email: "",
      voucher_message: "",
      error_voucher_name: "",
      error_voucher_email: "",
      error_voucher_message: "",
      error_voucher_mobile: "",
      voucherIndexFlag: "",
      favproductID: [],
      favdeleteproductID: [],
      loading: true,
    };
  }
  componentWillMount() {
    this.props.getFeatureProList();
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.productSubcatlist !== nextProps.productSubcatlist) {
      this.setState(
        { productSubcatlist: nextProps.productSubcatlist, loading: false },
        function() {
          $(".products-list-ulmain .addcart_row").hide();
          $(".products-list-ulmain .order_nowdiv").show();
          this.productsubcatlist();
        }
      );
    }
  }

  componentDidMount() {
    $(document).ready(function() {
      $(".tab_item").click(function() {
        $(".product_tab_item").removeClass("tab_item_active");
        $(
          ".product_tab_item[data-id='" + $(this).attr("data-id") + "']"
        ).addClass("tab_item_active");
        $(".tab_item").removeClass("active");
        $(this)
          .parent()
          .find(".tab_item")
          .addClass("active");
      });
    });
  }

  productsubcatlist() {
    var subcatlistArr = this.props.productSubcatlist;
    if (Object.keys(subcatlistArr).length > 0) {
      $("#no_product_error").hide();
      const productDetails = (
        <div className="innerproduct">
          <ul className="products-list-ulmain">
            {this.productList(subcatlistArr)}
          </ul>
        </div>
      );
      this.setState({ productDetails: productDetails }, function() {
        setTimeout(function() {
          hideLoader("productlist-main-div", "class");
        }, 300);
        var totalProducts = 0;
        if ($(".innerproduct").length > 0) {
          $(".innerproduct").map(function() {
            if ($(this).find(".products-list-ulmain li").length > 0) {
              totalProducts++;
            }
          });
          if (totalProducts > 0) {
            $("#no_product_error").hide();
            $(".innerproduct").show();
          } else {
            $("#no_product_error").show();
            $(".innerproduct").hide();
          }
        }
      });
      return productDetails;
    } else {
      //this.props.history.push("/checkout");
      /* if (this.props.setnoProduct === "Yes") {
        $("#no_product_error").show();
        hideLoader("productlist-main-div", "class");
        $(".innerproduct").hide();
      } */
    }
  }

  chkProStockCls(proSlug, Stock, checkLeadTime) {
    var searchProVal = this.props.productState.searchProVal;
    var actClstxt = searchProVal === proSlug ? " active" : "";

    if (checkLeadTime !== "") {
      actClstxt += " current-not-available";
    }

    var returnText =
      "products-single-li no-stock-product " + proSlug + actClstxt;
    if (Stock > 0) {
      returnText = "products-single-li " + proSlug + actClstxt;
    }
    return returnText;
  }

  /* Products List */
  productList(list) {
    var imageSource = this.props.productCommon.image_source;
    const listProduct = list.map((productDetail, index) => {
      var description =
        productDetail.product_short_description !== null &&
        productDetail.product_short_description !== ""
          ? Parser(stripslashes(productDetail.product_short_description))
          : "";

      var prodivId = "proIndex-" + productDetail.product_primary_id;
      var comboProId = "comboPro-" + productDetail.product_slug;
      let checkLeadTime = this.checkLeadTime(productDetail.product_lead_time);
      return (
        <li
          className={this.chkProStockCls(
            productDetail.product_slug,
            productDetail.product_stock,
            checkLeadTime
          )}
          id={prodivId}
          key={index}
        >
          {this.state.customerId !== "" && (
            <div>
              {this.state.favdeleteproductID.indexOf(
                productDetail.product_primary_id
              ) >= 0 ? (
                <a
                  href={void 0}
                  className="like"
                  onClick={this.addFavourite.bind(
                    this,
                    productDetail.product_primary_id,
                    "Yes"
                  )}
                >
                  <img src={cartLike} />
                  <img src={cartLikeActive} className="active-hart" />
                </a>
              ) : (!isNull(productDetail.fav_product_primary_id) &&
                  typeof productDetail.fav_product_primary_id !== undefined &&
                  typeof productDetail.fav_product_primary_id !==
                    "undefined") ||
                this.state.favproductID.indexOf(
                  productDetail.product_primary_id
                ) >= 0 ? (
                <a
                  href={void 0}
                  className="like active"
                  onClick={this.addFavourite.bind(
                    this,
                    productDetail.product_primary_id,
                    "No"
                  )}
                >
                  <img src={cartLike} />
                  <img src={cartLikeActive} className="active-hart" />
                </a>
              ) : (
                <a
                  href={void 0}
                  className="like"
                  onClick={this.addFavourite.bind(
                    this,
                    productDetail.product_primary_id,
                    "Yes"
                  )}
                >
                  <img src={cartLike} />
                  <img src={cartLikeActive} className="active-hart" />
                </a>
              )}
            </div>
          )}

          {productDetail.product_tag_info !== "" &&
            productDetail.product_tag_info !== null && (
              <div className="ribbon">
                <span>{productDetail.product_tag_info}</span>
              </div>
            )}
          <a
            href={"/products/" + productDetail.product_slug}
            onClick={this.viewProDetail.bind(this, productDetail)}
          >
            <div className="products-image-div">
              {productDetail.product_thumbnail !== "" ? (
                <img
                  src={imageSource + "/" + productDetail.product_thumbnail}
                />
              ) : productDetail.product_type !== "5" ? (
                <img src={noimage} />
              ) : productDetail.product_voucher === "f" ? (
                <img src={proVouchar} />
              ) : (
                <img src={cashVouchar} />
              )}
            </div>
          </a>

          <div className="product-info-div">
            <div className="product-title-maindiv">
              <div className="product-title">
                <h5>
                  {productDetail.product_alias !== ""
                    ? stripslashes(productDetail.product_alias)
                    : stripslashes(productDetail.product_name)}
                </h5>
              </div>
            </div>
            {description !== "" && description !== null && (
              <div className="product-short-description">
                <p>{description}</p>
              </div>
            )}

            <div className="products-ordernow-action">
              <div className="products-ordernow-inner">
                <div className="product-price">
                  {productDetail.product_specialprice_applicable === "yes"
                    ? parseFloat(productDetail.product_price) > 0 && (
                        <span className="price_disc">
                          {showPriceValueSub(productDetail.product_price, "Y")}
                        </span>
                      )
                    : parseFloat(productDetail.product_cost) > 0 && (
                        <span className="price_disc">
                          {showPriceValueSub(productDetail.product_cost, "Y")}
                        </span>
                      )}
                  <h3>
                    {productDetail.product_specialprice_applicable === "yes"
                      ? showPriceValueSub(
                          productDetail.product_special_price,
                          "Y"
                        )
                      : showPriceValueSub(productDetail.product_price, "Y")}
                  </h3>
                </div>

                {checkLeadTime !== "" ? (
                  <a
                    className="button order_nowdiv smiple_product_lk disbl_href_action"
                    href={void 0}
                  >
                    {language.addtocart}
                  </a>
                ) : productDetail.product_stock > 0 ||
                  productDetail.product_stock === null ? (
                  productDetail.product_type === "1" ||
                  productDetail.product_type === "5" ? (
                    <a
                      className="button order_nowdiv smiple_product_lk disbl_href_action"
                      href="/"
                      onClick={this.addToCartSimple.bind(
                        this,
                        productDetail,
                        "initial"
                      )}
                    >
                      {language.buynow}
                    </a>
                  ) : (
                    <a
                      href="/"
                      onClick={this.viewProDetail.bind(this, productDetail)}
                      title="Product Details"
                      id={comboProId}
                      className="button order_nowdiv compo_product_lk"
                    >
                      {language.buynow}
                    </a>
                  )
                ) : (
                  <a
                    className="button order_nowdiv disabled disbl_href_action"
                    href="/"
                  >
                    {language.soldout}
                  </a>
                )}

                <div className="addcart_row addcart_done_maindiv">
                  <div className="addcart-row-child">
                    <div className="qty_bx">
                      <span
                        className="qty_minus"
                        onClick={this.proQtyAction.bind(
                          this,
                          productDetail.product_primary_id,
                          "decr"
                        )}
                      >
                        -
                      </span>
                      <input
                        type="text"
                        className="proqty_input"
                        readOnly
                        value="1"
                      />
                      <span
                        className="qty_plus"
                        onClick={this.proQtyAction.bind(
                          this,
                          productDetail.product_primary_id,
                          "incr"
                        )}
                      >
                        +
                      </span>
                    </div>
                    <button
                      className="btn btn_black"
                      onClick={this.addToCartSimple.bind(
                        this,
                        productDetail,
                        "done"
                      )}
                    >
                      {language.addtocart1}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {checkLeadTime !== "" && (
            <div className="next-avail-data">{checkLeadTime}</div>
          )}
        </li>
      );
    });

    return listProduct;
  }

  checkLeadTime(product_lead_time) {
    if (
      product_lead_time !== "" &&
      parseInt(product_lead_time) > 0 &&
      cookie.load("orderDateTime") !== "" &&
      typeof cookie.load("orderDateTime") !== undefined &&
      typeof cookie.load("orderDateTime") !== "undefined"
    ) {
      var addedData = new Date(
        new Date().getTime() + product_lead_time * 24 * 60 * 60 * 1000
      );
      var orderDateTime = new Date(
        cookie
          .load("orderDateTime")
          .split('"')
          .join("")
      );
      var selectedDate =
        orderDateTime.getDate() +
        "-" +
        orderDateTime.getMonth() +
        "-" +
        orderDateTime.getFullYear();
      var leadDate =
        addedData.getDate() +
        "-" +
        addedData.getMonth() +
        "-" +
        addedData.getFullYear();
      if (selectedDate !== leadDate) {
        /*    var date1 = new Date('7/11/2010');
          var date2 = new Date('12/12/2010'); */
        var diffDays = addedData.getDate() - orderDateTime.getDate();
        var result = "";
        var avail = "";
        if (cookie.load("defaultAvilablityId") === deliveryId) {
          avail = "Delivery";
        } else {
          avail = "Pickup";
        }
        if (diffDays === 1) {
          // result = "This item is available for next day " + avail + " only";
        } else {
          /*  result =
            "This item is available for " + leadDate + " " + avail + " only"; */
        }
        return result;
      }
    } else {
      return "";
    }
  }

  addFavourite(productID, fav_flag) {
    var formData = {
      app_id: appId,
      product_id: productID,
      fav_flag: fav_flag,
      customer_id: cookie.load("UserId"),
      access_token:
        cookie.load("access_token") !== "" &&
        typeof cookie.load("access_token") !== undefined &&
        typeof cookie.load("access_token") !== "undefined"
          ? cookie.load("access_token")
          : "",
    };
    var favproductID = this.state.favproductID;
    var favdeleteproductID = this.state.favdeleteproductID;
    if (fav_flag === "Yes") {
      favproductID.push(productID);
      const index1 = favdeleteproductID.indexOf(productID);
      if (index1 > -1) {
        favdeleteproductID.splice(index1, 1);
      }
    } else {
      const index = favproductID.indexOf(productID);
      if (index > -1) {
        favproductID.splice(index, 1);
      }
      favdeleteproductID.push(productID);
    }
    this.setState(
      { favproductID: favproductID, favdeleteproductID: favdeleteproductID },
      function() {
        this.productsubcatlist(
          this.state.OldupdateproductList,
          this.state.oldnavigateMenu
        );
      }
    );

    var postObject = qs.stringify(formData);
    this.props.getFavouriteproducts(postObject);
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();

    if (productDetail.product_type === "5") {
      var IndexFlg = productDetail.product_primary_id;

      var proqtyQty = $("#proIndex-" + IndexFlg)
        .find(".proqty_input")
        .val();

      $.magnificPopup.open({
        items: {
          src: "#vouchergift-popup",
        },
        type: "inline",
      });
      this.setState({
        voucherProductDetail: productDetail,
        voucher_product_qty: proqtyQty,
        voucherIndexFlag: IndexFlg,
      });
    } else {
      var IndexFlg = productDetail.product_primary_id;

      if (actionFlg === "initial") {
        $("#proIndex-" + IndexFlg).addClass("active");
        $("#proIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .hide();
        $("#proIndex-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .show();
        return false;
      } else {
        showLoader("proIndex-" + IndexFlg, "Idtext");
        var availabilityId = cookie.load("defaultAvilablityId");
        /*var availabilityId = deliveryId;*/
        var availabilityName =
          availabilityId === deliveryId ? "Delivery" : "Pickup";
        var isAddonProduct = "No";
        var productId = productDetail.product_id;
        var customerId =
          typeof cookie.load("UserId") === "undefined"
            ? ""
            : cookie.load("UserId");
        var proqtyQty = $("#proIndex-" + IndexFlg)
          .find(".proqty_input")
          .val();
        var specialprice_applicable =
          productDetail.product_specialprice_applicable;
        var productCategoryId = productDetail.product_category_id;
        var productSubcategoryId = productDetail.product_subcategory_id;
        var postObject = {};
        postObject = {
          app_id: appId,
          product_id: productId,
          product_qty: proqtyQty,
          product_type: 1,
          availability_id: availabilityId,
          availability_name: availabilityName,
          isAddonProduct: isAddonProduct,
          specialprice_applicable: specialprice_applicable,
          reference_id: customerId === "" ? getReferenceID() : "",
          customer_id: customerId,
          category_id: productCategoryId,
          subcategory_id: productSubcategoryId,
          access_token:
            cookie.load("access_token") !== "" &&
            typeof cookie.load("access_token") !== undefined &&
            typeof cookie.load("access_token") !== "undefined"
              ? cookie.load("access_token")
              : "",
        };

        Axios.post(
          apiUrlV2 + "cart/simpleCartInsert",
          qs.stringify(postObject),
          headerconfig
        ).then((res) => {
          $("#proIndex-" + IndexFlg).removeClass("active");
          hideLoader("proIndex-" + IndexFlg, "Idtext");
          $("#proIndex-" + IndexFlg)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndex-" + IndexFlg)
            .find(".smiple_product_lk")
            .show();
          if (res.data.status === "ok") {
            this.props.sateValChange("cartflg", "yes");
            removePromoCkValue();
            $(".hcartdd_trigger").addClass("bounce");
            /* showCustomAlert(
              "success",
              "Great! Your item has been added to the cart",
              "cart-success-msg"
            ); */
            /*  this.handleShowAlertFun(
              "成功",
              "偉大な選択肢！カートにアイテムが追加されました。"
            ); */
          } else if (res.data.status === "error") {
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : language.itemnotadded;
            showCustomAlert("error", errMsgtxt);
            this.handleShowAlertFun("エラー", errMsgtxt);
          }
          return false;
        });
      }
    }
  }

  addToCartVoucher() {
    var error = 0;
    if (this.state.voucher_name === null || this.state.voucher_name === "") {
      error++;
      this.setState({
        error_voucher_name: Parser(
          '<span class="error">' + language.itemnotadded + "</span>"
        ),
      });
    } else {
      this.setState({ error_voucher_name: "" });
    }

    if (
      this.state.voucher_mobile === null ||
      this.state.voucher_mobile === ""
    ) {
      error++;
      this.setState({
        error_voucher_mobile: Parser(
          '<span class="error">' + language.itemnotadded + "</span>"
        ),
      });
    } else {
      this.setState({ error_voucher_mobile: "" });
    }

    if (this.state.voucher_email === null || this.state.voucher_email === "") {
      error++;
      this.setState({
        error_voucher_email: Parser(
          '<span class="error">' + language.itemnotadded + "</span>"
        ),
      });
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        this.state.voucher_email
      )
    ) {
      error++;
      this.setState({
        error_voucher_email: Parser(
          '<span class="error">' + language.thisnotvalidemail + "</span>"
        ),
      });
    } else {
      this.setState({ error_voucher_email: "" });
    }

    if (
      this.state.voucher_message === null ||
      this.state.voucher_message === ""
    ) {
      error++;
      this.setState({
        error_voucher_message: Parser(
          '<span class="error">' + language.thisfieldrequired + "</span>"
        ),
      });
    } else {
      this.setState({ error_voucher_message: "" });
    }

    if (error == 0) {
      var availabilityId = cookie.load("defaultAvilablityId");
      /*var availabilityId = deliveryId;*/
      var availabilityName =
        availabilityId === deliveryId ? "Delivery" : "Pickup";
      var isAddonProduct = "No";
      var productId = this.state.voucherProductDetail.product_id;
      var IndexFlg = this.state.voucherProductDetail.product_primary_id;
      var customerId =
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId");

      var TotalPrice =
        this.state.voucherProductDetail.product_price *
        this.state.voucher_product_qty;

      var ImagePath = this.state.voucherProductDetail.product_thumbnail;
      if (ImagePath !== "") {
        var postImagePath =
          this.state.productimagePath +
          this.state.voucherProductDetail.product_thumbnail;
      } else {
        postImagePath = "";
      }

      var postObject = {};
      postObject = {
        app_id: appId,
        product_id: this.state.voucherProductDetail.product_id,
        product_qty: this.state.voucher_product_qty,
        product_name: this.state.voucherProductDetail.product_name,
        product_sku: this.state.voucherProductDetail.product_sku,
        product_total_price: TotalPrice,
        product_unit_price: this.state.voucherProductDetail.product_price,
        product_remarks: "",
        product_type: 5,
        modifiers: "",
        menu_set_component: "",
        availability_id: availabilityId,
        product_image: postImagePath,
        product_edit_enable: "No",
        reference_id: customerId === "" ? getReferenceID() : "",
        customer_id: customerId,
        category_id: this.state.voucherProductDetail.product_category_id,
        subcategory_id: this.state.voucherProductDetail.product_subcategory_id,
        access_token:
          cookie.load("access_token") !== "" &&
          typeof cookie.load("access_token") !== undefined &&
          typeof cookie.load("access_token") !== "undefined"
            ? cookie.load("access_token")
            : "",
        voucher_gift_name:
          this.state.voucher_name !== ""
            ? this.state.voucher_name
            : cookie.load("UserFname"),
        voucher_gift_mobile:
          this.state.voucher_mobile !== "" ? this.state.voucher_mobile : "",
        voucher_gift_email:
          this.state.voucher_email !== ""
            ? this.state.voucher_email
            : cookie.load("UserEmail"),
        voucher_gift_message:
          this.state.voucher_message !== "" ? this.state.voucher_message : "",
        product_voucher_expiry_date:
          this.state.voucherProductDetail.product_voucher_expiry_date !== ""
            ? this.state.voucherProductDetail.product_voucher_expiry_date
            : "",
      };
      Axios.post(
        apiUrl + "cart/insert",
        qs.stringify(postObject),
        headerconfig
      ).then((res) => {
        $("#proIndex-" + this.state.voucherIndexFlag).removeClass("active");
        hideLoader("proIndex-" + this.state.voucherIndexFlag, "Idtext");
        $("#proIndex-" + this.state.voucherIndexFlag)
          .find(".addcart_done_maindiv")
          .hide();
        $("#proIndex-" + this.state.voucherIndexFlag)
          .find(".smiple_product_lk")
          .show();

        if (res.data.status === "ok") {
          this.setState({
            voucher_name: "",
            error_voucher_name: "",
            voucher_mobile: "",
            error_voucher_mobile: "",
            voucher_email: "",
            error_voucher_email: "",
            voucher_message: "",
            error_voucher_message: "",
          });
          this.props.sateValChange("cartflg", "yes");
          hideLoader("productlist-main-div", "class");
          removePromoCkValue();
          /* this.handleShowAlertFun(
            "成功",
            "偉大な選択肢！カートにアイテムが追加されました。"
          ); */
          this.setState({
            voucherProductDetail: "",
            voucher_product_qty: "",
          });
        } else if (res.data.status === "error") {
          var errMsgtxt =
            res.data.message !== "" ? res.data.message : language.itemnotadded;
          showCustomAlert("error", errMsgtxt);
          this.handleShowAlertFun("エラー", errMsgtxt);
          this.setState({
            voucherProductDetail: "",
            voucher_product_qty: "",
            voucherIndexFlag: "",
          });
        }

        return false;
      });
    }
  }

  addToCartVoucherMe() {
    var availabilityId = cookie.load("defaultAvilablityId");
    /*var availabilityId = deliveryId;*/
    var availabilityName =
      availabilityId === deliveryId ? "Delivery" : "Pickup";
    var isAddonProduct = "No";
    var productId = this.state.voucherProductDetail.product_id;
    var customerId =
      typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");

    var TotalPrice =
      this.state.voucherProductDetail.product_price *
      this.state.voucher_product_qty;

    var postObject = {};
    postObject = {
      app_id: appId,
      product_id: this.state.voucherProductDetail.product_id,
      product_qty: this.state.voucher_product_qty,
      product_type: 5,
      availability_id: availabilityId,
      product_name: this.state.voucherProductDetail.product_name,
      product_sku: this.state.voucherProductDetail.product_sku,
      product_total_price: TotalPrice,
      product_unit_price: this.state.voucherProductDetail.product_price,
      product_remarks: "",
      product_type: 5,
      modifiers: "",
      menu_set_component: "",
      availability_id: availabilityId,
      product_image: this.state.voucherProductDetail.product_thumbnail,
      product_edit_enable: "No",
      reference_id: customerId === "" ? getReferenceID() : "",
      customer_id: customerId,
      category_id: this.state.voucherProductDetail.product_category_id,
      subcategory_id: this.state.voucherProductDetail.product_subcategory_id,
      access_token:
        cookie.load("access_token") !== "" &&
        typeof cookie.load("access_token") !== undefined &&
        typeof cookie.load("access_token") !== "undefined"
          ? cookie.load("access_token")
          : "",
      voucher_gift_name:
        typeof cookie.load("UserFname") === "undefined"
          ? ""
          : cookie.load("UserFname"),
      voucher_gift_mobile:
        typeof cookie.load("UserMobile") === "undefined"
          ? ""
          : cookie.load("UserMobile"),
      voucher_gift_email:
        typeof cookie.load("UserEmail") === "undefined"
          ? ""
          : cookie.load("UserEmail"),
      voucher_gift_message: "",
      product_voucher_expiry_date:
        this.state.voucherProductDetail.product_voucher_expiry_date !== ""
          ? this.state.voucherProductDetail.product_voucher_expiry_date
          : "",
    };

    Axios.post(
      apiUrl + "cart/insert",
      qs.stringify(postObject),
      headerconfig
    ).then((res) => {
      $("#proIndex-" + this.state.voucherIndexFlag).removeClass("active");
      hideLoader("proIndex-" + this.state.voucherIndexFlag, "Idtext");
      $("#proIndex-" + this.state.voucherIndexFlag)
        .find(".addcart_done_maindiv")
        .hide();
      $("#proIndex-" + this.state.voucherIndexFlag)
        .find(".smiple_product_lk")
        .show();

      if (res.data.status === "ok") {
        this.props.sateValChange("cartflg", "yes");
        hideLoader("productlist-main-div", "class");
        removePromoCkValue();
        /*  this.handleShowAlertFun(
          "成功",
          "偉大な選択肢！カートにアイテムが追加されました。"
        ); */
        this.setState({
          voucherProductDetail: "",
          voucher_product_qty: "",
        });
      } else if (res.data.status === "error") {
        var errMsgtxt =
          res.data.message !== "" ? res.data.message : language.itemnotadded;
        showCustomAlert("error", errMsgtxt);
        this.handleShowAlertFun("エラー", errMsgtxt);
        this.setState({
          voucherProductDetail: "",
          voucher_product_qty: "",
          voucherIndexFlag: "",
        });
      }

      return false;
    });
  }

  viewProDetail(productDetail, event) {
    event.preventDefault();

    this.props.history.push(
      "/products/" +
        productDetail.pro_cate_slug +
        "/" +
        productDetail.pro_subcate_slug +
        "/" +
        productDetail.product_slug
    );
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.props.sateValChange("cartflg", value);
    }
    if (field === "selProductEndSection") {
      this.setState({ selProductEndSection: "" }, function() {
        this.productsubcatlist(
          this.state.OldupdateproductList,
          this.state.oldnavigateMenu
        );
      });
    }
  };

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  handleChangeTxt = (item, event) => {
    if (item === "voucher_mobile") {
      const re = /^[0-9\b]+$/;
      if (event.target.value === "" || re.test(event.target.value)) {
        this.setState({ [item]: event.target.value });
      }
      this.setState({ ["error_" + item]: "" });
    } else if (item === "voucher_email") {
      if (emailValidate(event.target.value) === true) {
        this.setState({ ["error_" + item]: "" });
      } else {
        this.setState({
          ["error_" + item]: Parser(
            '<span class="error">' + language.thisnotvalidemail + "</span>"
          ),
        });
      }
      this.setState({ [item]: event.target.value });
    } else {
      this.setState({ [item]: event.target.value });
      this.setState({ ["error_" + item]: "" });
    }
  };

  render() {
    var categoryList = [];
    var subcategoryList = [];
    if (Object.keys(this.props.navigateMenu).length > 0) {
      this.props.navigateMenu.map((item, index) => {
        categoryList.push({
          value: index + "~" + item.pro_cate_slug,
          label: stripslashes(item.menu_custom_title),
        });
        if (item.pro_cate_slug === this.state.filterCategory) {
          if (Object.keys(item.subcat_list_arr).length > 0) {
            item.subcat_list_arr.sub_result_set.map((scitem, scindex) => {
              subcategoryList.push({
                value:
                  scindex +
                  "~" +
                  scitem.pro_subcate_slug +
                  "~" +
                  scitem.pro_subcate_name,
                label: stripslashes(scitem.pro_subcate_name),
              });
            });
          }
        }
      });
    }
    return (
      <div className="productlist-main-div addon-page">
        <div className="innerproduct_row">
          {/* this.state.product_List */}
          {this.state.productDetails}

          <div
            className="no-product"
            id="no_product_error"
            style={{ display: "none" }}
          >
            <div className="container">
              <h2>{language.productnotfound}</h2>
              <p>{language.norpoductmsg}</p>
            </div>
          </div>
          <div className="link-center">
            <Link
              to={"/checkout"}
              className="button cartaction_checkout"
              title="Checkout Now"
            >
              {language.gotocart} <img src={go} alt="" />
            </Link>
          </div>
        </div>
        <div
          id="vouchergift-popup"
          className="mfp-hide popup_sec vouchergift-popup"
        >
          <div className="pop-whole full-login-new">
            <div className="full-login-new-header">
              <h3>{language.buythis}</h3>
            </div>
            <div className="full-login-new-body">
              <div className="voucher-popup-body">
                <div className="tab-menu-container">
                  <ul>
                    <li>
                      <a
                        href={void 0}
                        className="tab_item active"
                        data-id="productItem-1"
                      >
                        {language.forgift}
                      </a>
                    </li>
                    <li>
                      <a
                        href={void 0}
                        className="tab_item"
                        data-id="productItem-2"
                      >
                        {language.forme}
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  className="product_choosen_row_inner product_tab_item tab_item_active"
                  data-id="productItem-1"
                >
                  <div className="voucher-popup-inner">
                    <h2>{language.giftrecipientinfo}</h2>
                    <div className="form-group">
                      <div
                        className={
                          this.state.voucher_name !== ""
                            ? "focus-out focused"
                            : "focus-out"
                        }
                      >
                        <label>{name.firstname}</label>
                        <input
                          type="text"
                          id="guest-name"
                          name="guest-name"
                          value={this.state.voucher_name}
                          onChange={this.handleChangeTxt.bind(
                            this,
                            "voucher_name"
                          )}
                          className="form-control input-focus"
                        />
                        {this.state.error_voucher_name}
                      </div>
                    </div>
                    <div className="form-group">
                      <div
                        className={
                          this.state.voucher_mobile !== ""
                            ? "focus-out focused"
                            : "focus-out"
                        }
                      >
                        <label>{language.entermobile}</label>
                        <input
                          type="tel"
                          id="guest-number"
                          name="guest-number"
                          value={this.state.voucher_mobile}
                          onChange={this.handleChangeTxt.bind(
                            this,
                            "voucher_mobile"
                          )}
                          className="form-control input-focus"
                        />
                        {this.state.error_voucher_mobile}
                      </div>
                    </div>
                    <div className="form-group">
                      <div
                        className={
                          this.state.voucher_email !== ""
                            ? "focus-out focused"
                            : "focus-out"
                        }
                      >
                        <label>{language.email}</label>
                        <input
                          type="text"
                          id="guest-email"
                          name="guest-email"
                          value={this.state.voucher_email}
                          onChange={this.handleChangeTxt.bind(
                            this,
                            "voucher_email"
                          )}
                          className="form-control input-focus"
                        />
                        {this.state.error_voucher_email}
                      </div>
                    </div>
                    <div className="form-group">
                      <div
                        className={
                          this.state.voucher_message !== ""
                            ? "focus-out focused"
                            : "focus-out"
                        }
                      >
                        <label>{language.mesage}</label>
                        <textarea
                          type="text"
                          id="guest-message"
                          name="guest-message"
                          value={this.state.voucher_message}
                          onChange={this.handleChangeTxt.bind(
                            this,
                            "voucher_message"
                          )}
                          className="form-control input-focus"
                        />
                        {this.state.error_voucher_message}
                      </div>
                    </div>

                    <div className="howtouse-gift-vouchar">
                      <img src={smartPhone} />
                      <h3>{language.howtouse}</h3>
                      <p>
                        {language.giftdesc}
                        {language.giftdesc1}
                        {language.giftdesc2}
                      </p>
                    </div>
                    <div className="form-group gift-group">
                      <button
                        type="button"
                        className="button"
                        onClick={this.addToCartVoucher.bind(this)}
                      >
                        {language.addtocart}
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="product_choosen_row_inner product_tab_item"
                  data-id="productItem-2"
                >
                  <div className="howtouse-gift-vouchar">
                    <img src={smartPhone} />
                    <h3>{language.howtouse}</h3>
                    <p>{language.youreciveemailnotification}</p>
                  </div>
                  <div className="voucher-popup-inner">
                    <button
                      type="button"
                      className="button"
                      onClick={this.addToCartVoucherMe.bind(this)}
                    >
                      {language.addtocart}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var productSubcat = Array();
  var productCmn = Array();
  var setnoProduct = "No";
  if (Object.keys(state.featureproduct).length > 0) {
    var tempArr = state.featureproduct[0];
    if (tempArr.status === "ok") {
      productSubcat = tempArr.result_set;
      productCmn = tempArr.common;
    } else {
      setnoProduct = "Yes";
    }
  }

  return {
    productSubcatlist: productSubcat,
    productCommon: productCmn,
    setnoProduct: setnoProduct,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFeatureProList: () => {
      dispatch({
        type: GET_FEATUREPRO,
      });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(ProductList);
