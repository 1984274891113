/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_ACTIVITYCOUNT, SET_ACTIVITYCOUNT } from "../actions";
import {
  appId,
  apiUrl,
  deliveryId,
  headerconfig,
} from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetActivityCount = function* () {
  yield takeEvery(GET_ACTIVITYCOUNT, workerGetActivityCount);
};

function* workerGetActivityCount({ getObject }) {
  try {
    var access_token = (cookie.load('access_token')!=="" && typeof cookie.load('access_token')!==undefined && typeof cookie.load('access_token')!=="undefined")?'&access_token='+cookie.load('access_token'):'';
    var customerParam = "&status=A&customer_id=" + cookie.load("UserId")+access_token+"&availability_id=" + cookie.load("defaultAvilablityId");
    var actArrParam = "&act_arr=" + getObject;
    const uri =
      apiUrl +
      "reports/activity_counts1?app_id=" +
      appId +
      actArrParam +
      customerParam;
    const result = yield call(Axios.get, uri, headerconfig);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_ACTIVITYCOUNT, value: resultArr });
  } catch {
    console.log("Activitycount failed");
  }
}
