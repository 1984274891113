/* eslint-disable */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import axios from "axios";
import update from "immutability-helper";
/* import  MetaTags  from "react-meta-tags"; */
import { Helmet } from "react-helmet";
import { format } from "date-fns";
var dateFormat = require("dateformat");
import moment from "moment";
const timeInterval = 5;
var startIntervel = 0;
import { language } from "../Helpers/Language";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  pickupId,
  projectTitle,
  CountryTxt,
  headerconfig,
  cookieConfig,
} from "../Helpers/Config";

import {
  GET_GLOBAL_SETTINGS,
  GET_ZONE_DETAIL,
  GET_STATIC_BLOCK,
  GET_PICKUP_OUTLETS,
  GET_ALL_OUTLETS,
  DESTROY_CART_DETAIL,
  GET_LOGINDATA,
  GET_FBLOGINDATA,
  GET_FORGET_PASSWORD,
  GET_CHANGEPASSWORD,
  GET_REGISTRATION,
  GET_MENUDATA,
  GET_ALLUSERSECADDRDATA,
} from "../../actions";
import {
  getReferenceID,
  showAlert,
  showLoader,
  hideLoader,
  getAliasName,
  stripslashes,
  removeOrderDateTime,
  removePromoCkValue,
  addressFormat,
  encryptdecrypt,
} from "../Helpers/SettingHelper";
import Autocomplete from "./Autocomplete";
import OrderdatetimeSlot from "./OrderdatetimeSlot";
import OrderAdvancedDatetimeSlot from "./OrderAdvancedDatetimeSlot";
import {
  Login,
  Forgotpassword,
  Signup,
  Otpverify,
  Changepassword,
} from "../../components/Myaccount/Index";

/* import images */
import mainLogo from "../../common/images/logo.svg";
import iconUnhappy from "../../common/images/sad-smiley.png";
import iconWin from "../../common/images/icon-win.svg";
import Win from "../../common/images/win.png";
import warningImg from "../../common/images/warning.svg";
import warningplus from "../../common/images/18plus.png";

import deliveryImg from "../../common/images/delivery.svg";
import deliveryWtImg from "../../common/images/delivery-w.svg";
import takeawayWtImg from "../../common/images/takeaway-w.svg";
import loupe from "../../common/images/search-white.svg";
import cartLike from "../../common/images/cat-item-like-white.svg";
import cartLikeActive from "../../common/images/cat-item-like-bw-active.svg";
import footerLogo from "../../common/images/f-logo.svg";
import redarrow from "../../common/images/redarrow.png";

import Cartsidebar from "./Cartsidebar";
var Parser = require("html-react-parser");
var base64 = require("base-64");
var qs = require("qs");
class Header extends Component {
  constructor(props) {
    super(props);
    if (
      typeof cookie.load("UserId") !== "undefined" &&
      cookie.load("UserId") !== null
    ) {
      sessionStorage.setItem(
        "mytime",
        new Date(Date.now() + timeInterval * 240000)
      );
    }
    this.state = {
      seletedAvilablityId: "",
      seletedOutletId: "",
      order_tat_time: 0,
      globalsettings: [],
      secondaryaddresslist: [],
      deliveryOutlets: [],
      deliveryOutletsList: [],
      pickupOutlets: [],
      pickupOutletsList: [],
      delivery_outlet_id: "",
      searchProResult: [],
      selectedProResult: [],
      orderHandled: "",
      orderDeliveryAddress: "",
      nextavail: "",
      logindata: "",
      deliveryInfo: [],
      pickupInfo: [],
      /* For Advanced Slot Start */
      getDateTimeFlg: "",
      seleted_ord_date: "",
      seleted_ord_time: "",
      seleted_ord_slot: "",
      seleted_ord_slotTxt: "",
      seleted_ord_slot_str: "",
      seleted_ord_slot_end: "",
      isAdvanced: "no",
      slotType: "0",
      /* For Advanced Slot End */

      fields: {
        email: "",
        pin: "",
        terms: "",
      },
      fieldsfgtpassword: {
        email: "",
      },
      fpstatus: "initiating",
      regstatus: "initiating",
      fieldssignup: {
        firstname: "",
        email: "",
        pin: "",
        rePin: "",
        mobile: "",
        pdpa_consent: "",
        terms: "",
        completeReg: "N",
      },
      fieldsotpverify: {
        customer_otp_val: "",
      },
      fieldschpassword: {
        oldpassword: "",
        newpassword: "",
        confirmpassword: "",
      },
      enableOtp: false,
      menuData: [],
      openOutletTime: false,
      fbstatus: "ok",
      staticblacks: [],
      instantorders: "",
      deliveryAddress: "",
      deliveryLat: "",
      deliveryLon: "",
      userID:
        cookie.load("UserId") !== "" &&
        typeof cookie.load("UserId") !== undefined &&
        typeof cookie.load("UserId") !== "undefined"
          ? cookie.load("UserId")
          : "",
    };

    this.props.getGlobalSettings();
    this.props.getStaticBlock();
    this.props.getPickupOutlets();
    this.props.getAllOutlets(deliveryId);
    this.props.getSecondaryAddress();

    var availbty = cookie.load("defaultAvilablityId");
    var outltIdTxt =
      typeof cookie.load("orderOutletId") === "undefined"
        ? ""
        : cookie.load("orderOutletId");
    var zoneIdTxt =
      typeof cookie.load("orderZoneId") === "undefined"
        ? ""
        : cookie.load("orderZoneId");

    if (availbty === deliveryId && outltIdTxt !== "" && zoneIdTxt !== "") {
      this.state["delivery_outlet_id"] = outltIdTxt;
      this.props.getZoneDetail(outltIdTxt, zoneIdTxt);
    }
    if (navigator != undefined && navigator.userAgent != undefined) {
      var user_agent = navigator.userAgent.toLowerCase();
      if (user_agent.indexOf("android") > -1) {
        $(document.body).addClass("android-phone");
      } else if (user_agent.indexOf("ios") > -1) {
        $(document.body).addClass("iphone-device");
      }
    }
  }

  checkAuth() {
    if (this.state.userID !== "") {
      axios
        .get(
          apiUrl +
            "settings/checkcustomerstatus?app_id=" +
            appId +
            "&customer_id=" +
            this.state.userID +
            "&access_token=" +
            cookie.load("access_token"),
          headerconfig
        )
        .then((res) => {
          if (res.data.status === "ok") {
          } else if (res.data.status === "error") {
            this.props.history.push("/logout/home");
          }
        });
    }
  }

  componentDidMount() {
    if (
      cookie.load("UserId") === "" ||
      typeof cookie.load("UserId") === undefined ||
      typeof cookie.load("UserId") === "undefined"
    ) {
      if (
        this.props.match.path !== "/account/activation/:activationKey" &&
        this.props.match.path !== "/account/resetpassword/:resetKey" &&
        this.props.match.path !== "/logout"
      ) {
        $("body").on("keyup", function(e) {
          if (e.which === 27) {
            return false;
          }
        });
        $.magnificPopup.open({
          items: {
            src: "#login-popup",
          },
          type: "inline",
          showCloseBtn: false,
          midClick: true,
          closeOnBgClick: false,
          closeOnContentClick: false,
        });
      }
    }

    if (
      typeof sessionStorage.getItem("mytime") !== "undefined" &&
      sessionStorage.getItem("mytime") !== null &&
      typeof cookie.load("UserId") !== "undefined" &&
      cookie.load("UserId") !== null
    ) {
      this.onIdle();
    }
    var currnetThisN = this;

    setTimeout(function() {
      currnetThisN.checkAuth();
    }, 3000);

    if (this.props.match.path === "/products/:slugType/:slugValue/:proValue") {
      $(".scroll_top_parent").hide();
    } else {
      $(".scroll_top_parent").show();
    }

    //

    $(".custom_close").click(function(e) {
      e.preventDefault();
      $(".custom_alertcls, .custom_center_alertcls").hide();
    });
    $("html, body").animate(
      {
        scrollTop: $("body").offset().top,
      },
      100
    );

    $(".test-popup-link").magnificPopup({
      type: "image",
      showCloseBtn: true,
      verticalFit: true,
      callbacks: {
        change: function() {
          this.wrap.addClass("awardpopup");
        },
      },
      // other options
    });

    if (this.props.match.path === "/sign-in") {
      if (!cookie.load("UserId")) {
        $.magnificPopup.open({
          items: {
            src: "#signup-popup",
          },
          type: "inline",
        });
      } else {
        this.props.history.push("/myaccount");
      }
    }
    this.props.getMenuData("coca-cola-header");
    if ($(".open-popup-link").length > 0) {
      $(".open-popup-link").magnificPopup({
        type: "inline",
        midClick: true,
      });
    }

    if ($(".trigger_login").length > 0) {
      $(".trigger_login").magnificPopup({
        type: "inline",
        midClick: true,
      });
    }

    /* Input lable animation */
    if ($(".input-focus").length > 0) {
      $(".input-focus").focus(function() {
        $(this)
          .parents(".focus-out")
          .addClass("focused");
      });
      $(".input-focus").blur(function() {
        var inputValue = $(this).val();
        if (inputValue == "") {
          $(this).removeClass("filled");
          $(this)
            .parents(".focus-out")
            .removeClass("focused");
        } else {
          $(this).addClass("filled");
        }
      });
    }

    if ($(".hsearch_trigger").length > 0) {
      $(".hsearch_trigger").click(function(e) {
        e.preventDefault();
        e.stopPropagation();
        $(".search_result").hide();
        $(this).toggleClass("active");
        $(".hsearch_sec").toggleClass("open");
        $(".hsearch_sec .form-control").focus();
      });
      $(document).click(function(e) {
        if (
          !$(e.target).is(".hsearch_trigger, .hsearch_sec, .hsearch_sec * ")
        ) {
          if ($(".hsearch_sec").is(":visible")) {
            $(".hsearch_sec").removeClass("open");
            $(".hsearch_trigger").removeClass("active");
            $(".hsearch_sec .form-control").blur();
          }
        }
      });
    }

    if ($(".hsearch_trigger_mbl").length > 0) {
      $(".hsearch_trigger_mbl").click(function(e) {
        e.preventDefault();
        e.stopPropagation();
        $(".search_result_mbl").hide();
        $(this).toggleClass("active");
        $(".hsearch_sec_mbl").toggleClass("open");
        $(".hsearch_sec_mbl .form-control").focus();
      });
      $(document).click(function(e) {
        if (
          !$(e.target).is(
            ".hsearch_trigger_mbl, .hsearch_sec_mbl, .hsearch_sec_mbl * "
          )
        ) {
          if ($(".hsearch_sec_mbl").is(":visible")) {
            $(".hsearch_sec_mbl").removeClass("open");
            $(".hsearch_trigger_mbl").removeClass("active");
            $(".hsearch_sec_mbl .form-control").blur();
          }
        }
      });
    }

    if ($(".mobile_mainacc_menutrigger").length > 0) {
      $(".mobile_mainacc_menutrigger").click(function(e) {
        e.stopPropagation();
        if ($(".mobile_mainacc_menulist").is(":visible")) {
          $(".mobile_mainacc_menulist").hide();
        } else {
          $(".mobile_mainacc_menulist").show();
        }
      });
      $(document).click(function(e) {
        if (!$(e.target).is(".mobile_account_item, .mobile_account_item * ")) {
          if ($(".mobile_mainacc_menulist").is(":visible")) {
            $(".mobile_mainacc_menulist").hide();
          }
        }
      });
    }
    /* Mobile header menu */
    $(".hcategory_trigger").click(function() {
      $(this).toggleClass("active");
      $(".hcategory_menu").toggleClass("open");
    });

    $(document).click(function(e) {
      if (!$(e.target).is(".hcategory_trigger")) {
        if ($(".hcategory_menu").hasClass("open")) {
          $(".hcategory_menu").removeClass("open");
          $(".hcategory_trigger").removeClass("active");
        }
      }
    });

    $(".disbl_href_action").click(function(e) {
      e.preventDefault();
    });
    this.getSearchProductList();
  }

  componentWillReceiveProps(PropsDt) {
    if (
      typeof cookie.load("UserId") !== "undefined" &&
      cookie.load("UserId") !== null
    ) {
      startIntervel = 0;
      sessionStorage.setItem(
        "mytime",
        new Date(Date.now() + timeInterval * 240000)
      );
    }
    if (PropsDt.homePageState !== undefined) {
      if (this.state.openOutletTime !== PropsDt.homePageState.openOutletTime) {
        this.setState(
          { openOutletTime: PropsDt.homePageState.openOutletTime },
          function() {
            if (PropsDt.homePageState.openOutletTime === true) {
              this.pickOutlet(this.state.pickupOutlets[0]);
              this.props.sateValChange("openOutletTime", false);
            }
          }
        );
      }
    }

    if (PropsDt.staticblack !== this.state.staticblacks) {
      var instantorders = "";
      /*  
      var homeTestmonial = "";
      var homeOurSpecialDishes = ""; */
      if (Object.keys(PropsDt.staticblack).length > 0) {
        PropsDt.staticblack.map((data) => {
          if (data.staticblocks_slug === "home-instant-orders") {
            instantorders = data.staticblocks_description;
            return "";
          }
        });
      }

      instantorders =
        instantorders !== "" && instantorders !== null
          ? Parser(instantorders)
          : instantorders;
      this.setState({
        instantorders: instantorders,
      });
    }

    if (PropsDt.menudata !== this.props.menudata) {
      this.setState({ menudata: PropsDt.menudata[0].result_set });
    }

    if (PropsDt.outletslist !== this.state.pickupOutletsList) {
      this.setState(
        {
          pickupOutlets: PropsDt.outletslist,
          pickupOutletsList: PropsDt.outletslist,
        },
        function() {
          if (this.props.location.pathname === "/") {
            this.props.sateValChange("pickup_outlet", PropsDt.outletslist);
          }
        }
      );
    }

    if (PropsDt.alloutletslist !== this.state.deliveryOutletsList) {
      this.setState({
        deliveryOutlets: PropsDt.alloutletslist,
        deliveryOutletsList: PropsDt.outletslist,
      });
    }

    if (PropsDt.secondaryaddresslist !== this.state.secondaryaddresslist) {
      this.setState({ secondaryaddresslist: PropsDt.secondaryaddresslist });
    }

    if ("homePageState" in PropsDt) {
      if (
        PropsDt.homePageState.nextavail !== undefined &&
        PropsDt.homePageState.nextavail !== "" &&
        PropsDt.homePageState.nextavail !== this.state.nextavail
      ) {
        this.setState({ nextavail: PropsDt.homePageState.nextavail });
      }
    }

    if (PropsDt.globalsettings !== this.state.globalsettings) {
      if (
        PropsDt.globalsettings[0] !== "" &&
        PropsDt.globalsettings[0] !== undefined
      ) {
        this.setState({
          globalsettings: PropsDt.globalsettings[0].result_set,
        });
      }
    }

    if (this.state.fbstatus === "loading") {
      if (PropsDt.fblogin !== undefined) {
        if (PropsDt.fblogin.length > 0) {
          this.setState({ fbstatus: "ok" }, function() {
            this.doLogin(PropsDt.fblogin[0]);
          });
        }
      }
    }

    if (PropsDt.logindata !== this.props.logindata) {
      this.doLogin(PropsDt.logindata[0]);
    }

    if (this.state.fpstatus === "loading") {
      if (PropsDt.forgetpassword !== undefined) {
        this.setState({ fpstatus: "ok" });
        this.showMessage(PropsDt.forgetpassword[0]);
      }
    }

    if (this.state.regstatus === "loading") {
      if (PropsDt.registration !== undefined) {
        var formPay_load = this.state.fieldssignup;
        this.setState({
          regstatus: "ok",
          enableOtp: false,
          fieldssignup: {
            firstname: "",
            email: "",
            pin: "",
            rePin: "",
            mobile: "",
            pdpa_consent: "",
            terms: "",
            completeReg: "Y",
          },
        });
        hideLoader("otpverify_submit", "class");
        this.showMessage(PropsDt.registration[0]);
      }
    }
    if (PropsDt.changepassword !== this.props.changepassword) {
      //  const {changepassword} = nextProps.changepassword;
      $(".old_password").val("");
      $(".new_password").val("");
      $(".confirm_password").val("");
      hideLoader("change-pass-sbmt", "class");
      if (PropsDt.changepassword[0].status === "ok") {
        showAlert("Success", "Password changed successfully!");
      } else {
        if (PropsDt.changepassword[0].form_error) {
          showAlert("Alert", PropsDt.changepassword[0].form_error);
        } else {
          showAlert("Alert", PropsDt.changepassword[0].message);
        }
      }
      window.$.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  /*forget password  - start*/
  fieldforgot = (field, value) => {
    this.setState(
      update(this.state, { fieldsfgtpassword: { [field]: { $set: value } } })
    );
  };

  forgotpassword = () => {
    this.setState({ fpstatus: "loading" });
    const formPayload = this.state.fieldsfgtpassword;

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      email_address: formPayload.email,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
    };

    showLoader("forgotpassword-cls", "class");
    this.props.getForgetPassword(qs.stringify(postObject));
  };
  /*forget password  - end*/

  /* signin - start*/
  fieldChange = (field, value) => {
    if (field == "terms") {
      value = $("#login_terms").prop("checked");
    }
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };

  handleSignin = () => {
    const formPayload = this.state.fields;
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      login_username: formPayload.email,
      login_password: formPayload.pin,
    };
    showLoader("login_submit", "class");
    this.props.getLoginData(qs.stringify(postObject));
  };
  /* signin - end*/

  /* for signup - start*/
  fieldChangeSignup = (field, value) => {
    if (field == "terms") {
      value = $("#terms").prop("checked");
    }
    if (field == "pdpa_consent") {
      value = $("#pdpa_consent").prop("checked");
    }
    this.setState(
      update(this.state, { fieldssignup: { [field]: { $set: value } } })
    );
  };

  handleSignup = () => {
    const formPayload = this.state.fieldssignup;
    this.setState({ regstatus: "loading" });
    var pdpaConsent = formPayload.pdpa_consent === true ? "yes" : "no";

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      customer_first_name: encryptdecrypt(formPayload.firstname, "E"),
      customer_email: encryptdecrypt(formPayload.email, "E"),
      customer_password: encryptdecrypt(formPayload.pin, "E"),
      customer_phone: encryptdecrypt(formPayload.mobile, "E"),
      customer_pdpa_consent: pdpaConsent,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
    };

    showLoader("signup_submit", "class");
    this.props.getRegistration(qs.stringify(postObject));
  };

  /* for signup - end*/

  /* Change Password */
  fieldChangepassword = (field, value) => {
    this.setState(
      update(this.state, { fieldschpassword: { [field]: { $set: value } } })
    );
  };

  changepassword = () => {
    var old_password = $(".old_password").val();
    var new_password = $(".new_password").val();
    var confirm_password = $(".confirm_password").val();
    var postObject = {
      app_id: appId,
      type: "web",
      oldpassword: old_password,
      password: new_password,
      confirmpassword: confirm_password,
      refrence: cookie.load("UserId"),
      access_token:
        cookie.load("access_token") !== "" &&
        typeof cookie.load("access_token") !== undefined &&
        typeof cookie.load("access_token") !== "undefined"
          ? cookie.load("access_token")
          : "",
      passwordtype: "PIN",
    };
    showLoader("change-pass-sbmt", "class");
    var qs = require("qs");
    this.props.getChangePassword(qs.stringify(postObject));
  };

  /* main - menu navigation -start*/

  createLink(menu) {
    if (menu.nav_type === "0" && menu.nav_parent_title == "") {
      return (
        <Link to={"/" + menu.nav_title_slug} title={menu.nav_title}>
          <span>{menu.nav_title}</span>
        </Link>
      );
    } else if (menu.nav_type === "3" && menu.nav_parent_title == "") {
      var pageUrlTxt = menu.nav_pages;
      if (pageUrlTxt.includes("http")) {
        return (
          <a
            href={menu.nav_pages}
            title={menu.nav_title}
            target={menu.nav_link_type == "blank" ? "_blank" : ""}
          >
            <span>{menu.nav_title}</span>
          </a>
        );
      } else {
        return (
          <Link
            to={menu.nav_pages != "#" ? "/" + menu.nav_pages : ""}
            title={menu.nav_title}
            target={menu.nav_link_type == "blank" ? "_blank" : ""}
          >
            <span>{menu.nav_title}</span>
          </Link>
        );
      }
    }
  }

  createSubmenu(menu, type) {
    if (menu.nav_parent_title === "") {
      if (this.state.menudata) {
        var checkIngVal = 0;
        var liTxt = this.state.menudata.map(function(menuparent, i) {
          if (menu.nav_id == menuparent.nav_parent_title) {
            checkIngVal = 1;
            if (menuparent.nav_type === "0") {
              return (
                <li key={i + 100}>
                  <Link
                    to={"/" + menuparent.nav_title_slug}
                    title={menuparent.nav_title}
                  >
                    <span>{menuparent.nav_title}</span>
                  </Link>
                </li>
              );
            } else {
              return (
                <li key={i + 100}>
                  <Link
                    to={
                      menuparent.nav_pages != "#"
                        ? "/" + menuparent.nav_pages
                        : ""
                    }
                    title={menuparent.nav_title}
                    target={menuparent.nav_link_type == "blank" ? "_blank" : ""}
                  >
                    <span>{menuparent.nav_title}</span>
                  </Link>
                </li>
              );
            }
          }
        }, this);

        if (type === "span" && checkIngVal === 1) {
          return <a href="/" className="submenu-arow disbl_href_action"></a>;
        } else if (type === "ul" && checkIngVal === 1) {
          return <ul className="submenu_list">{liTxt}</ul>;
        } else {
          return "";
        }
      }
    }
  }

  menuActiveCls(nav_pages) {
    var currenturl = window.location.href;
    var returnClsTx = "";
    if (nav_pages === "home" || nav_pages === "#" || nav_pages === "/") {
      returnClsTx =
        this.props.match.path === "/home" || this.props.match.path === "/"
          ? "active"
          : "";
    } else if (nav_pages === "products") {
      returnClsTx =
        this.props.match.path === "/products" ||
        this.props.match.path === "/products/:slugType/:slugValue" ||
        this.props.match.path === "/products/:slugType/:slugValue/:proValue" ||
        this.props.match.path === "/checkout" ||
        this.props.match.path === "/thankyou/:orderId"
          ? "active"
          : "";
    } else {
      returnClsTx = currenturl.includes(nav_pages) ? "active" : nav_pages;
    }
    return returnClsTx;
  }

  listMainNavigation() {
    if (this.state.menudata) {
      return this.state.menudata.map(function(menu, i) {
        let nav_pages = menu.nav_pages;
        if (menu.nav_type === "0") {
          nav_pages = menu.nav_title_slug;
        }

        return (
          <li key={i + 100} className={this.menuActiveCls(nav_pages)}>
            {this.createLink(menu)}
            {this.createSubmenu(menu, "span")}
            {this.createSubmenu(menu, "ul")}
          </li>
        );
      }, this);
    }
  }

  /* menu navigation -end */
  /* show message */
  showMessage(response) {
    hideLoader("signup_submit", "class");
    hideLoader("forgotpassword-cls", "class");
    if (response.status === "ok") {
      showAlert("成功", response.message);
    } else {
      if (response.form_error) {
        showAlert("エラー", response.form_error);
      } else {
        showAlert("エラー", response.message);
      }
    }
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  chooseAvailabilityFun(availability, event) {
    event.preventDefault();
    var defaultAvilTy = cookie.load("defaultAvilablityId");

    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    } else if (
      defaultAvilTy === availability &&
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined
    ) {
      /*$.magnificPopup.close();
			this.props.history.push('/products');
			return false;*/
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      $(".delivery_outletpoup")
        .find(".outlet_error")
        .html("");
      popupIdtxt = "#delevery-postcode-popup";
    } else if (availability === pickupId) {
      if (
        this.state.pickupOutlets !== "" &&
        typeof this.state.pickupOutlets !== undefined &&
        typeof this.state.pickupOutlets !== undefined
      ) {
        if (this.state.pickupOutlets.length === 1) {
          this.pickOutlet(this.state.pickupOutlets[0]);
        } else {
          popupIdtxt = "#takeaway-popup";
        }
      } else {
        popupIdtxt = "#takeaway-popup";
      }
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  chooseAvailabilityMbl() {
    var availability = cookie.load("defaultAvilablityId");
    var showtext = "今すぐ購入";
    if (availability === deliveryId) {
      showtext = "配達";
    } else if (availability === pickupId) {
      showtext = "選び出す";
    }
    return showtext;
  }

  /* facebook login */
  responseFacebook = (response) => {
    if (
      response.name !== "" &&
      response.email !== "" &&
      response.name !== undefined &&
      response.email !== undefined
    ) {
      var vSplitName = response.name.split(" ");
      var firstname = vSplitName[0];
      var lastname = vSplitName[1];
      var birthday = response.birthday;
      var qs = require("qs");
      var photo = response.picture.data.url;
      /* update gender field */
      var postGender = "";
      if (
        typeof response.gender !== "undefined" &&
        response.gender === "male"
      ) {
        postGender = "M";
      } else if (
        typeof response.gender !== "undefined" &&
        response.gender === "female"
      ) {
        postGender = "F";
      }
      /* update DOB */
      var dob = "";
      if (typeof birthday !== "undefined" && birthday !== "") {
        dob = dateFormat(response.birthday, "yyyy-mm-dd");
      }

      var postObject = {
        app_id: appId,
        type: "web",
        login_firstname: response.first_name,
        login_lastname: response.last_name,
        login_username: response.email,
        customer_fb_id: response.id,
        customer_gender: postGender,
        customer_photo: photo,
        customer_dob: dob,
      };
      this.setState({ fbstatus: "loading" });
      this.props.getFbLoginData(qs.stringify(postObject));
    }
  };

  /* for login and facebook login*/
  doLogin(fbloginData) {
    hideLoader("login_submit", "class");
    if (fbloginData.status === "ok") {
      $.magnificPopup.close();
      var mobileno = "",
        cust_birthdate = "";
      if (
        typeof fbloginData.result_set.customer_phone === "undefined" ||
        fbloginData.result_set.customer_phone === "null" ||
        fbloginData.result_set.customer_phone === ""
      ) {
        mobileno = "";
      } else {
        mobileno = fbloginData.result_set.customer_phone;
      }

      if (
        typeof fbloginData.result_set.customer_birthdate !== "undefined" &&
        fbloginData.result_set.customer_birthdate !== "null" &&
        fbloginData.result_set.customer_birthdate !== null &&
        fbloginData.result_set.customer_birthdate !== "" &&
        fbloginData.result_set.customer_birthdate !== "0000-00-00"
      ) {
        cust_birthdate = fbloginData.result_set.customer_birthdate;
      }
      cookie.save(
        "UserId",
        base64.encode(fbloginData.result_set.customer_id),
        cookieConfig
      );
      sessionStorage.setItem(
        "mytime",
        new Date(Date.now() + timeInterval * 240000)
      );
      cookie.save(
        "UserEmail",
        fbloginData.result_set.customer_email,
        cookieConfig
      );
      cookie.save(
        "UserFname",
        fbloginData.result_set.customer_first_name !== ""
          ? fbloginData.result_set.customer_first_name
          : "",
        cookieConfig
      );
      cookie.save(
        "UserLname",
        fbloginData.result_set.customer_last_name !== ""
          ? fbloginData.result_set.customer_last_name
          : "",
        cookieConfig
      );
      cookie.save("UserMobile", mobileno, cookieConfig);
      cookie.save("UserBirthdate", cust_birthdate, cookieConfig);
      cookie.save(
        "UserDefaultAddress",
        fbloginData.result_set.customer_address_name,
        cookieConfig
      );
      cookie.save(
        "UserDefaultUnitOne",
        fbloginData.result_set.customer_address_line1,
        cookieConfig
      );
      cookie.save(
        "UserDefaultUnitTwo",
        fbloginData.result_set.customer_address_line2,
        cookieConfig
      );

      cookie.save(
        "unitNoOne",
        fbloginData.result_set.customer_address_name,
        cookieConfig
      );
      cookie.save(
        "unitNoTwo",
        fbloginData.result_set.customer_address_name2,
        cookieConfig
      );
      cookie.save(
        "UserDefaultPostalCode",
        fbloginData.result_set.customer_postal_code,
        cookieConfig
      );
      cookie.save(
        "Userregister_type",
        fbloginData.result_set.customer_register_type_corporate,
        cookieConfig
      );
      cookie.save(
        "access_token",
        fbloginData.result_set.access_token,
        cookieConfig
      );
      const { history } = this.props;
      var qs = require("qs");
      var postObject = {
        app_id: appId,
        reference_id: getReferenceID(),
        customer_id: base64.encode(fbloginData.result_set.customer_id),
        availability_id: cookie.load("defaultAvilablityId"),
      };

      axios
        .post(
          apiUrl + "cart/update_customer_info",
          qs.stringify(postObject),
          headerconfig
        )
        .then((res) => {
          showAlert(language.success, language.loginsuccess);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
          if (res.data.status === "ok") {
            if (cookie.load("loginpopupTrigger") === "fromcheckout") {
              cookie.remove("loginpopupTrigger", cookieConfig);
              history.push("/checkout");
            } else {
              history.push("/checkout");
            }
          } else {
            location.reload();
            //history.push("/myaccount");
          }
        });
    } else {
      cookie.remove("loginpopupTrigger", cookieConfig);
      $("#login-error").show();
      $("#login-error .error").html(fbloginData.message);
      setTimeout(function() {
        $("#login-error").hide();
        $("#login-error .error").html("");
      }, 4000);
      //showAlert("エラー", fbloginData.message);
      /*   $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      }); */
    }
  }
  closepopup(event) {
    event.preventDefault();
    this.props.history.push("/products");
    /* $.magnificPopup.open({
      items: {
        src: "#delevery-postcode-popup",
      },
      type: "inline",
    }); */
  }

  gobckOutletpopup() {
    $.magnificPopup.open({
      items: {
        src: "#delevery-postcode-popup",
      },
      type: "inline",
    });
  }

  gobckPkupOutletpopup() {
    $.magnificPopup.open({
      items: {
        src: "#takeaway-popup",
      },
      type: "inline",
    });
  }
  changeSessionTimer() {
    startIntervel = 0;
    sessionStorage.setItem(
      "mytime",
      new Date(Date.now() + timeInterval * 240000)
    );
  }
  logoutSessionTimer() {
    this.props.history.push("/logout");
  }

  changeAvailability() {
    var tempArr = [],
      tempVl = "";
    this.setState({
      seletedOutletId: tempVl,
      deliveryInfo: tempArr,
      pickupInfo: tempArr,
      seleted_ord_date: tempVl,
      seleted_ord_time: tempVl,
    });

    /* For Advanced Slot Start */
    this.setState({
      seletedOutletId: tempVl,
      deliveryInfo: tempArr,
      pickupInfo: tempArr,
      seleted_ord_date: tempVl,
      seleted_ord_time: tempVl,
      slotType: tempVl,
      seleted_ord_slot: tempVl,
      seleted_ord_slotTxt: tempVl,
      seleted_ord_slot_str: tempVl,
      seleted_ord_slot_end: tempVl,
    });
    /* For Advanced Slot End */

    this.destroyCart("Yes");

    var warning_avilablity = $("#warning_avilablity").val();
    var nextavail = this.state.nextavail;
    if (warning_avilablity !== "") {
      var nextavail = warning_avilablity;
    }

    var popupIdtxt = "";
    if (nextavail === deliveryId) {
      popupIdtxt = "#delevery-postcode-popup";
    } else if (nextavail === pickupId) {
      if (
        this.state.pickupOutlets !== "" &&
        typeof this.state.pickupOutlets !== undefined &&
        typeof this.state.pickupOutlets !== undefined
      ) {
        if (this.state.pickupOutlets.length === 1) {
          this.pickOutlet(this.state.pickupOutlets[0]);
        } else {
          popupIdtxt = "#takeaway-popup";
        }
      } else {
        popupIdtxt = "#takeaway-popup";
      }
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  destroyCart(clear = "No") {
    this.props.destroyCartDetail();
    this.deleteOrderCookie(clear);
  }

  deleteOrderCookie(clear = "Yes") {
    if (clear == "Yes") {
      cookie.remove("orderZoneId", cookieConfig);
      cookie.remove("orderOutletId", cookieConfig);
      cookie.remove("outletchosen", cookieConfig);
    }

    removeOrderDateTime();
    removePromoCkValue();
    cookie.remove("orderPaymentMode", cookieConfig);
    cookie.remove("orderTableNo", cookieConfig);
    cookie.remove("product_remarks", cookieConfig);
    cookie.remove("orderOutletName", cookieConfig);
    cookie.remove("carttotalitems", cookieConfig);
    cookie.remove("cartsubtotal", cookieConfig);
    cookie.remove("cartid", cookieConfig);
    cookie.remove("firstNavigation", cookieConfig);

    /* Delivery avilablity */
    cookie.remove("orderDateTime", cookieConfig);
    cookie.remove("deliveryDate", cookieConfig);
    cookie.remove("deliveryTime", cookieConfig);
    cookie.remove("unitNoOne", cookieConfig);
    cookie.remove("unitNoTwo", cookieConfig);

    /* For Advanced Slot */
    cookie.remove("isAdvanced", cookieConfig);
    cookie.remove("slotType", cookieConfig);
    cookie.remove("orderSlotVal", cookieConfig);
    cookie.remove("orderSlotTxt", cookieConfig);
    cookie.remove("orderSlotStrTime", cookieConfig);
    cookie.remove("orderSlotEndTime", cookieConfig);

    cookie.remove("promotion_id", cookieConfig);
    cookie.remove("promotion_applied", cookieConfig);
    cookie.remove("promotion_code", cookieConfig);
    cookie.remove("promotion_delivery_charge_applied", cookieConfig);
    cookie.remove("promotion_amount", cookieConfig);
    cookie.remove("promotion_category", cookieConfig);
    cookie.remove("prmo_type", cookieConfig);

    /*Remove voucher*/
    cookie.remove("voucher_applied", cookieConfig);
    cookie.remove("voucher_code", cookieConfig);
    cookie.remove("voucher_amount", cookieConfig);

    cookie.remove("points_redeemed", cookieConfig);
    cookie.remove("points_used", cookieConfig);
    cookie.remove("points_amount", cookieConfig);
    cookie.remove("prmo_type", cookieConfig);
  }

  /* find Zone*/
  findOutletBasedZone(first, availability) {
    if (first) {
      var postalcode = $("#postalcode").val();
    } else {
      var postalcode = $("#postalcode1").val();
    }

    /*  var outletIdTxt = this.state.delivery_outlet_id;

  if (outletIdTxt === "") {
    $(".postal_error").html(
      '<span class="error">Go Back and Select your delivery outlet.</span>'
    );
    return false;
  } */

    /*  if (postalcode.length < 5) {
      $(".postal_error").html(
        '<span class="error">Please enter valid postal code.</span>'
      );
      return false;
    } */

    showLoader("delivery_submit_cls", "class");

    axios
      .all([
        axios.get(
          apiUrlV2 +
            "outlets/findOutletZone?app_id=" +
            appId +
            "&skip_timing=Yes&availability_id=" +
            availability +
            "&postalcode_basedoutlet=yes&address_based=yes&latitude=" +
            this.state.deliveryLat +
            "&longitude=" +
            this.state.deliveryLon,
          headerconfig
        ),
      ])
      .then(
        axios.spread((res, timeslt) => {
          var deliveryInfo = [];
          /* Success response */
          if (res.data.status === "ok") {
            cookie.save("outletchosen", availability, cookieConfig);

            var additionalTatTime =
              res.data.result_set.zone_additional_tat_time !== ""
                ? res.data.result_set.zone_additional_tat_time
                : 0;
            var outletDeliveryTiming =
              res.data.result_set.outlet_delivery_timing !== ""
                ? res.data.result_set.outlet_delivery_timing
                : 0;
            var outletDeliveryTaT =
              parseInt(outletDeliveryTiming) + parseInt(additionalTatTime);

            var orderDeliveryAddress = this.state.deliveryAddress;
            var orderHandled =
              stripslashes(res.data.result_set.outlet_name) +
              ", Crew will be seeing you in " +
              outletDeliveryTaT +
              " Minutes";

            deliveryInfo["orderZoneId"] = res.data.result_set.zone_id;
            deliveryInfo["orderOutletId"] = res.data.result_set.outlet_id;
            deliveryInfo["orderOutletName"] = stripslashes(
              res.data.result_set.outlet_name
            );
            deliveryInfo["orderPostalCode"] = "";
            deliveryInfo["orderTAT"] = outletDeliveryTaT;
            deliveryInfo["orderDeliveryAddress"] = orderDeliveryAddress;
            deliveryInfo["orderHandled"] = orderHandled;
            deliveryInfo["defaultAvilablityId"] = availability;

            var unitNum = this.showUnitNum(
              res.data.result_set.outlet_unit_number1,
              res.data.result_set.outlet_unit_number2
            );
            var outlet_address_line2 =
              res.data.result_set.outlet_address_line2 !== ""
                ? " " + res.data.result_set.outlet_address_line2
                : "";
            var orderHandledText =
              res.data.result_set.outlet_address_line1 +
              outlet_address_line2 +
              ", " +
              unitNum +
              " " +
              CountryTxt +
              " " +
              postalcode;

            deliveryInfo["orderHandledByText"] = orderHandledText;

            this.setState(
              {
                deliveryInfo: deliveryInfo,
                seletedAvilablityId: availability,
                seletedOutletId: res.data.result_set.outlet_id,
                order_tat_time: outletDeliveryTaT,
                orderHandled: orderHandled,
                orderDeliveryAddress: orderDeliveryAddress,
              },
              function() {
                /* if (this.state.addAddress === "Yes") { */
                var postObject = {
                  app_id: appId,
                  refrence: cookie.load("UserId"),
                  access_token:
                    cookie.load("access_token") !== "" &&
                    typeof cookie.load("access_token") !== undefined &&
                    typeof cookie.load("access_token") !== "undefined"
                      ? cookie.load("access_token")
                      : "",
                  customer_email: cookie.load("UserEmail"),
                  customer_first_name: cookie.load("UserFname"),
                  customer_last_name: cookie.load("UserLname"),
                  customer_phone: cookie.load("UserMobile"),
                  customer_phone: cookie.load("UserMobile"),
                  customer_address_line1: this.state.deliveryAddress,
                  address_latitude: this.state.deliveryLat,
                  address_longitude: this.state.deliveryLon,
                  customer_order_status: "order",
                };

                axios
                  .post(
                    apiUrl + "customer/secondary_address_add",
                    qs.stringify(postObject),
                    headerconfig
                  )
                  .then((res) => {
                    if (res.data.status === "ok") {
                      hideLoader("delivery_submit_cls", "class");
                      $.magnificPopup.close();
                      removeOrderDateTime();
                      removePromoCkValue();
                      this.setOrderOutletDateTimeDataNew();
                    } else {
                    }
                  });
                /* } else {
                  axios
                    .get(
                      apiUrlV2 +
                        "settings/chkTimeslotIsAvaiable?app_id=" +
                        appId +
                        "&availability_id=" +
                        availability +
                        "&outletId=" +
                        res.data.result_set.outlet_id
                    )
                    .then((timeslt) => {
                      hideLoader("delivery_submit_cls", "class");
                      $.magnificPopup.close();
              
                      if (timeslt.data.status === "success") {
                        ///For Advanced Slot
                        var isAdvanced = timeslt.data.isAdvanced,
                          slotType = "0";
                        if (isAdvanced === "yes") {
                          slotType = timeslt.data.slotType;
                        }
                        this.setState({
                          getDateTimeFlg: "yes",
                          isAdvanced: isAdvanced,
                          slotType: slotType,
                        });
                        removeOrderDateTime();
                        removePromoCkValue();

                        $.magnificPopup.open({
                          items: {
                            src: "#awesome-popup",
                          },
                          type: "inline",
                        });
                      } else {
                        $.magnificPopup.open({
                          items: {
                            src: "#outlet-error-popup",
                          },
                          type: "inline",
                        });
                      }
                    });
                } */
              }
            );
          }

          /* Error response */
          if (res.data.status === "error") {
            this.setState({ deliveryInfo: deliveryInfo });
            hideLoader("delivery_submit_cls", "class");
            $.magnificPopup.close();
            $.magnificPopup.open({
              items: {
                src: "#error-postal-popup",
              },
              type: "inline",
            });

            if (first === 0) {
              var mgsTxt =
                res.data.message !== ""
                  ? res.data.message
                  : language.enteryourpostal;
              $(".postal_error").html(
                '<span class="error">' + mgsTxt + "</span>"
              );
            }
          }
        })
      );
  }

  setOrderOutletDateTimeDataNew() {
    /* var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;
    if (
      seletedOrdDate !== "" &&
      seletedOrdTime !== "" &&
      seletedOrdDate !== null &&
      seletedOrdTime !== null
    ) { */
    var orderInfoData = this.state.deliveryInfo;
    if (Object.keys(orderInfoData).length > 0) {
      /* var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
       
        var OrderHours = seletedOrdTime.getHours();
        var OrderMunts = seletedOrdTime.getMinutes();
        var OrderSecnd = seletedOrdTime.getSeconds();
        var orderDateTime = new Date(OrderDate);
        orderDateTime.setHours(OrderHours);
        orderDateTime.setMinutes(OrderMunts);
        orderDateTime.setSeconds(OrderSecnd);

        var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
        var deliveryTime =
          this.convPad(OrderHours) +
          ":" +
          this.convPad(OrderMunts) +
          ":" +
          this.convPad(OrderSecnd);
        cookie.save("orderDateTime", orderDateTime, cookieConfig);
        cookie.save("deliveryDate", deliveryDate, cookieConfig);
        cookie.save("deliveryTime", deliveryTime, cookieConfig); */

      /* For Advanced Slot */
      var isAdvanced = this.state.isAdvanced;
      var slotType = this.state.slotType;
      var orderSlotVal = "",
        orderSlotTxt = "",
        orderSlotStrTime = "",
        orderSlotEndTime = "";
      if (slotType === "2") {
        orderSlotVal = this.state.seleted_ord_slot;
        orderSlotTxt = this.state.seleted_ord_slotTxt;
        orderSlotStrTime = this.state.seleted_ord_slot_str;
        orderSlotEndTime = this.state.seleted_ord_slot_end;
      }
      cookie.save("isAdvanced", isAdvanced, cookieConfig);
      cookie.save("slotType", slotType, cookieConfig);
      cookie.save("orderSlotVal", orderSlotVal, cookieConfig);
      cookie.save("orderSlotTxt", orderSlotTxt, cookieConfig);
      /* cookie.save("orderSlotStrTime", orderSlotStrTime, cookieConfig);
        cookie.save("orderSlotEndTime", orderSlotEndTime, cookieConfig); */
      /* For Advanced Slot End */

      if (this.state.seletedAvilablityId === deliveryId) {
        cookie.save("orderZoneId", orderInfoData["orderZoneId"], cookieConfig);
        cookie.save(
          "orderDeliveryAddress",
          orderInfoData["orderDeliveryAddress"],
          cookieConfig
        );
        cookie.save(
          "latitude",
          orderInfoData["address_latitude"],
          cookieConfig
        );
        cookie.save(
          "longitude",
          orderInfoData["address_longitude"],
          cookieConfig
        );
      }

      cookie.save(
        "orderOutletId",
        orderInfoData["orderOutletId"],
        cookieConfig
      );
      cookie.save(
        "orderOutletName",
        orderInfoData["orderOutletName"],
        cookieConfig
      );
      cookie.save(
        "orderPostalCode",
        orderInfoData["orderPostalCode"],
        cookieConfig
      );
      cookie.save("orderTAT", orderInfoData["orderTAT"], cookieConfig);
      cookie.save("orderHandled", orderInfoData["orderHandled"], cookieConfig);
      cookie.save(
        "defaultAvilablityId",
        orderInfoData["defaultAvilablityId"],
        cookieConfig
      );
      cookie.save(
        "orderHandledByText",
        orderInfoData["orderHandledByText"],
        cookieConfig
      );
      cookie.save(
        "outletchosen",
        orderInfoData["defaultAvilablityId"],
        cookieConfig
      );

      this.props.history.push("/refpage/gocheckout");
    } else {
      $.magnificPopup.open({
        items: {
          src: "#outlet-error-popup",
        },
        type: "inline",
      });
    }
    /* } else {
      $(".ordrdatetime_error").html(
        '<span class="error"> Please select order date and time.</span>'
      );
    } */
  }

  /* Select outlet */
  selectOutlet(first, availability) {
    if (first) {
      var postalcode = $("#postalcode").val();
    } else {
      var postalcode = $("#postalcode1").val();
    }

    if (postalcode.length < 5) {
      $(".postal_error").html(
        '<span class="error">' + language.enteryourpostal + "</span>"
      );
      return false;
    }

    showLoader("delivery_submit_cls", "class");

    axios
      .get(
        apiUrl +
          "outlets/find_outlet?skip_timing=Yes&app_id=" +
          appId +
          "&availability_id=" +
          availability +
          "&postal_code=" +
          postalcode,
        headerconfig
      )
      .then((res) => {
        hideLoader("delivery_submit_cls", "class");

        /* Success response */
        if (res.data.status === "ok") {
          $.magnificPopup.close();
          cookie.save("outletchosen", availability, cookieConfig);
          var zip_sname =
            res.data.result_set.postal_code_information.zip_sname !== ""
              ? " " + res.data.result_set.postal_code_information.zip_sname
              : "";
          var orderDeliveryAddress =
            res.data.result_set.postal_code_information.zip_buno + zip_sname;
          var orderHandled =
            stripslashes(res.data.result_set.outlet_name) +
            ", Crew will be seeing you in " +
            res.data.result_set.outlet_delivery_timing +
            " Minutes";
          this.setState({
            orderDeliveryAddress:
              orderDeliveryAddress +
              " " +
              CountryTxt +
              " " +
              res.data.result_set.postal_code_information.zip_code,
          });
          this.setState({ orderHandled: orderHandled });
          cookie.save(
            "orderOutletId",
            res.data.result_set.outlet_id,
            cookieConfig
          );
          cookie.save(
            "orderOutletName",
            stripslashes(res.data.result_set.outlet_name),
            cookieConfig
          );
          cookie.save(
            "orderPostalCode",
            res.data.result_set.postal_code_information.zip_code,
            cookieConfig
          );
          cookie.save(
            "orderTAT",
            res.data.result_set.outlet_delivery_timing,
            cookieConfig
          );
          cookie.save(
            "orderDeliveryAddress",
            orderDeliveryAddress,
            cookieConfig
          );
          cookie.save("orderHandled", orderHandled, cookieConfig);
          cookie.save("defaultAvilablityId", availability, cookieConfig);
          var outlet_address_line2 =
            res.data.result_set.outlet_address_line2 !== ""
              ? " " + res.data.result_set.outlet_address_line2
              : "";
          var orderHandledText =
            res.data.result_set.outlet_address_line1 +
            outlet_address_line2 +
            " " +
            CountryTxt +
            " " +
            postalcode;
          cookie.save("orderHandledByText", orderHandledText, cookieConfig);

          removeOrderDateTime();
          removePromoCkValue();

          $.magnificPopup.open({
            items: {
              src: "#awesome-popup",
            },
            type: "inline",
          });
        }

        /* Error response */
        if (res.data.status === "error") {
          $.magnificPopup.close();
          $.magnificPopup.open({
            items: {
              src: "#error-postal-popup",
            },
            type: "inline",
          });

          if (first === 0) {
            var mgsTxt =
              res.data.message !== ""
                ? res.data.message
                : language.enteryourpostal;
            $(".postal_error").html(
              '<span class="error">' + mgsTxt + "</span>"
            );
          }
        }
      });
  }

  gotoProducts() {
    if (
      cookie.load("orderOutletId") == undefined ||
      cookie.load("orderOutletId") == ""
    ) {
      $(".outlet_error").html(
        '<span class="error">' + language.selectoneoutlet + "</span>"
      );
    } else {
      cookie.save(
        "outletchosen",
        cookie.load("defaultAvilablityId"),
        cookieConfig
      );
      $.magnificPopup.close();
      if (cookie.load("popuptriggerFrom") === "FeaturedPro") {
        cookie.remove("popuptriggerFrom", cookieConfig);
        this.props.history.push("/");
      } else {
        this.props.history.push("/products");
      }
    }
  }

  selectDatetm() {
    var seletedOutletId = this.state.seletedOutletId;
    var pickupInfo = this.state.pickupInfo;
    if (seletedOutletId !== "" && Object.keys(pickupInfo).length > 0) {
      showLoader("takeaway-btn-act", "class");

      axios
        .get(
          apiUrlV2 +
            "settings/chkTimeslotIsAvaiable?app_id=" +
            appId +
            "&availability_id=" +
            pickupId +
            "&outletId=" +
            seletedOutletId,
          headerconfig
        )
        .then((res) => {
          hideLoader("takeaway-btn-act", "class");

          /* Success response */
          if (res.data.status === "success") {
            /* For Advanced Slot */
            var isAdvanced = res.data.isAdvanced,
              slotType = "0";
            if (isAdvanced === "yes") {
              slotType = res.data.slotType;
            }
            this.setState({
              getDateTimeFlg: "yes",
              isAdvanced: isAdvanced,
              slotType: slotType,
            });

            removeOrderDateTime();
            removePromoCkValue();

            $.magnificPopup.open({
              items: {
                src: "#awesome-popup",
              },
              type: "inline",
            });
          } else {
            $.magnificPopup.open({
              items: {
                src: "#outlet-error-popup",
              },
              type: "inline",
            });
          }
        });
    } else {
      $(".outlet_error").html(
        '<span class="error">' + language.selectoneoutlet + "</span>"
      );
    }
  }

  selectDlyOutlet() {
    if (this.state.delivery_outlet_id === "") {
      $(".delivery_outletpoup")
        .find(".outlet_error")
        .html('<span class="error">' + language.selectoneoutlet + "</span>");
    } else {
      $.magnificPopup.close();
      $.magnificPopup.open({
        items: {
          src: "#delevery-postcode-popup",
        },
        type: "inline",
      });
    }
  }

  handleKeyPress = (event) => {
    var value = event.target.value.toLowerCase(),
      matches = this.state.pickupOutletsList.filter(function(item) {
        if (
          stripslashes(item.outlet_name)
            .toLowerCase()
            .indexOf(value) >= 0 ||
          item.outlet_address_line1.toLowerCase().indexOf(value) >= 0 ||
          item.outlet_postal_code.toLowerCase().indexOf(value) >= 0
        ) {
          return item;
        }
      });

    this.setState({ pickupOutlets: matches });
  };

  /* load outlets  */
  loadOutletsList() {
    if (Object.keys(this.state.pickupOutlets).length > 0) {
      return this.state.pickupOutlets.map((loaddata, index) => (
        <li key={index} className={this.activeOutlet(loaddata.outlet_id)}>
          <a href="/" onClick={this.pickOutlet.bind(this, loaddata)}>
            {stripslashes(loaddata.outlet_name)},{" "}
            {loaddata.outlet_address_line1},{" "}
            {this.showUnitNum(
              loaddata.outlet_unit_number1,
              loaddata.outlet_unit_number2
            )}
            , {CountryTxt} {loaddata.outlet_postal_code}
          </a>
        </li>
      ));
    } else {
      return (
        <li>
          <a>{language.nooutlet}</a>
        </li>
      );
    }
  }

  activeOutlet(outletID) {
    var seletedOutletId = this.state.seletedOutletId;
    var pickupInfo = this.state.pickupInfo;
    var actTxt =
      parseInt(seletedOutletId) === parseInt(outletID) &&
      Object.keys(pickupInfo).length > 0
        ? "active"
        : "";
    return actTxt;
  }

  handleKeyPressDly = (event) => {
    var value = event.target.value.toLowerCase(),
      matches = this.state.deliveryOutletsList.filter(function(item) {
        if (
          stripslashes(item.outlet_name)
            .toLowerCase()
            .indexOf(value) >= 0 ||
          item.outlet_address_line1.toLowerCase().indexOf(value) >= 0 ||
          item.outlet_postal_code.toLowerCase().indexOf(value) >= 0
        ) {
          return item;
        }
      });

    this.setState({ deliveryOutlets: matches });
  };

  showUnitNum(unit1, unit2) {
    unit1 = typeof unit1 !== "undefined" ? unit1 : "";
    unit2 = typeof unit2 !== "undefined" ? unit2 : "";

    if (unit1 !== "") {
      var unitNo = unit2 !== "" ? unit1 + " - " + unit2 : unit1;
    } else {
      var unitNo = unit2;
    }

    return unitNo !== "" ? "#" + unitNo : "";
  }

  /* load delivery outlets  */
  loadDeliveryOutletsList() {
    if (Object.keys(this.state.deliveryOutlets).length > 0) {
      return this.state.deliveryOutlets.map((loaddata, index) => (
        <li key={index} className={this.activeDlyOutlet(loaddata.outlet_id)}>
          <a href="/" onClick={this.deliveryOutlet.bind(this, loaddata)}>
            {stripslashes(loaddata.outlet_name)},{" "}
            {loaddata.outlet_address_line1},{" "}
            {this.showUnitNum(
              loaddata.outlet_unit_number1,
              loaddata.outlet_unit_number2
            )}
            , {CountryTxt} {loaddata.outlet_postal_code}
          </a>
        </li>
      ));
    } else {
      return (
        <li>
          <a>{language.nooutlet}</a>
        </li>
      );
    }
  }

  activeDlyOutlet(outletID) {
    var orderOutletId =
      this.state.delivery_outlet_id !== ""
        ? this.state.delivery_outlet_id
        : cookie.load("orderOutletId");

    return orderOutletId === outletID ? "active" : "";
  }

  /* pick outlet */
  pickOutlet(loaddata, event) {
    if (event !== undefined) {
      event.preventDefault();
    }

    var unitNum = this.showUnitNum(
      loaddata.outlet_unit_number1,
      loaddata.outlet_unit_number2
    );
    var outlet_address_line2 =
      loaddata.outlet_address_line2 !== ""
        ? " " + loaddata.outlet_address_line2
        : "";
    var orderHandled =
      stripslashes(loaddata.outlet_name) +
      " " +
      loaddata.outlet_address_line1 +
      outlet_address_line2 +
      ", " +
      unitNum +
      " " +
      CountryTxt +
      " " +
      loaddata.outlet_postal_code;

    var pickupInfo = [];
    pickupInfo["orderOutletId"] = loaddata.outlet_id;
    pickupInfo["orderOutletName"] = stripslashes(loaddata.outlet_name);
    pickupInfo["orderPostalCode"] = loaddata.outlet_postal_code;
    pickupInfo["orderTAT"] = loaddata.outlet_pickup_tat;
    pickupInfo["orderHandled"] = orderHandled;
    pickupInfo["orderHandledByText"] = orderHandled;
    pickupInfo["defaultAvilablityId"] = pickupId;

    this.setState(
      {
        pickupInfo: pickupInfo,
        seletedAvilablityId: pickupId,
        seletedOutletId: loaddata.outlet_id,
        order_tat_time: loaddata.outlet_pickup_tat,
        orderHandled: orderHandled,
      },
      function() {
        this.selectDatetm();
      }.bind(this)
    );
  }

  deliveryOutlet(loaddata, event) {
    event.preventDefault();
    $(".delivery_outletpoup")
      .find(".outlet_error")
      .html("");
    this.setState(
      { delivery_outlet_id: loaddata.outlet_id },
      function() {
        this.selectDlyOutlet();
      }.bind(this)
    );
  }

  trgContinuBtn(idTxt) {
    $("#" + idTxt).trigger("click");
  }

  componentDidUpdate() {}
  onIdle = () => {
    this.logoutTimer = setTimeout(() => {
      if (
        typeof sessionStorage.getItem("mytime") !== "undefined" &&
        sessionStorage.getItem("mytime") !== null &&
        typeof cookie.load("UserId") !== "undefined" &&
        cookie.load("UserId") !== null
      ) {
        startIntervel++;
        if (
          moment(sessionStorage.getItem("mytime")) <
          moment(new Date(Date.now()))
        ) {
          $.magnificPopup.open({
            items: {
              src: "#session-expired-popup",
            },
            type: "inline",
            closeOnBgClick: false,
            enableEscapeKey: false,
            showCloseBtn: false,
          });
        }
        if (startIntervel >= 360) {
          $.magnificPopup.close();
          //this.props.history.push("/logout");
        }
        this.onIdle();
      }
    }, 1000 * 5 * 1); // 5 seconds
  };

  getSearchProductList() {
    var orderOutletIdtext = cookie.load("orderOutletId");
    var addquery_txt = "";
    if (typeof orderOutletIdtext !== "undefined" && orderOutletIdtext !== "") {
      addquery_txt = "&outletId=" + orderOutletIdtext;
    }
    var searchResult = [];
    return axios
      .get(
        apiUrlV2 +
          "products/search_products?app_id=" +
          appId +
          "&status=A&availability=" +
          cookie.load("defaultAvilablityId") +
          addquery_txt,
        headerconfig
      )
      .then((response) => {
        if (response.data.status === "ok") {
          var resultData = response.data.result_set;
          for (var key in resultData) {
            var subcatListArr = !("product_list" in resultData[key])
              ? Array()
              : resultData[key].product_list;

            if (Object.keys(subcatListArr).length > 0) {
              if (Object.keys(subcatListArr[0]).length > 0) {
                var subCatArr = !("subcategorie" in subcatListArr[0][0])
                  ? Array()
                  : subcatListArr[0][0].subcategorie;

                for (var sctkey in subCatArr) {
                  var productsArr = !("products" in subCatArr[sctkey])
                    ? Array()
                    : subCatArr[sctkey].products;
                  for (var prokey in productsArr) {
                    var proNameTxt = getAliasName(
                      productsArr[prokey].product_alias,
                      productsArr[prokey].product_name
                    );
                    searchResult.push({
                      cate_slug: subCatArr[sctkey].pro_cate_slug,
                      subcate_slug: subCatArr[sctkey].pro_subcate_slug,
                      value: productsArr[prokey].product_slug,
                      label: stripslashes(proNameTxt),
                      product_brand_tag: productsArr[prokey].product_brand_tag,
                      product_tag: productsArr[prokey].product_tag,
                    });
                  }
                }
              }
            }
          }
        }
        if (
          this.props.match.path === "/products/:slugType/:slugValue" ||
          this.props.match.path === "/products"
        ) {
          this.props.sateValChange("productlist", searchResult);
        }
        this.setState({
          searchProResult: searchResult,
          selectedProResult: searchResult,
        });
      });
  }

  searchProKeyPress = (event) => {
    $(".search_result").show();
    var currentThis = this;
    var value = event.target.value.toLowerCase(),
      matches = this.state.searchProResult.filter(function(item) {
        var checkBrands = currentThis.checkBrands(
          item.product_brand_tag,
          value
        );
        var checkTag = currentThis.checkTag(item.product_tag, value);
        if (item.label.toLowerCase().indexOf(value) >= 0) {
          return item;
        } else if (checkBrands === 1) {
          return item;
        } else if (checkTag === 1) {
          return item;
        }
      });

    $("#clearSearch").show();
    if (value === "") {
      $("#clearSearch").hide();
    }

    this.setState({ selectedProResult: matches });
  };

  checkBrands(brand, value) {
    var result = 0;
    if (brand.length > 0) {
      brand.map((item) => {
        if (item.pro_brand_tag_name.toLowerCase().indexOf(value) >= 0) {
          result = 1;
        }
      });
    }
    return result;
  }
  checkTag(checkTag, value) {
    var result = 0;
    if (checkTag.length > 0) {
      checkTag.map((item) => {
        if (item.pro_tag_name.toLowerCase().indexOf(value) >= 0) {
          result = 1;
        }
      });
    }
    return result;
  }

  /* load product search result  */
  loadProSearchList() {
    if (Object.keys(this.state.selectedProResult).length > 0) {
      if ($("#search_result_mbl").length > 0) {
        $("#search_result_mbl").show();
      }

      return this.state.selectedProResult.map((loaddata, index) => (
        <li key={index}>
          <Link
            to={
              "/products/" +
              loaddata.cate_slug +
              "/" +
              loaddata.subcate_slug +
              "/" +
              loaddata.value
            }
            title="Product Details"
          >
            {loaddata.label}
          </Link>
        </li>
      ));
    } else {
      if ($("#search_result_mbl").length > 0) {
        $("#search_result_mbl").show();
      }
      return <li className="no-pro-found">{language.noproduct}</li>;
    }
  }

  clearSearchFun() {
    var emtytxt = "";
    $(".productsearch").val(emtytxt);
    this.setState({ selectedProResult: this.state.searchProResult });
  }

  ViewProducts(event) {
    var productSlug = event.value;
    this.props.history.push("/products/cat-ftrpro/slug-ftrpro/" + productSlug);
  }

  checkActiveDivHd(avlType) {
    var clsTxt = "ordericon_link ";
    var availability = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      availability == avlType &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      clsTxt += "active";
    }
    return clsTxt;
  }

  checkActiveDivMbl(avlType) {
    var clsTxt = "";
    var availability = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      availability == avlType &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      clsTxt += "active";
    }
    return clsTxt;
  }

  myAccountAction() {
    var currenturl = window.location.href;
    var substringtxt = "myaccount";

    if (currenturl.includes(substringtxt) !== true) {
      return (
        <div>
          <Link
            to={"/myaccount"}
            className="myacunt_act"
            title={language.myaccount}
          >
            {" "}
            {language.myaccount}{" "}
          </Link>
          <span className="mobile_mainacc_menutrigger"></span>
        </div>
      );
    } else {
      return (
        <div>
          <a className="myacunt_act disbl_href_action" href="/">
            {" "}
            {language.myaccount}{" "}
          </a>
          <span className="mobile_mainacc_menutrigger"></span>
        </div>
      );
    }
  }

  changPostalValue(type, pstVl) {
    if (type === 1) {
      $("#postalcode").val(pstVl);
    } else {
      $("#postalcode1").val(pstVl);
    }
  }

  userAddressList(typeTxt) {
    if (this.state.secondaryaddresslist.length > 0) {
      var addListTxt = this.state.secondaryaddresslist.map((addr, index) => (
        <div className="address_linfo" key={typeTxt + "-" + index}>
          <div className="custom_radio">
            <input
              type="radio"
              name={"address_chk" + typeTxt}
              value={addr.postal_code}
              className="address_chk"
              onChange={this.changPostalValue.bind(
                this,
                typeTxt,
                addr.postal_code
              )}
            />
            <span>
              {addressFormat(
                addr.unit_code,
                addr.unit_code2,
                addr.address,
                addr.country,
                addr.postal_code
              )}
            </span>
          </div>
        </div>
      ));

      return (
        <div>
          {addListTxt}
          <div className="address_linfo" key={typeTxt + "dfl"}>
            <div className="custom_radio">
              <input
                type="radio"
                name={"address_chk" + typeTxt}
                value=""
                defaultChecked={true}
                className="address_chk"
                onChange={this.changPostalValue.bind(this, typeTxt, "")}
              />
              <span>{language.enternewaddress}</span>
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  /* For Advanced Slot */
  setdateTimeFlg = (field, value) => {
    if (field == "tmflg") {
      this.setState({ getDateTimeFlg: value });
    } else if (field == "ordDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordTime") {
      var tmSltArr = value;
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotTime") {
      var tmSltArr = value;
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: tmSltArr["ordSlotVal"],
        seleted_ord_slotTxt: tmSltArr["ordSlotLbl"],
        seleted_ord_slot_str: tmSltArr["ordSlotStr"],
        seleted_ord_slot_end: tmSltArr["ordSlotEnd"],
      });
    } else if (field == "triggerErrorPopup") {
      $.magnificPopup.open({
        items: {
          src: "#outlet-error-popup",
        },
        type: "inline",
      });
    }
  };

  setOrderOutletDateTimeData() {
    var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;
    if (
      seletedOrdDate !== "" &&
      seletedOrdTime !== "" &&
      seletedOrdDate !== null &&
      seletedOrdTime !== null
    ) {
      var orderInfoData =
        this.state.seletedAvilablityId === pickupId
          ? this.state.pickupInfo
          : this.state.deliveryInfo;
      if (Object.keys(orderInfoData).length > 0) {
        var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
        /* For Advanced Slot */
        var OrderHours = seletedOrdTime.getHours();
        var OrderMunts = seletedOrdTime.getMinutes();
        var OrderSecnd = seletedOrdTime.getSeconds();
        var orderDateTime = new Date(OrderDate);
        orderDateTime.setHours(OrderHours);
        orderDateTime.setMinutes(OrderMunts);
        orderDateTime.setSeconds(OrderSecnd);

        var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
        var deliveryTime =
          this.convPad(OrderHours) +
          ":" +
          this.convPad(OrderMunts) +
          ":" +
          this.convPad(OrderSecnd);
        cookie.save("orderDateTime", orderDateTime, cookieConfig);
        cookie.save("deliveryDate", deliveryDate, cookieConfig);
        cookie.save("deliveryTime", deliveryTime, cookieConfig);

        /* For Advanced Slot */
        var isAdvanced = this.state.isAdvanced;
        var slotType = this.state.slotType;
        var orderSlotVal = "",
          orderSlotTxt = "",
          orderSlotStrTime = "",
          orderSlotEndTime = "";
        if (slotType === "2") {
          orderSlotVal = this.state.seleted_ord_slot;
          orderSlotTxt = this.state.seleted_ord_slotTxt;
          orderSlotStrTime = this.state.seleted_ord_slot_str;
          orderSlotEndTime = this.state.seleted_ord_slot_end;
        }
        cookie.save("isAdvanced", isAdvanced, cookieConfig);
        cookie.save("slotType", slotType, cookieConfig);
        cookie.save("orderSlotVal", orderSlotVal, cookieConfig);
        cookie.save("orderSlotTxt", orderSlotTxt, cookieConfig);
        cookie.save("orderSlotStrTime", orderSlotStrTime, cookieConfig);
        cookie.save("orderSlotEndTime", orderSlotEndTime, cookieConfig);
        /* For Advanced Slot End */

        if (this.state.seletedAvilablityId === deliveryId) {
          cookie.save(
            "orderZoneId",
            orderInfoData["orderZoneId"],
            cookieConfig
          );
          cookie.save(
            "orderDeliveryAddress",
            orderInfoData["orderDeliveryAddress"],
            cookieConfig
          );
        }

        cookie.save(
          "orderOutletId",
          orderInfoData["orderOutletId"],
          cookieConfig
        );
        cookie.save(
          "orderOutletName",
          orderInfoData["orderOutletName"],
          cookieConfig
        );
        cookie.save(
          "orderPostalCode",
          orderInfoData["orderPostalCode"],
          cookieConfig
        );
        cookie.save("orderTAT", orderInfoData["orderTAT"], cookieConfig);
        cookie.save(
          "orderHandled",
          orderInfoData["orderHandled"],
          cookieConfig
        );
        cookie.save(
          "defaultAvilablityId",
          orderInfoData["defaultAvilablityId"],
          cookieConfig
        );
        cookie.save(
          "orderHandledByText",
          orderInfoData["orderHandledByText"],
          cookieConfig
        );
        cookie.save(
          "outletchosen",
          orderInfoData["defaultAvilablityId"],
          cookieConfig
        );

        $.magnificPopup.close();

        if (cookie.load("popuptriggerFrom") === "FeaturedPro") {
          cookie.remove("popuptriggerFrom", cookieConfig);
          this.props.history.push("/");
        } else if (cookie.load("redirectfavourite") === "Yes") {
          cookie.remove("redirectfavourite", cookieConfig);
          this.props.history.push("/favourite");
        } else {
          this.props.history.push("/products");
        }
      } else {
        $.magnificPopup.open({
          items: {
            src: "#outlet-error-popup",
          },
          type: "inline",
        });
      }
    } else {
      $(".ordrdatetime_error").html(
        '<span class="error">' + language.selectdatetime + "</span>"
      );
    }
  }

  convPad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  searBlkFun(typeTxt) {
    var defaultAvilTyId = cookie.load("defaultAvilablityId");
    var liTopCls = "htico_search";
    var scearDivTopCls = "hsearch_sec";
    var triggerActCls = "hsearch_trigger";
    var searchResultCls = "search_result";
    var searchIconcls = "search_i_icon";
    if (typeTxt === "mbl") {
      liTopCls = "htico_search_mbl";
      scearDivTopCls = "hsearch_sec_mbl";
      triggerActCls = "hsearch_trigger_mbl";
      searchResultCls = "search_result_mbl";
      searchIconcls = "fa fa-search";
    }

    return (
      <li className={liTopCls}>
        <a
          href="#"
          /* data-effect="mfp-zoom-in" */
          className={triggerActCls}
          title="Search"
        >
          <img src={loupe} alt="" />
        </a>
        <div className={scearDivTopCls}>
          <div className="input-sec">
            <input
              type="text"
              name="searchkey"
              className="productsearch"
              id={"productsearch_" + typeTxt}
              placeholder={language.search}
              autoComplete="off"
              onKeyUp={this.searchProKeyPress}
            />

            <a
              href="/"
              id="clearSearch"
              onClick={this.clearSearchFun.bind(this)}
              className="search_text_clear disbl_href_action"
            >
              X
            </a>
          </div>

          <ul className={searchResultCls} id={searchResultCls}>
            {this.loadProSearchList()}
          </ul>
        </div>
      </li>
    );
  }
  sateValChange = (field, value) => {
    if (field === "address") {
      if (value !== "") {
        var addressdetails = value.split("~~");
        this.setState({
          deliveryAddress: addressdetails[0],
          deliveryLat: addressdetails[1],
          deliveryLon: addressdetails[2],
        });
      }
    }
    if (field === "addAddress") {
      this.setState({ addAddress: value });
    }
  };
  loadMetaTags() {
    if (this.props.match.path === "/products/:slugType/:slugValue/:proValue") {
      return (
        <Helmet>
          {(() => {
            if (
              this.props.productmeta.meta_title !== "" &&
              this.props.productmeta.meta_title !== null
            ) {
              return (
                <meta
                  name="title"
                  content={this.props.productmeta.meta_title}
                />
              );
            } else if (
              this.state.globalsettings.client_meta_title !== "" &&
              this.state.globalsettings.client_meta_title !== null
            ) {
              return (
                <meta
                  name="title"
                  content={this.state.globalsettings.client_meta_title}
                />
              );
            }
          })()}

          {(() => {
            if (
              this.props.productmeta.meta_title !== "" &&
              this.props.productmeta.meta_title !== null
            ) {
              return <title>{this.props.productmeta.meta_title}</title>;
            } else if (
              this.state.globalsettings.client_meta_title !== "" &&
              this.state.globalsettings.client_meta_title !== null
            ) {
              return (
                <title>{this.state.globalsettings.client_meta_title}</title>
              );
            }
          })()}

          {(() => {
            if (
              this.props.productmeta.meta_title !== "" &&
              this.props.productmeta.meta_title !== null
            ) {
              return (
                <meta
                  name="og:title"
                  content={this.props.productmeta.meta_title}
                />
              );
            } else if (
              this.state.globalsettings.client_meta_title !== "" &&
              this.state.globalsettings.client_meta_title !== null
            ) {
              return (
                <meta
                  name="og:title"
                  content={this.state.globalsettings.client_meta_title}
                />
              );
            }
          })()}

          {(() => {
            if (
              this.props.productmeta.meta_description !== "" &&
              this.props.productmeta.meta_description !== null
            ) {
              return (
                <meta
                  name="description"
                  content={this.props.productmeta.meta_description}
                />
              );
            } else if (
              this.state.globalsettings.client_meta_description !== "" &&
              this.state.globalsettings.client_meta_description !== null
            ) {
              return (
                <meta
                  name="description"
                  content={this.state.globalsettings.client_meta_description}
                />
              );
            }
          })()}
          {(() => {
            if (
              this.props.productmeta.meta_description !== "" &&
              this.props.productmeta.meta_description !== null
            ) {
              return (
                <meta
                  name="og:description"
                  content={this.props.productmeta.meta_description}
                />
              );
            } else if (
              this.state.globalsettings.client_meta_description !== "" &&
              this.state.globalsettings.client_meta_description !== null
            ) {
              return (
                <meta
                  name="og:description"
                  content={this.state.globalsettings.client_meta_description}
                />
              );
            }
          })()}

          {(() => {
            if (
              this.props.productmeta.meta_keywords !== "" &&
              this.props.productmeta.meta_keywords !== null
            ) {
              return (
                <meta
                  name="keywords"
                  content={this.props.productmeta.meta_keywords}
                />
              );
            } else if (
              this.state.globalsettings.client_meta_keyword !== "" &&
              this.state.globalsettings.client_meta_keyword !== null
            ) {
              return (
                <meta
                  name="keywords"
                  content={this.state.globalsettings.client_meta_keyword}
                />
              );
            }
          })()}
        </Helmet>
      );
    } else {
      return (
        <Helmet>
          {this.state.globalsettings.site_title !== "" &&
            this.state.globalsettings.site_title !== null && (
              <title>{this.state.globalsettings.site_title}</title>
            )}

          {this.state.globalsettings.client_meta_title !== "" &&
            this.state.globalsettings.client_meta_title !== null && (
              <meta
                name="title"
                content={this.state.globalsettings.client_meta_title}
              />
            )}
          {this.state.globalsettings.client_meta_description !== "" &&
            this.state.globalsettings.client_meta_description !== null && (
              <meta
                name="description"
                content={this.state.globalsettings.client_meta_description}
              />
            )}
          {this.state.globalsettings.client_meta_keyword !== "" &&
            this.state.globalsettings.client_meta_keyword !== null && (
              <meta
                name="keywords"
                content={this.state.globalsettings.client_meta_keyword}
              />
            )}

          {this.state.globalsettings.client_meta_title !== "" &&
            this.state.globalsettings.client_meta_title !== null && (
              <meta
                name="og:title"
                content={this.state.globalsettings.client_meta_title}
              />
            )}
          {this.state.globalsettings.client_meta_description !== "" &&
            this.state.globalsettings.client_meta_description !== null && (
              <meta
                name="og:description"
                content={this.state.globalsettings.client_meta_description}
              />
            )}
        </Helmet>
      );
    }
  }
  render() {
    var currenturl = window.location.href;
    var substring = "products";
    var isCheckout = "checkout";

    var showCatryName =
      typeof this.props.showCatryName !== "undefined"
        ? this.props.showCatryName
        : "Category Name";

    /* For Advanced Slot Start */
    var defaultAvilTyId = cookie.load("defaultAvilablityId");
    var settingsArr = this.props.globalsettings;
    var advancedTimeslotEnable = "0";
    if (Object.keys(settingsArr).length > 0) {
      if (Object.keys(settingsArr[0].result_set).length > 0) {
        advancedTimeslotEnable =
          settingsArr[0].result_set.client_advanced_timeslot_enable;
      }
    }
    return (
      <header>
        {this.loadMetaTags()}
        <div className="header-top-cls">
          <div className="container">
            <div className="logo-main-section">
              <div className="menu_icon trigger_menunav_act">
                <span className="icon-bar icon-bar1"></span>
                <span className="icon-bar icon-bar2"></span>
                <span className="icon-bar icon-bar3"></span>
              </div>
              <div className="mobile-menu">
                <div className="mobile-menu-header">
                  <div className="mobile-menu-close">
                    <span id="close_mobile_menu"></span>
                  </div>
                  <div className="mobile-menu-logo">
                    <Link to={"/"} title={projectTitle}>
                      <img src={mainLogo} alt="Logo" />
                    </Link>
                  </div>
                </div>
                <div className="mobile-menu-body">
                  <ul className="menu">
                    {this.state.menudata && this.listMainNavigation()}
                    {!cookie.load("UserId") && (
                      <>
                        <li>
                          <a
                            href="#login-popup"
                            data-effect="mfp-zoom-in"
                            className="open-popup-link htico_sign"
                            title={language.login}
                          >
                            <span>{language.login}</span>
                          </a>
                        </li>
                        {/* <li>
                            <a
                              href="#signup-popup"
                              data-effect="mfp-zoom-in"
                              className="open-popup-link"
                              title={language.signup}
                            >
                              {" "}
                              <span>{language.signup}</span>
                            </a>
                          </li> */}
                      </>
                    )}

                    {cookie.load("UserId") && (
                      <>
                        <li>
                          <Link
                            className={
                              this.props.match.path === "/myaccount" ||
                              this.props.match.path === "/myorders" ||
                              this.props.match.path === "/rewards" ||
                              this.props.match.path === "/voucher" ||
                              this.props.match.path === "/mypromotions"
                                ? "htico_sign active"
                                : "htico_sign"
                            }
                            to="/myaccount"
                            title={language.myaccount}
                          >
                            <i /> <span>{language.myaccount}</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="htico_sign"
                            to="/logout"
                            title={language.logout}
                          >
                            <i /> <span>{language.logout}</span>
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>

              <div className="logo">
                <Link to={"/"} title={projectTitle}>
                  <img src={mainLogo} alt="Logo" />
                </Link>
              </div>

              <div className="hmenu-login-section">
                <div className="hmenu_sec">
                  <ul className="hmenu_list desktop_hmenu_list">
                    {this.state.menudata && this.listMainNavigation()}
                  </ul>
                </div>
                <ul className="hmenu-login-act">
                  {/* <li className="hordertype_sec">
                      <a
                        href="/products"
                        className="hordertype_btn"
                        title="すべての商品を⾒る"
                      >
                        すべての商品を⾒る{" "}
                        <img className="redarrow1" src={redarrow} />
                      </a>
                    </li> */}
                  {!cookie.load("UserId") && (
                    <li className="hsign_sec">
                      <a
                        href="#login-popup"
                        data-effect="mfp-zoom-in"
                        className="open-popup-link htico_sign"
                        title={language.login}
                      >
                        <span>{language.login}</span>
                      </a>
                    </li>
                  )}

                  {cookie.load("UserId") && (
                    <li className="hsign_sec">
                      <Link
                        className={
                          this.props.match.path === "/myaccount" ||
                          this.props.match.path === "/myorders" ||
                          this.props.match.path === "/rewards" ||
                          this.props.match.path === "/voucher" ||
                          this.props.match.path === "/mypromotions"
                            ? "htico_sign active"
                            : "htico_sign"
                        }
                        to="/myaccount"
                        title={language.myaccount}
                      >
                        <i /> <span>{language.myaccount}</span>
                      </Link>
                      <span>|</span>
                      <Link
                        className="htico_sign"
                        to="/logout"
                        title={language.logout}
                      >
                        <i /> <span>{language.logout}</span>
                      </Link>
                    </li>
                  )}

                  {this.searBlkFun("mbl")}

                  {this.searBlkFun("desktop")}
                  <li
                    className={
                      this.props.match.path === "/favourite"
                        ? "hcart_like active"
                        : "hcart_like"
                    }
                  >
                    <Link
                      to={"/favourite"}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!cookie.load("UserId")) {
                          window.$.magnificPopup.open({
                            items: {
                              src: "#login-popup",
                            },
                            type: "inline",
                          });
                        } else {
                          this.props.history.push("/favourite");
                        }
                      }}
                    >
                      <img
                        src={cartLike}
                        alt="Favourite"
                        title={language.favourite}
                      />
                      <img
                        src={cartLikeActive}
                        alt="Favourite"
                        title={language.favourite}
                      />
                    </Link>
                  </li>
                  <li className="htico_cart">
                    <Cartsidebar
                      {...this.props}
                      headerState={this.state}
                      prpSateValChange={this.props.sateValChange}
                      pageName="header"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* mobile_accountdel_body - start */}
        {/* this.props.match.path !== "/products" &&
            this.props.match.path !== "/products/:slugType/:slugValue" &&
            this.props.match.path !==
              "/products/:slugType/:slugValue/:proValue" && (
              <div className="mobile-order-nowbtn">
                <Link
                  className="mobile_account_menu_click"
                  to="/ttt"
                  onClick={this.closepopup.bind(this)}
                >
                  今すぐ注文 <img className="redarrow1" src={redarrow} />
                </Link>
              </div>
            ) */}

        <div
          className="custom_alertcls alert-success alert-dismissible1 alert_fixed success_hide"
          id="jquery-success-msg"
          style={{ display: "none" }}
        >
          {" "}
          <a
            href="/"
            type="button"
            className="custom_close"
            data-dismiss="alert"
            aria-label="Close"
          >
            {" "}
            <span aria-hidden="true">×</span>{" "}
          </a>{" "}
          <p className="jquery-success-msg">{language.cartaddedsuccess}</p>{" "}
        </div>

        <div
          className="custom_alertcls alert-danger single-danger alert-dismissible alert_fixed error_hide"
          id="jquery-error-msg"
          style={{ display: "none" }}
        >
          {" "}
          <a
            href="/"
            className="custom_close"
            data-dismiss="alert"
            aria-label="Close"
          >
            {" "}
            <span aria-hidden="true">×</span>{" "}
          </a>{" "}
          <p className="jquery-error-msg">{language.somthingwrong}</p>{" "}
        </div>

        <div
          className="custom_center_alertcls alert-success alert-dismissible1 alert_fixed success_hide"
          id="jquery-common-success-msg"
          style={{ display: "none" }}
        >
          {" "}
          <a
            href="/"
            type="button"
            className="custom_close"
            data-dismiss="alert"
            aria-label="Close"
          >
            {" "}
            <span aria-hidden="true">×</span>{" "}
          </a>{" "}
          <p className="jquery-common-success-msg">
            {language.cartaddedsuccess}
          </p>{" "}
        </div>

        <div
          className="custom_center_alertcls alert-danger single-danger alert-dismissible alert_fixed error_hide"
          id="jquery-common-error-msg"
          style={{ display: "none" }}
        >
          {" "}
          <a
            href="/"
            className="custom_close"
            data-dismiss="alert"
            aria-label="Close"
          >
            {" "}
            <span aria-hidden="true">×</span>{" "}
          </a>{" "}
          <p className="jquery-common-error-msg">{language.somthingwrong}</p>{" "}
        </div>

        {/* login popup */}

        <div id="login-popup" className="mfp-hide popup_sec login-popup">
          <div className="pop-whole full-login-new">
            <div className="full-login-new-header">
              <img src={footerLogo} alt="Logo" />
              <h3>{language.douhaveacc}</h3>
              <p>{language.updateinfo}</p>
            </div>
            <div className="full-login-new-body">
              <Login
                fields={this.state.fields}
                onChange={this.fieldChange}
                onValid={this.handleSignin}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>
        </div>

        {/* Signup popup */}
        <div id="signup-popup" className="mfp-hide popup_sec signup-popup">
          <div className="pop-whole full-login-new">
            <div className="full-login-new-header">
              <img src={footerLogo} alt="Logo" />
              <h3>{language.createacc}</h3>
              <p>{language.updateinfo}</p>
            </div>
            <div className="full-login-new-body">
              <div className="popup-footer signup-popup-head"></div>
              {this.state.enableOtp === false && (
                <Signup
                  fields={this.state.fieldssignup}
                  onChange={this.fieldChangeSignup}
                  onValid={this.handleSignup}
                  onInvalid={() => console.log("Form invalid!")}
                />
              )}
              {this.state.enableOtp === true && (
                <Otpverify
                  fields={this.state.fieldsotpverify}
                  onChange={this.fieldChangeOtpVerify}
                  onValid={this.handleOtpVerify}
                  onInvalid={() => console.log("Form invalid!")}
                />
              )}
            </div>
          </div>
        </div>

        {/* Forgot Password Popup */}

        <div
          id="forgot-password-popup"
          className="mfp-hide popup_sec forgot-password-popup"
        >
          <div className="pop-whole full-login-new">
            <div className="full-login-new-header">
              <img src={footerLogo} alt="Logo" />
              <h3>{language.forgothead}</h3>
              <p>{language.resetpinhere}</p>
            </div>

            <div className="full-login-new-body">
              <Forgotpassword
                fields={this.state.fieldsfgtpassword}
                onChange={this.fieldforgot}
                onValid={this.forgotpassword}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>
        </div>

        {/* Change Password */}
        <div
          id="change-password-popup"
          className="white-popup mfp-hide popup_sec changepw_popup"
        >
          <div className="pouup_in">
            <div id="form-msg"></div>
            <h3 className="title1 text-center">{language.changepin}</h3>
            <Changepassword
              fields={this.state.fieldschpassword}
              onChange={this.fieldChangepassword}
              onValid={this.changepassword}
              onInvalid={() => console.log("Form invalid!")}
            />
          </div>
        </div>

        {/* Order popup - start */}
        <div
          id="order-popup"
          className="white-popup mfp-hide popup_sec order_popup"
        >
          <div className="order-body">
            <h2>{language.ordernow}</h2>
            <p>{language.selectordertype}</p>
            <div className="order_delivery_row">
              <div className="order_delivery_col">
                <ul className="order_delivery_item">
                  <li>
                    <a
                      href="/"
                      onClick={this.chooseAvailabilityFun.bind(
                        this,
                        deliveryId
                      )}
                      className={this.checkActiveDivHd(deliveryId)}
                    >
                      <div className="header-ordernow-single-img">
                        <img className="order_type_img" src={deliveryImg} />
                        <img className="order_type_imgwt" src={deliveryWtImg} />
                        <h3>{language.delivery}</h3>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Order popup - end */}

        {/* Delivery Popup - Start */}
        <div
          id="delevery-popup"
          className="white-popup mfp-hide popup_sec delivery_outletpoup self_popup"
        >
          <div className="order-body">
            <div className="self_popup_hea_row">
              <div className="self_popup_hea_col_left">
                <img className="outlet-scooter-img" src={deliveryImg} />
              </div>
              <div className="self_popup_hea_col">
                <h2>{language.pleasecoose}</h2>
                <p>{language.yourdeliveryoutlet}</p>
              </div>
            </div>

            <div className="self_pop_row">
              <div className="self_pop_col self_pop_col_right">
                <div className="self_pop_item">
                  <div className="self_pop_locbx">
                    <div className="form-group">
                      <div className="focus-out">
                        <label>{language.selectoutlet}</label>
                        <input
                          type="input"
                          className="form-control input-focus"
                          onKeyUp={this.handleKeyPressDly}
                        />
                        <div className="outlet_error"></div>
                      </div>
                    </div>
                  </div>

                  <div className="self_outlet">
                    <h2>{language.nearoutlet}</h2>
                    <ul className="self_outlet_inner">
                      {this.loadDeliveryOutletsList()}
                    </ul>
                    <a
                      className="button disbl_href_action"
                      id="delivery-continue-link"
                      href="/"
                      onClick={this.selectDlyOutlet.bind(this)}
                    >
                      {language.continue}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Delivery Popup - end */}

        {/*  Delivery Postal code Popup - start */}
        <div
          id="delevery-postcode-popup"
          className="white-popup mfp-hide popup_sec delevery_popup"
        >
          <div className="popup_equalrw">
            <div className="popup_ttsec">
              <div className="innervmid_in">
                <div className="pop_title">
                  <img className="pop-scooter-img" src={deliveryWtImg} />
                  <h2 className="text-uppercase">{language.letmeknow}</h2>
                  <small>{language.yourdeliverylocation}</small>
                </div>
              </div>
            </div>
            <div className="popup_right">
              <div className="innervmid_in">
                {/* cookie.load("UserId") && (
                    <div className="address-list-cls address-list-main">
                      {this.userAddressList(1)}
                    </div>
                  ) */}

                <div className="form-group">
                  <div
                    className={
                      this.state.secondaryaddresslist.length > 0
                        ? "focus-out focused"
                        : "focus-out focused"
                    }
                  >
                    <label>{language.enterzipcode}</label>
                    {/*this.props.match.path !== "/myaccount" && (
                       <Autocomplete sateValChange={this.sateValChange} />
                    ) */}
                    {/* <input
                        type="text"
                        id="postalcode"
                        pattern="\d*"
                        maxLength="6"
                        className="form-control input-focus"
                      /> */}
                    <div className="postal_error"></div>
                  </div>
                </div>
                <div className="btn_sec">
                  <div className="two-button-row single-btn-row">
                    <div className="con_first delivery_submit_cls">
                      {/*<input type="button" onClick={this.selectOutlet.bind(this, 1, deliveryId)} className="button button-right delivery_submit" value="Continue" />*/}
                      <input
                        type="button"
                        onClick={this.findOutletBasedZone.bind(
                          this,
                          1,
                          deliveryId
                        )}
                        className="button button-right delivery_submit"
                        value={language.proceedaddrss}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Delevery Popup - end */}

        {/* Delevery Popup error - start */}
        <div
          id="error-postal-popup"
          className="white-popup mfp-hide popup_sec error_postal_popup"
        >
          <div className="popup_equalrw">
            <div className="popup_ttsec">
              <div className="innervmid_in">
                <div className="pop_title poptt_icontop text-center">
                  <img src={iconUnhappy} />
                  <h2 className="text-uppercase">{language.sorry}</h2>
                  <small>{language.zipcodenotfound}</small>
                </div>
              </div>
            </div>
            <div className="popup_right">
              <div className="innervmid_in">
                {/*<h4>Enter your postal code</h4>*/}

                {cookie.load("UserId") && (
                  <div className="address-list-cls address-list-error">
                    {this.userAddressList(2)}
                  </div>
                )}

                <form className="form_sec">
                  <div className="form-group">
                    <div
                      className={
                        this.state.secondaryaddresslist.length > 0
                          ? "focus-out focused"
                          : "focus-out"
                      }
                    >
                      <label>{language.enterzipcode}</label>
                      <input
                        type="text"
                        id="postalcode1"
                        pattern="\d*"
                        maxLength="7"
                        className="form-control input-focus"
                      />
                      <div className="postal_error"></div>
                    </div>
                  </div>
                  <div className="btn_sec delivery_submit_cls delivery_submit_div">
                    {/*<input type="button" onClick={this.selectOutlet.bind(this, 0, deliveryId)} className="button delivery_submit" value="Continue" />*/}
                    <input
                      type="button"
                      onClick={this.findOutletBasedZone.bind(
                        this,
                        0,
                        deliveryId
                      )}
                      className="button delivery_submit"
                      value={language.continue}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Delevery Popup error - Start */}

        {/* success popup - Start */}
        <div
          id="awesome-popup"
          className="white-popup mfp-hide popup_sec delevery_popup delevery_popup_datetime"
        >
          <div className="popup_equalrw">
            <div className="popup_ttsec">
              <div className="innervmid_in">
                <div className="pop_title">
                  <h2 className="text-uppercase">
                    {this.state.seletedAvilablityId === deliveryId
                      ? language.delivery
                      : language.pickup}
                  </h2>
                  <p>{language.letusnkowdatetime}</p>
                </div>
              </div>
            </div>
            <div className="popup_right">
              <div className="innervmid_in">
                <div className="pop_title">
                  <img className="pop-scooter-img" src={Win} />
                  <h2 className="text-uppercase">{language.amazing}</h2>
                  {this.state.seletedAvilablityId === deliveryId ? (
                    <p>{language.candelivery}</p>
                  ) : (
                    <p>{language.canpickup}</p>
                  )}
                </div>
                {this.state.seletedAvilablityId === deliveryId ? (
                  <div className="awesome_del">
                    <h5>{language.shippingaddr} :</h5>
                    <h2>{this.state.orderDeliveryAddress}</h2>
                  </div>
                ) : (
                  <div className="awesome_del">
                    <h2>{this.state.orderHandled}</h2>
                  </div>
                )}

                <div className="datetime_selt_sec">
                  <div className="datetime_selt_lbl">
                    {this.state.seletedAvilablityId === deliveryId
                      ? language.selectdeliverydatetime
                      : language.selectpickupdatetime}
                  </div>

                  {!currenturl.includes(isCheckout) && (
                    <div>
                      {advancedTimeslotEnable === "1" ? (
                        <OrderAdvancedDatetimeSlot
                          {...this.props}
                          hdrState={this.state}
                          setdateTimeFlg={this.setdateTimeFlg}
                        />
                      ) : (
                        <OrderdatetimeSlot
                          {...this.props}
                          hdrState={this.state}
                          setdateTimeFlg={this.setdateTimeFlg}
                        />
                      )}
                    </div>
                  )}

                  <div className="ordrdatetime_error"></div>
                </div>

                <div className="btn_sec">
                  <input
                    type="button"
                    onClick={this.setOrderOutletDateTimeData.bind(this)}
                    className="button"
                    value={language.continue}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* success popup - end */}

        {/* success popup - Start */}
        <div
          id="awesome-popup-old"
          className="white-popup mfp-hide awesome_popup"
        >
          <div className="popup_equalrw">
            <div className="popup_ttsec">
              <div className="innervmid_in">
                <div className="pop_title poptt_icontop text-center">
                  <img src={iconWin} />
                  <h2 className="text-uppercase">{language.amazing}</h2>
                  <small>{language.candelivery}</small>
                </div>
                <div className="awesome_del">
                  <h5>{language.shippingaddr} :</h5>
                  <h2>{this.state.orderDeliveryAddress}</h2>
                </div>
                <div className="btn_sec">
                  <input
                    type="button"
                    onClick={this.gotoProducts.bind(this)}
                    className="button"
                    value={language.continue}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* success popup - end */}

        {/* error Popup - start */}
        <div
          id="outlet-error-popup"
          className="white-popup mfp-hide popup_sec warning_popup outlet_error_popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_body">
                  <img className="warning-popup-img" src={warningImg} />
                  <h2 className="text-uppercase">{language.sorry}</h2>
                  <p>{language.cantdeliver}</p>
                  <p>{language.comeback}</p>
                  <div className="alt_btns">
                    {this.state.seletedAvilablityId === pickupId ? (
                      <a
                        href="/"
                        onClick={this.gobckPkupOutletpopup.bind(this)}
                        className="button button-right popup-modal-dismiss disbl_href_action"
                      >
                        {language.changeoutlet}
                      </a>
                    ) : (
                      <a
                        href="/"
                        onClick={this.gobckOutletpopup.bind(this)}
                        className="button button-right popup-modal-dismiss disbl_href_action"
                      >
                        {language.changeaddress}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* error Popup - end */}

        {/* Takeaway Popup - Start */}
        <div
          id="takeaway-popup"
          className="white-popup mfp-hide popup_sec self_popup"
        >
          <div className="order-body">
            <div className="self_popup_hea_row">
              <div className="self_popup_hea_col_left">
                <img src={takeawayWtImg} />
              </div>
              <div className="self_popup_hea_col">
                <h2>{language.pleasecoose}</h2>
                <p>{language.selefcollectoutlet}</p>
              </div>
            </div>

            <div className="self_pop_row">
              <div className="self_pop_col self_pop_col_right">
                <div className="self_pop_item">
                  <div className="self_pop_locbx">
                    {/*<h4>Search Cedele Outlet.</h4>*/}
                    <div className="form-group">
                      <div className="focus-out">
                        <label>{language.selectoutlet}</label>
                        <input
                          type="input"
                          className="form-control input-focus"
                          onKeyUp={this.handleKeyPress}
                        />
                        <div className="outlet_error"></div>
                      </div>
                    </div>
                  </div>

                  <div className="self_outlet">
                    <h2>{language.nearoutlet}</h2>
                    <ul className="self_outlet_inner">
                      {this.loadOutletsList()}
                    </ul>
                    <a
                      className="button takeaway-btn-act disbl_href_action"
                      id="takeaway-continue-link"
                      href="/"
                      onClick={this.selectDatetm.bind(this)}
                    >
                      {language.continue}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Takeaway Popup - end */}

        {/*  Coming Soon Popup - start */}
        <div
          id="comingsoon-popup"
          className="white-popup mfp-hide popup_sec comingsoon_popup"
        >
          <div className="order-body">
            <div className="comingsoon_popup_hea_row">
              <div className="comingsoon_popup_hea_col">
                <h2>{language.comingsoon}</h2>
              </div>
            </div>
            <div className="comingsoon_pop_row">
              <p>{language.choosediffavail}</p>
              <a
                href="/"
                onClick={this.closepopup.bind(this)}
                className="button disbl_href_action"
                title={language.return}
              >
                {language.return}
              </a>
            </div>
          </div>
        </div>
        {/* Coming Soon Popup - end */}

        {/* Warning Popup - start */}
        <div
          id="warning-popup"
          className="white-popup mfp-hide popup_sec warning_popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_header">{language.warning}</div>
                <div className="alert_body">
                  <img className="warning-popup-img" src={warningplus} />
                  <p>{language.clearcartswitch}</p>
                  <p>{language.wantcontinue}</p>
                  <div className="alt_btns">
                    <a
                      href="/"
                      className="popup-modal-dismiss button button-left disbl_href_action"
                    >
                      {language.no}
                    </a>
                    <a
                      href="/"
                      onClick={this.changeAvailability.bind(this)}
                      className="button button-right popup-modal-dismiss disbl_href_action"
                    >
                      {language.yes}
                    </a>
                    <input type="hidden" id="warning_avilablity" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Warning Popup - end */}

        {/* Warning Popup - start */}
        <div
          id="session-expired-popup"
          className="white-popup mfp-hide popup_sec warning_popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_header">確認</div>
                <div className="alert_body">
                  <img className="warning-popup-img" src={warningImg} />
                  <p>5分で自動ログアウトになります</p>
                  <p>ログインを延長しますか？</p>
                  <div className="alt_btns">
                    <a
                      href="/"
                      onClick={this.changeSessionTimer.bind(this)}
                      className="popup-modal-dismiss button button-left disbl_href_action"
                    >
                      延長する
                    </a>

                    <a
                      href="/"
                      onClick={this.logoutSessionTimer.bind(this)}
                      className="button button-right popup-modal-dismiss disbl_href_action"
                    >
                      {language.logout}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Warning Popup - end */}

        <div className="mbl-menu-overly"></div>
      </header>
    );
  }
}

const mapStateTopProps = (state) => {
  var zonedetailArr = Array();
  if (Object.keys(state.zonedetail).length > 0) {
    if (state.zonedetail[0].status === "ok") {
      zonedetailArr = state.zonedetail[0].result_set;
    }
  }

  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  var outletsArr = Array();
  if (Object.keys(state.outlets).length > 0) {
    if (state.outlets[0].status === "ok") {
      outletsArr = state.outlets[0].result_set;
    }
  }

  var alloutletsArr = Array();
  if (Object.keys(state.alloutlets).length > 0) {
    if (state.alloutlets[0].status === "ok") {
      alloutletsArr = state.alloutlets[0].result_set;
    }
  }

  var secondarydataArr = Array();
  if (Object.keys(state.secondaryaddress).length > 0) {
    if (state.secondaryaddress[0].status === "ok") {
      secondarydataArr = state.secondaryaddress[0].result_set;
    }
  }

  return {
    globalsettings: state.settings,
    staticblack: blacksArr,
    zonedetails: zonedetailArr,
    outletslist: outletsArr,
    alloutletslist: alloutletsArr,
    logindata: state.login,
    fblogin: state.fblogin,
    forgetpassword: state.forgetpassword,
    registration: state.registration,
    menudata: state.menudata,
    secondaryaddresslist: secondarydataArr,
    changepassword: state.changepassword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    getZoneDetail: (outletId, zoneId) => {
      dispatch({ type: GET_ZONE_DETAIL, outletId, zoneId });
    },
    getPickupOutlets: () => {
      dispatch({ type: GET_PICKUP_OUTLETS });
    },
    getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
    getLoginData: (formPayload) => {
      dispatch({ type: GET_LOGINDATA, formPayload });
    },
    getFbLoginData: (formPayload) => {
      dispatch({ type: GET_FBLOGINDATA, formPayload });
    },
    getRegistration: (formPayload) => {
      dispatch({ type: GET_REGISTRATION, formPayload });
    },
    getForgetPassword: (formPayload) => {
      dispatch({ type: GET_FORGET_PASSWORD, formPayload });
    },
    getChangePassword: (formPayload) => {
      dispatch({ type: GET_CHANGEPASSWORD, formPayload });
    },
    getMenuData: (menuslug) => {
      dispatch({ type: GET_MENUDATA, menuslug });
    },
    getSecondaryAddress: () => {
      dispatch({ type: GET_ALLUSERSECADDRDATA });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(Header));
