/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { format } from "date-fns";
import cookie from "react-cookies";
import { connect } from "react-redux";
import Select from "react-select";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";
import { isNull } from "underscore";
import { language } from "../Helpers/Language";
import {
  appId,
  siteURL,
  apiUrl,
  apiUrlV2,
  deliveryId,
  currencySymbol,
  defaultoutlet,
  headerconfig,
} from "../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showLoader,
  hideLoader,
  showCustomAlert,
  showAlert,
  removePromoCkValue,
  showPrices,
  showPriceValue,
  showPriceValueSub,
  subscribeCycle,
  showPoint,
} from "../Helpers/SettingHelper";
import Slider from "react-slick";
import {
  GET_PRODUCT_DETAIL,
  GET_FAVOURITE,
  GET_MENU_NAVIGATION,
} from "../../actions";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import MenuNavigation from "./MenuNavigation";
import noimage from "../../common/images/no-img-product.png";
import share from "../../common/images/share.svg";
import likedark from "../../common/images/cat-item-like-black.svg";
import cartLikeActive from "../../common/images/cat-item-like-bw-active.svg";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import { lang } from "moment";
var Parser = require("html-react-parser");
var qs = require("qs");

var settingsProduct = {
  items: 4,
  autoplay: false,
  margin: 18,
  loop: true,
  nav: true,
  dots: false,
  responsive: {
    0: {
      items: 1,
    },
    780: {
      items: 3,
    },
    1280: {
      items: 4,
    },
  },
};
class ProductDetail extends Component {
  constructor(props) {
    super(props);
    var selectedProSlug = "";
    if (
      this.props.match.params !== "" &&
      typeof this.props.match.params !== undefined &&
      typeof this.props.match.params !== "undefined"
    ) {
      if (
        this.props.match.params.proValue !== "" &&
        typeof this.props.match.params.proValue !== undefined &&
        typeof this.props.match.params.proValue !== "undefined"
      ) {
        selectedProSlug = this.props.match.params.proValue;
      }
    }
    var todayDate = new Date();
    var getHrs = todayDate.getHours();
    if (parseInt(getHrs) >= 7) {
      todayDate.setDate(parseInt(todayDate.getDate()) + 1);
    }
    console.log(todayDate, getHrs, "getHrsgetHrs");
    this.state = {
      selectedProSlug: selectedProSlug,
      navigateMenu: [],
      navigateMenuTmp: [],
      selectedCategoryName: "",
      selectedSlugType: "",
      selectedSlugValue: "",
      catNavIndex: 0,
      productdetailstatus: "",
      productdetail: [],
      productcommon: [],
      selectedProId: "",
      remaining: 30,
      product_remarks: "",
      mdfinput_value: 1,
      mdfApi_call: "Yes",
      modParentProductId: "",
      modProductPrice: "",
      modProductTotalPrice: "",
      compoinput_value: 1,
      incrCompoPrice: 0,
      compoApi_call: "Yes",
      cartTriggerFlg: "No",
      customerId:
        cookie.load("UserId") !== "" &&
        typeof cookie.load("UserId") !== "undefined" &&
        typeof cookie.load("UserId") !== undefined
          ? cookie.load("UserId")
          : "",
      favproductID: [],
      favdeleteproductID: [],
      viewMenu: false,
      readMore: false,
      purchaseType: "onetime",
      startDate: "",
      subscription_cycle: "",
      subscriptionError: "",
      sub_cycle_error: "",
      sub_date_error: "",
      subscriptionMinDate: todayDate,
      productmeta: [],
    };
    if (selectedProSlug !== "") {
      this.props.getProductDetail(selectedProSlug);
      this.props.getMenuNavigation();
    }
    this.handleChangeDate = this.handleChangeDate.bind(this);
  }
  componentDidMount() {
    if ($("#ProductDetailMdl").length > 0) {
      $("html, body").animate(
        {
          scrollTop: $("#ProductDetailMdl").offset().top - 200,
        },
        1000
      );
    }
    $(document).ready(function() {
      $(window).on("scroll", function() {
        if ($(".prodetailinner-main-div").length > 0) {
          if (
            $(window).scrollTop() >=
            $(".prodetailinner-main-div").offset().top +
              $(".prodetailinner-main-div").outerHeight() -
              window.innerHeight -
              10
          ) {
            $(".prd_chosen_sub_row").removeClass("productdetails-sticky");
          } else {
            $(".prd_chosen_sub_row").addClass("productdetails-sticky");
          }
        }
      });
    });
  }
  componentWillReceiveProps(nxtProps) {
    if (this.state.selectedProSlug !== nxtProps.match.params.proValue) {
      this.setState(
        { selectedProSlug: nxtProps.match.params.proValue },
        function() {
          this.props.getProductDetail(nxtProps.match.params.proValue);
          $("html, body").animate(
            {
              scrollTop: $("#ProductDetailMdl").offset().top - 200,
            },
            1000
          );
        }
      );
    }
    if (this.state.productdetail !== nxtProps.productdetail) {
      if (nxtProps.productdetail !== "" && nxtProps.productdetail.length > 0) {
        this.setState({ productdetail: nxtProps.productdetail }, function() {
          var productmeta = {
            meta_title: nxtProps.productdetail[0].product_meta_title,
            meta_description:
              nxtProps.productdetail[0].product_meta_description,
            meta_keywords: nxtProps.productdetail[0].product_meta_keywords,
          };
          this.setState({ productmeta: productmeta });
          this.loadCategoryProducts(
            nxtProps.productdetail[0].catgory_name,
            nxtProps.productdetail[0].catgory_description,
            nxtProps.productdetail[0].cat_slug
          );
        });
      }
    }
  }
  handleChangeDate(datevalue) {
    this.setState({ startDate: datevalue, sub_date_error: "" });
  }
  addFavourite(productID, fav_flag) {
    if (this.state.customerId !== "") {
      var formData = {
        app_id: appId,
        product_id: productID,
        fav_flag: fav_flag,
        customer_id: this.state.customerId,
        access_token:
          cookie.load("access_token") !== "" &&
          typeof cookie.load("access_token") !== undefined &&
          typeof cookie.load("access_token") !== "undefined"
            ? cookie.load("access_token")
            : "",
      };
      var favproductID = this.state.favproductID;
      var favdeleteproductID = this.state.favdeleteproductID;
      if (fav_flag === "Yes") {
        favproductID.push(productID);
        const index1 = favdeleteproductID.indexOf(productID);
        if (index1 > -1) {
          favdeleteproductID.splice(index1, 1);
        }
      } else {
        const index = favproductID.indexOf(productID);
        if (index > -1) {
          favproductID.splice(index, 1);
        }
        favdeleteproductID.push(productID);
      }
      this.setState({
        favproductID: favproductID,
        favdeleteproductID: favdeleteproductID,
      });

      var postObject = qs.stringify(formData);
      this.props.getFavouriteproducts(postObject);
    } else {
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }
  }
  showProductTags(tags) {
    return tags.map((item, index) => (
      <li key={index}>{stripslashes(item.pro_tag_name)}</li>
    ));
  }

  /* copy product detail page URL for sharing */
  copyCurrentURL(proDetails) {
    var dummy = document.createElement("textArea");
    /*var	text = window.location.href;*/
    var text =
      siteURL +
      "products/" +
      proDetails.cat_slug +
      "/" +
      proDetails.subcate_slug +
      "/" +
      proDetails.product_slug;
    document.body.appendChild(dummy);
    dummy.value = text;
    var range, selection;
    if (navigator.userAgent.match(/ipad|iphone/i)) {
      range = document.createRange();
      range.selectNodeContents(dummy);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      dummy.setSelectionRange(0, 999999);
    } else {
      dummy.select();
    }

    document.execCommand("copy");
    document.body.removeChild(dummy);
    /*$('html, body').animate({
			scrollTop: 100
		}, 2000);*/
    $(".copied")
      .fadeIn()
      .delay(1000)
      .fadeOut();
  }

  read_More() {
    this.setState({ readMore: !this.state.readMore });
  }

  productDetailsMain() {
    var proDetStatus = this.props.productdetailstatus;
    var proDetails = this.props.productdetail;
    var current = this;
    if (Object.keys(proDetails).length > 0 && proDetStatus === "success") {
      var imageSource = this.props.productcommon.image_source;
      var product_gallery_image_source = this.props.productcommon
        .product_gallery_image_source;

      if (this.state.selectedProSlug === proDetails[0].product_slug) {
        hideLoader("prodetailinner-main-div", "class");
      }
      setTimeout(function() {
        $("#proIndex-" + proDetails[0].product_primary_id).removeClass(
          "active"
        );
        $("#comboPro-" + proDetails[0].product_slug).removeClass(
          "loader-main-cls"
        );
        $("#comboPro-" + proDetails[0].product_slug)
          .find(".loader-product-sub-div")
          .remove();
        $(".productDetailMain").show();
        $("#dvLoading").hide();
      }, 1000);

      var desc = "";

      const settings = {
        dots: true,
        arrows: false,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };

      return proDetails.map((data, index) => {
        desc =
          data.product_long_description !== "" &&
          data.product_long_description !== null
            ? stripslashes(data.product_long_description)
            : data.product_short_description !== "" &&
              data.product_short_description !== null
            ? stripslashes(data.product_short_description)
            : "";

        var comboLgth = data.set_menu_component
          ? data.set_menu_component.length
          : 0;
        var ModifLgth = data.modifiers ? data.modifiers.length : 0;
        var isCombo = data.product_type === "2" && comboLgth > 0 ? "Yes" : "No";
        var isModifier =
          data.product_type === "4" && ModifLgth > 0 ? "Yes" : "No";
        var isSimple =
          data.product_type === "1" && comboLgth === 0 && ModifLgth === 0
            ? "Yes"
            : "No";

        return (
          <>
            <div key={index}>
              <div
                className={
                  "inn-product-popup " + "product_" + data.product_slug
                }
                key={data.product_primary_id}
                id={"product_" + data.product_primary_id}
              >
                {/* prodet_bansec div - start */}
                <div className="prodet_bansec">
                  {/* product image div - start */}
                  {data.product_tag_info !== "" &&
                    data.product_tag_info !== null && (
                      <div className="ribbon">
                        <span>{data.product_tag_info}</span>
                      </div>
                    )}
                  <div className="inn-product-img-bg prodet_top">
                    <Slider {...settings}>
                      {data.image_gallery.length > 0 ? (
                        data.image_gallery.map(function(item, imgindex) {
                          return (
                            <div key={imgindex}>
                              {
                                <img
                                  src={
                                    product_gallery_image_source +
                                    "/" +
                                    item.pro_gallery_image
                                  }
                                />
                              }
                            </div>
                          );
                        })
                      ) : (
                        <div>
                          {data.image_gallery.length === 1 ? (
                            <img
                              src={
                                product_gallery_image_source +
                                "/" +
                                data.image_gallery[0].pro_gallery_image
                              }
                            />
                          ) : data.product_thumbnail !== "" ? (
                            <img
                              src={imageSource + "/" + data.product_thumbnail}
                            />
                          ) : (
                            <img src={noimage} />
                          )}
                        </div>
                      )}
                    </Slider>
                  </div>
                  <div className="tag-wishlist">
                    <div className="product-tag-list">
                      {/* data.product_tag.length > 0 && (
                        <ul>{this.showProductTags(data.product_tag)}</ul>
                      ) */}
                    </div>
                    <div className="share-wishlist">
                      <ul>
                        <li className="share-big">
                          <a
                            href={void 0}
                            onClick={this.copyCurrentURL.bind(this, data)}
                          >
                            {" "}
                            <img src={share} />{" "}
                          </a>
                          <div className="copied" style={{ display: "none" }}>
                            {language.copied}
                          </div>
                        </li>
                        <li className="like-big">
                          {this.state.customerId !== "" ? (
                            <div>
                              {this.state.favdeleteproductID.indexOf(
                                data.product_primary_id
                              ) >= 0 ? (
                                <a
                                  href={void 0}
                                  className="like"
                                  onClick={this.addFavourite.bind(
                                    this,
                                    data.product_primary_id,
                                    "Yes"
                                  )}
                                >
                                  <img src={likedark} />
                                  <img
                                    src={cartLikeActive}
                                    className="active-hart"
                                  />
                                </a>
                              ) : (!isNull(data.fav_product_primary_id) &&
                                  typeof data.fav_product_primary_id !==
                                    undefined &&
                                  typeof data.fav_product_primary_id !==
                                    "undefined") ||
                                this.state.favproductID.indexOf(
                                  data.product_primary_id
                                ) >= 0 ? (
                                <a
                                  href={void 0}
                                  className="like active"
                                  onClick={this.addFavourite.bind(
                                    this,
                                    data.product_primary_id,
                                    "No"
                                  )}
                                >
                                  <img src={likedark} />
                                  <img
                                    src={cartLikeActive}
                                    className="active-hart"
                                  />
                                </a>
                              ) : (
                                <a
                                  href={void 0}
                                  className="like"
                                  onClick={this.addFavourite.bind(
                                    this,
                                    data.product_primary_id,
                                    "Yes"
                                  )}
                                >
                                  <img src={likedark} />
                                  <img
                                    src={cartLikeActive}
                                    className="active-hart"
                                  />
                                </a>
                              )}
                            </div>
                          ) : (
                            <a
                              href={void 0}
                              className="like"
                              onClick={this.addFavourite.bind(
                                this,
                                data.product_primary_id,
                                "Yes"
                              )}
                            >
                              <img src={likedark} />
                              <img
                                src={cartLikeActive}
                                className="active-hart"
                              />
                            </a>
                          )}

                          {/*   <a href="#">
                            {" "}
                            <img src={likedark} />
                          </a>{" "} */}
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* product image div - end */}
                </div>
                {/* prodet_bansec div - end */}

                {/* combo or modifier top div - start */}
                <div className="inn_product_row">
                  {/* product info div - start */}
                  <div className="prodet_baninfo">
                    <div className="inn_product_hea">
                      <div className="inn_product_review">
                        <div className="inn_product_hea_left">
                          <h4>{data.catgory_name}</h4>
                          <h3>
                            {" "}
                            {data.product_alias !== ""
                              ? stripslashes(data.product_alias)
                              : stripslashes(data.product_name)}{" "}
                          </h3>
                        </div>
                      </div>
                      {desc !== "" && desc !== null && (
                        <div
                          className={
                            this.state.readMore === true
                              ? "desc active"
                              : "desc"
                          }
                        >
                          {Parser(desc)}
                        </div>
                      )}
                      <a
                        href={void 0}
                        className={
                          this.state.readMore === true
                            ? "hide-more"
                            : "read-more"
                        }
                        onClick={this.read_More.bind(this)}
                      >
                        {this.state.readMore === true
                          ? language.hide
                          : language.productreadmore}
                      </a>
                      {data.product_revel_id !== "" &&
                        data.product_revel_id !== null && (
                          <>
                            <br />
                            <b>{language.sku}</b> :{" "}
                            <span id="product_revel_idtxt">
                              {data.product_revel_id}
                            </span>
                          </>
                        )}
                      <input
                        type="hidden"
                        id="product_dimension_idtxt"
                        value={
                          data.product_height +
                          "~" +
                          data.product_width +
                          "~" +
                          data.product_weight +
                          "~" +
                          data.product_volume
                        }
                      />
                    </div>
                  </div>
                  {/* product info div - end */}

                  <div className="product-showing-bottom">
                    {isCombo === "Yes" ? this.comboProDetails(data) : ""}
                    {isModifier === "Yes" ? this.modifierProDetails(data) : ""}
                    {isSimple === "Yes" ? this.simpleProDetails(data) : ""}
                  </div>
                </div>
                {/* combo or modifier top div - end */}
              </div>
            </div>

            {this.state.categoryPro}
          </>
        );
      });
    } else if (
      Object.keys(proDetails).length === 0 &&
      proDetStatus === "failure"
    ) {
      return (
        <div className="product-detail-empty">{language.invalidproduct}</div>
      );
    } else {
      return "";
    }
  }

  loadCategoryProducts(catgory_name, catgory_description, cat_slug) {
    var availabilityId =
      cookie.load("defaultAvilablityId") === undefined ||
      cookie.load("defaultAvilablityId") == ""
        ? deliveryId
        : cookie.load("defaultAvilablityId");

    var orderOutletId =
      cookie.load("orderOutletId") === undefined ||
      cookie.load("orderOutletId") == ""
        ? defaultoutlet
        : cookie.load("orderOutletId");
    var deliveryDate =
      cookie.load("deliveryDate") == undefined ||
      cookie.load("deliveryDate") == ""
        ? ""
        : cookie.load("deliveryDate").split("/");
    var delivery_Date = "";
    if (deliveryDate !== "") {
      delivery_Date =
        deliveryDate[2] + "-" + deliveryDate[1] + "-" + deliveryDate[0];
    } else {
      delivery_Date = format(new Date(), "yyyy-MM-dd");
    }
    var spicelPride =
      "&specialprice_applicable=orderdate&order_datetxt=" + delivery_Date;
    Axios.get(
      apiUrlV2 +
        "products/getAllProducts?app_id=" +
        appId +
        "&availability=" +
        availabilityId +
        "&category_slug=" +
        cat_slug +
        "&outletId=" +
        orderOutletId +
        spicelPride,
      headerconfig
    ).then((res) => {
      var response = res.data;
      if (response.status === "ok") {
        this.displayCategoryProducts(
          catgory_name,
          catgory_description,
          response.result_set,
          response.common
        );
      }
    });
  }

  displayCategoryProducts(
    catgory_name,
    catgory_description,
    categoryProducts,
    common
  ) {
    var categoryPro = "";
    if (categoryProducts !== "" && categoryProducts.length > 0) {
      categoryPro = (
        <section className="product-details-category-section">
          <div className="category-products-section">
            <div className="container-noneed">
              <h3>{catgory_name}</h3>
              {catgory_description !== "" && catgory_description !== null && (
                <p>{stripslashes(catgory_description)}</p>
              )}
              <div className="category-products-main">
                <div className="products-list-ulmain">
                  <OwlCarousel options={settingsProduct}>
                    {categoryProducts.map((subcat, index) => {
                      if (subcat.subcategorie.length > 0) {
                        return subcat.subcategorie.map(
                          (productsList, index1) => {
                            if (productsList.products.length > 0) {
                              return productsList.products.map(
                                (products, index2) => {
                                  if (
                                    this.state.selectedProSlug !==
                                    products.product_slug
                                  ) {
                                    return (
                                      <div
                                        className="products-single-li"
                                        key={
                                          index + "-" + index1 + "-" + index2
                                        }
                                      >
                                        <Link
                                          to={
                                            "/products/" +
                                            productsList.pro_cate_slug +
                                            "/" +
                                            productsList.pro_subcate_slug +
                                            "/" +
                                            products.product_slug
                                          }
                                        >
                                          <div className="products-image-div">
                                            {products.product_thumbnail !==
                                            "" ? (
                                              <img
                                                src={
                                                  common.product_image_source +
                                                  "/" +
                                                  products.product_thumbnail
                                                }
                                              />
                                            ) : (
                                              <img src={noimage} />
                                            )}
                                          </div>
                                        </Link>
                                        <div className="product-info-div">
                                          <div className="product-title-maindiv">
                                            <div className="product-title">
                                              <h3>
                                                {stripslashes(
                                                  products.product_name
                                                )}
                                              </h3>
                                            </div>
                                          </div>

                                          <div className="product-short-description">
                                            <p>
                                              {products.product_short_description !=
                                              ""
                                                ? Parser(
                                                    stripslashes(
                                                      products.product_short_description
                                                    )
                                                  )
                                                : Parser("")}
                                            </p>
                                          </div>

                                          <div className="products-ordernow-action">
                                            <div className="products-ordernow-inner">
                                              <div className="product-price">
                                                {products.product_specialprice_applicable ===
                                                "yes"
                                                  ? products.product_price >
                                                      0 && (
                                                      <span className="price_disc">
                                                        {showPriceValue(
                                                          products.product_price
                                                        )}
                                                      </span>
                                                    )
                                                  : parseFloat(
                                                      products.product_cost
                                                    ) > 0 && (
                                                      <span className="price_disc">
                                                        {showPriceValueSub(
                                                          products.product_cost
                                                        )}
                                                      </span>
                                                    )}
                                                <h3>
                                                  {products.product_specialprice_applicable ===
                                                  "yes"
                                                    ? showPriceValue(
                                                        products.product_special_price
                                                      )
                                                    : showPriceValue(
                                                        products.product_price
                                                      )}
                                                </h3>
                                              </div>
                                              {products.product_stock > 0 ||
                                              products.product_stock ===
                                                null ? (
                                                <Link
                                                  className="button order_nowdiv smiple_product_lk disbl_href_action"
                                                  to={
                                                    "/products/" +
                                                    productsList.pro_cate_slug +
                                                    "/" +
                                                    productsList.pro_subcate_slug +
                                                    "/" +
                                                    products.product_slug
                                                  }
                                                >
                                                  {language.buynow}
                                                </Link>
                                              ) : (
                                                <a
                                                  className="button disabled disbl_href_action"
                                                  href="/"
                                                >
                                                  {language.soldout}
                                                </a>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                }
                              );
                            }
                          }
                        );
                      }
                    })}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
    this.setState({ categoryPro: categoryPro });
  }

  closeProductDetails() {
    $(".productDetailMain").hide();
    this.props.sateValChange("selProductEndSection", "Y");
  }

  handleChangepurchaseType = (e) => {
    this.setState({ purchaseType: e.target.value }, function() {
      if (this.state.purchaseType === "onetime") {
        $("#id_price_final_subscribe").hide();
        $("#id_price_final").show();
        $(".special_price .price_disc").show();
        $("#simple_earn_points").show();
        $("#simple_earn_points_subscription").hide();
        $("#sub_save_rhs_onetime").show();
        $("#sub_save_rhs_subscription").hide();
      } else {
        $(".special_price .price_disc").hide();
        $("#id_price_final").hide();
        $("#id_price_final_subscribe").show();
        $("#simple_earn_points").hide();
        $("#simple_earn_points_subscription").show();
        $("#sub_save_rhs_onetime").hide();
        $("#sub_save_rhs_subscription").show();
      }
    });
  };
  onChangeOption = (name, e) => {
    this.setState({ [name]: e, subscriptionError: "", sub_cycle_error: "" });
  };
  openSubscription() {
    $.magnificPopup.open({
      items: {
        src: "#subscription-popup",
      },
      type: "inline",
    });
  }
  confirmSubscription() {
    var suberror = 0;
    var subscription_cycle = "";
    var subscription_date = "";

    if (this.state.subscription_cycle !== "") {
      this.setState({
        sub_cycle_error: "",
      });
      subscription_cycle = this.state.subscription_cycle.value;
    } else {
      this.setState({
        sub_cycle_error: language.サブスクリプションサイクルを選択してください,
      });
      suberror++;
    }

    subscription_date = this.state.startDate;
    if (subscription_date === "") {
      this.setState({
        sub_date_error: language.selectsubscribstardate,
      });
      suberror++;
    } else {
      this.setState({
        sub_date_error: "",
      });
    }
    if (suberror === 0) {
      $.magnificPopup.close();
    }
  }
  closeSubscriptionPopup() {
    $.magnificPopup.close();
  }

  /* show modifier product details */
  simpleProDetails(proDetailArr) {
    var modProductSubscribeTotalPrice = proDetailArr.product_subscription_price;

    var modProductTotalPrice =
      proDetailArr.product_specialprice_applicable === "yes"
        ? proDetailArr.product_special_price
        : proDetailArr.product_price;
    var subscriptionCycle = [];
    var defaultSubscrip = [];

    if (
      proDetailArr.product_subscription_cycle !== "" &&
      proDetailArr.product_subscription_cycle !== null
    ) {
      console.log(
        proDetailArr.product_subscription_cycle,
        "proDetailArr.product_subscription_cycle"
      );
      proDetailArr.product_subscription_cycle.split(",").map((item) => {
        var label = "";
        var percentageearnpoint = "";
        if (item === "D") {
          var addedPoints_Split = "";
          var added_Points = "";
          if (
            proDetailArr.product_subscription_daily_point !== "" &&
            proDetailArr.product_subscription_daily_point !== null
          ) {
            var addedPoints_Split = proDetailArr.product_subscription_daily_point.split(
              "."
            );
            if (addedPoints_Split[1] === "00") {
              added_Points = addedPoints_Split[0];
            }
            percentageearnpoint = " - " + added_Points + "%";
          }
          label = "毎日" + percentageearnpoint;
          defaultSubscrip = {
            value: item,
            label: label + percentageearnpoint,
          };
        } else if (item === "W") {
          var addedPoints_Split = "";
          var added_Points = "";
          if (
            proDetailArr.product_subscription_weekly_point !== "" &&
            proDetailArr.product_subscription_weekly_point !== null
          ) {
            var addedPoints_Split = proDetailArr.product_subscription_weekly_point.split(
              "."
            );
            if (addedPoints_Split[1] === "00") {
              added_Points = addedPoints_Split[0];
            }
            percentageearnpoint = " - " + added_Points + "%";
          }
          label = "毎週" + percentageearnpoint;
          if (defaultSubscrip === "") {
            defaultSubscrip = {
              value: item,
              label: label + percentageearnpoint,
            };
          }
        } else if (item === "M") {
          var addedPoints_Split = "";
          var added_Points = "";
          if (
            proDetailArr.product_subscription_monthly_point !== "" &&
            proDetailArr.product_subscription_monthly_point !== null
          ) {
            var addedPoints_Split = proDetailArr.product_subscription_monthly_point.split(
              "."
            );
            if (addedPoints_Split[1] === "00") {
              added_Points = addedPoints_Split[0];
            }
            percentageearnpoint = " - " + added_Points + "%";
          }
          label = "毎月" + percentageearnpoint;
          if (defaultSubscrip === "") {
            defaultSubscrip = {
              value: item,
              label: label + percentageearnpoint,
            };
          }
        }
        subscriptionCycle.push({ value: item, label: label });
      });
    }
    var addedPoints = "";
    var totalpointEarnSubscribe = 0;
    var totalpointEarn = 0;

    if (proDetailArr.product_enable_subscription === "1") {
      if (this.state.subscription_cycle.value === "D") {
        addedPoints = proDetailArr.product_subscription_daily_point;
      } else if (this.state.subscription_cycle.value === "W") {
        addedPoints = proDetailArr.product_subscription_weekly_point;
      } else if (this.state.subscription_cycle.value === "M") {
        addedPoints = proDetailArr.product_subscription_monthly_point;
      } else {
        addedPoints = proDetailArr.product_subscription_daily_point;
      }
      if (addedPoints !== "") {
        var addedPointsSplit = addedPoints.split(".");
        if (addedPointsSplit[1] === "00") {
          addedPoints = addedPointsSplit[0];
        }
      }
      if (addedPoints !== "") {
        totalpointEarnSubscribe = showPoint(
          modProductTotalPrice * (addedPoints / 100)
        );
      }
    }
    var addedPointsOnetime = 0;
    if (
      proDetailArr.product_reward_point !== "" &&
      proDetailArr.product_reward_point !== null &&
      proDetailArr.product_reward_point !== "0.00"
    ) {
      var addedPointsSplit = proDetailArr.product_reward_point.split(".");
      if (addedPointsSplit[1] === "00") {
        addedPointsOnetime = addedPointsSplit[0];
      } else {
        addedPointsOnetime = proDetailArr.product_reward_point;
      }
      if (addedPointsOnetime !== "") {
        totalpointEarn = showPoint(
          modProductTotalPrice * (addedPointsOnetime / 100)
        );
      }
    }

    return (
      <div>
        <div className="sub-save">
          <ul>
            <li>
              <div className="sub-save-lhs">
                {proDetailArr.product_enable_subscription === "1" && (
                  <div>
                    <input
                      type="radio"
                      defaultChecked={
                        this.state.purchaseType === "onetime" ? true : false
                      }
                      name="purchaseType"
                      className="bigbox"
                      onChange={this.handleChangepurchaseType.bind(this)}
                      value="onetime"
                      id="onetime"
                    />
                    <label htmlFor="onetime">{language.onetimepurchase}</label>
                  </div>
                )}
              </div>
              <div className="sub-save-rhs">
                <div className="total_price product_details_price">
                  <div className="product-price special_price">
                    {proDetailArr.product_specialprice_applicable === "yes"
                      ? parseFloat(proDetailArr.product_price) > 0 && (
                          <span className="price_disc">
                            {showPriceValueSub(proDetailArr.product_price, "Y")}
                          </span>
                        )
                      : parseFloat(proDetailArr.product_cost) > 0 && (
                          <span className="price_disc">
                            {showPriceValueSub(proDetailArr.product_cost, "Y")}
                          </span>
                        )}
                  </div>

                  <span id="id_price_final">
                    {showPrices(modProductTotalPrice, "Y")}
                  </span>
                  <span
                    id="id_price_final_subscribe"
                    style={{ display: "none" }}
                  >
                    {showPrices(modProductSubscribeTotalPrice, "Y")}
                  </span>
                </div>
              </div>
            </li>
            {proDetailArr.product_enable_subscription === "1" ? (
              <li>
                <div className="sub-save-lhs">
                  <input
                    type="radio"
                    defaultChecked={
                      this.state.purchaseType === "subscribe" ? true : false
                    }
                    name="purchaseType"
                    onChange={this.handleChangepurchaseType.bind(this)}
                    className="bigbox"
                    value="subscribe"
                    id="subscribe"
                  />
                  <label htmlFor="subscribe">{language.subscribeearn}</label>
                  {parseFloat(addedPoints) > 0 && (
                    <span>最大{addedPoints}％のポイントを獲得できます</span>
                  )}
                </div>
                {parseFloat(addedPointsOnetime) > 0 &&
                  this.state.purchaseType !== "subscribe" && (
                    <div className="sub-save-rhs" id="sub_save_rhs_onetime">
                      <span className="wpro">
                        {addedPointsOnetime}％ポイント獲得
                      </span>
                    </div>
                  )}
                {parseFloat(addedPoints) > 0 &&
                  this.state.purchaseType === "subscribe" && (
                    <div
                      className="sub-save-rhs"
                      id="sub_save_rhs_subscription"
                      style={{ display: "none" }}
                    >
                      <span className="wpro">{addedPoints}％ポイント獲得</span>
                    </div>
                  )}

                {this.state.purchaseType === "subscribe" && (
                  <>
                    <div
                      className="suscription-display"
                      onClick={this.openSubscription.bind(this)}
                    >
                      {this.state.subscription_cycle !== "" ? (
                        <span>
                          {" "}
                          {language.deliveryeverything} 1{" "}
                          {subscribeCycle(this.state.subscription_cycle.value)}
                        </span>
                      ) : (
                        <span>{language.selectdeliverycycle}</span>
                      )}
                      {this.state.subscriptionError != "" && (
                        <span className="error">
                          {language.thisfieldrequired}
                        </span>
                      )}
                    </div>

                    <div
                      id="subscription-popup"
                      className="mfp-hide popup_sec subscription-popup"
                    >
                      <div className="pop-whole full-login-new">
                        <div className="full-login-new-header">
                          <h3>{language.subscribdeliverypace}</h3>
                        </div>
                        <div className="full-login-new-body">
                          <ul>
                            <li>
                              <span>{language.deliveryeverything}</span>
                              <div className="subscription-cycle">
                                <Select
                                  /* defaultValue={defaultSubscrip} */
                                  placeholder={language.select}
                                  options={subscriptionCycle}
                                  onChange={this.onChangeOption.bind(
                                    this,
                                    "subscription_cycle"
                                  )}
                                />
                                {this.state.sub_cycle_error !== "" && (
                                  <span className="error">
                                    {this.state.sub_cycle_error}
                                  </span>
                                )}
                              </div>
                            </li>
                            <li>
                              <span>{language.firstdelivery}</span>
                              <div className="syd_date">
                                <div className="form-group222">
                                  <DatePicker
                                    className="form-control ordr-datetime-cls"
                                    minDate={this.state.subscriptionMinDate}
                                    dateFormat="yyyy/dd/MM"
                                    selected={this.state.startDate}
                                    onChange={this.handleChangeDate}
                                    locale="ja"
                                    autoComplete="off"
                                  />
                                  {this.state.sub_date_error !== "" && (
                                    <span className="error">
                                      {this.state.sub_date_error}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </li>
                          </ul>
                          <div className="subscription-info">
                            {language.subscribmsg}
                          </div>
                          <div className="btn_sec">
                            <a
                              href={void 0}
                              onClick={this.confirmSubscription.bind(this)}
                              className="gradient-btn"
                            >
                              {language.conform}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </li>
            ) : (
              <li>
                {parseFloat(addedPointsOnetime) > 0 && (
                  <>
                    <div className="sub-save-lhs"></div>

                    <div className="sub-save-rhs" id="sub_save_rhs_onetime">
                      <span className="wpro">
                        {addedPointsOnetime}％ポイント獲得
                      </span>
                    </div>
                  </>
                )}
              </li>
            )}
          </ul>
        </div>
        <div className="prd_chosen_sub_row productdetails-sticky">
          <div
            id={"proDtIndex-" + proDetailArr.product_primary_id}
            className="prd_chosen_sub_col popup_addcart_cls modfir_addcart_cls"
          >
            <div className="addcart_row prd_chosen_sub_item_left cart_update_div addcart_done_maindiv">
              <p className="sel-quality">{language.nubmers}</p>
              <div className="qty_bx">
                <span
                  className="qty_minus"
                  onClick={this.proQtyAction.bind(
                    this,
                    proDetailArr.product_primary_id,
                    proDetailArr,
                    "decr"
                  )}
                >
                  -
                </span>
                <input
                  type="text"
                  value="1"
                  className="proqty_input"
                  readOnly="1"
                />
                <span
                  className="qty_plus"
                  onClick={this.proQtyAction.bind(
                    this,
                    proDetailArr.product_primary_id,
                    proDetailArr,
                    "incr"
                  )}
                >
                  +
                </span>
              </div>
            </div>

            <div className="prd_chosen_sub_item_right cart_update_div addcart_done_maindiv addtocartbtn">
              <button
                onClick={this.addToCartSimple.bind(this, proDetailArr, "done")}
              >
                {language.updatebuynow}
              </button>
            </div>

            <div className="cart-success-msg alert alert_success mdfcart_success_msg"></div>
            <div className="cart-error-msg alert alert_danger mdfcart_error_msg"></div>
          </div>
        </div>
        {totalpointEarn > 0 && (
          <div className="product-earning-points" id="simple_earn_points">
            <span>商品を受け取ると{totalpointEarn}ポイント獲得</span>
          </div>
        )}
        {totalpointEarnSubscribe > 0 && (
          <div
            className="product-earning-points"
            id="simple_earn_points_subscription"
            style={{ display: "none" }}
          >
            <span>
              商品を受け取ると{totalpointEarnSubscribe}
              ポイント獲得
            </span>
          </div>
        )}
      </div>
    );
  }

  proQtyAction(indxFlg, proDetailArr, actionFlg) {
    var proqtyInput = $("#proDtIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proDtIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);

    var compoTotalPrice =
      proDetailArr.product_specialprice_applicable === "yes"
        ? proDetailArr.product_special_price
        : proDetailArr.product_price;
    var multiplePrice = compoTotalPrice * proqtyInput;

    var multiplePrice = compoTotalPrice * proqtyInput;

    var priceValu = multiplePrice.toFixed(0).replace(/./g, function(c, i, a) {
      return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
    });

    var totalpointEarn = "";
    var addedPoints = "";
    if (this.state.purchaseType === "subscribe") {
      if (proDetailArr.product_enable_subscription === "1") {
        if (this.state.subscription_cycle.value === "D") {
          addedPoints = proDetailArr.product_subscription_daily_point;
        } else if (this.state.subscription_cycle.value === "W") {
          addedPoints = proDetailArr.product_subscription_weekly_point;
        } else if (this.state.subscription_cycle.value === "M") {
          addedPoints = proDetailArr.product_subscription_monthly_point;
        } else {
          addedPoints = proDetailArr.product_subscription_daily_point;
        }
        if (addedPoints !== "") {
          var addedPointsSplit = addedPoints.split(".");
          if (addedPointsSplit[1] === "00") {
            addedPoints = addedPointsSplit[0];
          }
        }
        if (addedPoints !== "") {
          totalpointEarn = showPoint(
            proDetailArr.product_subscription_price *
              proqtyInput *
              (addedPoints / 100)
          );
        }
      }
      $("#id_price_final_subscribe").html(
        "<sup>" +
          currencySymbol +
          "</sup>" +
          proDetailArr.product_subscription_price * proqtyInput
      );
      if (totalpointEarn !== "") {
        $("#simple_earn_points_subscription").html(
          "<span>商品を受け取ると" + totalpointEarn + "ポイント獲得</span>"
        );
      }
    } else {
      $("#id_price_final").html(
        "<sup>" + currencySymbol + "</sup>" + priceValu
      );
      if (parseFloat(proDetailArr.product_reward_point) > 0) {
        var totalpointEarn = showPoint(
          multiplePrice * (proDetailArr.product_reward_point / 100)
        );
      }
      if (totalpointEarn !== "") {
        $("#simple_earn_points").html(
          "<span>商品を受け取ると" + totalpointEarn + "ポイント獲得</span>"
        );
      }
    }

    if (proDetailArr.product_specialprice_applicable === "yes") {
      var priceSpecialValu = parseFloat(
        proDetailArr.product_price * proqtyInput
      )
        .toFixed(0)
        .replace(/./g, function(c, i, a) {
          return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        });
      $(".special_price .price_disc").html(
        "<sup>" + currencySymbol + "</sup>" + priceSpecialValu
      );
    }
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg) {
    var IndexFlg = productDetail.product_primary_id;

    if (actionFlg === "initial") {
      $("#proDtIndex-" + IndexFlg)
        .find(".smiple_product_lk")
        .hide();
      $("#proDtIndex-" + IndexFlg)
        .find(".addcart_done_maindiv")
        .show();
      return false;
    } else {
      var availabilityId = cookie.load("defaultAvilablityId");
      /*var availabilityId = deliveryId;*/
      var availabilityName =
        availabilityId === deliveryId ? "Delivery" : "Pickup";
      var isAddonProduct = "No";
      var productId = productDetail.product_id;
      var customerId = this.state.customerId;
      var proqtyQty = $("#proDtIndex-" + IndexFlg)
        .find(".proqty_input")
        .val();
      var specialprice_applicable =
        productDetail.product_specialprice_applicable;
      var subscribe = "No";
      var subscription_cycle = "";
      var subscription_date = "";
      var productCategoryId = productDetail.product_category_id;
      var productSubcategoryId = productDetail.product_subcategory_id;
      if (
        productDetail.product_enable_subscription === "1" &&
        this.state.purchaseType === "subscribe"
      ) {
        subscribe = "Yes";

        if (this.state.subscription_cycle !== "") {
          this.setState({
            subscriptionError: "",
          });
          subscription_cycle = this.state.subscription_cycle.value;
        } else {
          this.setState({
            subscriptionError: language.selectsubscribcycle,
          });
          return false;
        }

        subscription_date = this.state.startDate;

        if (subscription_date === "") {
          this.setState({
            subscriptionError: language.selectsubscribstardate,
          });
          return false;
        } else {
          this.setState({
            subscriptionError: "",
          });
        }
      }
      setTimeout(function() {
        showLoader("addtocartbtn", "class");
      }, 1);
      var postObject = {};
      postObject = {
        app_id: appId,
        product_id: productId,
        product_qty: proqtyQty,
        product_type: 1,
        availability_id: availabilityId,
        availability_name: availabilityName,
        isAddonProduct: isAddonProduct,
        specialprice_applicable: specialprice_applicable,
        subscription: subscribe,
        subscription_cycle: subscription_cycle,
        subscription_date: subscription_date,
        reference_id: customerId === "" ? getReferenceID() : "",
        customer_id: customerId,
        category_id: productCategoryId,
        subcategory_id: productSubcategoryId,
        access_token:
          cookie.load("access_token") !== "" &&
          typeof cookie.load("access_token") !== undefined &&
          typeof cookie.load("access_token") !== "undefined"
            ? cookie.load("access_token")
            : "",
      };
      $(".hcartdd_trigger").removeClass("bounce");
      Axios.post(
        apiUrlV2 + "cart/simpleCartInsert",
        qs.stringify(postObject),
        headerconfig
      ).then((res) => {
        hideLoader("addtocartbtn", "class");

        if (res.data.status === "ok") {
          this.setState({ cartTriggerFlg: "yes" });
          removePromoCkValue();
          $(".hcartdd_trigger").addClass("bounce");
          /*  showCustomAlert(
            "success",
            "偉大な選択肢！カートにアイテムが追加されました。",
            "cart-success-msg"
          ); */
        } else if (res.data.status === "error") {
          var errMsgtxt =
            res.data.message !== "" ? res.data.message : language.itemnotadded;
          showCustomAlert("error", errMsgtxt);
          this.handleShowAlertFun(language.error, errMsgtxt);
        }

        return false;
      });
    }
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  /* show modifier product details */
  modifierProDetails(proDetailArr) {
    var modProductPrice =
      this.state.modProductPrice !== ""
        ? this.state.modProductPrice
        : proDetailArr.product_price;
    var modProductTotalPrice =
      this.state.modProductTotalPrice !== ""
        ? this.state.modProductTotalPrice
        : proDetailArr.product_price;
    $("#id_price_final").html(
      "<sup>" + currencySymbol + "</sup>" + modProductTotalPrice
    );
    return (
      <div>
        <div className="prd_chosen_row mdf_common_rows">
          <div id="modErrorDiv" className="modfr-alert-danger single-danger">
            {" "}
            {language.productwrongcombination}{" "}
          </div>

          <div className="product_chosen_inner">
            <input
              type="hidden"
              id="modProductSlug"
              name="modProductSlug"
              value={proDetailArr.product_slug}
            />
            <input
              type="hidden"
              id="modProductId"
              name="modProductId"
              value={proDetailArr.product_id}
            />
            <input
              type="hidden"
              id="modProductPrice"
              name="modProductPrice"
              value={modProductPrice}
            />
            <input
              type="hidden"
              id="modParentProductId"
              name="modParentProductId"
              value={this.state.modParentProductId}
            />
            <input
              type="hidden"
              id="modProductTotalPrice"
              name="modProductTotalPrice"
              value={modProductTotalPrice}
            />

            <div className="product_chosen_col">
              <div className="product_chosen_col_inner">
                {this.modifierProOptions(proDetailArr)}
              </div>
            </div>

            <div className="product_chosen_col product_chosen_col_right">
              <div className="text-box">
                <textarea
                  placeholder={language.remarkplacehoder}
                  name="special_notes"
                  id="special_notes"
                  maxLength="30"
                  value={this.state.product_remarks}
                  onChange={this.updateRemarks.bind(this)}
                ></textarea>
                <em>
                  {this.state.remaining} {language.remainingchar}
                </em>
              </div>
            </div>
          </div>
        </div>

        <div className="prd_chosen_sub_row productdetails-sticky">
          <div className="total_price product_details_price">
            <sup>¥</sup>
          </div>

          <div className="sub-save">
            <ul>
              <li>
                <div className="sub-save-lhs">
                  {/*  <input type="checkbox" className="bigbox" />
                  <label>{language.onetimepurchase}</label> */}
                </div>
                <div className="sub-save-rhs">
                  <div className="total_price product_details_price">
                    <span id="id_price_final">{modProductTotalPrice}</span>
                  </div>
                </div>
              </li>
              {/* <li>
                <div className="sub-save-lhs">
                  <input type="checkbox" className="bigbox" />
                  <label>購読して稼ぐ</label>
                  <span>最大{addedPoints}％のポイントを獲得できます</span>
                </div>
                <div className="sub-save-rhs">
                  <span className="wpro">{addedPoints}％ポイント獲得</span>
                </div>
              </li> */}
            </ul>
          </div>

          <div className="prd_chosen_sub_col popup_addcart_cls modfir_addcart_cls">
            <div
              className="addcart_row prd_chosen_sub_item_left cart_update_div mdf_update_div"
              /* style={{ display: "none" }} */
            >
              <p className="sel-quality">{language.nubmers}</p>
              <div className="qty_bx">
                <span
                  className="qty_minus"
                  onClick={this.mfdrQtyAction.bind(this, "decr")}
                >
                  -
                </span>
                <input
                  type="text"
                  value={this.state.mdfinput_value}
                  className="modfir_proqty_input"
                  readOnly="1"
                />
                <span
                  className="qty_plus"
                  onClick={this.mfdrQtyAction.bind(this, "incr")}
                >
                  +
                </span>
              </div>
            </div>

            <div className="prd_chosen_sub_item_right cart_update_div mdf_update_div addtocartbtn">
              <button
                onClick={this.addToCartModifier.bind(
                  this,
                  proDetailArr,
                  "done"
                )}
              >
                {language.buynow}
              </button>
            </div>

            <div
              className="prd_chosen_sub_item_right prd_chosen_item_full cart_add_div mdf_add_div"
              style={{ display: "none" }}
            >
              <button
                onClick={this.addToCartModifier.bind(
                  this,
                  proDetailArr,
                  "initial"
                )}
              >
                {language.buynow}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /* show modifier product option */
  modifierProOptions(mdfproducts) {
    var modifiersArr =
      mdfproducts.modifiers !== null && mdfproducts.modifiers !== ""
        ? mdfproducts.modifiers
        : Array();

    if (modifiersArr.length > 0) {
      const html = modifiersArr.map((item, index) => (
        <div
          className="product_chosen_item_left product_chosen_item_left_full"
          key={index}
        >
          <div className="product_chosen_addons">
            <div className="product_chosen_hea">
              <h6>{stripslashes(item.pro_modifier_name)}</h6>
              <span></span>
            </div>
          </div>

          <div className="form-group custom-select-bxcls">
            <div className={"re_select re_select_" + index}>
              {this.modifierValuesOpn(item)}
            </div>
          </div>
        </div>
      ));

      return html;
    } else {
      return "";
    }
  }

  modifierValuesOpn(mdfMainArr) {
    var mdfrValueArr =
      mdfMainArr.modifiers_values !== null && mdfMainArr.modifiers_values !== ""
        ? mdfMainArr.modifiers_values
        : Array();
    if (mdfrValueArr.length > 0) {
      var mainMdfId = mdfMainArr.pro_modifier_id;
      var mainMdfNm = stripslashes(mdfMainArr.pro_modifier_name);
      var mdfrSelectBoxHtml = "";
      var defaultMdfVal = "";
      $.each(mdfrValueArr, function(index, item) {
        var datamdfVl =
          mainMdfId +
          "~" +
          mainMdfNm +
          "~" +
          stripslashes(item.pro_modifier_value_name) +
          "~" +
          item.pro_modifier_value_id +
          "~" +
          item.pro_modifier_value_price;

        var incPrc =
          parseFloat(item.pro_modifier_value_price) > 0
            ? " ( + $" + item.pro_modifier_value_price + " )"
            : "";

        mdfrSelectBoxHtml +=
          "<option value='" +
          item.pro_modifier_value_id +
          "' data-mdfvl='" +
          datamdfVl +
          "'>" +
          item.pro_modifier_value_name +
          incPrc +
          "</option>";
        if (item.pro_modifier_value_is_default === "Yes") {
          defaultMdfVal = item.pro_modifier_value_id;
        }
      });
      var mdfrSelectDropDown = Parser(mdfrSelectBoxHtml);
      var statMdfVal = this.state["modifier~~" + mainMdfId];
      var mdfSelectVl =
        statMdfVal !== undefined && statMdfVal !== ""
          ? statMdfVal
          : defaultMdfVal;

      return (
        <select
          name="modifier_main"
          value={mdfSelectVl}
          className="modifierList"
          onChange={this.handleChange.bind(this, mainMdfId)}
          id={"modif" + mainMdfId}
        >
          {mdfrSelectDropDown}
        </select>
      );
    } else {
      return "";
    }
  }

  handleChange(mdfVl, event) {
    $("#modErrorDiv").hide();
    this.setState({
      mdfApi_call: "Yes",
      ["modifier~~" + mdfVl]: event.target.value,
    });
  }

  mfdrQtyAction(actionFlg) {
    var proqtyInput = $(".modfir_proqty_input").val();
    var modProductPrice = $("#modProductPrice").val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }

    var productTotalPrice =
      parseFloat(modProductPrice) * parseFloat(proqtyInput);
    productTotalPrice = parseFloat(productTotalPrice).toFixed(2);

    /*$('.modfir_proqty_input').val(proqtyInput);*/
    this.setState({
      mdfApi_call: "No",
      mdfinput_value: proqtyInput,
      modProductTotalPrice: productTotalPrice,
    });
  }

  updateRemarks(event) {
    this.setState({ mdfApi_call: "No", product_remarks: event.target.value });
    this.setState({ remaining: 30 - event.target.value.length });
  }

  addToCartModifier(mdfProdDetail, actionFlg) {
    if (actionFlg === "initial") {
      $(".mdf_add_div").hide();
      $(".mdf_update_div").show();
      return false;
    } else {
      var modCount = $(".modifierList").length;
      $("#modErrorDiv").hide();
      var modifier = [];
      if (modCount > 0) {
        var errorChk = 0;
        $(".modifierList").each(function() {
          var modVal = $(this).val();
          var modSelectVal = $(this)
            .find("option:selected")
            .attr("data-mdfvl");
          if (modVal != "" && modSelectVal != "") {
            var modifier_sets = modSelectVal.split("~");
            var modifierVal = [];
            modifierVal.push({
              modifier_value_id: modifier_sets[3],
              modifier_value_qty: "1",
              modifier_value_name: modifier_sets[2],
              modifier_value_price: modifier_sets[4],
            });
            modifier.push({
              modifier_id: modifier_sets[0],
              modifier_name: modifier_sets[1],
              modifiers_values: modifierVal,
            });
          } else if (modSelectVal == "" || modVal == "") {
            errorChk = 1;
          }
        });

        if (errorChk == 1) {
          $(".mdfcart_error_msg").html(language.productinvalid);
          $(".mdfcart_error_msg").show();
          $(".mdfcart_error_msg")
            .delay(6000)
            .fadeOut();
          return false;
        } else if (errorChk == 0) {
          showLoader("addtocartbtn", "class");

          var availabilityId = cookie.load("defaultAvilablityId");
          var customerId = this.state.customerId;
          /*var availabilityId = deliveryId;*/
          var availabilityName =
            availabilityId === deliveryId ? "Delivery" : "Pickup";

          var productRemarks = this.state.product_remarks;
          var prodcutQty = this.state.mdfinput_value;
          var modParentId = this.state.modParentProductId;
          var modProductId = $("#modProductId").val();

          var postObject = {};
          postObject = {
            app_id: appId,
            product_id: modProductId,
            product_modifier_parent_id: modParentId,
            availability_id: availabilityId,
            availability_name: availabilityName,
            product_qty: prodcutQty,
            product_type: 4,
            modifiers: JSON.stringify(modifier),
            menu_set_component: "",
            product_remarks: productRemarks,
            reference_id: customerId === "" ? getReferenceID() : "",
            customer_id: customerId,
            access_token:
              cookie.load("access_token") !== "" &&
              typeof cookie.load("access_token") !== undefined &&
              typeof cookie.load("access_token") !== "undefined"
                ? cookie.load("access_token")
                : "",
          };
          $(".hcartdd_trigger").removeClass("bounce");
          Axios.post(
            apiUrlV2 + "cart/simpleCartInsert",
            qs.stringify(postObject),
            headerconfig
          ).then((res) => {
            hideLoader("addtocartbtn", "class");
            if (res.data.status === "ok") {
              /* $(".mdf_update_div").hide();
              $(".mdf_add_div").show(); */

              $(".mdfcart_success_msg").show();
              $(".mdfcart_success_msg")
                .delay(6000)
                .fadeOut();
              this.setState({ cartTriggerFlg: "yes" });
              removePromoCkValue();
              $(".hcartdd_trigger").addClass("bounce");
              /*   showCustomAlert(
                "success",
                "偉大な選択肢！カートにアイテムが追加されました。",
                "cart-success-msg"
              ); */
              return false;
            } else if (res.data.status === "error") {
              var errMsgtxt =
                res.data.message !== ""
                  ? res.data.message
                  : language.productinvalid;
              $(".mdfcart_error_msg").html(errMsgtxt);
              $(".mdfcart_error_msg").show();
              $(".mdfcart_error_msg")
                .delay(6000)
                .fadeOut();
            }
          });
        }
      } else {
        $(".mdfcart_error_msg").html(language.productinvalid);
        $(".mdfcart_error_msg").show();
        $(".mdfcart_error_msg")
          .delay(6000)
          .fadeOut();
        return false;
      }
    }
  }

  /* show combo product Details */
  comboProDetails(proDetailArr) {
    var comboArr =
      proDetailArr.set_menu_component !== null &&
      proDetailArr.set_menu_component !== ""
        ? proDetailArr.set_menu_component
        : Array();
    if (Object.keys(comboArr).length > 0) {
      var minMaxSelect = parseInt(proDetailArr.product_apply_minmax_select);
      //var compoTotalPrice = proDetailArr.product_price;
      var compoTotalPrice =
        proDetailArr.product_specialprice_applicable === "yes"
          ? proDetailArr.product_special_price
          : proDetailArr.product_price;
      if (this.state.purchaseType === "subscribe") {
        var compoTotalPrice = proDetailArr.product_subscription_price;
      }
      var compoTotalDisplayPrice = showPrices(compoTotalPrice);
      //$("#compoTotalPrice").html(showPrices(compoTotalPrice));
      var subscriptionCycle = [];
      var defaultSubscrip = [];

      if (
        proDetailArr.product_subscription_cycle !== "" &&
        proDetailArr.product_subscription_cycle !== null
      ) {
        proDetailArr.product_subscription_cycle.split(",").map((item) => {
          var label = "";
          var percentageearnpoint = "";
          if (item === "D") {
            var addedPoints_Split = "";
            var added_Points = "";
            if (
              proDetailArr.product_subscription_daily_point !== "" &&
              proDetailArr.product_subscription_daily_point !== null
            ) {
              var addedPoints_Split = proDetailArr.product_subscription_daily_point.split(
                "."
              );
              if (addedPoints_Split[1] === "00") {
                added_Points = addedPoints_Split[0];
              }
              percentageearnpoint = " - " + added_Points + "%";
            }
            label = "毎日" + percentageearnpoint;
            defaultSubscrip = {
              value: item,
              label: label + percentageearnpoint,
            };
          } else if (item === "W") {
            var addedPoints_Split = "";
            var added_Points = "";
            if (
              proDetailArr.product_subscription_weekly_point !== "" &&
              proDetailArr.product_subscription_weekly_point !== null
            ) {
              var addedPoints_Split = proDetailArr.product_subscription_weekly_point.split(
                "."
              );
              if (addedPoints_Split[1] === "00") {
                added_Points = addedPoints_Split[0];
              }
              percentageearnpoint = " - " + added_Points + "%";
            }
            label = "毎週" + percentageearnpoint;
            if (defaultSubscrip === "") {
              defaultSubscrip = {
                value: item,
                label: label + percentageearnpoint,
              };
            }
          } else if (item === "M") {
            var addedPoints_Split = "";
            var added_Points = "";
            if (
              proDetailArr.product_subscription_monthly_point !== "" &&
              proDetailArr.product_subscription_monthly_point !== null
            ) {
              var addedPoints_Split = proDetailArr.product_subscription_monthly_point.split(
                "."
              );
              if (addedPoints_Split[1] === "00") {
                added_Points = addedPoints_Split[0];
              }
              percentageearnpoint = " - " + added_Points + "%";
            }
            label = "毎月" + percentageearnpoint;
            if (defaultSubscrip === "") {
              defaultSubscrip = {
                value: item,
                label: label + percentageearnpoint,
              };
            }
          }
          subscriptionCycle.push({ value: item, label: label });
        });
      }
      var addedPoints = "";
      if (proDetailArr.product_enable_subscription === "1") {
        if (this.state.subscription_cycle.value === "D") {
          addedPoints = proDetailArr.product_subscription_daily_point;
        } else if (this.state.subscription_cycle.value === "W") {
          addedPoints = proDetailArr.product_subscription_weekly_point;
        } else if (this.state.subscription_cycle.value === "M") {
          addedPoints = proDetailArr.product_subscription_monthly_point;
        } else {
          addedPoints = proDetailArr.product_subscription_daily_point;
        }
        if (addedPoints !== "") {
          var addedPointsSplit = addedPoints.split(".");
          if (addedPointsSplit[1] === "00") {
            addedPoints = addedPointsSplit[0];
          }
        }
      }
      return (
        <div>
          <div className="prd_chosen_row compo_top_div">
            <div className="product_chosen_inner">
              <input
                type="hidden"
                id="set_menu_component_type"
                name="set_menu_component_type"
                value={minMaxSelect}
              />
              <input
                type="hidden"
                id="product_unitprice"
                name="product_unitprice"
                value={compoTotalPrice}
              />
              <input
                type="hidden"
                id="incr_compo_price"
                name="incr_compo_price"
                value={this.state.incrCompoPrice}
              />

              <div className="product_chosen_col common_compo_div">
                {minMaxSelect === 0 && this.singleComboDet(comboArr)}
                {minMaxSelect === 1 && this.multipleComboDet(comboArr)}
              </div>

              <div
                className="product_chosen_col product_chosen_col_right"
                style={{ display: "none" }}
              >
                <div className="text-box">
                  <textarea
                    placeholder={language.remarkplacehoder}
                    name="special_notes"
                    id="special_notes"
                    maxLength="30"
                    value={this.state.product_remarks}
                    onChange={this.updateRemarksCompo.bind(this)}
                  ></textarea>
                  <em>
                    {this.state.remaining} {language.remainingchar}
                  </em>
                </div>
              </div>
            </div>
          </div>
          <div className="sub-save">
            <ul>
              <li>
                <div className="sub-save-lhs">
                  {proDetailArr.product_enable_subscription === "1" && (
                    <div>
                      <input
                        type="radio"
                        defaultChecked={
                          this.state.purchaseType === "onetime" ? true : false
                        }
                        name="purchaseType"
                        className="bigbox"
                        onChange={this.handleChangepurchaseType.bind(this)}
                        value="onetime"
                        id="onetime"
                      />
                      <label htmlFor="onetime">
                        {language.onetimepurchase}
                      </label>
                    </div>
                  )}
                </div>

                <div className="sub-save-rhs">
                  <div className="total_price product_details_price">
                    <div className="product-price special_price">
                      <input
                        type="hidden"
                        id="current_price"
                        value={
                          proDetailArr.product_specialprice_applicable === "yes"
                            ? parseFloat(proDetailArr.product_price) > 0 &&
                              proDetailArr.product_price
                            : parseFloat(proDetailArr.product_cost) > 0 &&
                              proDetailArr.product_cost
                        }
                      />
                      {proDetailArr.product_specialprice_applicable === "yes"
                        ? parseFloat(proDetailArr.product_price) > 0 && (
                            <span className="price_disc">
                              {showPriceValueSub(
                                proDetailArr.product_price,
                                "Y"
                              )}
                            </span>
                          )
                        : parseFloat(proDetailArr.product_cost) > 0 && (
                            <span className="price_disc">
                              {showPriceValueSub(
                                proDetailArr.product_cost,
                                "Y"
                              )}
                            </span>
                          )}
                    </div>

                    <span id="compoprice_final">{compoTotalDisplayPrice}</span>
                  </div>
                </div>
              </li>

              {proDetailArr.product_enable_subscription === "1" && (
                <li>
                  <div className="sub-save-lhs">
                    <input
                      type="radio"
                      defaultChecked={
                        this.state.purchaseType === "subscribe" ? true : false
                      }
                      name="purchaseType"
                      onChange={this.handleChangepurchaseType.bind(this)}
                      className="bigbox"
                      value="subscribe"
                      id="subscribe"
                    />
                    <label htmlFor="subscribe">{language.subscribeearn}</label>
                    {parseFloat(addedPoints) > 0 && (
                      <span>最大{addedPoints}％のポイントを獲得できます</span>
                    )}
                  </div>
                  {this.state.purchaseType === "subscribe" && (
                    <>
                      {parseFloat(addedPoints) > 0 && (
                        <div className="sub-save-rhs">
                          <span className="wpro">
                            {addedPoints}％ポイント獲得
                          </span>
                        </div>
                      )}
                      <div
                        className="suscription-display"
                        onClick={this.openSubscription.bind(this)}
                      >
                        {this.state.subscription_cycle !== "" ? (
                          <span>
                            {" "}
                            {language.deliveryeverything} 1{" "}
                            {subscribeCycle(
                              this.state.subscription_cycle.value
                            )}
                          </span>
                        ) : (
                          <span>{language.selectdeliverycycle}</span>
                        )}
                        {this.state.subscriptionError != "" && (
                          <span className="error">
                            {language.thisfieldrequired}
                          </span>
                        )}
                      </div>

                      <div
                        id="subscription-popup"
                        className="mfp-hide popup_sec subscription-popup"
                      >
                        <div className="pop-whole full-login-new">
                          <div className="full-login-new-header">
                            <h3>{language.subscribdeliverypace}</h3>
                            <a
                              href={void 0}
                              className="close-sub"
                              onClick={this.closeSubscriptionPopup.bind(this)}
                            ></a>
                          </div>
                          <div className="full-login-new-body">
                            <ul>
                              <li>
                                <span>{language.deliveryeverything}</span>
                                <div className="subscription-cycle">
                                  <Select
                                    /* defaultValue={defaultSubscrip} */
                                    placeholder={language.select}
                                    options={subscriptionCycle}
                                    onChange={this.onChangeOption.bind(
                                      this,
                                      "subscription_cycle"
                                    )}
                                  />
                                  {this.state.sub_cycle_error !== "" && (
                                    <span className="error">
                                      {this.state.sub_cycle_error}
                                    </span>
                                  )}
                                </div>
                              </li>
                              <li>
                                <span>{language.firstdelivery}</span>
                                <div className="syd_date">
                                  <div className="form-group222">
                                    <DatePicker
                                      className="form-control ordr-datetime-cls"
                                      minDate={this.state.subscriptionMinDate}
                                      dateFormat="yyyy/dd/MM"
                                      selected={this.state.startDate}
                                      onChange={this.handleChangeDate}
                                      locale="ja"
                                      autoComplete="off"
                                    />
                                    {this.state.sub_date_error !== "" && (
                                      <span className="error">
                                        {this.state.sub_date_error}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <div className="subscription-info">
                              {language.subscribmsg}
                            </div>
                            <div className="btn_sec">
                              <a
                                href={void 0}
                                onClick={this.confirmSubscription.bind(this)}
                                className="gradient-btn"
                              >
                                {language.conform}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </li>
              )}

              {/* <li>
                <div className="sub-save-lhs">
                  <input type="checkbox" className="bigbox" />
                  <label>購読して稼ぐ</label>
                  <span>最大{addedPoints}％のポイントを獲得できます</span>
                </div>
                <div className="sub-save-rhs">
                  <span className="wpro">{addedPoints}％ポイント獲得</span>
                </div>
              </li> */}
            </ul>
          </div>

          <div className="prd_chosen_sub_row productdetails-sticky">
            <div className="prd_chosen_sub_col popup_addcart_cls compo_addcart_cls">
              {/* style={{ display: "none" }}  */}

              <div className="addcart_row prd_chosen_sub_item_left cart_update_div compo_update_div">
                <p className="sel-quality">{language.nubmers}</p>
                <div className="qty_bx">
                  <span
                    className="qty_minus"
                    onClick={this.compoQtyAction.bind(this, "decr")}
                  >
                    -
                  </span>
                  <input
                    type="text"
                    value={this.state.compoinput_value}
                    className="compo_proqty_input"
                    readOnly="1"
                  />
                  <span
                    className="qty_plus"
                    onClick={this.compoQtyAction.bind(this, "incr")}
                  >
                    +
                  </span>
                </div>
              </div>

              <div className="prd_chosen_sub_item_right cart_update_div compo_update_div addtocartbtn">
                <button
                  onClick={this.addToCartCombo.bind(this, proDetailArr, "done")}
                >
                  {language.updatebuynow}
                </button>
              </div>
              {/* <div className="cart-success-msg alert alert_success compocart_success_msg"></div>
              <div className="cart-error-msg alert alert_danger compocart_error_msg"></div> */}
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  singleComboDet(compoProDetailArr) {
    if (Object.keys(compoProDetailArr).length > 0) {
      const html = compoProDetailArr.map((item, index1) => (
        <div
          className={
            "product_chosen_item_left product_chosen_item_left_full compo_list_div main_combo_div compo_acc_active maincombo-" +
            item.menu_component_id
          }
          data-maincomboidtxt={item.menu_component_id}
          data-combodata={
            item.menu_component_id + "~" + item.menu_component_name + "~0"
          }
          data-combopriceapply={
            item.menu_component_apply_price !== ""
              ? item.menu_component_apply_price
              : 0
          }
          key={"compo" + index1}
        >
          <div className="product_chosen_addons compo_pro_acc compo_acc_action">
            <div className="product_chosen_hea compopro_acc_head">
              <h6>{stripslashes(item.menu_component_name)}</h6>
            </div>
          </div>

          <div className="compo_acc_innerdiv">
            <div className="form-group custom-select-bxcls">
              {this.showSingleComboOptions(item)}
            </div>

            <div
              className="mdfr_list_divlcs"
              data-mismatchpro=""
              data-invcomboprice=""
            >
              <div
                className="mdfr_list_divlcs_error"
                style={{ display: "none", color: "red" }}
              >
                {language.validmodifier}
              </div>
              {this.showSingleComboMdfr(item)}
            </div>
          </div>
        </div>
      ));

      return (
        <div className="product_chosen_col_inner compo_inner_main">{html}</div>
      );
    } else {
      return "";
    }
  }

  /* show single combo product option */
  showSingleComboOptions(splProducts) {
    var compoListArr =
      splProducts.product_details !== null && splProducts.product_details !== ""
        ? splProducts.product_details
        : Array();
    var menuComponentApplyPrice =
      splProducts.menu_component_apply_price !== ""
        ? splProducts.menu_component_apply_price
        : 0;
    if (compoListArr.length > 0) {
      var defaultSelectVl = splProducts.menu_component_default_select;
      var menuSetCmpId = splProducts.menu_set_component_id;
      var menuCmpId = splProducts.menu_component_id;
      var menuComponentNm = stripslashes(splProducts.menu_component_name);
      var compSelectBoxHtml = "";
      var mdfMainComboProId = "";
      var ismdfrProCount = 0;
      var comboPropriceAply = "";
      var comboProPrice = 0;
      var indvlComponentNm = "";
      var showPricetxt = "";
      var compomainselval = "";
      var $_react_this = this;
      $.each(compoListArr, function(index2, item1) {
        mdfMainComboProId = item1[0].product_id;
        ismdfrProCount =
          item1[0].modifiers !== null && item1[0].modifiers !== ""
            ? Object.keys(item1[0].modifiers).length
            : 0;
        /* comboProPrice = item1[0].product_price; */

        var specialpriceapply = "no";
        var specialpriceval = 0;
        comboProPrice = item1[0].product_price;
        if ($_react_this.state.purchaseType === "subscribe") {
          comboProPrice = item1[0].product_subscription_price;
        } else if (item1[0].product_specialprice_applicable === "yes") {
          specialpriceapply = "yes";
          specialpriceval = item1[0].product_price;
          comboProPrice = item1[0].product_special_price;
        }

        var productrevelid = item1[0].product_revel_id;
        var productdimension =
          item1[0].product_height +
          "~" +
          item1[0].product_width +
          "~" +
          item1[0].product_weight +
          "~" +
          item1[0].product_volume;

        comboPropriceAply =
          parseFloat(comboProPrice) > 0 &&
          parseInt(menuComponentApplyPrice) === 1 &&
          parseInt(ismdfrProCount) === 0
            ? comboProPrice
            : 0;
        indvlComponentNm =
          item1[0].product_alias !== ""
            ? stripslashes(item1[0].product_alias)
            : stripslashes(item1[0].product_name);
        /*  $ */
        showPricetxt =
          parseFloat(comboPropriceAply) > 0
            ? " ( +" + parseFloat(comboPropriceAply).toFixed(2) + " )"
            : "";

        compomainselval =
          item1[0].product_id +
          "~" +
          indvlComponentNm +
          "~" +
          item1[0].product_sku +
          "~" +
          comboProPrice;

        compSelectBoxHtml +=
          "<option value='" +
          item1[0].product_id +
          "' data-compomainselval='" +
          compomainselval +
          "' data-mdfcombopro='" +
          mdfMainComboProId +
          "' data-combopropriceaply='" +
          comboPropriceAply +
          "' data-specialpriceapply='" +
          specialpriceapply +
          "' data-specialpriceval='" +
          specialpriceval +
          "' data-productrevelid='" +
          productrevelid +
          "' data-productdimension='" +
          productdimension +
          "' data-ismdfrprochk='" +
          ismdfrProCount +
          "' >" +
          indvlComponentNm +
          "</option>";
        /*  showPricetxt + */
      });

      var compSelectDropDown = Parser(compSelectBoxHtml);
      var statMdfVal = this.state["compo~~" + menuCmpId];
      var mdfSelectVl =
        statMdfVal !== undefined && statMdfVal !== ""
          ? statMdfVal
          : defaultSelectVl;

      return (
        <select
          name="compomain_select"
          value={mdfSelectVl}
          className="components_selct components_selctbox_cls"
          onChange={this.handleChangeCompoMain.bind(this, menuCmpId)}
          id={"cmpp" + menuCmpId}
        >
          {compSelectDropDown}
        </select>
      );
    } else {
      return "";
    }
  }

  showSingleComboMdfr(splProducts) {
    var compoListArr =
      splProducts.product_details !== null && splProducts.product_details !== ""
        ? splProducts.product_details
        : Array();
    if (compoListArr.length > 0) {
      var defaultSelectVl = splProducts.menu_component_default_select;
      var menuSetCmpId = splProducts.menu_set_component_id;
      var menuCmpId = splProducts.menu_component_id;
      var menuComponentNm = stripslashes(splProducts.menu_component_name);

      const html = compoListArr.map((item1, index2) => (
        <div className="mdfr_list_divlcs_inv" key={"cmdf-" + index2}>
          {item1[0].modifiers !== null &&
            item1[0].modifiers !== "" &&
            this.singleComboMdfrOption(
              menuCmpId,
              item1[0].product_id,
              item1[0].modifiers
            )}
        </div>
      ));

      return html;
    } else {
      return "";
    }
  }

  singleComboMdfrOption(menuCmpIdTxt, productIdTxt, cmpProMdfr) {
    if (Object.keys(cmpProMdfr).length > 0) {
      const html = cmpProMdfr.map((item2, index3) => (
        <div
          className={
            "compoMdfr_item_left individual_combo_mdf compo_mdf_" + productIdTxt
          }
          key={"cmd-" + index3}
        >
          <div className="product_chosen_addons">
            <div className="product_chosen_hea">
              <h6>{stripslashes(item2.pro_modifier_name)}</h6>
            </div>
          </div>

          <div className="form-group custom-select-bxcls">
            <div className={"re_select re_select_cmd" + index3}>
              {this.singleComboMdfrValuesOpn(menuCmpIdTxt, item2)}
            </div>
          </div>
        </div>
      ));

      return html;
    } else {
      return "";
    }
  }

  singleComboMdfrValuesOpn(menuCmpIdTxt, mdfMainArr) {
    var mdfrValueArr =
      mdfMainArr.modifiers_values !== null && mdfMainArr.modifiers_values !== ""
        ? mdfMainArr.modifiers_values
        : Array();
    if (mdfrValueArr.length > 0) {
      var mainMdfId = mdfMainArr.pro_modifier_id;
      var mainMdfNm = stripslashes(mdfMainArr.pro_modifier_name);
      var mdfrSelectBoxHtml = "";
      var defaultMdfVal = "";
      $.each(mdfrValueArr, function(index4, item3) {
        var datamdfVl =
          mainMdfId +
          "~" +
          mainMdfNm +
          "~" +
          item3.pro_modifier_value_id +
          "~" +
          stripslashes(item3.pro_modifier_value_name) +
          "~" +
          item3.pro_modifier_value_price;

        var incPrc =
          parseFloat(item3.pro_modifier_value_price) > 0
            ? " ( + $" + item3.pro_modifier_value_price + " )"
            : "";

        mdfrSelectBoxHtml +=
          "<option value='" +
          item3.pro_modifier_value_id +
          "' data-selectmdfval='" +
          datamdfVl +
          "' data-mdfrpricevaluetxt='" +
          item3.pro_modifier_value_price +
          "' >" +
          item3.pro_modifier_value_name +
          incPrc +
          "</option>";
        if (item3.pro_modifier_value_is_default === "Yes") {
          defaultMdfVal = item3.pro_modifier_value_id;
        }
      });
      var mdfrSelectDropDown = Parser(mdfrSelectBoxHtml);
      var statMdfVal = this.state[
        "compoInner~~" + menuCmpIdTxt + "~~" + mainMdfId
      ];
      var mdfSelectVl =
        statMdfVal !== undefined && statMdfVal !== ""
          ? statMdfVal
          : defaultMdfVal;

      return (
        <select
          name="compoinner_select"
          value={mdfSelectVl}
          className="components_mdf_selct"
          onChange={this.handleChangeCompoInner.bind(
            this,
            menuCmpIdTxt,
            mainMdfId
          )}
          id={"modifvl" + mainMdfId}
        >
          {mdfrSelectDropDown}
        </select>
      );
    } else {
      return "";
    }
  }

  multipleComboDet(compoProDetailArr) {
    if (Object.keys(compoProDetailArr).length > 0) {
      const html = compoProDetailArr.map((item, index1) => (
        <div
          className={
            "product_chosen_item_left product_chosen_item_left_full compo_list_div main_combo_div minmax_maincombo_div compo_acc_active maincombo-" +
            item.menu_component_id
          }
          data-maincomboidtxt={item.menu_component_id}
          data-combodata={
            item.menu_component_id + "~" + item.menu_component_name + "~1"
          }
          data-combopriceapply={
            item.menu_component_apply_price !== ""
              ? item.menu_component_apply_price
              : 0
          }
          data-minselectcombo={item.menu_component_min_select}
          data-maxselectcombo={item.menu_component_max_select}
          data-modifierapply={item.menu_component_modifier_apply}
          key={"compo" + index1}
        >
          <div className="product_chosen_addons compo_pro_acc compo_acc_action">
            <div className="product_chosen_hea compopro_acc_head">
              <h6>{stripslashes(item.menu_component_name)}</h6>
            </div>
          </div>

          <div className="compo_acc_innerdiv">
            {item.menu_component_modifier_apply === "1" ? (
              <div className="compo_mdfselect_maindiv">
                <div className="form-group custom-select-bxcls">
                  {this.showSingleComboOptions(item)}
                </div>

                <div
                  className="mdfr_list_divlcs"
                  data-mismatchpro=""
                  data-invcomboprice=""
                  data-invcombosplpriceaply=""
                  data-invcombosplprice=""
                  data-productrevelidmain=""
                  data-productdimension=""
                >
                  <div
                    className="mdfr_list_divlcs_error"
                    style={{ display: "none", color: "red" }}
                  >
                    {language.validmodifier}
                  </div>
                  {this.showSingleComboMdfr(item)}
                </div>
              </div>
            ) : (
              <div className="compo_minmax_maindiv">
                <div className="max-min-bar">
                  {"You've chosen"} <span className="minSelectCls">0</span> (
                  {language.min}. {item.menu_component_min_select} &{" "}
                  {language.max}. {item.menu_component_max_select}){" "}
                </div>

                <div className="error_combo_div"> {language.selectminmax} </div>

                {this.showMultipleComboOptions(item)}
              </div>
            )}
          </div>
        </div>
      ));

      return (
        <div className="product_chosen_col_inner compo_inner_main">{html}</div>
      );
    } else {
      return "";
    }
  }

  showComboMultiSelect(mutilSlct) {
    return mutilSlct === 1 ? "none" : "";
  }

  /* show multiple combo product option */
  showMultipleComboOptions(splProducts) {
    var multiSelectApply =
      splProducts.menu_component_multipleselection_apply !== ""
        ? parseInt(splProducts.menu_component_multipleselection_apply)
        : 0;
    var compoListArr =
      splProducts.product_details !== null && splProducts.product_details !== ""
        ? splProducts.product_details
        : Array();
    var menuComponentApplyPrice =
      splProducts.menu_component_apply_price !== ""
        ? splProducts.menu_component_apply_price
        : 0;
    if (compoListArr.length > 0) {
      var defaultSelectVl = splProducts.menu_component_default_select;
      var menuSetCmpId = splProducts.menu_set_component_id;
      var menuCmpId = splProducts.menu_component_id;

      const compoMtplHtml = compoListArr.map((item1, index1) => (
        <div
          className={
            "chosen_adn mdfr_list_divlcs individual_combo_pro indvcombo-" +
            menuCmpId +
            "-" +
            item1[0].product_primary_id
          }
          data-mismatchpro=""
          data-invcomboprice="0"
          data-comboprice={item1[0].product_price}
          data-productdata={this.getCmpProData(item1)}
          key={index1}
        >
          <div className="bb-txt2 margin-15 chosen_adn_left">
            <span>
              {item1[0].product_alias !== ""
                ? stripslashes(item1[0].product_alias)
                : stripslashes(item1[0].product_name)}
            </span>{" "}
            <span className="combo_pro_price" style={{ display: "none" }}>
              {parseFloat(item1[0].product_price) > 0
                ? " ( +" + currencySymbol + item1[0].product_price + " )"
                : ""}
            </span>
          </div>
          <div
            className="radio pull-right combo-inc-parent chosen_adn_right"
            style={{ display: this.showComboMultiSelect(multiSelectApply) }}
          >
            <div className="qty_bx">
              <span
                className="qty_minus combo_inc"
                onClick={this.decComboQty.bind(
                  this,
                  menuCmpId,
                  item1[0].product_primary_id
                )}
              >
                -
              </span>

              <label
                type="text"
                disabled
                data-qtyval="0"
                className="combo-input-label combo-input combolst_qty_value"
              >
                0
              </label>

              <span
                className="qty_plus combo_dec"
                onClick={this.incComboQty.bind(
                  this,
                  menuCmpId,
                  item1[0].product_primary_id
                )}
              >
                +
              </span>
            </div>
          </div>

          {multiSelectApply === 1 && (
            <div className="checkboxcls pull-right">
              <input
                className="css-checkboxcls"
                type="checkbox"
                onChange={this.comboMultiSelectUpdate.bind(
                  this,
                  menuCmpId,
                  item1[0].product_primary_id
                )}
                value={item1[0].product_price}
                name={"comboMultiStVal_" + menuCmpId}
                id={
                  "comboMultiStVal_" +
                  menuCmpId +
                  "_" +
                  item1[0].product_primary_id
                }
              />{" "}
              <label
                htmlFor={
                  "comboMultiStVal_" +
                  menuCmpId +
                  "_" +
                  item1[0].product_primary_id
                }
                className="css-label-chkbox"
              ></label>
            </div>
          )}
        </div>
      ));

      return <div>{compoMtplHtml}</div>;
    } else {
      return "";
    }
  }

  getCmpProData(proData) {
    var proName =
      proData[0].product_alias !== ""
        ? stripslashes(proData[0].product_alias)
        : stripslashes(proData[0].product_name);
    var pro_datetxt =
      proData[0].product_id +
      "~" +
      proName +
      "~" +
      proData[0].product_sku +
      "~" +
      proData[0].product_price;
    return pro_datetxt;
  }

  comboMultiSelectUpdate(menuCmpId, productPryId) {
    if (
      $("#comboMultiStVal_" + menuCmpId + "_" + productPryId).prop("checked") ==
      true
    ) {
      this.incComboQty(menuCmpId, productPryId, "checkboxact");
    } else {
      this.decComboQty(menuCmpId, productPryId);
    }
  }

  incComboQty(menuCmpId, proId, actionFrm) {
    var $_this = $(".indvcombo-" + menuCmpId + "-" + proId);
    var intValInc = $_this.find(".combolst_qty_value").attr("data-qtyval");
    intValInc = intValInc !== "" ? parseInt(intValInc) : 0;
    var minselectcombo =
      $_this.closest(".main_combo_div").attr("data-minselectcombo") != ""
        ? $_this.closest(".main_combo_div").attr("data-minselectcombo")
        : "0";
    var maxselectcombo =
      $_this.closest(".main_combo_div").attr("data-maxselectcombo") != ""
        ? $_this.closest(".main_combo_div").attr("data-maxselectcombo")
        : "0";
    var combopriceapply =
      $_this.closest(".main_combo_div").attr("data-combopriceapply") != ""
        ? $_this.closest(".main_combo_div").attr("data-combopriceapply")
        : "0";
    var invCompoQty = this.getInvCompoQty($_this);
    /* actionFrm === "checkboxact" && */
    if (parseInt(invCompoQty) >= parseInt(maxselectcombo)) {
      $("#comboMultiStVal_" + menuCmpId + "_" + proId).prop("checked", false);
      return false;
    }

    var chk_val = 0;
    if (!isNaN(intValInc) && parseInt(invCompoQty) < parseInt(maxselectcombo)) {
      intValInc = parseInt(intValInc + 1);
      chk_val = 1;
    }

    $_this.find(".combolst_qty_value").attr("data-qtyval", intValInc);
    $_this.find(".combolst_qty_value").html(intValInc);

    var comboProPrice = $_this.attr("data-invcomboprice");
    var invCompoQty = this.getInvCompoQty($_this);
    if (
      (invCompoQty > parseInt(minselectcombo) ||
        parseInt(combopriceapply) == 1) &&
      chk_val == 1
    ) {
      $_this.find(".combo_pro_price").show();
      var invComboproPrice = $_this.attr("data-invcomboprice");
      var comboprice = $_this.attr("data-comboprice");
      comboProPrice = parseFloat(invComboproPrice) + parseFloat(comboprice);
    }

    if (parseInt(minselectcombo) == 0 || parseInt(combopriceapply) == 1) {
      $_this.find(".combo_pro_price").show();
    }
    $_this.attr("data-invcomboprice", comboProPrice);
    this.updateProductPricefun();
  }

  decComboQty(menuCmpId, proId) {
    var $_this = $(".indvcombo-" + menuCmpId + "-" + proId);
    var intValInc = $_this.find(".combolst_qty_value").attr("data-qtyval");
    intValInc = intValInc !== "" ? parseInt(intValInc) : 0;
    var minselectcombo =
      $_this.closest(".main_combo_div").attr("data-minselectcombo") != ""
        ? $_this.closest(".main_combo_div").attr("data-minselectcombo")
        : "0";
    var maxselectcombo =
      $_this.closest(".main_combo_div").attr("data-maxselectcombo") != ""
        ? $_this.closest(".main_combo_div").attr("data-maxselectcombo")
        : "0";
    var combopriceapply =
      $_this.closest(".main_combo_div").attr("data-combopriceapply") != ""
        ? $_this.closest(".main_combo_div").attr("data-combopriceapply")
        : "0";

    var minusChkVal = 0;
    if (!isNaN(intValInc) && parseInt(intValInc) >= 1) {
      var intValInc = parseInt(intValInc - 1);
      minusChkVal = 1;
    }

    $_this.find(".combolst_qty_value").attr("data-qtyval", intValInc);
    $_this.find(".combolst_qty_value").html(intValInc);
    var invCompoQty = this.getInvCompoQty($_this);
    var comboProPrice = $_this.attr("data-invcomboprice");
    var defComboprice = $_this.attr("data-comboprice");
    if (
      (invCompoQty >= parseInt(minselectcombo) && minusChkVal == 1) ||
      (parseInt(combopriceapply) == 1 && minusChkVal == 1)
    ) {
      if (
        parseInt(combopriceapply) == 1 &&
        minusChkVal == 1 &&
        parseFloat(defComboprice) == 0 &&
        parseFloat(comboProPrice) == 0
      ) {
        var temp_price = 0;
        $_this.attr("data-invcomboprice", temp_price);
        $_this.find(".combo_pro_price").hide();
      } else if (
        parseFloat(comboProPrice) >= parseFloat(defComboprice) &&
        parseFloat(comboProPrice) > 0
      ) {
        var temp_price = parseFloat(comboProPrice) - parseFloat(defComboprice);
        $_this.attr("data-invcomboprice", temp_price);
        if (parseFloat(temp_price) == 0) {
          $_this.find(".combo_pro_price").hide();
        }
      } else {
        var rtn_val = 0;
        $_this
          .closest(".main_combo_div")
          .find(".individual_combo_pro")
          .each(function() {
            var thisInvPrc = $(this).attr("data-invcomboprice");
            if (parseFloat(thisInvPrc) > 0 && rtn_val == 0) {
              rtn_val = 1;
              var comboproprice = thisInvPrc;
              var def_combo_price = $(this).attr("data-comboprice");
              var tempPrice =
                parseFloat(comboproprice) - parseFloat(def_combo_price);
              $(this).attr("data-invcomboprice", tempPrice);
              if (parseFloat(tempPrice) == 0) {
                $(this)
                  .find(".combo_pro_price")
                  .hide();
              }
            }
          });
      }
    }

    if (parseInt(minselectcombo) == 0 || parseInt(combopriceapply) == 1) {
      $_this.find(".combo_pro_price").show();
    }

    this.updateProductPricefun();
  }

  getInvCompoQty($_this) {
    $_this
      .closest(".main_combo_div")
      .find(".error_combo_div")
      .hide();
    var combolst_qty = 0;
    $_this
      .closest(".main_combo_div")
      .find(".combolst_qty_value")
      .each(function() {
        combolst_qty += parseInt($(this).attr("data-qtyval"));
      });
    return combolst_qty;
  }

  updateRemarksCompo(event) {
    this.setState({ compoApi_call: "No", product_remarks: event.target.value });
    this.setState({ remaining: 30 - event.target.value.length });
  }

  updateProductPricefun() {
    var minmaxMainCnt = $(".compo_minmax_maindiv").length;
    if (minmaxMainCnt > 0) {
      $(".compo_minmax_maindiv").each(function(indx) {
        var invQtyCnt = 0;
        $(this)
          .find(".individual_combo_pro")
          .each(function(indx2) {
            var qtyval = $(this)
              .find(".combolst_qty_value")
              .attr("data-qtyval");
            invQtyCnt = parseInt(invQtyCnt) + parseInt(qtyval);
          });
        $(this)
          .find(".minSelectCls")
          .html(invQtyCnt);
      });
    }

    var combo_pro_price = 0;
    var combo_pro_splprice_aply = "no";
    var combo_pro_splprice = 0;
    var productrevelidmain = "";
    var productdimension = "";
    $(".mdfr_list_divlcs").each(function() {
      var invcomboPriceVl = $(this).attr("data-invcomboprice");
      invcomboPriceVl = invcomboPriceVl !== "" ? invcomboPriceVl : 0;
      combo_pro_price += parseFloat(invcomboPriceVl);
      combo_pro_splprice_aply = $(this).attr("data-invcombosplpriceaply");
      combo_pro_splprice = $(this).attr("data-invcombosplprice");
      productrevelidmain = $(this).attr("data-productrevelidmain");
      productdimension = $(this).attr("data-productdimension");
    });

    var qty_txt =
      $(".compo_proqty_input").val() != ""
        ? parseInt($(".compo_proqty_input").val())
        : 0;
    var pro_price_val =
      $("#product_unitprice").val() != ""
        ? parseFloat($("#product_unitprice").val())
        : 0;

    /*var exc_price = parseFloat(pro_price_val) + parseFloat(combo_pro_price);*/
    var exc_price = parseFloat(combo_pro_price);
    exc_price = parseInt(qty_txt) * parseFloat(exc_price);

    $("#incr_compo_price").val(exc_price);
    $("#compoprice_final").html(
      "<sup>" +
        currencySymbol +
        "</sup>" +
        exc_price.toFixed(0).replace(/./g, function(c, i, a) {
          return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        })
    );

    if (productrevelidmain !== "") {
      $("#product_revel_idtxt").html(productrevelidmain);
    }
    if (productdimension !== "") {
      $("#product_dimension_idtxt").val(productdimension);
    }

    if (combo_pro_splprice_aply === "yes" && combo_pro_splprice !== "") {
      var priceSpecialValu = (
        parseFloat(combo_pro_splprice) *
        parseFloat($(".compo_proqty_input").val())
      )
        .toFixed(0)
        .replace(/./g, function(c, i, a) {
          return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        });

      $(".special_price .price_disc").html(
        "<sup>" + currencySymbol + "</sup>" + priceSpecialValu
      );
    } else {
      $(".special_price .price_disc").html(" ");
    }
  }

  compoQtyAction(actionFlg) {
    var proqtyInput = $(".compo_proqty_input").val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }

    this.setState({ compoApi_call: "No", compoinput_value: proqtyInput });
  }

  handleChangeCompoMain(compoVl, event) {
    this.setState({
      compoApi_call: "Yes",
      ["compo~~" + compoVl]: event.target.value,
    });
  }

  handleChangeCompoInner(menuCmpIdTxt, compoVl, event) {
    this.setState({
      compoApi_call: "Yes",
      ["compoInner~~" + menuCmpIdTxt + "~~" + compoVl]: event.target.value,
    });
  }

  getComboproData($_this, CompoType) {
    var comboproSet = Array(),
      comboproMdf = Array(),
      aplypriceZero = 0;

    if (CompoType === 1) {
      $_this.find(".individual_combo_pro").each(function() {
        var componentsProDet = $(this).attr("data-productdata");
        var compoProDet = componentsProDet.split("~");
        var combolstQtyValue = $(this)
          .find(".combolst_qty_value")
          .attr("data-qtyval");
        var comboProInvPrice = $(this).attr("data-invcomboprice");
        if (parseInt(combolstQtyValue) > 0) {
          comboproSet.push({
            product_id: compoProDet[0],
            product_name: compoProDet[1],
            product_sku: compoProDet[2],
            product_price: comboProInvPrice,
            product_qty: combolstQtyValue,
            modifiers: comboproMdf,
          });
        }
      });
    } else {
      $_this.find(".components_selct").each(function() {
        var mdfcombopro_id = $(this)
          .find(":selected")
          .attr("data-mdfcombopro");
        var combopriceapplychk = $(this)
          .closest(".main_combo_div")
          .attr("data-combopriceapply");
        var aplyprice_temp = $(this)
          .find(":selected")
          .attr("data-combopropriceaply");
        var comboMdfSet = Array();
        $(this)
          .closest(".main_combo_div")
          .find(".compo_mdf_" + mdfcombopro_id)
          .each(function() {
            var combopro_mdf_txt = $(this)
              .find(".components_mdf_selct")
              .val();
            var comboMdfValueSet = Array();
            if (combopro_mdf_txt !== "") {
              var modifierCombosets_txt = $(this)
                .find(".components_mdf_selct")
                .find("option:selected")
                .attr("data-selectmdfval");
              var mdfSetDet =
                modifierCombosets_txt !== "" &&
                modifierCombosets_txt !== undefined
                  ? modifierCombosets_txt.split("~")
                  : Array();

              if (Object.keys(mdfSetDet).length > 0) {
                comboMdfValueSet.push({
                  modifier_value_name: mdfSetDet[3],
                  modifier_value_id: mdfSetDet[2],
                  modifier_value_price: mdfSetDet[4],
                  modifier_value_qty: 1,
                });
                comboMdfSet.push({
                  modifier_name: mdfSetDet[1],
                  modifier_id: mdfSetDet[0],
                  modifiers_values: comboMdfValueSet,
                });
              }
            }
          });

        var componentsProDet = $(this)
          .find("option:selected")
          .attr("data-compomainselval");
        var compoProDet = componentsProDet.split("~");
        var combolstQtyValue = 1;
        var comboProInvPrice =
          parseFloat(combopriceapplychk) > 0
            ? parseFloat(aplyprice_temp)
            : parseFloat(aplypriceZero);
        comboproSet.push({
          product_id: compoProDet[0],
          product_name: compoProDet[1],
          product_sku: compoProDet[2],
          product_price: comboProInvPrice,
          product_qty: combolstQtyValue,
          modifiers: comboMdfSet,
        });
      });
    }

    return comboproSet;
  }

  checkMinValfun($_this) {
    var combo_qtycount = 0,
      min_val_error = 0;
    var min_selectcombo =
      $_this.attr("data-minselectcombo") != ""
        ? $_this.attr("data-minselectcombo")
        : "0";
    $_this.find(".combolst_qty_value").each(function() {
      var qtyval = $(this).attr("data-qtyval");
      combo_qtycount += parseInt(qtyval);
    });
    if (parseInt(min_selectcombo) > parseInt(combo_qtycount)) {
      min_val_error = 1;
    }
    return min_val_error;
  }

  addToCartCombo(compoProdDetail, actionFlg) {
    var InvalidMdfrCompo = "No";
    $(".main_combo_div").each(function() {
      if (
        $(this)
          .find(".mdfr_list_divlcs")
          .attr("data-mismatchpro") == "1"
      ) {
        InvalidMdfrCompo = "Yes";
      }
    });

    if (InvalidMdfrCompo == "No") {
      if (actionFlg === "initial") {
        $(".compo_add_div").hide();
        $(".compo_update_div").show();
        return false;
      } else {
        var $_react_this = this;
        var menuSet = Array();
        var productDetailsMain = Array();
        var modifier = Array();
        var CompoType = $("#set_menu_component_type").val();
        CompoType = parseInt(CompoType);
        var compo_errors = "0";

        if (CompoType === 1) {
          $(".main_combo_div").each(function() {
            var modifierapply = $(this).attr("data-modifierapply");
            if (modifierapply === "1") {
              var combodata_txt = $(this).attr("data-combodata");
              var menu_component = combodata_txt.split("~");
              var productDetails = $_react_this.getComboproData($(this), 0);
              productDetailsMain.push({
                menu_component_id: menu_component[0],
                menu_component_name: menu_component[1],
                product_details: productDetails,
                min_max_flag: menu_component[2],
                product_selection: "single",
              });
            } else {
              var check_min_val = $_react_this.checkMinValfun($(this));
              if (check_min_val == 0) {
                var combodata_txt = $(this).attr("data-combodata");
                menu_component = combodata_txt.split("~");
                var productDetails = $_react_this.getComboproData(
                  $(this),
                  CompoType
                );
                productDetailsMain.push({
                  menu_component_id: menu_component[0],
                  menu_component_name: menu_component[1],
                  product_details: productDetails,
                  min_max_flag: menu_component[2],
                  product_selection: "multiple",
                });
              } else {
                compo_errors = "1";
                $(this)
                  .find(".error_combo_div")
                  .show();
                if (
                  $("#ProductDetailMdl .error_combo_div:visible").length > 0
                ) {
                  $("html, body").animate(
                    {
                      scrollTop:
                        $(
                          "#ProductDetailMdl .error_combo_div:visible:first"
                        ).offset().top - 200,
                    },
                    1000
                  );
                }
                return false;
              }
            }
          });
        } else {
          $(".main_combo_div").each(function() {
            var combodata_txt = $(this).attr("data-combodata");
            var menu_component = combodata_txt.split("~");
            var productDetails = $_react_this.getComboproData(
              $(this),
              CompoType
            );
            if (productDetails !== "") {
              productDetailsMain.push({
                menu_component_id: menu_component[0],
                menu_component_name: menu_component[1],
                product_details: productDetails,
                min_max_flag: menu_component[2],
                product_selection: "singleonly",
              });
            }
          });
        }
        menuSet = productDetailsMain;

        var specialprice_applicable =
          compoProdDetail.product_specialprice_applicable;

        var product_actual_price =
          compoProdDetail.product_specialprice_applicable == "yes"
            ? compoProdDetail.product_special_price
            : compoProdDetail.product_price;
        if (this.state.purchaseType === "subscribe") {
          product_actual_price = compoProdDetail.product_subscription_price;
        }
        var unitprice_inputval =
          $("#product_unitprice").val() != ""
            ? $("#product_unitprice").val()
            : 0;

        /*if (
          specialprice_applicable === "yes" &&
          parseFloat(product_actual_price) !== parseFloat(unitprice_inputval)
        ) {
          compo_errors = "1";
        }*/

        if (compo_errors == "0" && Object.keys(menuSet).length > 0) {
          var prCommon = this.props.productcommon;

          var product_revel_id = $("#product_revel_idtxt").html();
          if (product_revel_id === "") {
            product_revel_id = compoProdDetail.product_revel_id;
          }

          var productdimension = $("#product_dimension_idtxt").val();
          if (productdimension === "") {
            productdimension =
              compoProdDetail.product_height +
              "~" +
              compoProdDetail.product_width +
              "~" +
              compoProdDetail.product_weight +
              "~" +
              compoProdDetail.product_volume;
          }

          var productId = compoProdDetail.product_id;
          var productName =
            compoProdDetail.product_alias !== ""
              ? stripslashes(compoProdDetail.product_alias)
              : stripslashes(compoProdDetail.product_name);
          var productImage =
            compoProdDetail.product_thumbnail !== ""
              ? prCommon.image_source + "/" + compoProdDetail.product_thumbnail
              : "";
          var productSku = compoProdDetail.product_sku;
          var productSlug = compoProdDetail.product_slug;

          var productRemarks = this.state.product_remarks;
          var prodcutQty = this.state.compoinput_value;
          var incrCompoPrice = $("#incr_compo_price").val();

          var totalCompoPrice =
            incrCompoPrice !== "" ? parseFloat(incrCompoPrice) : 0;
          var unitProductPrice =
            parseFloat(totalCompoPrice) / parseFloat(prodcutQty);
          unitProductPrice = unitProductPrice.toFixed(2);

          var availabilityId = cookie.load("defaultAvilablityId");
          var customerId = this.state.customerId;
          var productCategoryId = compoProdDetail.product_category_id;
          var productSubcategoryId = compoProdDetail.product_subcategory_id;
          if (
            parseFloat(totalCompoPrice) > 0 &&
            parseFloat(unitProductPrice) > 0
          ) {
            var subscribe = "No";
            var subscription_cycle = "";
            var subscription_date = "";

            if (
              compoProdDetail.product_enable_subscription === "1" &&
              this.state.purchaseType === "subscribe"
            ) {
              subscribe = "Yes";

              if (this.state.subscription_cycle !== "") {
                this.setState({
                  subscriptionError: "",
                });
                subscription_cycle = this.state.subscription_cycle.value;
              } else {
                this.setState({
                  subscriptionError: language.selectsubscribcycle,
                });
                return false;
              }

              subscription_date = this.state.startDate;
              if (subscription_date === "") {
                this.setState({
                  subscriptionError: language.selectsubscribstardate,
                });
                return false;
              } else {
                this.setState({
                  subscriptionError: "",
                });
              }
            }
            var deliveryDate =
              cookie.load("deliveryDate") == undefined ||
              cookie.load("deliveryDate") == ""
                ? ""
                : cookie.load("deliveryDate").split("/");
            var delivery_Date = "";
            if (deliveryDate !== "") {
              delivery_Date =
                deliveryDate[2] + "-" + deliveryDate[1] + "-" + deliveryDate[0];
            } else {
              delivery_Date = format(new Date(), "yyyy-MM-dd");
            }
            setTimeout(function() {
              showLoader("addtocartbtn", "class");
            }, 1);
            var postObject = {};
            postObject = {
              app_id: appId,
              product_id: productId,
              product_qty: prodcutQty,
              availability_id: availabilityId,
              product_name: productName,
              product_total_price: totalCompoPrice,
              product_unit_price: unitProductPrice,
              specialprice_applicable: "yes",
              product_actual_price: unitProductPrice,
              product_remarks: productRemarks,
              product_image: productImage,
              product_sku: productSku,
              product_slug: productSlug,
              product_revel_id: product_revel_id,
              product_dimension: productdimension,
              modifiers: JSON.stringify(modifier),
              menu_set_component: JSON.stringify(menuSet),
              individual: "yes",
              customer_id: customerId,
              category_id: productCategoryId,
              subcategory_id: productSubcategoryId,
              access_token:
                cookie.load("access_token") !== "" &&
                typeof cookie.load("access_token") !== undefined &&
                typeof cookie.load("access_token") !== "undefined"
                  ? cookie.load("access_token")
                  : "",
              reference_id: customerId === "" ? getReferenceID() : "",
              product_edit_enable: "No",
              subscription: subscribe,
              subscription_cycle: subscription_cycle,
              subscription_date: subscription_date,
              order_datetxt: delivery_Date,
              specialpriceapplicablefor: "orderdate",
            };
            $(".hcartdd_trigger").removeClass("bounce");
            Axios.post(
              apiUrl + "cart/insert",
              qs.stringify(postObject),
              headerconfig
            ).then((res) => {
              hideLoader("addtocartbtn", "class");
              if (res.data.status === "ok") {
                this.setState({ product_remarks: "" });
                /* $(".compo_update_div").hide();
                  $(".compo_add_div").show(); */
                this.setState({ cartTriggerFlg: "yes" });
                removePromoCkValue();
                $(".hcartdd_trigger").addClass("bounce");
                /* showCustomAlert(
                  "success",
                  "偉大な選択肢！カートにアイテムが追加されました。",
                  "cart-success-msg"
                ); */
                /*  this.handleShowAlertFun(
                  "成功",
                  "偉大な選択肢！カートにアイテムが追加されました。"
                ); */
                return false;
              } else if (res.data.status === "error") {
                var errMsgtxt =
                  res.data.message !== ""
                    ? res.data.message
                    : language.productinvalid;
                $(".compocart_error_msg").html(errMsgtxt);
                $(".compocart_error_msg").show();
                $(".compocart_error_msg")
                  .delay(6000)
                  .fadeOut();
              }
            });
          } else {
            hideLoader("addtocartbtn", "class");
            if ($("#ProductDetailMdl .error_combo_div:visible").length > 0) {
              var sectionOffset = $(
                "#ProductDetailMdl .error_combo_div:visible:first"
              ).position();
              $("#ProductDetailMdl").animate(
                {
                  scrollTop: sectionOffset.top - 30,
                },
                "slow"
              );
            }
            $(".compocart_error_msg").html(
              "Sorry!. Product price was not valid."
            );
            $(".compocart_error_msg").show();
            $(".compocart_error_msg")
              .delay(6000)
              .fadeOut();
            return false;
          }
        } else {
          if ($("#ProductDetailMdl .error_combo_div:visible").length > 0) {
            var sectionOffset = $(
              "#ProductDetailMdl .error_combo_div:visible:first"
            ).position();
            $("#ProductDetailMdl").animate(
              {
                scrollTop: sectionOffset.top - 30,
              },
              "slow"
            );
          }
          $(".compocart_error_msg").html(language.productinvalid);
          $(".compocart_error_msg").show();
          $(".compocart_error_msg")
            .delay(6000)
            .fadeOut();
          return false;
        }
      }
    } else {
      if ($("#ProductDetailMdl .error_combo_div:visible").length > 0) {
        var sectionOffset = $(
          "#ProductDetailMdl .error_combo_div:visible:first"
        ).position();
        $("#ProductDetailMdl").animate(
          {
            scrollTop: sectionOffset.top - 30,
          },
          "slow"
        );
      }
      $(".compocart_error_msg").html(language.productinvalid);
      $(".compocart_error_msg").show();
      $(".compocart_error_msg")
        .delay(6000)
        .fadeOut();
      return false;
    }
  }

  checkProductPrice() {
    var allModVal = "";
    var errorChk = 0;
    var productID = $("#modProductId").val();
    var inc_lp = 1;
    var TotalCnt = $(".modifierList").length;

    $(".modfir_addcart_cls").show();
    showLoader("modfir_addcart_cls", "class");

    $(".modifierList").each(function() {
      var modVal = $(this).val();
      var modSelectVal = $(this)
        .find("option:selected")
        .attr("data-mdfvl");
      if (modVal != "" && modSelectVal != "") {
        var modifier_sets = modSelectVal.split("~");
        allModVal +=
          inc_lp == TotalCnt ? modifier_sets[3] : modifier_sets[3] + ";";
      } else if (modSelectVal == "" || modVal == "") {
        errorChk = 1;
      }
      inc_lp++;
    });

    if (errorChk === 0 && allModVal != "") {
      Axios.get(
        apiUrl +
          "products/validate_product?app_id=" +
          appId +
          "&product_id=" +
          productID +
          "&modifier_value_id=" +
          allModVal,
        headerconfig
      ).then((res) => {
        var response = res.data;
        hideLoader("modfir_addcart_cls", "class");
        if (response.status === "ok") {
          var proQty = $(".modfir_proqty_input").val();
          var productPrice = response.result_set[0].product_price;
          var productTotalPrice =
            parseFloat(response.result_set[0].product_price) *
            parseFloat(proQty);
          productTotalPrice = parseFloat(productTotalPrice).toFixed(2);
          /*$("#modParentProductId").val(response.result_set[0].alias_product_primary_id);
					$("#modProductPrice").val(productPrice);
					$("#modProductTotalPrice").val(productTotalPrice);
					$('#id_price_final').html(productTotalPrice);*/
          this.setState({
            mdfApi_call: "No",
            modParentProductId: response.result_set[0].alias_product_primary_id,
            modProductPrice: productPrice,
            modProductTotalPrice: productTotalPrice,
          });
        } else {
          $(".modfir_addcart_cls").hide();
          $("#modErrorDiv").show();
          $("#modErrorDiv")
            .delay(6000)
            .fadeOut();
        }
      });
    }
  }

  setModifierValFun($_this) {
    var mdfcombopro = $_this.find(":selected").attr("data-mdfcombopro");
    $_this
      .closest(".main_combo_div")
      .find(".individual_combo_mdf")
      .hide();
    $_this
      .closest(".main_combo_div")
      .find(".compo_mdf_" + mdfcombopro)
      .show();
  }

  setOverallSubmdfrPrice($_this) {
    var mainmdfrid = $_this.find(":selected").attr("data-mdfcombopro");
    var ismdfrprochk = $_this.find(":selected").attr("data-ismdfrprochk");
    var combopriceapplychk = $_this
      .closest(".main_combo_div")
      .attr("data-combopriceapply");
    var maincomboidtxt = $_this
      .closest(".main_combo_div")
      .attr("data-maincomboidtxt");
    var mdfrpricevaluetxt_val = 0,
      mdfr_spl_pricevalue = 0,
      mdfr_splprice_aply = "no",
      inv_comopo_mismatch_pro = "",
      productrevelid = "",
      productdimension = "";

    if (parseFloat(ismdfrprochk) > 0) {
      if (this.state.compoApi_call === "Yes") {
        var rtrn_msg = this.checkModifierPricefun(
          $_this,
          maincomboidtxt,
          mainmdfrid
        );
      }
    } else {
      if (parseFloat(combopriceapplychk) > 0) {
        var aplyprice_temp = $_this
          .find(":selected")
          .attr("data-combopropriceaply");
        mdfrpricevaluetxt_val = parseFloat(aplyprice_temp);
        var splprice_temp = $_this
          .find(":selected")
          .attr("data-specialpriceval");
        mdfr_spl_pricevalue = parseFloat(splprice_temp);
        mdfr_splprice_aply = $_this
          .find(":selected")
          .attr("data-specialpriceapply");
        productrevelid = $_this.find(":selected").attr("data-productrevelid");
        productdimension = $_this
          .find(":selected")
          .attr("data-productdimension");
      }

      $_this
        .closest(".main_combo_div")
        .find(".mdfr_list_divlcs")
        .attr("data-invcomboprice", mdfrpricevaluetxt_val);
      $_this
        .closest(".main_combo_div")
        .find(".mdfr_list_divlcs")
        .attr("data-invcombosplprice", mdfr_spl_pricevalue);
      $_this
        .closest(".main_combo_div")
        .find(".mdfr_list_divlcs")
        .attr("data-invcombosplpriceaply", mdfr_splprice_aply);
      $_this
        .closest(".main_combo_div")
        .find(".mdfr_list_divlcs")
        .attr("data-mismatchpro", inv_comopo_mismatch_pro);
      $_this
        .closest(".main_combo_div")
        .find(".mdfr_list_divlcs")
        .attr("data-productrevelidmain", productrevelid);
      $_this
        .closest(".main_combo_div")
        .find(".mdfr_list_divlcs")
        .attr("data-productdimension", productdimension);

      $_this
        .closest(".main_combo_div")
        .find(".mdfr_list_divlcs_error")
        .hide();
    }

    this.checkModifierErrorfun();
  }

  checkModifierPricefun($_this, maincomboidtxt, mdfcombopro_id) {
    var returntxt_msg = "";
    var sub_mdfr_ids = "";
    $(".maincombo-" + maincomboidtxt)
      .find(".compo_mdf_" + mdfcombopro_id)
      .each(function() {
        var modVal = $(this)
          .find(".components_mdf_selct")
          .val();
        if (modVal !== "") {
          var modifier_combosets_txt = $(this)
            .find(".components_mdf_selct")
            .find("option:selected")
            .attr("data-selectmdfval");
          var modifier_combosets =
            modifier_combosets_txt !== "" &&
            modifier_combosets_txt !== undefined
              ? modifier_combosets_txt.split("~")
              : new Array();
          if (modifier_combosets.length >= 2) {
            if (modifier_combosets[2]) {
              if (sub_mdfr_ids != "") {
                sub_mdfr_ids += ";";
              }
              sub_mdfr_ids = sub_mdfr_ids + modifier_combosets[2];
            }
          }
        }
      });

    if (sub_mdfr_ids !== "") {
      showLoader("compo_addcart_cls", "class");
      Axios.get(
        apiUrl +
          "products/validate_product?app_id=" +
          appId +
          "&product_id=" +
          mdfcombopro_id +
          "&modifier_value_id=" +
          sub_mdfr_ids,
        headerconfig
      ).then((res) => {
        var response = res.data;
        if (response.status === "ok") {
          var tempval = "";
          this.updateIndvModifrprice(
            $_this
              .closest(".main_combo_div")
              .find(".compo_mdf_" + mdfcombopro_id + ":first")
          );
        } else {
          var tempval = "1";
        }
        $_this
          .closest(".main_combo_div")
          .find(".mdfr_list_divlcs")
          .attr("data-mismatchpro", tempval);
        this.checkModifierErrorfun();
        hideLoader("compo_addcart_cls", "class");
      });
    }

    return returntxt_msg;
  }

  updateIndvModifrprice($_this) {
    var mdfrpricevaluetxt_val = 0;
    $_this
      .closest(".mdfr_list_divlcs_inv")
      .find(".components_mdf_selct")
      .each(function() {
        var mdfrpricevaluetxt = $(this)
          .find(":selected")
          .attr("data-mdfrpricevaluetxt");
        mdfrpricevaluetxt =
          mdfrpricevaluetxt !== "" && mdfrpricevaluetxt !== undefined
            ? parseFloat(mdfrpricevaluetxt)
            : 0;
        mdfrpricevaluetxt_val =
          parseFloat(mdfrpricevaluetxt_val) + parseFloat(mdfrpricevaluetxt);
      });
    $_this
      .closest(".mdfr_list_divlcs")
      .attr("data-invcomboprice", mdfrpricevaluetxt_val);
  }

  checkModifierErrorfun() {
    var over_allerror = "";
    $(".mdfr_list_divlcs").each(function() {
      if ($(this).attr("data-mismatchpro") == "1") {
        over_allerror = "1";
        $(this)
          .find(".mdfr_list_divlcs_error")
          .show();
      } else {
        $(this)
          .find(".mdfr_list_divlcs_error")
          .hide();
      }
    });

    /*$('#comopo_mismatch_pro').val(over_allerror);*/

    this.updateProductPricefun();

    if (over_allerror == "1") {
      $(".compo_addcart_cls").hide();
    } else {
      $(".compo_addcart_cls").show();
    }
  }

  componentDidUpdate() {
    var TotalCnt = $(".modifierList").length;
    var modProductSlug = $("#modProductSlug").val();
    if (
      TotalCnt > 0 &&
      this.state.mdfApi_call === "Yes" &&
      this.state.selectedProSlug === modProductSlug
    ) {
      this.checkProductPrice();
    }

    var $_reactThis = this;
    var individualComboCnt = $(".individual_combo_mdf").length;
    if (individualComboCnt > 0) {
      $(".main_combo_div").each(function() {
        $_reactThis.setModifierValFun($(this).find(".components_selct"));
      });

      if ($(".components_selct").length > 0) {
        $(".components_selct").each(function() {
          $_reactThis.setOverallSubmdfrPrice($(this));
        });
      }
    }

    var indlMinMxComboCnt = $(".individual_combo_pro").length;
    if (indlMinMxComboCnt > 0) {
      $(".main_combo_div").each(function() {
        var minselectcombo_txt =
          $(this).data("minselectcombo") != ""
            ? $(this).data("minselectcombo")
            : "0";
        var combopriceapply_txt =
          $(this).data("combopriceapply") != ""
            ? $(this).data("combopriceapply")
            : "0";
        if (
          parseInt(minselectcombo_txt) === 0 ||
          parseInt(combopriceapply_txt) === 1
        ) {
          $(this)
            .find(".combo_pro_price")
            .show();
        } else {
          // $(this).find(".combo_pro_price").hide();
        }
      });
    }

    var minmaxMainCnt = $(".minmax_maincombo_div").length;
    var chkAplyModfInMinmax = 0;
    if (minmaxMainCnt > 0) {
      chkAplyModfInMinmax = $(".minmax_maincombo_div").find(
        ".components_selctbox_cls"
      ).length;
      this.updateProductPricefun();
    }

    var singleSelectCompo = $(".components_selctbox_cls").length;
    if (
      singleSelectCompo > 0 &&
      ((minmaxMainCnt === 0 &&
        indlMinMxComboCnt === 0 &&
        individualComboCnt === 0) ||
        chkAplyModfInMinmax > 0)
    ) {
      if ($(".components_selct").length > 0) {
        $(".components_selct").each(function() {
          $_reactThis.setOverallSubmdfrPrice($(this));
        });
      }
    }
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
  };
  view_Menu() {
    this.setState({ viewMenu: !this.state.viewMenu });
  }

  render() {
    return (
      <div className="productdetails-main-div">
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
          productmeta={this.state.productmeta}
        />

        <div className="prodetailinner-main-div" id="ProductDetailMdl">
          <div className="container">
            {this.props.productdetailstatus === "success" && (
              <div className="product-go-back">
                <Link
                  to={
                    "/products/category/" + this.props.productdetail[0].cat_slug
                  }
                  className="go-back"
                >
                  {language.return}
                </Link>{" "}
                <b>|</b>{" "}
                <span
                  className="show-category"
                  onClick={this.view_Menu.bind(this)}
                >
                  {this.state.viewMenu === true
                    ? language.hidecagory
                    : language.viewcategory}
                </span>
                {this.state.viewMenu === true && (
                  <section className="product-home-category">
                    <div className="normal-menu-nav">
                      <MenuNavigation
                        {...this.props}
                        productState={this.state}
                        sateValChange={this.sateValChange}
                        includepage={"home"}
                      />
                    </div>
                  </section>
                )}
              </div>
            )}
            {this.productDetailsMain()}
          </div>
        </div>

        <div id="dvLoading" className="dvLoadrCls"></div>
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var proDateilArr = Array();
  var proCommonArr = Array();
  var prodetailstatus = "";
  if (Object.keys(state.productdetail).length > 0) {
    if (
      state.productdetail[0].status === "ok" &&
      Object.keys(state.productdetail[0].result_set).length > 0
    ) {
      proDateilArr = state.productdetail[0].result_set;
      proCommonArr = state.productdetail[0].common;
      prodetailstatus = "success";
    } else {
      prodetailstatus = "failure";
    }
  }

  var tempArr = Array();
  var navigateRst = Array();
  var navigateCmn = Array();
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
      }
    }
  }
  return {
    productdetail: proDateilArr,
    productcommon: proCommonArr,
    productdetailstatus: prodetailstatus,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductDetail: (proSlug) => {
      dispatch({ type: GET_PRODUCT_DETAIL, proSlug });
    },
    getFavouriteproducts: (formPayload) => {
      dispatch({ type: GET_FAVOURITE, formPayload });
    },
    getMenuNavigation: () => {
      dispatch({ type: GET_MENU_NAVIGATION });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(ProductDetail);
