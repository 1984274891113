/* eslint-disable */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { language } from "../Helpers/Language";
import { appId, apiUrl, headerconfig, cookieConfig } from "../Helpers/Config";
import axios from "axios";
import cookie from "react-cookies";

/* import modules */
import Header from "../Layout/Header";
import { showAlert } from "../Helpers/SettingHelper";
var qs = require("qs");

class Account extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let activationKey =
      typeof this.props.match.params.activationKey != "undefined"
        ? this.props.match.params.activationKey
        : "";
    var postObject = {
      app_id: appId,
      key: activationKey,
    };
    axios
      .post(
        apiUrl + "customer/activation",
        qs.stringify(postObject),
        headerconfig
      )
      .then((res) => {
        const { history } = this.props;

        if (res.data.status === "ok") {
          showAlert(
            language.success,
            language.successaticvate
          );
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
          cookie.save("loginPopup", 1, cookieConfig);
          history.push("/");
        } else {
          showAlert(
            language.error,
           language.activateexpired
          );
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
          history.push("/");
        }
      });
  }

  render() {
    return <div></div>;
  }
}

Header.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(Account);
