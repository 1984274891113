/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import axios from "axios";
import { language } from "../Helpers/Language";
import {
  stripslashes,
  getCalculatedAmount,
  showLoader,
  hideLoader,
  getPromoCkValue,
  getreedemPointValue,
  getCCPromoValue,
  removePromoCkValue,
  removePromoOnlyValue,
  resetCrtStyle,
  showAlert,
  showPrices,
  getReferenceID,
  subscribeCycle,
  showPoint,
} from "../Helpers/SettingHelper";
import {
  appId,
  apiUrl,
  deliveryId,
  currencySymbol,
  headerconfig,
  cookieConfig,
} from "../Helpers/Config";
import productImg from "../../common/images/noimg-400x400.jpg";
import go from "../../common/images/checkout-go.png";

import {
  GET_CART_DETAIL,
  UPDATE_CART_DETAIL,
  DELETE_CART_DETAIL,
  DESTROY_CART_DETAIL,
} from "../../actions";
var Parser = require("html-react-parser");
import { format } from "date-fns";
class Cartsidebar extends Component {
  constructor(props) {
    super(props);
    var unitNoOne =
      typeof cookie.load("unitNoOne") === "undefined"
        ? ""
        : cookie.load("unitNoOne");
    var unitNoTwo =
      typeof cookie.load("unitNoTwo") === "undefined"
        ? ""
        : cookie.load("unitNoTwo");
    this.state = {
      cartlistdetail: [],
      overAllcart: [],
      cartItems: [],
      cartDetails: [],
      cartStatus: "",
      settings: [],
      cartTotalItmCount: 0,
      cartTotalAmount: 0,
      updateCartResponse: [],
      startMsg: 0,
      unitnumber1: unitNoOne,
      unitnumber2: unitNoTwo,
      customerId:
        cookie.load("UserId") !== "" &&
        typeof cookie.load("UserId") !== "undefined" &&
        typeof cookie.load("UserId") !== undefined
          ? cookie.load("UserId")
          : "",
    };
  }

  componentWillMount() {
    this.props.getCartDetail();
  }

  componentWillReceiveProps(headerProps) {
    if (headerProps.cartTriggerFlg === "yes") {
      headerProps.prpSateValChange("cartflg", "no");
      this.props.getCartDetail();
      resetCrtStyle();
    }
    hideLoader("product-details", "class");
    hideLoader("cart_body", "class");

    if (this.state.updateCartResponse !== headerProps.updateCartResponse) {
      if (Object.keys(headerProps.updateCartResponse).length > 0) {
        this.setState(
          { updateCartResponse: headerProps.updateCartResponse },
          function() {
            var Response = headerProps.updateCartResponse;
            if (Object.keys(Response).length > 0) {
              if (Response[0].status === "error") {
                if (this.state.startMsg === 1) {
                  this.handleShowAlertFunct("Error", Response[0].message);
                  this.setState({ startMsg: 0 });
                }
              }
            }
          }
        );
      }
    }
  }

  removePromoFun(event) {
    event.preventDefault();
    /*removePromoCkValue();*/
    removePromoOnlyValue();
    this.props.getCartDetail();
  }

  handleShowAlertFunct(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  closeCartlist() {
    $("body").removeClass("cart-items-open");
    $("body").css("overflow", "auto");
    $(".hcartdd_trigger").removeClass("active");
    $(".hcart_dropdown").removeClass("open");
  }
  changeAddrrFun(event) {
    event.preventDefault();
    $.magnificPopup.open({
      items: {
        src: "#warning-popup",
      },
      type: "inline",
    });
    return false;
  }

  handleAddrChange(event) {
    if (event.target.name === "unit_no1") {
      cookie.save("unitNoOne", event.target.value, cookieConfig);
      this.setState({ unitnumber1: event.target.value });
    } else if (event.target.name === "unit_no2") {
      cookie.save("unitNoTwo", event.target.value, cookieConfig);
      this.setState({ unitnumber2: event.target.value });
    }
  }

  cartDetailsList() {
    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();
      var randomPointArr = getreedemPointValue();
      var ccPromoTionArr = getCCPromoValue();

      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }
      var zoneDetails = this.props.zonedetails;
      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        cartItemsArr,
        promoTionArr,
        randomPointArr,
        ccPromoTionArr,
        0
      );

      return (
        <div className="hcart_dropdown">
          <div className="hcart_tt">
            <div className="row-replace">
              <div className="col-sm-cls text-center cart-hdr-top">
                <div
                  id="cart-close-span"
                  onClick={this.closeCartlist.bind(this)}
                ></div>
                <h3>{language.orderdetails}</h3>
                <div className="inner-cart">
                  <a href={void 0} className="">
                    <i></i>
                    <span className="hcart_round">
                      {this.props.cartTotalItmCount}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="hcart_scrollarea">
            <div className="cart_table">
              <div className="cart_body">
                <div className="hcart_tt">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <h3>{language.productdetail}</h3>
                    </div>
                    <div className="col-sm-cls text-right">
                      <a
                        href="/"
                        onClick={this.clearCartItm.bind(this)}
                        className="hclear_cart"
                        title={language.emptycart}
                      >
                        {language.emptycart}
                      </a>
                    </div>
                  </div>
                </div>

                {this.cartItemList()}
              </div>

              <div className="cart_footer">
                <div className="cart_row">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <p className="text-uppercase">{language.subtotal}</p>
                    </div>
                    <div className="col-sm-cls text-right">
                      <span>
                        {showPrices(calculatedAmount["cartSubTotalAmount"])}
                      </span>
                    </div>
                  </div>
                </div>
                {parseFloat(calculatedAmount["orderGstAmount"]) > 0 &&
                  calculatedAmount["inclusiveGST"] === "Yes" && (
                    <div className="inclisive-gst-parent">
                      <p className="inclisive-gst">
                        {language.inclusivegst} (
                        {calculatedAmount["orderDisplayGst"]}
                        %):
                        {showPrices(
                          parseFloat(
                            calculatedAmount["orderGstAmount"]
                          ).toFixed(2)
                        )}
                      </p>
                    </div>
                  )}
                {parseFloat(calculatedAmount["deliveryCharge"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">{language.delivery}</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(
                              calculatedAmount["deliveryCharge"]
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(calculatedAmount["additionalDelivery"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          {language.additionaldelivery}
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(
                              calculatedAmount["additionalDelivery"]
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(
                  this.props.globalsettings[0].result_set
                    .stripe_express_platform_fee
                ) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">{language.platformfee}</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            this.props.globalsettings[0].result_set
                              .stripe_express_platform_fee
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(calculatedAmount["ccpromotionAmount"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          {language.corporatediscount}(-)
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          (
                          {showPrices(
                            parseFloat(
                              calculatedAmount["ccpromotionAmount"]
                            ).toFixed(2)
                          )}
                          )
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(calculatedAmount["randomPointAmount"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">{language.dicount}(-)</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          (
                          {showPrices(
                            parseFloat(
                              calculatedAmount["randomPointAmount"]
                            ).toFixed(2)
                          )}
                          )
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(calculatedAmount["promotionAmount"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">{language.promocode}</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(
                              calculatedAmount["promotionAmount"]
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                    {/*<a
                      href="/"
                      onClick={this.removePromoFun.bind(this)}
                      className="cart_remove"
                    ></a>*/}
                  </div>
                )}
                {parseFloat(calculatedAmount["orderGstAmount"]) > 0 &&
                  calculatedAmount["inclusiveGST"] === "No" && (
                    <div className="cart_row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-uppercase">
                            {language.gst}{" "}
                            {calculatedAmount["orderProductDisplayGst"] !==
                              1 && (
                              <> ({calculatedAmount["orderDisplayGst"]} %)</>
                            )}
                          </p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            {showPrices(
                              parseFloat(
                                calculatedAmount["orderGstAmount"]
                              ).toFixed(2)
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                {parseFloat(calculatedAmount["voucherDiscountAmount"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          {language.voucherdiscount}
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(
                              calculatedAmount["voucherDiscountAmount"]
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                <div className="cart_row grant-total-cls">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <p className="text-uppercase">{language.total}</p>
                    </div>
                    <div className="col-sm-cls text-right">
                      <span>
                        {showPrices(calculatedAmount["grandTotalAmount"], "Y")}
                      </span>
                    </div>
                  </div>
                </div>
                {parseFloat(cartDetailsArr.total_earn_points) > 0 && (
                  <div className="cart_row cart-total-earning">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p> {language.pointsearning}</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPoint(cartDetailsArr.total_earn_points)}{" "}
                          {language.points}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {cookie.load("defaultAvilablityId") === deliveryId &&
                  this.loadMinimumPercentage()}
                {cookie.load("defaultAvilablityId") === deliveryId &&
                  this.loadDeliveryPercentage()}

                {parseFloat(cartDetailsArr.cart_special_discount) > 0 && (
                  <div className="member-discount-total">
                    * {cartDetailsArr.cart_special_discount_type}
                    {showPrices(cartDetailsArr.cart_special_discount)} Applied
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="cart_action">
            <div className="cartaction_bottom">
              <Link
                onClick={this.cartListProductCheck.bind(this)}
                className="gradient-btn cartaction_checkout"
                title="Checkout Now"
              >
                {language.proceedpurchase} <img src={go} alt="" />
              </Link>
            </div>
          </div>
        </div>
      );
    } else {
      $("body").removeClass("cart-items-open");
      $("body").css("overflow", "auto");
      $(".hcartdd_trigger").removeClass("active");

      return "";
    }
  }

  cartListProductCheck() {
    showLoader("cartaction_checkout", "class");
    var qs = require("qs");
    var customerId = this.state.customerId;
    var availabilityId = cookie.load("defaultAvilablityId");
    var postObject = {
      app_id: appId,
      availability_id: availabilityId,
      cart_id: this.props.cartDetails.cart_id,
      reference_id: customerId === "" ? getReferenceID() : "",
      customer_id: customerId,
    };

    axios
      .post(
        apiUrl + "cart/cart_list_product_stock_checking",
        qs.stringify(postObject),
        headerconfig
      )
      .then((res) => {
        if (res.data.status === "success") {
          hideLoader("cartaction_checkout", "class");
          $("body")
            .removeClass("cart-items-open")
            .css("overflow", "initial");
          this.props.history.push("/addon");
        } else {
          hideLoader("cartaction_checkout", "class");
          this.handleShowAlertFun("エラー", res.data.message);
        }
      });
  }
  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  /* this  function used to load delivery percentage */
  loadDeliveryPercentage() {
    var freeDeliveryAmnt = 0;
    var DeliveryAmnt = 0;
    var remainAmnt = 0;
    var delPercentage = 0;

    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();
      var randomPointArr = getreedemPointValue();
      var ccPromoTionArr = getCCPromoValue();

      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }

      var zoneDetails = this.props.zonedetails;
      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        cartItemsArr,
        promoTionArr,
        randomPointArr,
        ccPromoTionArr,
        0
      );

      freeDeliveryAmnt = parseFloat(calculatedAmount["freeDeliveryAmnt"]);
      DeliveryAmnt = parseFloat(calculatedAmount["deliveryCharge"]);
      var subTotal = parseFloat(calculatedAmount["cartSubTotalAmount"]);
      var percentage = (subTotal * 100) / freeDeliveryAmnt;
      percentage = Math.round(percentage);
      delPercentage = percentage;
      remainAmnt = parseFloat(freeDeliveryAmnt - subTotal).toFixed(2);
    }

    if (DeliveryAmnt > 0 && freeDeliveryAmnt > 0 && remainAmnt > 0) {
      return (
        <div className="cart_row progress_bar_div">
          <div className="indication">
            <div className="indication_progress">
              <span
                className="progress_bar"
                style={{ width: delPercentage + "%" }}
              />
            </div>
            <p className="help-block">
              {language.proceedpurchase} {showPrices(remainAmnt)}
            </p>
          </div>
        </div>
      );
    }
  }

  /* this  function used to load delivery percentage */
  loadMinimumPercentage() {
    var minOrderAmnt = 0;
    var remainAmnt = 0;
    var delPercentage = 0;

    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();
      var randomPointArr = getreedemPointValue();
      var ccPromoTionArr = getCCPromoValue();

      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }

      var zoneDetails = this.props.zonedetails;
      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        cartItemsArr,
        promoTionArr,
        randomPointArr,
        ccPromoTionArr,
        0
      );

      minOrderAmnt = parseFloat(calculatedAmount["minOrderAmnt"]);
      var subTotal = parseFloat(calculatedAmount["cartSubTotalAmount"]);
      var percentage = (subTotal * 100) / minOrderAmnt;
      percentage = Math.round(percentage);
      delPercentage = percentage;
      remainAmnt = parseFloat(minOrderAmnt - subTotal).toFixed(2);
    }

    if (minOrderAmnt > 0 && remainAmnt > 0) {
      return (
        <div className="cart_row progress_bar_div progress_bar_min_amt">
          <div className="indication">
            <div className="indication_progress">
              <span
                className="progress_bar"
                style={{ width: delPercentage + "%" }}
              />
            </div>
            <p className="help-block">
              {language.minimumorder} {showPrices(remainAmnt)}円
            </p>
          </div>
        </div>
      );
    }
  }

  cartItemList() {
    var cartItemsArr = this.props.cartItems;
    if (Object.keys(cartItemsArr).length > 0) {
      return cartItemsArr.map((product, index) => {
        var pointPercentage = product.cart_item_point_percentage;
        if (parseFloat(pointPercentage) > 0) {
          var addedPointsSplit = pointPercentage.split(".");
          if (addedPointsSplit[1] === "00") {
            pointPercentage = addedPointsSplit[0];
          }
        }
        return (
          <div
            className="cart_row product-details"
            id={"rowcartid-" + product.cart_item_id}
            key={index}
          >
            <div className="row-replace">
              <div className="col-sm-cls cart_left">
                <div className="cart_img">
                  {product.cart_item_product_image !== "" ? (
                    <img src={product.cart_item_product_image} alt="" />
                  ) : (
                    <img src={productImg} alt="" />
                  )}
                </div>
                <div className="cart_info text-left">
                  <h4>{stripslashes(product.cart_item_product_name)}</h4>
                  {product.cart_item_voucher_id !== "" &&
                    product.cart_item_voucher_id != null && (
                      <span className="voucher-discount-applied">
                        {language.discountapplied}
                      </span>
                    )}
                  {product.cart_item_product_type === "5" &&
                    product.cart_item_product_voucher_gift_email !== "" &&
                    product.cart_item_product_voucher_gift_email !==
                      cookie.load("UserEmail") &&
                    product.cart_item_product_voucher_gift_mobile !== "" &&
                    (product.cart_voucher_order_item_id === "" ||
                      product.cart_voucher_order_item_id === null) && (
                      <div className="cart_extrainfo">
                        <p>
                          <b>{language.giftdetails}</b>
                        </p>
                        <p>
                          {language.name}:{" "}
                          {product.cart_item_product_voucher_gift_name}
                        </p>
                        <p>
                          {language.mobile}:{" "}
                          {product.cart_item_product_voucher_gift_mobile}
                        </p>
                        <p>
                          {language.email}:{" "}
                          {product.cart_item_product_voucher_gift_email}
                        </p>
                        <p>
                          {language.mesage}:{" "}
                          {product.cart_item_product_voucher_gift_message}
                        </p>
                      </div>
                    )}
                  {this.loadModifierItems(
                    product.cart_item_type,
                    product.modifiers,
                    product.set_menu_component
                  )}
                  {product.product_revel_id !== "" &&
                    product.product_revel_id !== null && (
                      <p>
                        {language.sku}:{product.product_revel_id}
                      </p>
                    )}
                  {product.cart_item_subscription !== "" &&
                    product.cart_item_subscription !== "0" &&
                    product.cart_item_subscription !== null && (
                      <>
                        <p>
                          {language.subscription}:
                          {subscribeCycle(product.cart_item_subscription_cycle)}
                        </p>

                        {product.cart_item_subscription_start_date !== "" &&
                          product.cart_item_subscription_start_date !== null &&
                          product.cart_item_subscription_start_date !==
                            "0000-00-00" && (
                            <p>
                              {language.startdate}:
                              {format(
                                new Date(
                                  product.cart_item_subscription_start_date
                                ),
                                "yyyy/MM/dd"
                              )}
                            </p>
                          )}
                      </>
                    )}

                  {product.cart_item_special_notes !== "" && (
                    <p className="help-block">
                      {stripslashes(product.cart_item_special_notes)}
                    </p>
                  )}

                  {parseFloat(product.cart_item_promotion_discount) > 0 && (
                    <span className="member-discount-desc">
                      {showPrices(product.cart_item_promotion_discount)}{" "}
                      {product.cart_item_promotion_type}{" "}
                      {language.discountapplied}
                    </span>
                  )}

                  {parseFloat(product.cart_product_tax) > 0 &&
                    parseFloat(product.cart_product_tax_percentage) > 0 && (
                      <p>
                        {language.gst}(
                        {parseFloat(
                          product.cart_product_tax_percentage
                        ).toFixed(0)}
                        %)
                      </p>
                    )}
                  {parseFloat(product.cart_item_point_percentage) > 0 && (
                    <p className="cart-earning-points">
                      {pointPercentage}%のポイント：
                      {showPoint(product.cart_item_points)}ポイント
                    </p>
                  )}
                </div>
              </div>
              <div className="col-sm-cls cart_right text-right">
                <div className="cart_price">
                  <p>{showPrices(product.cart_item_total_price)}</p>
                </div>

                {product.cart_item_voucher_product_free != 1 ? (
                  <div className="qty_bx">
                    <span
                      className="qty_minus"
                      onClick={this.updateCartQty.bind(this, product, "decr")}
                    >
                      -
                    </span>
                    <input type="text" value={product.cart_item_qty} readOnly />
                    <span
                      className="qty_plus"
                      onClick={this.updateCartQty.bind(this, product, "incr")}
                    >
                      +
                    </span>
                  </div>
                ) : (
                  <div className="qty_bx free_product">
                    <span className="qty_minus"></span>
                    <input type="text" value={product.cart_item_qty} readOnly />
                    <span className="qty_plus"></span>
                  </div>
                )}
              </div>
            </div>
            <a
              href="/"
              onClick={this.deleteCartItm.bind(this, product)}
              className="cart_remove"
            ></a>
          </div>
        );
      });
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo">';

    var temp_html = "";

    if (itemType === "Modifier" && len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modVlPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModVlPrice =
          modVlPrice > 0
            ? " <b>(+" + currencySymbol + modVlPrice + ")</b>"
            : "";
        /* + newModVlPrice */
        temp_html += "<p><b>" + modName + ": </b>" + modval + "</b></p> ";
      }

      html += temp_html + "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (itemType === "Component" && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);

        if (combo[i]["product_details"][0]["modifiers"].length) {
          html +=
            "<p><b>" +
            comboName +
            ":</b> </p><p>" +
            comboVal +
            "  " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        } else {
          html +=
            "<p><b>" +
            comboName +
            ":</b> </p><p>" +
            comboVal +
            " " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        }
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        if (parseInt(combos[r]["cart_menu_component_product_qty"]) > 0) {
          var comboPro = combos[r]["cart_menu_component_product_name"];
          var comboQty = combos[r]["cart_menu_component_product_qty"];
          var comboPrice = combos[r]["cart_menu_component_product_price"];
          var newPrice =
            comboPrice > 0
              ? " <b>(+" + currencySymbol + comboPrice + ")</b>"
              : "";
          /*  newPrice + */
          if (parseInt(comboQty) > 1) {
            comboQty = comboQty + " X ";
          } else {
            comboQty = "";
          }

          html += "<p>" + comboQty + comboPro + " </p> ";
        }
      }
      return html;
    }
    return "";
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "<div >";
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : "";
        /* + newModValPrice  */
        html += "<p><b>" + modName + ": </b> " + modval + "</p> ";
      }
      html += "</div>";

      return html;
    }

    return "";
  }

  updateCartQty(itemArr, type) {
    var productId = itemArr.cart_item_product_id;
    var cartItemId = itemArr.cart_item_id;
    var cartQty = itemArr.cart_item_qty;
    var orderVoucherId = itemArr.cart_voucher_order_item_id;
    var totalItmCount = this.props.cartTotalItmCount;

    showLoader("rowcartid-" + cartItemId, "Idtext");

    if (type === "decr") {
      cartQty = parseInt(cartQty) - 1;
      if (parseInt(totalItmCount) === 0) {
      } else if (parseInt(cartQty) === 0) {
        this.props.deleteCartDetail(cartItemId);
      } else {
        this.props.updateCartDetail(
          productId,
          cartItemId,
          cartQty,
          orderVoucherId
        );
      }
    } else {
      cartQty = parseInt(cartQty) + 1;
      this.props.updateCartDetail(
        productId,
        cartItemId,
        cartQty,
        orderVoucherId
      );
    }
    this.setState({ startMsg: 1 });
    removePromoCkValue();
  }

  deleteCartItm(itemArr, event) {
    event.preventDefault();
    var cartItemId = itemArr.cart_item_id;
    showLoader("rowcartid-" + cartItemId, "Idtext");
    this.props.deleteCartDetail(cartItemId);
    removePromoCkValue();
  }

  clearCartItm(event) {
    event.preventDefault();
    showLoader("cart_body", "class");
    this.props.destroyCartDetail();
    removePromoCkValue();
  }
  openCart(e) {
    e.preventDefault();
    var currenturl = window.location.href;
    var substring1 = "checkout";
    var addonsubstring = "addon";
    if (
      currenturl.includes(substring1) !== true &&
      currenturl.includes(addonsubstring) !== true &&
      parseInt(this.props.cartTotalItmCount) > 0
    ) {
      if ($("body.cart-items-open").length > 0) {
        $("body").removeClass("cart-items-open");
        $(".hcartdd_trigger").removeClass("active");
        $(".hcart_dropdown").removeClass("open");
      } else {
        $("body").addClass("cart-items-open");
        $(".hcartdd_trigger").addClass("active");
        $(".hcart_dropdown").addClass("open");
      }
    }
  }

  render() {
    var currenturl = window.location.href;
    var substring1 = "checkout";
    var addonsubstring = "addon";

    return (
      <>
        <a
          href="#"
          onClick={this.openCart.bind(this)}
          className={
            currenturl.includes(substring1) !== true &&
            currenturl.includes(addonsubstring) !== true
              ? "hcartdd_trigger"
              : "hcartdd_trigger_dis"
          }
          title=""
        >
          <i></i>
          <span className="hcart_round">{this.props.cartTotalItmCount}</span>
          <input
            type="hidden"
            id="totalitmcount"
            value={this.props.cartTotalItmCount}
          />
        </a>

        {currenturl.includes(substring1) !== true &&
        currenturl.includes(addonsubstring) !== true
          ? this.cartDetailsList()
          : ""}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var overAllcart = Array();
  var cartDetails = Array();
  var cartItems = Array();
  var updateCartResponse = Array();
  var cartTotalItmCount = 0;
  var cartStatus = "";

  if (Object.keys(state.updatecartdetail).length > 0) {
    if (state.updatecartdetail[0].status === "error") {
      updateCartResponse = state.updatecartdetail;
    }
  }

  if (Object.keys(state.cartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cartlistdetail[0])
      ? Array()
      : state.cartlistdetail[0].result_set;
    if (
      state.cartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      overAllcart = resultSetArr;
      cartDetails = resultSetArr.cart_details;
      cartItems = resultSetArr.cart_items;
      cartTotalItmCount = resultSetArr.cart_details.cart_total_items;
      cartStatus = "success";
    } else {
      cartStatus = "failure";
    }
  }

  return {
    overAllcart: overAllcart,
    cartDetails: cartDetails,
    cartItems: cartItems,
    cartTotalItmCount: cartTotalItmCount,
    cartStatus: cartStatus,
    updateCartResponse: updateCartResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCartDetail: () => {
      dispatch({ type: GET_CART_DETAIL });
    },
    updateCartDetail: (productId, cartItemId, cartQty, orderVoucherId) => {
      dispatch({
        type: UPDATE_CART_DETAIL,
        productId,
        cartItemId,
        cartQty,
        orderVoucherId,
      });
    },
    deleteCartDetail: (cartItemId) => {
      dispatch({ type: DELETE_CART_DETAIL, cartItemId });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Cartsidebar);
