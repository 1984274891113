/* eslint-disable */
import React, { Component } from "react";
/* import modules */
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import PropTypes from "prop-types";

import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";

import Axios from "axios";
import { language } from "../Helpers/Language";
import {
  appId,
  apiUrl,
  stripeReference,
  headerconfig,
  cookieConfig,
} from "../Helpers/Config";
import {
  showPrices,
  subscribeCycle,
  showLoader,
  hideLoader,
  showAlert,
  showCustomAlert,
  removePointWithOutValue,
} from "../Helpers/SettingHelper";
import { connect } from "react-redux";
import Moment from "moment";

import Sidebar from "./Sidebar";
import Mobilesidebar from "./Mobilesidebar";

import cookie from "react-cookies";
import productImg from "../../common/images/noimg-400x400.jpg";
import warningplus from "../../common/images/18plus.png";
var Parser = require("html-react-parser");
var qs = require("qs");

const settings = {
  items: 4,
  autoplay: false,
  loop: true,
  dots: true,
  nav: false,
  margin: 10,
  responsive: {
    0: {
      items: 1,
    },
    480: {
      items: 2,
    },
    780: {
      items: 3,
    },
    1080: {
      items: 4,
    },
  },
};
class Subscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      globalSettings: "",
      displayactiveList: "",
      displaycancelldList: "",
      displaypromotionProduct: "",
      cancelalertMsg: "",
    };

    if (cookie.load("UserId") === undefined) {
      cookie.save("loginpopupTrigger", "Yes", cookieConfig);
      props.history.push("/");
    }
  }
  componentDidMount() {
    this.laodActiveSubscription();
    this.laodCancelledSubscription();
    this.laodPromotionProduct();
  }

  componentWillReceiveProps(nextProps) {
    $("body").removeClass("cart-items-open");
    $(".hcartdd_trigger").removeClass("active");
    $(".hcart_dropdown").removeClass("open");

    if (this.state.globalSettings !== nextProps.settingsArr) {
      if (Object.keys(nextProps.settingsArr).length > 0) {
        this.setState(
          {
            globalSettings: nextProps.settingsArr,
          },
          function () {}
        );
      }
    }
  }

  laodPromotionProduct() {
    var access_token =
      cookie.load("access_token") !== "" &&
      typeof cookie.load("access_token") !== undefined &&
      typeof cookie.load("access_token") !== "undefined"
        ? "&access_token=" + cookie.load("access_token")
        : "";
    Axios.get(
      apiUrl +
        "products/promotion_products_list?app_id=" +
        appId +
        "&customer_id=" +
        cookie.load("UserId") +
        access_token,
      headerconfig
    ).then((response) => {
      $("#dvLoading").fadeOut();
      if (response.data.status === "ok") {
        this.setState(
          {
            promotionProduct: response.data.result_set,
            image_source: response.data.common.image_source,
          },
          function () {
            this.displaypromotionProductList();
          }
        );
      }
    });
  }
  displaypromotionProductList() {
    var displaypromotionProduct = "";
    if (this.state.promotionProduct.length > 0) {
      displaypromotionProduct = this.state.promotionProduct.map(
        (item, index) => {
          var productName =
            item.product_alias !== "" && item.product_alias !== null
              ? item.product_alias
              : item.product_name;
          if (item.product_enable_subscription === "1") {
            var pointPercentage = "";
            var subscribeTxt = "";
            if (item.product_subscription_monthly_point !== "") {
              pointPercentage = item.product_subscription_monthly_point;
            } else if (item.product_subscription_weekly_point !== "") {
              pointPercentage = item.product_subscription_weekly_point;
            } else if (item.product_subscription_daily_point !== "") {
              pointPercentage = item.product_subscription_daily_point;
            }

            if (item.product_subscription_cycle.indexOf("M") >= 0) {
              subscribeTxt = language.monthlysubscrib;
            } else if (item.product_subscription_cycle.indexOf("W") >= 0) {
              subscribeTxt = language.weeklysubscrib;
            } else if (item.product_subscription_cycle.indexOf("D") >= 0) {
              subscribeTxt = language.dailysubscrib;
            }

            return (
              <div key={index}>
                <Link
                  to={
                    "/products/" +
                    item.pro_cate_slug +
                    "/" +
                    item.pro_subcate_slug +
                    "/" +
                    item.product_slug
                  }
                >
                  <div className="subt-slide">
                    <div className="subt-lhs">
                      {" "}
                      <img
                        src={
                          item.product_thumbnail !== "" &&
                          item.product_thumbnail !== null
                            ? this.state.image_source +
                              "/" +
                              item.product_thumbnail
                            : productImg
                        }
                        alt="Image"
                      />{" "}
                    </div>
                    <div className="subt-rhs">
                      {pointPercentage !== "" && pointPercentage > 0 && (
                        <h5>
                          {removePointWithOutValue(pointPercentage)}% U Cash
                        </h5>
                      )}
                      <span>{productName}</span>
                      <p>{subscribeTxt}</p>
                    </div>
                  </div>
                </Link>
              </div>
            );
          }
        }
      );
    }
    this.setState({ displaypromotionProduct: displaypromotionProduct });
  }
  laodActiveSubscription() {
    var access_token =
      cookie.load("access_token") !== "" &&
      typeof cookie.load("access_token") !== undefined &&
      typeof cookie.load("access_token") !== "undefined"
        ? "&access_token=" + cookie.load("access_token")
        : "";
    Axios.get(
      apiUrl +
        "reports/order_subscription_history?app_id=" +
        appId +
        "&customer_id=" +
        cookie.load("UserId") +
        access_token +
        "&status=A",
      headerconfig
    ).then((response) => {
      $("#dvLoading").fadeOut();
      if (response.data.status === "ok") {
        this.setState({ activeList: response.data.result_set }, function () {
          this.displayactiveList();
        });
      }
    });
  }
  laodCancelledSubscription() {
    var access_token =
      cookie.load("access_token") !== "" &&
      typeof cookie.load("access_token") !== undefined &&
      typeof cookie.load("access_token") !== "undefined"
        ? "&access_token=" + cookie.load("access_token")
        : "";
    Axios.get(
      apiUrl +
        "reports/order_subscription_history?app_id=" +
        appId +
        "&customer_id=" +
        cookie.load("UserId") +
        access_token +
        "&status=C",
      headerconfig
    ).then((response) => {
      $("#dvLoading").fadeOut();
      if (response.data.status === "ok") {
        this.setState({ cancelldList: response.data.result_set }, function () {
          this.displaycancelldList();
        });
      }
    });
  }

  displayactiveList() {
    var result = "";
    if (this.state.activeList.length > 0) {
      result = this.state.activeList.map((item, index) => {
        return (
          <li key={index} id={"subscripe_" + item.subscription_primary_id}>
            <div className="subt-lhs">
              {" "}
              <img
                src={
                  item.item_image !== "" && item.item_image != null
                    ? item.item_image
                    : productImg
                }
                alt="Image"
              />{" "}
            </div>
            <div className="subt-rhs">
              <div className="subt-rhs-top">
                <h5>{item.item_name}</h5>
                {this.loadComob(item.set_menu_component)}
              </div>
              <h6>
                {language.subscription}：
                {subscribeCycle(item.subscription_cycle)}-{language.form}{" "}
                {Moment(item.subscription_start_date).format("YYYY/MM/DD")}
              </h6>
              <h6>
                {language.enddate}:{" "}
                {Moment(item.subscription_end_date).format("YYYY/MM/DD")}
              </h6>

              <p>
                {showPrices(item.subscription_amount)}{" "}
                <strong>{language.onceweekdetails}</strong>
              </p>
              <div className="qua">
                <span>
                  {item.subscription_last_delivered_date !== "" &&
                    item.subscription_last_delivered_date !== null && (
                      <em>
                        {language.lastdelivered}{" "}
                        {Moment(item.subscription_last_delivered_date).format(
                          "YYYY/MM/DD"
                        )}{" "}
                        {Moment(item.subscription_last_delivered_date).format(
                          "HH.mm"
                        )}
                      </em>
                    )}
                </span>{" "}
                <dl>
                  {language.qty} {item.item_qty}
                </dl>
              </div>
              <div className="subscription-txt">
                {item.renewal === "Yes" && (
                  <a
                    href={void 0}
                    onClick={this.createRenewal.bind(
                      this,
                      item.subscription_primary_id
                    )}
                    className="renewal"
                  >
                    {language.renewal}
                  </a>
                )}
                {item.subscription_status === "incomplete" &&
                  item.subscription_invoice != "" &&
                  item.subscription_invoice != null && (
                    <>
                      <a
                        href={item.subscription_invoice}
                        target="blank"
                        className="Complete hidden"
                      >
                        {language.completepaymet}
                      </a>
                      <br></br>
                    </>
                  )}
                <a
                  href={void 0}
                  onClick={this.cancelSubsription.bind(this, item)}
                  className="cancel"
                >
                  {language.cancel}
                </a>
              </div>
            </div>
          </li>
        );
      });
    }
    this.setState({ displayactiveList: result });
  }

  displaycancelldList() {
    var result = "";
    if (this.state.cancelldList.length > 0) {
      result = this.state.cancelldList.map((item, index) => {
        return (
          <li key={index}>
            <div className="subt-lhs">
              {" "}
              <img
                src={
                  item.item_image !== "" && item.item_image != null
                    ? item.item_image
                    : productImg
                }
                alt="Image"
              />{" "}
            </div>
            <div className="subt-rhs">
              <div className="subt-rhs-top">
                <h5>{item.item_name}</h5>
                {this.loadComob(item.set_menu_component)}
              </div>
              <h6>
                サブスクリプション：{subscribeCycle(item.subscription_cycle)}
                -から{" "}
                {Moment(item.subscription_start_date).format("YYYY/MM/DD")}
              </h6>
              <p>
                {showPrices(item.subscription_amount)}{" "}
                <strong>{language.onceweekdetails}</strong>
              </p>
              <div className="qua">
                <span>
                  {item.subscription_last_delivered_date !== "" &&
                    item.subscription_last_delivered_date !== null && (
                      <em>
                        {language.lastdelivered}{" "}
                        {Moment(item.subscription_last_delivered_date).format(
                          "YYYY/MM/DD"
                        )}{" "}
                        {Moment(item.subscription_last_delivered_date).format(
                          "HH.mm"
                        )}
                      </em>
                    )}
                </span>{" "}
                <dl>
                  {language.qty} {item.item_qty}
                </dl>
              </div>
            </div>
            <div className="staus">
              <a href={void 0} className="button">
                {item.subscription_completed === "1" ? "完了" : ""}
                {item.subscription_cancel_status === "1" ? "キャンセル" : ""}
              </a>
            </div>
          </li>
        );
      });
    }
    this.setState({ displaycancelldList: result });
  }

  loadComob(comobPro) {
    if (comobPro.length > 0) {
      return comobPro.map((item) => {
        if (item.product_details.length > 0) {
          return item.product_details.map((item1) => {
            return <span>{item1.menu_product_sku}</span>;
          });
        }
      });
    }
  }

  cancelSubsription(item) {
    this.setState(
      {
        item_id: item.item_id,
        item_order_primary_id: item.order_primary_id,
        cancelalertMsg: item.cancel_message,
      },
      function () {
        $.magnificPopup.open({
          items: {
            src: "#cancellsubscription-popup",
          },
          type: "inline",
        });
      }
    );
  }
  proceedcancelSubsription(e) {
    e.preventDefault();
    showLoader("cancel_proceed", "Idtext");
    var postObject = {
      app_id: appId,
      order_primary_id: this.state.item_order_primary_id,
      order_item_id: this.state.item_id,
      payment_reference: stripeReference,
      stripe_envir: this.state.globalSettings.stripe_envir,
    };
    Axios.post(
      apiUrl + "subscription/cancelSubscription",
      qs.stringify(postObject),
      headerconfig
    ).then((res) => {
      hideLoader("cancel_proceed", "Idtext");
      $.magnificPopup.close();
      if (res.data.status === "ok") {
        this.laodActiveSubscription();
        this.laodCancelledSubscription();
        location.reload();
        showAlert("Success", res.data.message);
      } else {
        if (res.data.form_error !== undefined) {
          showAlert("Error", res.data.form_error);
        } else {
          showAlert("Error", res.data.message);
        }
      }
    });
  }

  createRenewal(subscription_primary_id, e) {
    e.preventDefault();
    showLoader("subscripe_" + subscription_primary_id, "Idtext");
    var postObject = {
      app_id: appId,
      subscription_id: subscription_primary_id,
    };

    Axios.post(
      apiUrl + "subscription/createRenewal",
      qs.stringify(postObject),
      headerconfig
    ).then((res) => {
      hideLoader("subscripe_" + subscription_primary_id, "Idtext");
      $.magnificPopup.close();
      if (res.data.status === "ok") {
        this.laodActiveSubscription();
        this.laodCancelledSubscription();
        showCustomAlert("success", res.data.message);
      } else {
        if (res.data.form_error !== undefined) {
          showCustomAlert("error", res.data.form_error);
        } else {
          showCustomAlert("error", res.data.message);
        }
      }
    });
  }

  render() {
    return (
      <div className="myacc-main-div">
        <Header />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <div className="container">
              <h4>{language.myaccount}</h4>
              <p>{language.myaccoutinfo}</p>
            </div>
          </div>
        </div>
        <Mobilesidebar pageName={"subscription"} />
        <div className="innersection_wrap myadmin_wrap">
          {/* container div - start */}
          <div className="container">
            <div className="mainacc_menusec">
              <Sidebar pageName={"subscription"} />
              <div className="mainacc_menucontent">
                <div className="main_tabsec">
                  <div className="order-tab-section">
                    <div className="mainacc_mobrow">
                      <div className="tab_sec main_tabsec_inner">
                        <div className="myacc_filter">
                          <div className="subscription-main">
                            <div className="subscription-top">
                              <h4>{language.activesubscrib}</h4>
                              {this.state.displayactiveList !== "" && (
                                <ul>{this.state.displayactiveList}</ul>
                              )}
                              {/* <div className="goldtext">
                                {" "}
                                <p>
                                  ￥ 頭いい！あなたは節約しています ￥788
                                  配達ごとに 788U の現金を手に入れる
                                </p>{" "}
                              </div> */}
                            </div>
                            <div className="subscription-slide">
                              <h6 className="textcenter">
                                {language.boostsubscrib}
                              </h6>
                              <OwlCarousel options={settings}>
                                {this.state.displaypromotionProduct}

                                {/* <div>
                                  <div className="subt-slide">
                                    <div className="subt-lhs">
                                      {" "}
                                      <img src={productImg} alt="Image" />{" "}
                                    </div>
                                    <div className="subt-rhs">
                                      <h5>10% U Cash</h5>
                                      <span>Fanta Hakuto</span>
                                      <p>毎月のサブスクリプション</p>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div className="subt-slide">
                                    <div className="subt-lhs">
                                      {" "}
                                      <img src={productImg} alt="Image" />{" "}
                                    </div>
                                    <div className="subt-rhs">
                                      <h5>10% U Cash</h5>
                                      <span>Fanta Hakuto</span>
                                      <p>毎月のサブスクリプション</p>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div className="subt-slide">
                                    <div className="subt-lhs">
                                      {" "}
                                      <img src={productImg} alt="Image" />{" "}
                                    </div>
                                    <div className="subt-rhs">
                                      <h5>10% U Cash</h5>
                                      <span>Fanta Hakuto</span>
                                      <p>毎月のサブスクリプション</p>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div className="subt-slide">
                                    <div className="subt-lhs">
                                      {" "}
                                      <img src={productImg} alt="Image" />{" "}
                                    </div>
                                    <div className="subt-rhs">
                                      <h5>10% U Cash</h5>
                                      <span>Fanta Hakuto</span>
                                      <p>毎月のサブスクリプション</p>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div className="subt-slide">
                                    <div className="subt-lhs">
                                      {" "}
                                      <img src={productImg} alt="Image" />{" "}
                                    </div>
                                    <div className="subt-rhs">
                                      <h5>10% U Cash</h5>
                                      <span>Fanta Hakuto</span>
                                      <p>毎月のサブスクリプション</p>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div className="subt-slide">
                                    <div className="subt-lhs">
                                      {" "}
                                      <img src={productImg} alt="Image" />{" "}
                                    </div>
                                    <div className="subt-rhs">
                                      <h5>10% U Cash</h5>
                                      <span>Fanta Hakuto</span>
                                      <p>毎月のサブスクリプション</p>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div className="subt-slide">
                                    <div className="subt-lhs">
                                      {" "}
                                      <img src={productImg} alt="Image" />{" "}
                                    </div>
                                    <div className="subt-rhs">
                                      <h5>10% U Cash</h5>
                                      <span>Fanta Hakuto</span>
                                      <p>毎月のサブスクリプション</p>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div className="subt-slide">
                                    <div className="subt-lhs">
                                      {" "}
                                      <img src={productImg} alt="Image" />{" "}
                                    </div>
                                    <div className="subt-rhs">
                                      <h5>10% U Cash</h5>
                                      <span>Fanta Hakuto</span>
                                      <p>毎月のサブスクリプション</p>
                                    </div>
                                  </div>
                                </div> */}
                              </OwlCarousel>
                            </div>
                            <div className="subscription-expiry">
                              <h6 className="textcenter">
                                {language.suspendsubscrib}
                              </h6>
                              {this.state.displaycancelldList !== "" && (
                                <ul>{this.state.displaycancelldList}</ul>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* container div - end */}
        </div>

        <Footer />

        <div
          id="cancellsubscription-popup"
          className="white-popup mfp-hide popup_sec cancellsubscription_popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_header">{language.warning}</div>
                <div className="alert_body">
                  <img className="warning-popup-img" src={warningplus} />
                  {this.state.cancelalertMsg !== "" && (
                    <p>{this.state.cancelalertMsg}</p>
                  )}
                  <div className="alt_btns">
                    <a
                      href="/"
                      className="popup-modal-dismiss button button-left disbl_href_action"
                    >
                      {language.no}
                    </a>
                    <a
                      href="/"
                      onClick={this.proceedcancelSubsription.bind(this)}
                      id="cancel_proceed"
                      className="button button-right"
                    >
                      {language.yes}
                    </a>
                    <input type="hidden" id="warning_avilablity" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="dvLoading"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var globalSettings = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }
  return {
    settingsArr: globalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

Subscription.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Subscription)
);
