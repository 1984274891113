/* eslint-disable */
import React, { Component } from "react";
/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { format } from "date-fns";
var Parser = require("html-react-parser");
import { language } from "../Helpers/Language";
import { deliveryId, cookieConfig } from "../Helpers/Config";
import {
  addressFormat,
  stripslashes,
  showAlert,
  showPrices,
  subscribeCycle,
  showPoint,
} from "../Helpers/SettingHelper";
import { connect } from "react-redux";
import Moment, { lang } from "moment";

import {
  GET_CUSTOMER_DETAIL,
  GET_ACTIVITYCOUNT,
  GET_REWARDEARNED,
  GET_REWARDREDEEM,
  GET_PRINTORDER,
  GET_ORDERHISTORY,
} from "../../actions";
import Sidebar from "./Sidebar";
import Mobilesidebar from "./Mobilesidebar";
import cookie from "react-cookies";
import scotterImg from "../../common/images/scotter-icon.png";
import mapImg from "../../common/images/map-icon.png";

import loyality from "../../common/images/loyality.png";

class Rewards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartItems: [],
      cartData: [],
      receiptDetails: [],
      activityPoints: {
        reward_ponits: "",
        reward_ponits_monthly: "",
        order_all: "",
        promotion: "",
      },
      status: "",
      status1: "",
      showearnitems: 10,
      showredeemitems: 10,
      order_all: 0,
      overall_orders: 0,
      reward_ponits: 0,
      currentTab: "C",
    };
    if (cookie.load("UserId") === undefined) {
      cookie.save("loginpopupTrigger", "Yes", cookieConfig);
      props.history.push("/");
    }
  }
  componentDidMount() {
    var access_token =
      cookie.load("access_token") !== "" &&
      typeof cookie.load("access_token") !== undefined &&
      typeof cookie.load("access_token") !== "undefined"
        ? "&access_token=" + cookie.load("access_token")
        : "";
    var params = "&status=A&refrence=" + cookie.load("UserId") + access_token;
    this.props.getCustomerDetail(params);
    this.getActivityCounts();
    this.props.getRewardEarned(cookie.load("UserId"), this.state.showearnitems);
    this.props.getRewardRedeem(
      cookie.load("UserId"),
      this.state.showredeemitems
    );
    $("#dvLoading").fadeOut(2000);
  }

  /* for load more button -start */

  loadrewardearn() {
    var pageNext = this.state.showearnitems + 10;
    this.setState({ showearnitems: pageNext }, function () {
      this.loadereward();
    });
  }
  loadrewardredeem() {
    var pagepNext = this.state.showredeemitems + 10;
    this.setState({ showredeemitems: pagepNext }, function () {
      this.loadrreward();
    });
  }

  loadereward() {
    $(".load_more_data").append('<b class="gloading_img"></b>');
    this.props.getRewardEarned(cookie.load("UserId"), this.state.showearnitems);
  }
  loadrreward() {
    $(".load_more_data1").append('<b class="gloading_img"></b>');
    this.props.getRewardRedeem(
      cookie.load("UserId"),
      this.state.showredeemitems
    );
  }

  /* for load more button -end */

  /* Get Redeem Points Count History Details */
  getActivityCounts() {
    const inputKeys = ["reward", "overall_orders"];
    this.props.getActivityCount(JSON.stringify(inputKeys));
  }

  componentWillReceiveProps(nextProps) {
    /*activity count -start */
    if (Object.keys(nextProps.activitycount).length > 0) {
      if (nextProps.activitycount !== this.props.activitycount) {
        if (
          nextProps.activitycount[0].status &&
          nextProps.activitycount[0].result_set
        ) {
          this.setState({
            overall_orders:
              nextProps.activitycount[0].result_set.overall_orders,
          });
          if (
            nextProps.activitycount[0].result_set.reward_ponits !== undefined
          ) {
            this.setState({
              reward_ponits:
                nextProps.activitycount[0].result_set.reward_ponits,
            });
          }
        }
      }
    } else {
      this.setState({ overall_orders: 0, reward_ponits: 0 });
    }
    /*activity count - end*/

    /* reward earned - start */
    if (nextProps.rewardearned !== this.props.rewardearned) {
      $("b").removeClass("gloading_img");
      $("#dvLoading").fadeOut(2000);
      if (typeof nextProps.rewardearned[0].result_set !== "undefined") {
        this.setState({ pointsEarned: nextProps.rewardearned[0].result_set });
        this.setState({ status: "ok" });
        if (
          this.state.showearnitems >
          nextProps.rewardearned[0].common.total_records
        ) {
          $(".load_more_data").hide();
        } else {
          $(".load_more_data").show();
        }
      } else {
        $(".load_more_data").hide();
      }
    }
    /* reward earned - end */

    /* reward redeem - start */
    if (nextProps.rewardredeem !== this.props.rewardredeem) {
      $("b").removeClass("gloading_img");
      $("#dvLoading").fadeOut(2000);
      if (typeof nextProps.rewardredeem[0].result_set !== "undefined") {
        this.setState({ pointsRedeemed: nextProps.rewardredeem[0].result_set });
        this.setState({ status1: "ok" });
        if (
          this.state.showredeemitems >
          nextProps.rewardredeem[0].common.total_records
        ) {
          $(".load_more_data1").hide();
        } else {
          $(".load_more_data1").show();
        }
      } else {
        $(".load_more_data1").hide();
      }
    }
    /* reward redeem - end */

    /* view receipt - start */
    if (nextProps.orderhistory !== this.props.orderhistory) {
      if (nextProps.orderhistory[0].status === "ok") {
        this.setState({
          catrngCartItems: nextProps.orderhistory[0].result_set[0]["items"],
          catrngReceiptDetails: nextProps.orderhistory[0].result_set[0],
        });
        $("#dvLoading").fadeOut(1500);
        setTimeout(function () {
          $.magnificPopup.open({
            items: {
              src: ".receipt_popup",
            },
            type: "inline",
          });
        }, 1000);
      } else {
        $("#dvLoading").fadeOut(2000);
      }
    }
    /* view receipt - end */

    /* print reward - start */
    if (nextProps.printorder !== this.props.printorder) {
      if (nextProps.printorder[0].status === "ok") {
        $("#dvLoading").fadeOut(2000);
        window.open(nextProps.printorder[0].pdf_url, "_blank");
      } else {
        if (nextProps.printorder[0].form_error) {
          showAlert("Error", nextProps.printorder[0].form_error);
        } else {
          showAlert("Error", nextProps.printorder[0].message);
        }
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }
    }
  }
  /* print reward - end */

  /* reward earned - start */
  __rewardListing() {
    if (this.state.status === "ok") {
      return this.state.pointsEarned.map((points, index) => {
        const orderDate = Moment(points.order_date).format("YYYY/MM/DD");
        /* Advanced Slot */
        const orderTime = this.showOrdTimeSlot(points);
        return (
          <div
            key={index}
            className={
              points.lh_from === "order" && points.order_primary_id !== ""
                ? "current_order"
                : "current_order custom_points_cls"
            }
          >
            {(points.lh_from === "order" && points.order_primary_id !== "") ||
            points.lh_from === "pos" ? (
              <div>
                <div className="myacc_head_sec">
                  <div className="head_left">
                    <div className="head-group">
                      {points.lh_from === "order" ||
                      points.lh_from === "Gift" ? (
                        <h4>
                          {language.ordernumber} - {points.order_local_no}
                        </h4>
                      ) : (
                        <h4>{language.addedbyadmin}</h4>
                      )}
                    </div>
                  </div>
                  <div className="head_right">
                    <div className="head-group">
                      {points.lh_from === "order" ||
                      points.lh_from === "Gift" ? (
                        <h4>
                          <span>オーダー値</span>
                          {showPrices(points.order_total_amount, "Y")}
                        </h4>
                      ) : (
                        <h4>{language.autocridit}</h4>
                      )}
                    </div>
                  </div>
                </div>

                <div className="order_details_body">
                  <div className="delivery_total delivery_total_number">
                    <div className="expiry_on_lhs">
                      {points.lh_from === "order" && (
                        <div className="delivery_total_left">
                          <h4 className="checkoutDate">
                            {Moment(points.order_date).format("YYYY/MM/DD")}{" "}
                            {this.showOrdTimeSlot(points)}
                          </h4>
                        </div>
                      )}
                    </div>
                    <div className="expiry_on_full">
                      <h4 className="checkoutDate">
                        {language.expirydate} :
                        {Moment(points.lh_expiry_on).format("YYYY/MM/DD")}
                      </h4>
                    </div>
                  </div>
                  {/* <div className="delivery_total delivery_total_number">
                    <div className="delivery_total_left">
                      <h2>
                        {points.order_availability_id === deliveryId
                          ? language.deliverydate
                          : "Pickup"}{" "}
                      </h2>
                      <h4 className="checkoutDate">{orderDate}</h4>
                    </div>
                    <div className="delivery_total_left delivery_total_right">
                      <h2>
                        {points.order_availability_id === deliveryId
                          ? language.time
                          : "Pickup"}{" "}
                      </h2>
                      <h4 className="checkoutTime">{orderTime}</h4>
                    </div>
                  </div> */}
                </div>

                <div className="order_details_footer">
                  <div className="order_amt">
                    <div className="order_amt-left">
                      <h3>{points.lh_status}</h3>
                    </div>
                    <div className="order_amt-right">
                      <h3 className="text-right">
                        {showPoint(points.lh_credit_points)} {language.points}
                      </h3>
                    </div>
                  </div>
                  {points.order_id !== "" && points.order_id !== null && (
                    <div className="order_btns">
                      <a
                        href={void 0}
                        onClick={this.printReceipt.bind(
                          this,
                          points.order_primary_id
                        )}
                        className="button print_invoice"
                        title=""
                      >
                        {language.printinvoice}
                      </a>
                      <a
                        href={void 0}
                        onClick={this.getReceipt.bind(this, points.order_id)}
                        className="button view_recipt"
                        title=""
                      >
                        {language.viewreceipt}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div>
                <div className="myacc_head_sec">
                  <div className="head_left">
                    <div className="head-group">
                      <h4>
                        {language.ordernumber} - {points.order_local_no}
                      </h4>
                    </div>
                  </div>
                  <div className="head_right">
                    <div className="head-group">
                      <h4>{points.order_availability_name}</h4>
                    </div>
                  </div>
                </div>

                <div className="order_details_body">
                  <div className="delivery_total delivery_total_number">
                    <div className="expiry_on_lhs">
                      <div className="delivery_total_left">
                        <h4 className="checkoutDate">
                          {Moment(points.order_date).format("YYYY/MM/DD")}
                        </h4>
                      </div>{" "}
                      &nbsp;
                      <div className="delivery_total_left delivery_total_right">
                        <h4 className="checkoutTime">
                          {Moment(points.order_date).format("h:mm A")}
                        </h4>
                      </div>
                    </div>
                    <div className="delivery_total_left delivery_total_right expiry_on_rhs">
                      <h4 className="checkoutDate">
                        {language.expirydate} :
                        {Moment(points.lh_expiry_on).format("YYYY/MM/DD")}
                      </h4>
                    </div>
                  </div>

                  <div className="order_amt_div">
                    <h3>
                      {points.lh_reason !== ""
                        ? stripslashes(points.lh_reason)
                        : ""}
                    </h3>
                  </div>
                </div>

                <div className="order_details_footer">
                  <div className="order_amt">
                    <div className="order_amt-left">
                      <h3>{points.lh_status}</h3>
                    </div>
                    <div className="order_amt-right">
                      <h3 className="text-right">
                        {showPoint(points.lh_credit_points)} {language.points}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      });
    } else {
      return <div className="no-recrds-found">{language.nopoindfound}</div>;
    }
  }
  /* reward earned - end */

  /* reward redeem - start */

  __rewardRedeemListing() {
    if (this.state.status1 === "ok") {
      return this.state.pointsRedeemed.map((points, index) => {
        const orderDate = Moment(points.order_date).format("YYYY/MM/DD");
        /* Advanced Slot */
        const orderTime = this.showOrdTimeSlot(points);
        return (
          <div key={index} className="current_order">
            <div className="myacc_head_sec">
              <div className="head_left">
                <div className="head-group">
                  <h4>
                    {language.ordernumber} - {points.order_local_no}
                  </h4>
                </div>
              </div>
              <div className="head_right">
                <div className="head-group">
                  {points.lh_source === "Admin" ? (
                    <h4>{language.autocridit}</h4>
                  ) : (
                    <h4>
                      {points.order_availability_name === "Delivery"
                        ? language.rewaddelivery
                        : points.order_availability_name}
                    </h4>
                  )}
                </div>
              </div>
            </div>

            <div className="order_details_body">
              <div className="delivery_total delivery_total_number">
                <div className="delivery_total_left">
                  <h4 className="checkoutDate">
                    {points.lh_source === "Admin"
                      ? Moment(points.lh_created_on).format("YYYY/MM/DD")
                      : Moment(points.order_date).format("YYYY/MM/DD")}
                  </h4>
                </div>
                <div className="delivery_total_left delivery_total_right">
                  <h4 className="checkoutTime">
                    {points.lh_source === "Admin"
                      ? Moment(points.lh_created_on).format("hh:mm A")
                      : this.showOrdTimeSlot(points)}
                  </h4>
                </div>
                {/* <div className="delivery_total delivery_total_number">
                  <div className="delivery_total_left">
                    <h2>
                      {points.order_availability_id === deliveryId
                        ? language.deliverydate
                        : "Pickup"}{" "}
                    </h2>
                    <h4 className="checkoutDate">{orderDate}</h4>
                  </div>{" "}
                  &nbsp;
                  <div className="delivery_total_left delivery_total_right">
                    <h2>
                      {points.order_availability_id === deliveryId
                        ? language.time
                        : "Pickup"}{" "}
                    </h2>
                    <h4 className="checkoutTime">{orderTime}</h4>
                  </div>
                </div> */}
              </div>

              <div className="order_amt_div">
                <h3>
                  {language.orderamount}
                  {points.order_total_amount > 0
                    ? showPoint(points.order_total_amount)
                    : ""}
                </h3>
              </div>
            </div>

            <div className="order_details_footer">
              <div className="order_amt">
                <div className="order_amt-left">
                  <h3>
                    {points.lh_redeem_status === "Redeemed"
                      ? "受取"
                      : points.lh_redeem_status}
                  </h3>
                </div>
                <div className="order_amt-right">
                  <h3 className="text-right">
                    {showPoint(points.lh_redeem_point)} {language.points}
                  </h3>
                </div>
              </div>
              {points.lh_source !== "Admin" && (
                <div className="order_btns">
                  <a
                    href="javascript:void(0);"
                    onClick={this.printReceipt.bind(
                      this,
                      points.order_primary_id
                    )}
                    className="button print_invoice"
                    title=""
                  >
                    {language.printinvoice}
                  </a>
                  <a
                    href="javascript:void(0);"
                    onClick={this.getReceipt.bind(this, points.order_id)}
                    className="button view_recipt"
                    title=""
                  >
                    {language.viewreceipt}
                  </a>
                </div>
              )}
            </div>
          </div>
        );
      });
    } else {
      return <div className="no-recrds-found">{language.norecordfound}</div>;
    }
  }
  /* reward redeem - end */

  getReceipt(orderId) {
    $("#dvLoading").fadeIn();
    var access_token =
      cookie.load("access_token") !== "" &&
      typeof cookie.load("access_token") !== undefined &&
      typeof cookie.load("access_token") !== "undefined"
        ? "&access_token=" + cookie.load("access_token")
        : "";
    var params =
      "&customer_id=" +
      cookie.load("UserId") +
      "&order_id=" +
      orderId +
      access_token;
    this.props.getOrderHistory(params);
  }

  printReceipt(orderprimaryId) {
    $("#dvLoading").fadeIn();
    var availabilityIdNew = deliveryId;
    this.props.getPrintOrder(orderprimaryId, availabilityIdNew);
  }

  /* Advanced Slot */
  showOrdTimeSlot(orderDetail) {
    var ordTmSlt = Moment(orderDetail.order_date).format("hh:mm A");
    if (orderDetail.order_is_timeslot === "Yes") {
      var slotTime1 =
        orderDetail.order_pickup_time_slot_from !== ""
          ? orderDetail.order_pickup_time_slot_from.split(":")
          : Array();
      var slotTime2 =
        orderDetail.order_pickup_time_slot_to !== ""
          ? orderDetail.order_pickup_time_slot_to.split(":")
          : Array();
      if (
        Object.keys(slotTime1).length > 0 &&
        Object.keys(slotTime2).length > 0
      ) {
        var startTimeVal = parseInt(slotTime1[0]);
        var startMinitVal = parseInt(slotTime1[1]);
        var strdatevalobj = new Date();
        strdatevalobj.setHours(startTimeVal);
        strdatevalobj.setMinutes(startMinitVal);

        var endTimeVal = parseInt(slotTime2[0]);
        var endMinitVal = parseInt(slotTime2[1]);
        var enddatevalobj = new Date();
        enddatevalobj.setHours(endTimeVal);
        enddatevalobj.setMinutes(endMinitVal);

        ordTmSlt =
          format(strdatevalobj, "p") + " - " + format(enddatevalobj, "p");
      }
    }

    return ordTmSlt;
  }
  openActiveTab(currentTab) {
    console.log(currentTab, "currentTabcurrentTab");
    this.setState({ currentTab: currentTab });
  }

  render() {
    let calc_kakis_perc_display = "";
    let tier2 = "";
    let tier3 = "";
    var membership_display = "";
    if (typeof this.props.customerdetail !== undefined) {
      let customer_membership_type = "";
      let customer_unique_id = "";
      let calc_kakis_perc = 0;

      let membership_spent_msg = "";
      var membership_spent_amount = 0;
      var membership_max_amount = 0;
      var customer_membership_msg = "";

      if (Object.keys(this.props.customerdetail).length) {
        var member = this.props.customerdetail[0].result_set;
        membership_display = member.customer_membership_displayname;
        customer_membership_type = member.customer_membership_type;
        customer_unique_id = member.customer_unique_id;

        membership_max_amount = parseFloat(member.membership_max_amount);
        membership_spent_amount = member.membership_spent_amount;

        membership_spent_msg = member.membership_spent_msg;
        if (customer_membership_type === "Normal") {
          tier2 = "member-blur";
          tier3 = "member-blur";
        } else if (customer_membership_type === "Silver") {
          tier3 = "member-blur";
        }
        if (customer_membership_type !== "Gold") {
          customer_membership_msg = member.customer_membership_msg;
        }

        if (parseInt(customer_unique_id)) {
          customer_unique_id = (
            <div className="user-id">
              <span>User Id</span> - {customer_unique_id}
            </div>
          );
        } else {
          customer_unique_id = "";
        }

        if (parseInt(membership_max_amount)) {
          calc_kakis_perc =
            (membership_spent_amount / membership_max_amount) * 100;
        }
        /* &&
          this.props.customerdetail[0].result_set.customer_membership_type !==
            "Gold" */
        if (membership_max_amount) {
          calc_kakis_perc_display = (
            <div className="loyalbar-btm">
              <div className="loyalbar-btm-inner">
                <div className="reward-progress">
                  <span
                    className="progress_bar"
                    style={{ width: calc_kakis_perc + "%" }}
                  ></span>
                </div>
                <p>{Parser(membership_spent_msg)}</p>
              </div>
            </div>
          );
        }
      }
    }
    return (
      <div className="myacc-main-div">
        <Header />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <div className="container">
              <h4>{language.myaccount}</h4>
              <p>{language.myaccoutinfo}</p>
            </div>
          </div>
        </div>
        <Mobilesidebar pageName={"rewards"} />
        <div className="innersection_wrap myadmin_wrap">
          {/* container div - start */}
          <div className="container">
            <div className="mainacc_menusec">
              <Sidebar pageName={"rewards"} />
              <div className="mainacc_menucontent">
                <div className="main_tabsec">
                  <div className="order-tab-section">
                    <div className="mainacc_mobrow">
                      <div className="loyalbar-points-mobile">
                        <h4>{language.congradsyourearn}</h4>

                        <span>
                          {this.state.reward_ponits !== "" &&
                          this.state.reward_ponits > 0
                            ? showPoint(this.state.reward_ponits)
                            : "0"}
                        </span>
                      </div>
                      <div className="loyalbar">
                        <div className="loyal-round1"></div>
                        <div className="loyal-round2"></div>
                        <div className="loyalbar-top">
                          <div className="loyalbar-lhs">
                            <img src={loyality} alt="Logo" />
                            <span>{/*membership_display*/}</span>
                            <h4>{language.congradsyourearn}</h4>
                            <p>
                              <span>
                                {" "}
                                {this.state.reward_ponits !== "" &&
                                this.state.reward_ponits > 0
                                  ? showPoint(this.state.reward_ponits)
                                  : "0"}
                              </span>
                              ポイント
                            </p>
                          </div>
                          {/*<div className="loyalbar-rhs">
                            <ul>
                              <li>
                                <img src={badge1} alt="Badge" />
                                <p>レベル1</p>
                              </li>
                              <li className={tier2}>
                                <img src={badge2} alt="Badge" />
                                <p>レベル2</p>
                              </li>
                              <li className={tier3}>
                                <img src={badge3} alt="Badge" />
                                <p>レベル3</p>
                              </li>
                            </ul>
                          </div>*/}
                        </div>
                        {calc_kakis_perc_display}
                        {/* <div className="loyalbar-btm">
                          <div className="loyalbar-btm-inner">
                            <div className="reward-progress"> </div>
                          </div>
                        </div> */}
                      </div>

                      <div className="tab_sec main_tabsec_inner">
                        <div className="myacc_filter">
                          <div className="tab_sec filter_tabsec">
                            <ul className="nav nav-tabs text-center">
                              <li
                                className={
                                  this.state.currentTab === "C" ? "active" : ""
                                }
                              >
                                <a
                                  data-toggle="tab"
                                  href={void 0}
                                  onClick={this.openActiveTab.bind(this, "C")}
                                  aria-expanded="true"
                                >
                                  <span>{language.rewardearnds}</span>
                                </a>
                              </li>
                              <li
                                className={
                                  this.state.currentTab === "P" ? "active" : ""
                                }
                              >
                                <a
                                  data-toggle="tab"
                                  href={void 0}
                                  onClick={this.openActiveTab.bind(this, "P")}
                                  aria-expanded="false"
                                >
                                  <span>{language.rewardredemdtab}</span>
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content">
                              {this.state.currentTab === "C" && (
                                <div
                                  id="tab-id-inn5"
                                  className="tab-pane fade active in"
                                >
                                  <h4 className="tab_mobtrigger inner_tab_border active">
                                    {language.rewardearnds}
                                    <i></i>
                                  </h4>
                                  <div className="tab_mobrow filter_tabin">
                                    <div className="order-delivery">
                                      <div className="ord-body">
                                        <div className="cur-order-body reward-list-body">
                                          <div className="myacc_order_details">
                                            {this.__rewardListing(
                                              this.state.pointsEarned
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="load_more_div">
                                    <button
                                      className="load_more_data"
                                      style={{ display: "none" }}
                                      onClick={this.loadrewardearn.bind(this)}
                                    >
                                      {language.loadmore}
                                    </button>
                                  </div>
                                </div>
                              )}
                              {this.state.currentTab === "P" && (
                                <div
                                  id="tab-id-inn6"
                                  className="tab-pane fade active in"
                                >
                                  <h4 className="tab_mobtrigger inner_tab_border active">
                                    {language.rewardredemd}
                                    <i></i>
                                  </h4>
                                  <div className="tab_mobrow filter_tabin">
                                    <div className="cur-order-body reward-list-body">
                                      <div className="myacc_order_details">
                                        {this.__rewardRedeemListing(
                                          this.state.pointsRedeemed
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="load_more_div">
                                    <button
                                      className="load_more_data1"
                                      style={{ display: "none" }}
                                      onClick={this.loadrewardredeem.bind(this)}
                                    >
                                      {language.loadmore}
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* container div - end */}
        </div>

        <Footer />
        <div
          id="receipt-popup"
          className="white-popup mfp-hide popup_sec receipt_popup"
        >
          <div className="pouup_in">
            <Viewreceipt
              details={this.state.catrngReceiptDetails}
              cartItems={this.state.catrngCartItems}
            />
          </div>
        </div>
        <div id="dvLoading"></div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    activitycount: state.activitycount,
    rewardearned: state.rewardearned,
    rewardredeem: state.rewardredeem,
    orderhistory: state.orderhistory,
    printorder: state.printorder,
    customerdetail: state.customerdetail,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
    getRewardEarned: (customer_id, limit) => {
      dispatch({ type: GET_REWARDEARNED, customer_id, limit });
    },
    getRewardRedeem: (customer_id, limit) => {
      dispatch({ type: GET_REWARDREDEEM, customer_id, limit });
    },
    getOrderHistory: (params) => {
      dispatch({ type: GET_ORDERHISTORY, params });
    },
    getPrintOrder: (orderprimaryId, availabilityId) => {
      dispatch({ type: GET_PRINTORDER, orderprimaryId, availabilityId });
    },
    getCustomerDetail: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params });
    },
  };
};
Rewards.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Rewards)
);

class Viewreceipt extends React.Component {
  constructor(props) {
    super(props);
  }

  loadItems(cartItems) {
    if (cartItems.length > 0) {
      return cartItems.map((item, index) => (
        <div className="cart_row product-details oreder_itm_row" key={index}>
          <div className="row-replace oreder-row-inv">
            <div className="col-xs-7 cart_left">
              <div className="cart_info">
                <h4>
                  {stripslashes(item.item_name)} X {item.item_qty}
                </h4>

                {Object.keys(item.voucher_details).length > 0 && (
                  <div className="cart_extrainfo">
                    <p>
                      <b>{language.giftdetails}</b>
                    </p>
                    {item.voucher_details.voucher_gift_name !== "" &&
                      item.voucher_details.voucher_gift_name !== null && (
                        <p>
                          {language.name}:{" "}
                          {item.voucher_details.voucher_gift_name}
                        </p>
                      )}
                    {item.voucher_details.order_item_voucher_mobile !== "" &&
                      item.voucher_details.order_item_voucher_mobile !==
                        null && (
                        <p>
                          {language.mobile}:{" "}
                          {item.voucher_details.order_item_voucher_mobile}
                        </p>
                      )}
                    {item.voucher_details.order_item_voucher_email !== "" &&
                      item.voucher_details.order_item_voucher_email !==
                        null && (
                        <p>
                          {language.email}:{" "}
                          {item.voucher_details.order_item_voucher_email}
                        </p>
                      )}
                    {item.voucher_details.order_item_voucher_message !== "" &&
                      item.voucher_details.order_item_voucher_message !==
                        null && (
                        <p>
                          {language.mesage}:{" "}
                          {item.voucher_details.order_item_voucher_message}
                        </p>
                      )}
                  </div>
                )}

                {this.loadModifierItems(
                  "Component",
                  item.modifiers,
                  item.set_menu_component
                )}
                {item.item_revel_id !== "" && item.item_revel_id !== null && (
                  <p>
                    {language.sku}:{item.item_revel_id}
                  </p>
                )}
                {item.subscription === "Yes" &&
                  item.subscription_started === "Yes" && (
                    <>
                      <p>
                        {language.subscription}:
                        {subscribeCycle(item.subscription_cycle)}
                      </p>

                      {item.subscription_start_date !== "" &&
                        item.subscription_start_date !== null &&
                        item.subscription_start_date !== "0000-00-00" && (
                          <p>
                            {language.startdate}:{item.subscription_start_date}
                          </p>
                        )}
                      {item.subscription_end_date !== "" &&
                        item.subscription_end_date !== null &&
                        item.subscription_end_date !== "0000-00-00" && (
                          <p>
                            {language.enddate}:{item.subscription_end_date}
                          </p>
                        )}
                    </>
                  )}
              </div>

              {item.item_specification !== "" && (
                <p className="help-block">
                  {stripslashes(item.item_specification)}
                </p>
              )}
            </div>
            <div className="col-xs-5 cart_right text-right">
              <div className="cart_price">
                <p>¥{parseFloat(item.item_total_amount).toFixed(0)}</p>
              </div>
            </div>
          </div>
        </div>
      ));
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo">';

    if (len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        html +=
          "<p><b>" +
          stripslashes(modName) +
          ":</b></p><p> " +
          stripslashes(modval) +
          "</p> ";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);

        html +=
          "<p><b>" +
          comboName +
          ":</b></p><p> " +
          comboVal +
          " " +
          this.showComboModifiers(combo[i]["product_details"][0]["modifiers"]) +
          "</p> ";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        var comboPro = combos[r]["menu_product_name"];
        var comboQty = combos[r]["menu_product_qty"];
        var comboPrice = combos[r]["menu_product_price"];
        var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
        /* + newPrice */
        if (parseInt(comboQty) > 1) {
          comboQty = comboQty + " X ";
        } else {
          comboQty = "";
        }
        html += "<p>" + comboQty + comboPro + " </p> ";
      }
      return html;
    }
    return "";
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "";
    if (lenMod > 0) {
      html = "<div >";
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["order_modifier_price"];
        var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : "";
        html +=
          "<p><b>" +
          modName +
          ":</b></p><p> " +
          modval +
          newModValPrice +
          "</p> ";
      }
      html += "</div>";
    }
    return html;
  }

  /* Advanced Slot */
  showOrdTimeSlot(orderDetail) {
    var ordTmSlt = Moment(orderDetail.order_date).format("hh:mm A");
    if (orderDetail.order_is_timeslot === "Yes") {
      var slotTime1 =
        orderDetail.order_pickup_time_slot_from !== ""
          ? orderDetail.order_pickup_time_slot_from.split(":")
          : Array();
      var slotTime2 =
        orderDetail.order_pickup_time_slot_to !== ""
          ? orderDetail.order_pickup_time_slot_to.split(":")
          : Array();
      if (
        Object.keys(slotTime1).length > 0 &&
        Object.keys(slotTime2).length > 0
      ) {
        var startTimeVal = parseInt(slotTime1[0]);
        var startMinitVal = parseInt(slotTime1[1]);
        var strdatevalobj = new Date();
        strdatevalobj.setHours(startTimeVal);
        strdatevalobj.setMinutes(startMinitVal);

        var endTimeVal = parseInt(slotTime2[0]);
        var endMinitVal = parseInt(slotTime2[1]);
        var enddatevalobj = new Date();
        enddatevalobj.setHours(endTimeVal);
        enddatevalobj.setMinutes(endMinitVal);

        ordTmSlt =
          format(strdatevalobj, "p") + " - " + format(enddatevalobj, "p");
      }
    }

    return ordTmSlt;
  }

  render() {
    const { details, cartItems } = this.props;
    if (details !== undefined) {
      return (
        <div className="Viewreceipt">
          <div className="tnk-you">
            <div className="tnk-header">
              <div className="tnk-detail">
                <div className="tnk-order">
                  <h3>
                    {language.ordernumber} - {details.order_local_no}
                  </h3>
                  <p>
                    {language.orderat} :{" "}
                    {Moment(details.order_created_on).format(
                      "YYYY/MM/DD hh:mm A"
                    )}
                    <br></br>
                    {language.paymentmethod} :{" "}
                    {(() => {
                      if (
                        details.order_method_name === "Credit card/Debit card"
                      ) {
                        return language.criditordebitcard;
                      } else if (details.order_method_name === "Cash") {
                        return language.cash;
                      } else if (details.order_method_name === "Promotion") {
                        return language.promotion;
                      } else if (
                        details.order_method_name === "Corporate Wallet"
                      ) {
                        return language.corporatewallet;
                      } else {
                        return details.order_method_name;
                      }
                    })()}
                  </p>
                </div>
                <h2>{language.orderdetails}</h2>
              </div>
              <div className="tnk-delivery">
                {details.order_availability_id === deliveryId ? (
                  <div className="delivery_total">
                    <div className="delivery_total_left">
                      <img src={scotterImg} />
                      <h3>{language.orderprocessing}</h3>{" "}
                      <span>{details.outlet_name}</span>
                      <span>
                        {addressFormat(
                          details.outlet_unit_number1,
                          details.outlet_unit_number2,
                          details.outlet_address_line1,
                          details.outlet_address_line2,
                          details.outlet_postal_code
                        )}
                      </span>
                    </div>
                    <div className="delivery_total_left delivery_total_right">
                      <img src={mapImg} />
                      <h3>{language.deliverylocation}</h3>{" "}
                      <span>{details.order_customer_address_line1}</span>{" "}
                      <span>
                        {details.order_customer_unit_no1 != "" &&
                        details.order_customer_unit_no2 != ""
                          ? details.order_customer_unit_no1 +
                            "-" +
                            details.order_customer_unit_no2
                          : ""}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="delivery_total pickup-order-div">
                    <div className="delivery_total_left">
                      <h3>Pickup Location</h3>
                      <span>{details.outlet_name}</span>
                      <span>
                        {addressFormat(
                          details.outlet_unit_number1,
                          details.outlet_unit_number2,
                          details.outlet_address_line1,
                          details.outlet_address_line2,
                          details.outlet_postal_code
                        )}
                      </span>
                    </div>
                  </div>
                )}

                <div className="delivery_total delivery_total_number">
                  <div className="delivery_total_left">
                    <h2>
                      {details.order_availability_id === deliveryId
                        ? language.deliverydate
                        : "Pickup"}{" "}
                    </h2>
                    <h4 className="checkoutDate">
                      {Moment(details.order_date).format("YYYY/MM/DD")}
                    </h4>
                  </div>
                  <div className="delivery_total_left delivery_total_right">
                    <h2>
                      {details.order_availability_id === deliveryId
                        ? language.time
                        : "Pickup"}{" "}
                    </h2>
                    <h4 className="checkoutTime">
                      {/* Advanced Slot */}
                      {this.showOrdTimeSlot(details)}
                    </h4>
                  </div>
                </div>
                <div className="order-items-maindiv">
                  {this.loadItems(cartItems)}
                  {details.order_remarks !== "" && (
                    <div className="remark_notesec text-left">
                      <h4>{language.remarks}</h4>
                      <p>{details.order_remarks}</p>
                    </div>
                  )}
                  <br />
                  {details.order_customer_send_gift === "Yes" && (
                    <>
                      {details.order_recipient_name !== "" &&
                        details.order_recipient_name !== null && (
                          <div className="remark_notesec text-left">
                            <h4>{language.recipientname}</h4>
                            <p>{details.order_recipient_name}</p>
                          </div>
                        )}
                      {details.order_recipient_contact_no !== "" &&
                        details.order_recipient_contact_no !== null && (
                          <div className="remark_notesec text-left">
                            <h4>{language.recipientnumber}</h4>
                            <p>{details.order_recipient_contact_no}</p>
                          </div>
                        )}
                      {details.order_gift_message !== "" &&
                        details.order_gift_message !== null && (
                          <div className="remark_notesec text-left">
                            <h4>{language.giftmessage}</h4>
                            <p>{details.order_gift_message}</p>
                          </div>
                        )}
                    </>
                  )}
                </div>

                <div className="cart_footer tnk_cart_footer">
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">{language.subtotal}</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>{showPrices(details.order_sub_total)}</span>
                      </div>
                    </div>
                  </div>

                  {parseFloat(details.order_discount_amount) > 0 && (
                    <div className="cart_row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-uppercase">
                            {details.order_discount_type === "redeem"
                              ? language.dicount + " (-)"
                              : language.promocode + " (-)"}
                          </p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            ¥{showPrices(details.order_discount_amount)}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {parseFloat(details.order_redeemed_points) > 0 && (
                    <div className="cart_row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-uppercase">
                            {language.rewardpoints}(-)
                          </p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            {showPrices(
                              parseFloat(details.order_redeemed_points).toFixed(
                                2
                              )
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                  {parseFloat(details.order_delivery_charge) > 0 && (
                    <div className="cart_row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-uppercase">Delivery Charges</p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            {showPrices(
                              (
                                parseFloat(details.order_delivery_charge) +
                                parseFloat(details.order_additional_delivery)
                              ).toFixed(2)
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {details.connect_platform_fee > 0 && (
                    <div className="cart_row gst-row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-uppercase">
                            {language.platformfee}
                          </p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            {showPrices(details.connect_platform_fee)}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {details.order_tax_calculate_amount > 0 && (
                    <div className="cart_row gst-row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-uppercase">
                            {language.gst}
                            {/* ({details.order_tax_charge}%) */}
                          </p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            {showPrices(details.order_tax_calculate_amount)}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="cart_row cart_footer_totrow grant-total-cls">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">{language.total}</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>{showPrices(details.order_total_amount)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
