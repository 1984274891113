/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {
  appId,
  apiUrl,
  stripeReference,
  headerconfig,
  siteURL,
} from "../Helpers/Config";
import {
  removePromoCkValue,
  showAlert,
  showLoader,
} from "../Helpers/SettingHelper";
import cookie from "react-cookies";
import tickImage from "../../common/images/tick_popup.png";
import loadingImage from "../../common/images/loading_popup.gif";
import { ReactSession } from "react-client-session";
import { language } from "../Helpers/Language";

var qs = require("qs");
class Placeorder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payment_attempt: 0,
      validateimage: loadingImage,
      processingText: language.processingorder,
    };

    setTimeout(
      function () {
        $.magnificPopup.open({
          items: {
            src: ".processing",
          },
          type: "inline",
          showCloseBtn: false,
          midClick: false,
          closeOnBgClick: false,
        });
      }.bind(this),
      300
    );

    var urlParams = new URLSearchParams(this.props.location.search);

    if (
      (urlParams.get("redirect_status") == "succeeded" ||
        urlParams.get("redirect_status") == "processing" ||
        urlParams.get("source_type") == "card") &&
      urlParams.get("payment_intent") == cookie.load("paymentIntent")
    ) {
      var intentObjects = {};
      intentObjects = {
        app_id: appId,
        payment_reference: stripeReference,
        customer_id: cookie.load("UserId"),
        intent_id: urlParams.get("payment_intent"),
        access_token:
          cookie.load("access_token") !== "" &&
          typeof cookie.load("access_token") !== undefined &&
          typeof cookie.load("access_token") !== "undefined"
            ? cookie.load("access_token")
            : "",
      };

      axios
        .post(apiUrl + "payment/getpayment_intent", intentObjects, headerconfig)
        .then((res) => {
          if (res.data.result_set.status == "succeeded") {
            var postObject =
              window.sessionStorage.getItem("postObject") +
              "&connect_stripe_account_id=" +
              cookie.load("stripe_account_id") +
              "&connect_total_amount=" +
              cookie.load("connect_total_amount") +
              "&connect_platform_fee=" +
              cookie.load("platform_fee");

            axios
              .post(apiUrl + "ordersv1/submit_order", postObject, headerconfig)
              .then((res) => {
                console.log(res, "resresresres");
                if (res.data.status === "ok") {
                  var localOrderNo = res.data.common.local_order_no;
                  var subscription = res.data.common.subscription;
                  var orderID = res.data.common.order_id;

                  var customerId =
                    typeof cookie.load("UserId") === "undefined"
                      ? ""
                      : cookie.load("UserId");

                  if (
                    window.sessionStorage.getItem("subscriptionavailability") ==
                    1
                  ) {
                    var subscribeObjects = {};
                    subscribeObjects = {
                      app_id: appId,
                      order_primary_id: res.data.common.order_primary_id,
                      payment_reference: stripeReference,
                      customer_id: cookie.load("UserId"),
                      payment_intent: urlParams.get("payment_intent"),
                      stripe_account_id: cookie.load("stripe_account_id"),
                      stripe_fee_percent:
                        typeof cookie.load("stripe_fee_percent") === "undefined"
                          ? ""
                          : cookie.load("stripe_fee_percent"),
                      access_token:
                        cookie.load("access_token") !== "" &&
                        typeof cookie.load("access_token") !== undefined &&
                        typeof cookie.load("access_token") !== "undefined"
                          ? cookie.load("access_token")
                          : "",
                    };

                    axios
                      .post(
                        apiUrl + "subscription/createSubscription",
                        qs.stringify(subscribeObjects),
                        headerconfig
                      )
                      .then((captureRes) => {
                        if (captureRes.data.status === "ok") {
                          var customerId =
                            typeof cookie.load("UserId") === "undefined"
                              ? ""
                              : cookie.load("UserId");

                          var postObject = {
                            app_id: appId,
                            order_id: orderID,
                            payment_intent: urlParams.get("payment_intent"),
                            customerId: customerId,
                            access_token:
                              cookie.load("access_token") !== "" &&
                              typeof cookie.load("access_token") !==
                                undefined &&
                              typeof cookie.load("access_token") !== "undefined"
                                ? cookie.load("access_token")
                                : "",
                          };

                          axios
                            .post(
                              apiUrl + "orders/statusUpdate",
                              qs.stringify(postObject),
                              headerconfig
                            )
                            .then((captureRes) => {
                              if (captureRes.data.status === "ok") {
                                this.setState({ validateimage: tickImage });
                                this.showSuccessPage(localOrderNo);
                                return;
                              } else {
                                this.deleteOrderCookie();
                                this.setState({
                                  processingText:
                                    "Please check in myorders, to get order details.",
                                });
                                setTimeout(function () {
                                  window.location = siteURL + "myorders";
                                }, 5000);
                              }
                            })
                            .catch(
                              function (error) {
                                this.deleteOrderCookie();
                                this.setState({
                                  processingText: language.checkmyorder,
                                });
                                setTimeout(function () {
                                  window.location = siteURL + "myorders";
                                }, 5000);
                              }.bind(this)
                            );
                        } else if (captureRes.data.status === "error") {
                          this.deleteOrderCookie();
                          this.setState({
                            processingText: language.checkmyorder,
                          });
                          setTimeout(function () {
                            window.location = siteURL + "myorders";
                          }, 5000);
                        }
                      });
                  } else {
                    var customerId =
                      typeof cookie.load("UserId") === "undefined"
                        ? ""
                        : cookie.load("UserId");

                    var postObject = {
                      app_id: appId,
                      order_id: orderID,
                      payment_intent: urlParams.get("payment_intent"),
                      customerId: customerId,
                      access_token:
                        cookie.load("access_token") !== "" &&
                        typeof cookie.load("access_token") !== undefined &&
                        typeof cookie.load("access_token") !== "undefined"
                          ? cookie.load("access_token")
                          : "",
                      saveMyCard: window.sessionStorage.getItem("saveMyCard"),
                    };

                    axios
                      .post(
                        apiUrl + "orders/statusUpdate",
                        qs.stringify(postObject),
                        headerconfig
                      )
                      .then((captureRes) => {
                        if (captureRes.data.status === "ok") {
                          this.setState({ validateimage: tickImage });
                          this.showSuccessPage(localOrderNo);
                          return;
                        } else {
                          this.deleteOrderCookie();
                          this.setState({
                            processingText:
                              "Please check in myorders, to get order details.",
                          });
                          console.log(captureRes, "statusUpdate faild");
                          setTimeout(function () {
                            window.location = siteURL + "myorders";
                          }, 5000);
                        }
                      })
                      .catch(
                        function (error) {
                          console.log(error, "Status Update catch");
                          this.deleteOrderCookie();
                          this.setState({
                            processingText: language.checkmyorder,
                          });
                          setTimeout(function () {
                            window.location = siteURL + "myorders";
                          }, 5000);
                        }.bind(this)
                      );
                  }
                } else {
                  console.log("lastelse");
                  this.deleteOrderCookie();
                  this.setState({ processingText: res.data.message });
                  showAlert(language.error, res.data.message);
                  $.magnificPopup.open({
                    items: {
                      src: ".alert_popup",
                    },
                    type: "inline",
                  });
                  setTimeout(function () {
                    window.location = siteURL + "myorders";
                  }, 5000);
                }
              })
              .catch(
                function (error) {
                  console.log(error, "errorerror");
                  this.deleteOrderCookie();
                  this.setState({ processingText: language.checkmyorder });
                  setTimeout(function () {
                    window.location = siteURL + "myorders";
                  }, 5000);
                }.bind(this)
              );
          } else {
            setTimeout(
              function () {
                this.deleteOrderCookie();
                this.setState({ processingText: language.checkmyorder });
              }.bind(this),
              300
            );
            setTimeout(function () {
              window.location = siteURL + "myorders";
            }, 5000);
          }
        })
        .catch(
          function (error) {
            console.log(error, "errorerror");
            this.deleteOrderCookie();
            this.setState({ processingText: language.checkmyorder });
            setTimeout(function () {
              window.location = siteURL + "myorders";
            }, 5000);
          }.bind(this)
        );
    } else {
      setTimeout(
        function () {
          this.deleteOrderCookie();
          this.setState({ processingText: language.checkmyorder });
        }.bind(this),
        300
      );
      setTimeout(function () {
        window.location = siteURL + "myorders";
      }, 5000);
    }
  }

  /* sucess page */
  showSuccessPage(localOrderNo) {
    this.deleteOrderCookie();
    cookie.save("ChkOrderid", localOrderNo, { path: "/" });
    $.magnificPopup.close();
    window.location = siteURL + "thankyou/" + localOrderNo;
  }

  /* this function used to  delete all cookie values */
  deleteOrderCookie() {
    removePromoCkValue();

    cookie.remove("orderPaymentMode", { path: "/" });
    cookie.remove("orderTableNo", { path: "/" });
    cookie.remove("product_remarks", { path: "/" });
    cookie.remove("orderOutletName", { path: "/" });
    cookie.remove("orderOutletId", { path: "/" });
    cookie.remove("carttotalitems", { path: "/" });
    cookie.remove("cartsubtotal", { path: "/" });
    cookie.remove("cartid", { path: "/" });

    /* Delivery avilablity */
    cookie.remove("deliveryDate", { path: "/" });
    cookie.remove("deliveryTime", { path: "/" });
    cookie.remove("unitNoOne", { path: "/" });
    cookie.remove("unitNoTwo", { path: "/" });
    cookie.remove("firstNavigation", { path: "/" });

    cookie.remove("promotion_id", { path: "/" });
    cookie.remove("promotion_applied", { path: "/" });
    cookie.remove("promotion_code", { path: "/" });
    cookie.remove("promotion_delivery_charge_applied", { path: "/" });
    cookie.remove("promotion_amount", { path: "/" });
    cookie.remove("promotion_category", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });

    /*Remove voucher*/
    cookie.remove("voucher_applied", { path: "/" });
    cookie.remove("voucher_code", { path: "/" });
    cookie.remove("voucher_amount", { path: "/" });

    cookie.remove("points_redeemed", { path: "/" });
    cookie.remove("points_used", { path: "/" });
    cookie.remove("points_amount", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });
    cookie.remove("chopchoptoken", { path: "/" });
    window.sessionStorage.removeItem("subscriptionavailability");
    window.sessionStorage.removeItem("postObject");
    window.sessionStorage.removeItem("saveMyCard");
  }

  sateValChange = (field, value) => {};

  render() {
    return (
      <div className="thankyou-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}

        <div className="common-inner-blckdiv">
          {/*<div className="common-inner-banner"><p>Thank You</p></div>*/}
        </div>

        {/* container - start */}
        <div className="container">
          <div className="innersection_wrap tnk-you">
            <div className="mainacc_toptext tick">
              <h2>{language.ordertext}</h2>
            </div>

            {/* order-detail-maindiv - start */}
            <div className="thank-order-detaildiv">
              <div className="tnk-detail text-center">
                <div className="container">
                  <div className="common-inner-blckdiv">
                    <div className="common-inner-banner">
                      <p>{language.placeorder}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* container - end */}

        <div
          id="processing-popup"
          className="white-popup mfp-hide popup_sec processing"
        >
          <div className="pouup_in">
            <h3 className="title1 text-center">{this.state.processingText}</h3>
            <div className="process_inner">
              <div className="process_col">
                <div className="process_left">
                  <img src={this.state.validateimage} />
                </div>
                <div className="process_right">
                  <h5>{language.waitingforpayment}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer section */}
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateTopProps, mapDispatchToProps)(Placeorder);
