/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Axios from "axios";
import cookie from "react-cookies";
import "../../common/css/owl.carousel.css";
import { language } from "../Helpers/Language";
import {
  appId,
  apiUrlV2,
  deliveryId,
  headerconfig,
  cookieConfig,
} from "../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  showPriceValueSub,
  showLoader,
  hideLoader,
  showCustomAlert,
  showAlert,
  removePromoCkValue,
} from "../Helpers/SettingHelper";
import noimage from "../../common/images/no-img-product.png";

var qs = require("qs");

import { GET_BUYAGAINPROLIST } from "../../actions";

var Parser = require("html-react-parser");
var settingspro = {
  items: 4,
  autoplay: false,
  margin: 20,
  nav: true,
  dots: false,
  responsive: {
    0: {
      items: 2,
      margin: 10,
    },
    780: {
      items: 3,
    },
    1280: {
      items: 3,
    },
  },
};
class Buyagainproducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }

  componentWillMount() {
    this.props.getBuyagainList();
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();
    var avilablityId =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : deliveryId;
    var orderOutletId = cookie.load("orderOutletId");
    /* if (
      avilablityId !== "" &&
      orderOutletId != undefined &&
      orderOutletId !== "" &&
      orderOutletId != undefined
    ) { */
    var IndexFlg = productDetail.product_primary_id;
    if (actionFlg === "initial") {
      $("#proIndex-" + IndexFlg).addClass("active");
      $("#proIndex-" + IndexFlg)
        .find(".smiple_product_lk")
        .hide();
      $("#proIndex-" + IndexFlg)
        .find(".addcart_done_maindiv")
        .show();
    } else {
      showLoader("proIndex-" + IndexFlg, "Idtext");
      var availabilityId = cookie.load("defaultAvilablityId");
      var availabilityName =
        availabilityId === deliveryId ? "Delivery" : "Pickup";
      var isAddonProduct = "No";
      var productId = productDetail.product_id;
      var customerId =
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId");
      var proqtyQty = $("#proIndex-" + IndexFlg)
        .find(".proqty_input")
        .val();
      var specialprice_applicable =
        productDetail.product_specialprice_applicable;
      var productCategoryId = productDetail.product_category_id;
      var productSubcategoryId = productDetail.product_subcategory_id;

      var postObject = {};
      postObject = {
        app_id: appId,
        product_id: productId,
        product_qty: proqtyQty,
        product_type: 1,
        availability_id: availabilityId,
        availability_name: availabilityName,
        isAddonProduct: isAddonProduct,
        specialprice_applicable: specialprice_applicable,
        reference_id: customerId === "" ? getReferenceID() : "",
        customer_id: customerId,
        category_id: productCategoryId,
        subcategory_id: productSubcategoryId,
        access_token:
          cookie.load("access_token") !== "" &&
          typeof cookie.load("access_token") !== undefined &&
          typeof cookie.load("access_token") !== "undefined"
            ? cookie.load("access_token")
            : "",
      };

      Axios.post(
        apiUrlV2 + "cart/simpleCartInsert",
        qs.stringify(postObject),
        headerconfig
      ).then((res) => {
        $("#proIndex-" + IndexFlg).removeClass("active");
        hideLoader("proIndex-" + IndexFlg, "Idtext");
        $("#proIndex-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .hide();
        $("#proIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .show();
        if (res.data.status === "ok") {
          this.props.sateValChange("cartflg", "yes");
          $(".hcartdd_trigger").addClass("bounce");
          removePromoCkValue();
          /* showCustomAlert(
            "success",
            "Great! Your item has been added to the cart",
            "cart-success-msg"
          ); */
          /*  this.handleShowAlertFun(
            "success",
            "Great choice! Item added to your cart."
          ); */
          return false;
        } else if (res.data.status === "error") {
          var errMsgtxt =
            res.data.message !== ""
              ? res.data.message
              : "Sorry! Products can`t add your cart.";
          showCustomAlert("error", errMsgtxt);
        }
      });
    }
    /*  } else {
      cookie.save("popuptriggerFrom", "FeaturedPro", cookieConfig);
      $.magnificPopup.open({
        items: {
          src: ".order_popup",
        },
        type: "inline",
      });
    } */
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  viewProDetail(productDetail, event) {
    event.preventDefault();
    var avilablityId = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    /*  if (
      avilablityId !== "" &&
      orderOutletId != undefined &&
      orderOutletId !== "" &&
      orderOutletId != undefined
    ) { */
    var productSlug = productDetail.product_slug;
    if (productSlug !== "") {
      this.props.history.push(
        "/products/" +
          productDetail.pro_cate_slug +
          "/" +
          productDetail.pro_subcate_slug +
          "/" +
          productSlug
      );
    }
    /* } else {
      cookie.save("popuptriggerFrom", "FeaturedPro", cookieConfig);
      $.magnificPopup.open({
        items: {
          src: ".order_popup",
        },
        type: "inline",
      });
    } */
    return false;
  }

  render() {
    let buyagainArr = this.props.buyagainproduct;
    let buyagainlist = [];
    let buyagainimagesource = "";
    if (
      buyagainArr !== null &&
      buyagainArr !== "" &&
      typeof buyagainArr !== undefined &&
      typeof buyagainArr !== "undefined"
    ) {
      if (Object.keys(buyagainArr).length > 0) {
        if (buyagainArr[0].status === "ok") {
          buyagainlist = buyagainArr[0].result_set;
          buyagainimagesource = buyagainArr[0].common.image_source;
        }
      }
    }

    return (
      <section className="featuredpro-section buy-agian">
        {Object.keys(buyagainlist).length > 0 ? (
          <div className="featured-products-section">
            <div className="container">
              {this.props.featuredproducts !== "" &&
                this.props.featuredproducts}
              <div className="featured-products-main">
                <ul className="products-list-ulmain">
                  {buyagainlist.map((buyagain, index) => {
                    var prodivId = "proIndex-" + buyagain.product_primary_id;
                    var comboProId = "comboPro-" + buyagain.product_slug;
                    return (
                      <li
                        className="products-single-li"
                        id={prodivId}
                        key={index}
                      >
                        {buyagain.product_tag_info !== "" &&
                          buyagain.product_tag_info !== null && (
                            <div className="ribbon">
                              <span>{buyagain.product_tag_info}</span>
                            </div>
                          )}
                        <Link
                          to={
                            "/products/" +
                            buyagain.pro_cate_slug +
                            "/" +
                            buyagain.pro_subcate_slug +
                            "/" +
                            buyagain.product_slug
                          }
                          onClick={this.viewProDetail.bind(this, buyagain)}
                        >
                          <div className="products-image-div">
                            {buyagain.product_thumbnail !== "" ? (
                              <img
                                src={
                                  buyagainimagesource +
                                  "/" +
                                  buyagain.product_thumbnail
                                }
                              />
                            ) : (
                              <img src={noimage} />
                            )}
                          </div>
                        </Link>

                        <div className="product-info-div">
                          <div className="product-title-maindiv">
                            <div className="product-title">
                              <h3>
                                {buyagain.product_alias !== ""
                                  ? stripslashes(buyagain.product_alias)
                                  : stripslashes(buyagain.product_name)}
                              </h3>
                            </div>
                          </div>

                          <div className="product-short-description">
                            <p>
                              {buyagain.product_short_description != ""
                                ? Parser(
                                    stripslashes(
                                      buyagain.product_short_description
                                    )
                                  )
                                : Parser("")}
                            </p>
                          </div>

                          <div className="products-ordernow-action">
                            <div className="products-ordernow-inner">
                              <div className="product-price">
                                {buyagain.product_specialprice_applicable ===
                                "yes"
                                  ? parseFloat(buyagain.product_price) > 0 && (
                                      <span className="price_disc">
                                        {showPriceValueSub(
                                          buyagain.product_price,
                                          "Y"
                                        )}
                                      </span>
                                    )
                                  : parseFloat(buyagain.product_cost) > 0 && (
                                      <span className="price_disc">
                                        {showPriceValueSub(
                                          buyagain.product_cost,
                                          "Y"
                                        )}
                                      </span>
                                    )}
                                <h3>
                                  {buyagain.product_specialprice_applicable ===
                                  "yes"
                                    ? showPriceValueSub(
                                        buyagain.product_special_price,
                                        "Y"
                                      )
                                    : showPriceValueSub(
                                        buyagain.product_price,
                                        "Y"
                                      )}
                                </h3>
                              </div>

                              <div className="addcart_row addcart_done_maindiv">
                                <div className="addcart-row-child">
                                  <div className="qty_bx">
                                    <span
                                      className="qty_minus"
                                      onClick={this.proQtyAction.bind(
                                        this,
                                        buyagain.product_primary_id,
                                        "decr"
                                      )}
                                    >
                                      -
                                    </span>
                                    <input
                                      type="text"
                                      className="proqty_input"
                                      readOnly
                                      value="1"
                                    />
                                    <span
                                      className="qty_plus"
                                      onClick={this.proQtyAction.bind(
                                        this,
                                        buyagain.product_primary_id,
                                        "incr"
                                      )}
                                    >
                                      +
                                    </span>
                                  </div>
                                  <button
                                    className="btn btn_black order_done"
                                    onClick={this.addToCartSimple.bind(
                                      this,
                                      buyagain,
                                      "done"
                                    )}
                                  >
                                    {language.updatebuynow}
                                  </button>
                                </div>
                              </div>
                              {buyagain.product_stock > 0 ||
                              buyagain.product_stock === null ? (
                                buyagain.product_type === "1" ? (
                                  <a
                                    className="button order_nowdiv smiple_product_lk disbl_href_action"
                                    href={"/products/" + buyagain.product_slug}
                                    onClick={this.addToCartSimple.bind(
                                      this,
                                      buyagain,
                                      "initial"
                                    )}
                                  >
                                    {language.buynow}
                                  </a>
                                ) : (
                                  <a
                                    href={"/products/" + buyagain.product_slug}
                                    onClick={this.viewProDetail.bind(
                                      this,
                                      buyagain
                                    )}
                                    title="Product Details"
                                    id={comboProId}
                                    className="button order_nowdiv compo_product_lk"
                                  >
                                    {language.buynow}
                                  </a>
                                )
                              ) : (
                                <a
                                  className="button disabled disbl_href_action"
                                  href="/"
                                >
                                  {language.soldout}
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    buyagainproduct: state.buyagainproductlist,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBuyagainList: () => {
      dispatch({ type: GET_BUYAGAINPROLIST });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Buyagainproducts);
