/* eslint-disable */
import React, { Component } from "react";
/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import { language } from "../Helpers/Language";
import Buyagainproducts from "./Buyagainproducts";
import {
  appId,
  apiUrl,
  deliveryId,
  headerconfig,
  siteURL,
  cookieConfig,
} from "../Helpers/Config";
import {
  addressFormat,
  stripslashes,
  showPrices,
  showCustomAlert,
  subscribeCycle,
} from "../Helpers/SettingHelper";
import { connect } from "react-redux";
import Moment, { lang } from "moment";
import { GET_ACTIVITYCOUNT } from "../../actions";
import Sidebar from "./Sidebar";
import Mobilesidebar from "./Mobilesidebar";

import cookie from "react-cookies";
import scotterImg from "../../common/images/scotter.png";
import mapImg from "../../common/images/map-icon.png";
import productImg from "../../common/images/noimg-400x400.jpg";
var Parser = require("html-react-parser");
var qs = require("qs");
class Buyagain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cartItems: [],
      cartData: [],
      cart_brktime_list: [],
      showitems: 10,
      showpitems: 10,
      showresvitems: 10,
      resviewmore: "none",
      order_all: 0,
      overall_orders: 0,
      deliveryTakeaway_orders: 0,
      orderID: "",
    };

    if (cookie.load("UserId") === undefined) {
      cookie.save("loginpopupTrigger", "Yes", cookieConfig);
      props.history.push("/");
    }
  }

  componentDidMount() {
    /* delivery current - past orders */
    var access_token =
      cookie.load("access_token") !== "" &&
      typeof cookie.load("access_token") !== undefined &&
      typeof cookie.load("access_token") !== "undefined"
        ? "&access_token=" + cookie.load("access_token")
        : "";
    $("#dvLoading").fadeOut(2000);

    //Default Action
    $(".filter_tabsec").hide(); //Hide all content
    $("ul.web_order_typecls li:first")
      .addClass("active")
      .show(); //Activate first tab
    $(".filter_tabsec:first").show(); //Show first tab content

    //On Click Event
    $("ul.web_order_typecls li").click(function() {
      $("ul.web_order_typecls li").removeClass("active"); //Remove any "active" class
      $(this).addClass("active"); //Add "active" class to selected tab
      $(".filter_tabsec").hide(); //Hide all tab content
      var activeTab = $(this)
        .find("a")
        .attr("href"); //Find the rel attribute value to identify the active tab + content
      $(activeTab).fadeIn(); //Fade in the active content
      return false;
    });

    this.getActivityCounts();
  }

  getActivityCounts() {
    const inputKeys = [
      "order_all",
      "overall_orders",
      "deliveryTakeaway_orders",
    ];
    this.props.getActivityCount(JSON.stringify(inputKeys));
  }

  componentWillReceiveProps(nextProps) {
    /*activity count -start */
    if (Object.keys(nextProps.activitycount).length > 0) {
      if (nextProps.activitycount !== this.props.activitycount) {
        if (
          nextProps.activitycount[0].status &&
          nextProps.activitycount[0].result_set
        ) {
          this.setState({
            order_all: nextProps.activitycount[0].result_set.order_all,
            overall_orders:
              nextProps.activitycount[0].result_set.overall_orders,
            deliveryTakeaway_orders:
              nextProps.activitycount[0].result_set.deliveryTakeaway_orders,
          });
          $("#dvLoading").fadeOut(2000);
        }
      }
    } else {
      this.setState({
        order_all: 0,
        overall_orders: 0,
        deliveryTakeaway_orders: 0,
      });
    }

    if (nextProps.orderhistory !== this.props.orderhistory) {
      if (nextProps.orderhistory[0].status === "ok") {
        this.setState({
          CartItems: nextProps.orderhistory[0].result_set[0]["items"],
          ReceiptDetails: nextProps.orderhistory[0].result_set[0],
        });

        $("#dvLoading").fadeOut(5000);
        setTimeout(function() {
          $.magnificPopup.open({
            items: {
              src: ".receipt_popup",
            },
            type: "inline",
          });
        }, 1000);
      } else {
        this.setState({ ReceiptDetails: [] });
      }
    }
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
  };
  render() {
    return (
      <div className="myacc-main-div">
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
        />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <div className="container">
              <h4>{language.myaccount}</h4>
              <p>{language.myaccoutinfo}</p>
            </div>
          </div>
        </div>
        <Mobilesidebar pageName={"buyagain"} />
        <div className="innersection_wrap myadmin_wrap">
          {/* container div - start */}
          <div className="container">
            <div className="mainacc_menusec">
              <Sidebar pageName={"buyagain"} />
              <div className="mainacc_menucontent">
                <Buyagainproducts
                  {...this.props}
                  sateValChange={this.sateValChange}
                />
              </div>
            </div>
          </div>
          {/* container div - end */}
        </div>

        <Footer />

        <div id="dvLoading"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activitycount: state.activitycount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
  };
};

Buyagain.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Buyagain)
);
