export const appId = "8969BCFA-2AB1-4517-93D9-D22DC5A829C8";
export const projectTitle = "Coca-Cola Bottlers";

/* Live */
/* export const apiUrl = "https://uranus.ninjaos.com/api/";
export const apiUrlV2 = "https://uranus.ninjaos.com/apiv2/";
export const apiUrlPro = "https://uranus.ninjaos.com/ninjapro/";
export const apiUrlNotify = "https://uranus.ninjaos.com/Pushorder/";
export const siteURL = "https://www.urry.com/";
//export const siteURL = "https://dev.urry.com/";
//export const siteURL = "https://uat.urry.com/";
//export const siteURL = "http://localhost:3000/";
export const timThumpUrl = "https://uranus.ninjaos.com/timthumb.php?src=";
export const mediaUrl = "https://uranus.ninjaos.com/media/dev_team/";
export const blogImageUrl = "https://uranus.ninjaos.com/media/dev_team/blog/";
export const tagImageUrl = "https://uranus.ninjaos.com/media/dev_team/tag/";
export const proFolderUrl =
  "https://uranus.ninjaos.com/media/dev_team/products/"; */

export const apiUrl = "https://uranus.promobuddy.asia/api/";
export const apiUrlV2 = "https://uranus.promobuddy.asia/apiv2/";
export const apiUrlPro = "https://uranus.promobuddy.asia/ninjapro/";
export const apiUrlNotify = "https://uranus.promobuddy.asia/Pushorder/";
//export const siteURL = "https://www.urry.com/";
//export const siteURL = "https://dev.urry.com/";
export const siteURL = "https://urry.promobuddy.asia/";
//export const siteURL = "http://localhost:3001/";
export const timThumpUrl = "https://uranus.promobuddy.asia/timthumb.php?src=";
export const mediaUrl = "https://uranus.promobuddy.asia/media/dev_team/";
export const blogImageUrl =
  "https://uranus.promobuddy.asia/media/dev_team/blog/";
export const tagImageUrl = "https://uranus.promobuddy.asia/media/dev_team/tag/";
export const proFolderUrl =
  "https://uranus.promobuddy.asia/media/dev_team/products/";

/* export const apiUrl = "https://uranus.ninjaos.com/dev/api/";
export const apiUrlV2 = "https://uranus.ninjaos.com/dev/apiv2/";
export const apiUrlPro = "https://uranus.ninjaos.com/dev/ninjapro/";
export const apiUrlNotify = "https://uranus.ninjaos.com/dev/Pushorder/";
//export const siteURL = "http://localhost:3000/";
export const siteURL = "https://dev.urry.com/";
export const timThumpUrl = "https://uranus.ninjaos.com/dev/timthumb.php?src=";
export const mediaUrl = "https://uranus.ninjaos.com/dev/media/dev_team/";
export const blogImageUrl =
  "https://uranus.ninjaos.com/dev/media/dev_team/blog/";
export const tagImageUrl = "https://uranus.ninjaos.comdev/media/dev_team/tag/";
export const proFolderUrl =
  "https://uranus.ninjaos.com/dev/media/dev_team/products/"; */

/* export const apiUrl = "https://uranus.ninjaos.com/uat/api/";
export const apiUrlV2 = "https://uranus.ninjaos.com/uat/apiv2/";
export const apiUrlPro = "https://uranus.ninjaos.com/uat/ninjapro/";
export const apiUrlNotify = "https://uranus.ninjaos.com/uat/Pushorder/";
export const siteURL = "https://uat.urry.com/";
export const timThumpUrl = "https://uranus.ninjaos.com/uat/timthumb.php?src=";
export const mediaUrl = "https://uranus.ninjaos.com/uat/media/dev_team/";
export const blogImageUrl =
  "https://uranus.ninjaos.com/uat/media/dev_team/blog/";
export const tagImageUrl = "https://uranus.ninjaos.com/uat/media/dev_team/tag/";
export const proFolderUrl =
  "https://uranus.ninjaos.com/uat/media/dev_team/products/"; */

export const stripeImage = "/urry/img/stripe_logo.png";

export const deliveryId = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId = "718B1A92-5EBB-4F25-B24D-3067606F67F0";

export const CountryTxt = "Japan";
export const productNoImg = "/urry/img/product-noimg.jpg";
export const stripeCompany = "Coca-Cola Bottlers";

export const fbAppId = "399521621484386";

export const stripeKey =
  "sk_test_51LK9WCKoYR4LSjVg7ddjItuHtBc3omWmprA9p62r7uM85ucT6sxoBIjINXeix8NFUo8QtDv2UgZhG1uAVMfs5VBj00LiN6n9y4";
export const stripeEmail = "dev@jankosoft.com";
export const stripeReference = "cocacolabottlers";
export const stripeDesc = "My Checkout";
export const stripeCurrency = "JPY";
export const currencySymbol = "¥";
export const defaultoutlet = "1";
export const cookieConfig = {
  path: "/",
  secure: true,
  httpOnly: false,
};
export const headerconfig =
  ""; /* {
  headers: {
    "x-api-key": "W6W3SUXRTC7J5JUT6OYTWB14DBG718",
  },
} */
