/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_BUYAGAINPROLIST, SET_BUYAGAINPROLIST } from "../actions";
import { apiUrl, appId, headerconfig } from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";
import { format } from "date-fns";
export const watchGetBuyagainproductslist = function*() {
  yield takeEvery(GET_BUYAGAINPROLIST, workerGetBuyagainproductslist);
};

function* workerGetBuyagainproductslist() {
  try {
    var deliveryDate =
      cookie.load("deliveryDate") == undefined ||
      cookie.load("deliveryDate") == ""
        ? ""
        : cookie.load("deliveryDate").split("/");
    var delivery_Date = "";
    if (deliveryDate !== "") {
      delivery_Date =
        deliveryDate[2] + "-" + deliveryDate[1] + "-" + deliveryDate[0];
    } else {
      delivery_Date = format(new Date(), "yyyy-MM-dd");
    }

    var spicelPride =
      "&specialprice_applicable=orderdate&order_datetxt=" + delivery_Date;
    var access_token =
      cookie.load("access_token") !== "" &&
      typeof cookie.load("access_token") !== undefined &&
      typeof cookie.load("access_token") !== "undefined"
        ? "&access_token=" + cookie.load("access_token")
        : "";
    const uri =
      apiUrl +
      "products/buyagain_products_list?app_id=" +
      appId +
      "&customer_id=" +
      cookie.load("UserId") +
      access_token +
      spicelPride;
    const result = yield call(Axios.get, uri, headerconfig);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_BUYAGAINPROLIST, value: resultArr });
  } catch (e) {
    console.log(e, "Get Buyagain Products List Failed");
  }
}
