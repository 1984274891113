/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import Moment, { lang } from "moment";
import cookie from "react-cookies";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { format } from "date-fns";
var eoLocale = require("date-fns/locale/ja");
import { language } from "../Helpers/Language";
import {
  stripslashes,
  addressFormat,
  showPrices,
  showAlert,
  removePromoCkValue,
  subscribeCycle,
} from "../Helpers/SettingHelper";
import {
  deliveryId,
  apiUrlNotify,
  headerconfig,
  cookieConfig,
} from "../Helpers/Config";
import thnkyou_tick_img from "../../common/images/like1.png";
import scootyImg from "../../common/images/scooty.png";
import cartMpImg from "../../common/images/cart-map.png";
import mm2 from "../../common/images/mobile-myaccount2.png";
import productImg from "../../common/images/noimg-400x400.jpg";

import {
  GET_ORDER_DETAIL,
  GET_PRINTORDER,
  DESTROY_CART_DETAIL,
} from "../../actions";

var Parser = require("html-react-parser");

class Thankyou extends Component {
  constructor(props) {
    super(props);
    this.props.destroyCartDetail();
  }

  componentWillMount() {
    let orderId =
      typeof this.props.match.params.orderId !== "undefined"
        ? this.props.match.params.orderId
        : "";
    var chkOrderid =
      typeof cookie.load("ChkOrderid") === "undefined"
        ? ""
        : cookie.load("ChkOrderid");
    /*  && chkOrderid === orderId */
    if (orderId !== "") {
      this.props.getOrderDetail(orderId);
      this.deleteOrderCookie();
    } else {
      showAlert("Error", "Invalid order detail.");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      this.props.history.push("/");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.printorder !== this.props.printorder) {
      if (nextProps.printorder[0].status === "ok") {
        $("#dvLoading").fadeOut(2000);
        window.open(nextProps.printorder[0].pdf_url, "_blank");
      }
    }
  }

  printReceipt(orderId, availabilityId) {
    $("#dvLoading").fadeIn();
    this.props.getPrintOrder(orderId, deliveryId);
  }

  /* this function used to  delete all cookie values */
  deleteOrderCookie() {
    removePromoCkValue();

    cookie.remove("orderPaymentMode", cookieConfig);
    cookie.remove("orderTableNo", cookieConfig);
    cookie.remove("product_remarks", cookieConfig);
    cookie.remove("orderOutletName", cookieConfig);
    cookie.remove("carttotalitems", cookieConfig);
    cookie.remove("cartsubtotal", cookieConfig);
    cookie.remove("cartid", cookieConfig);

    /* Delivery avilablity */
    cookie.remove("deliveryDate", cookieConfig);
    cookie.remove("deliveryTime", cookieConfig);
    cookie.remove("unitNoOne", cookieConfig);
    cookie.remove("unitNoTwo", cookieConfig);
    cookie.remove("firstNavigation", cookieConfig);

    cookie.remove("promotion_id", cookieConfig);
    cookie.remove("promotion_applied", cookieConfig);
    cookie.remove("promotion_code", cookieConfig);
    cookie.remove("promotion_delivery_charge_applied", cookieConfig);
    cookie.remove("promotion_amount", cookieConfig);
    cookie.remove("promotion_category", cookieConfig);
    cookie.remove("prmo_type", cookieConfig);

    /*Remove voucher*/
    cookie.remove("voucher_applied", cookieConfig);
    cookie.remove("voucher_code", cookieConfig);
    cookie.remove("voucher_amount", cookieConfig);

    cookie.remove("points_redeemed", cookieConfig);
    cookie.remove("points_used", cookieConfig);
    cookie.remove("points_amount", cookieConfig);
    cookie.remove("prmo_type", cookieConfig);
    cookie.remove("stripe_account_id", cookieConfig);
    cookie.remove("connect_total_amount", cookieConfig);
    cookie.remove("platform_fee", cookieConfig);
    cookie.remove("paymentIntent", cookieConfig);
    cookie.remove("clientSecret", cookieConfig);
    cookie.remove("stripe_fee_percent", cookieConfig);
  }

  sendNotification() {
    axios.get(apiUrlNotify + "order_notify", headerconfig).then((res) => {});
  }

  loadItems(orderDetail) {
    if (Object.keys(orderDetail).length > 0) {
      var cartItems = orderDetail["items"];
      return cartItems.map((item, index) => (
        <div key={index}>
          <div className="cart_row product-details">
            <div className="row-replace">
              <div className="col-sm-cls cart_left">
                <div className="cart_img">
                  {item.item_image !== "" ? (
                    <img src={item.item_image} alt="" />
                  ) : (
                    <img src={productImg} alt="" />
                  )}
                </div>
                <div className="cart_info text-left">
                  <h4>
                    {stripslashes(item.item_name)} X {item.item_qty}
                  </h4>
                  {Object.keys(item.voucher_details).length > 0 && (
                    <div className="cart_extrainfo">
                      <p>
                        <b>{language.giftdetails}</b>
                      </p>
                      {item.voucher_details.voucher_gift_name !== "" &&
                        item.voucher_details.voucher_gift_name !== null && (
                          <p>
                            {language.name}:{" "}
                            {item.voucher_details.voucher_gift_name}
                          </p>
                        )}
                      {item.voucher_details.order_item_voucher_mobile !== "" &&
                        item.voucher_details.order_item_voucher_mobile !==
                          null && (
                          <p>
                            {language.mobile}:{" "}
                            {item.voucher_details.order_item_voucher_mobile}
                          </p>
                        )}
                      {item.voucher_details.order_item_voucher_email !== "" &&
                        item.voucher_details.order_item_voucher_email !==
                          null && (
                          <p>
                            {language.email}:{" "}
                            {item.voucher_details.order_item_voucher_email}
                          </p>
                        )}
                      {item.voucher_details.order_item_voucher_message !== "" &&
                        item.voucher_details.order_item_voucher_message !==
                          null && (
                          <p>
                            {language.mesage}:{" "}
                            {item.voucher_details.order_item_voucher_message}
                          </p>
                        )}
                    </div>
                  )}
                  {this.loadModifierItems(
                    "Component",
                    item.modifiers,
                    item.set_menu_component
                  )}

                  {item.item_revel_id !== "" && item.item_revel_id !== null && (
                    <p>SKU:{item.item_revel_id}</p>
                  )}
                  {item.subscription === "Yes" &&
                    item.subscription_started === "Yes" && (
                      <>
                        <p>
                          {language.subscription}:
                          {subscribeCycle(item.subscription_cycle)}
                        </p>

                        {item.subscription_start_date !== "" &&
                          item.subscription_start_date !== null &&
                          item.subscription_start_date !== "0000-00-00" && (
                            <p>
                              {language.startdate}:
                              {item.subscription_start_date}
                            </p>
                          )}
                        {item.subscription_end_date !== "" &&
                          item.subscription_end_date !== null &&
                          item.subscription_end_date !== "0000-00-00" && (
                            <p>
                              {language.enddate}:{item.subscription_end_date}
                            </p>
                          )}
                      </>
                    )}

                  {item.item_specification !== "" && (
                    <p className="help-block">
                      {stripslashes(item.item_specification)}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-sm-cls cart_right text-right">
                <div className="cart_price">
                  <p>
                    {showPrices(parseFloat(item.item_total_amount).toFixed(2))}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
    } else {
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo">';

    if (len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        html +=
          "<p><b>" +
          stripslashes(modName) +
          ": </b> " +
          stripslashes(modval) +
          "</p> ";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);

        html +=
          "<p><b>" +
          comboName +
          ":</b></p><p> " +
          comboVal +
          " " +
          this.showComboModifiers(combo[i]["product_details"][0]["modifiers"]) +
          "</p> ";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        var comboPro = combos[r]["menu_product_name"];
        var comboQty = combos[r]["menu_product_qty"];
        var comboPrice = combos[r]["menu_product_price"];
        var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
        var comboQtyChk = comboQty !== "" ? parseInt(comboQty) : 0;
        if (comboQtyChk > 0) {
          /*  + newPrice  */
          if (parseInt(comboQty) > 1) {
            comboQty = comboQty + " X ";
          } else {
            comboQty = "";
          }
          html += "<p>" + comboQty + comboPro + " </p> ";
        }
      }
      return html;
    }
    return "";
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "<div >";
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["order_modifier_price"];
        var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : "";
        /*  newModValPrice + */
        html += "<p><b>" + modName + ":</b></p><p> " + modval + "</p> ";
      }
      html += "</div>";

      return html;
    }

    return "";
  }

  /* Advanced Slot */
  showOrdTimeSlot(orderDetail) {
    var ordTmSlt = Moment(orderDetail.order_date).format("hh:mm A");
    if (orderDetail.order_is_timeslot === "Yes") {
      var slotTime1 =
        orderDetail.order_pickup_time_slot_from !== ""
          ? orderDetail.order_pickup_time_slot_from.split(":")
          : Array();
      var slotTime2 =
        orderDetail.order_pickup_time_slot_to !== ""
          ? orderDetail.order_pickup_time_slot_to.split(":")
          : Array();
      if (
        Object.keys(slotTime1).length > 0 &&
        Object.keys(slotTime2).length > 0
      ) {
        var startTimeVal = parseInt(slotTime1[0]);
        var startMinitVal = parseInt(slotTime1[1]);
        var strdatevalobj = new Date();
        strdatevalobj.setHours(startTimeVal);
        strdatevalobj.setMinutes(startMinitVal);

        var endTimeVal = parseInt(slotTime2[0]);
        var endMinitVal = parseInt(slotTime2[1]);
        var enddatevalobj = new Date();
        enddatevalobj.setHours(endTimeVal);
        enddatevalobj.setMinutes(endMinitVal);

        ordTmSlt =
          format(strdatevalobj, "p") + " - " + format(enddatevalobj, "p");
      }
    }

    return ordTmSlt;
  }

  render() {
    let orderArr = this.props.orderdetail;
    var orderDetail = [];

    if (Object.keys(orderArr).length > 0) {
      if (orderArr[0].status === "ok") {
        orderDetail = orderArr[0].result_set[0];
      }
    }

    return (
      <div className="thankyou-main-div">
        {/* Header start */}
        <Header />
        {/* Header End */}

        <div className="common-inner-blckdiv">
          {/*<div className="common-inner-banner"><p>Thank You</p></div>*/}
        </div>

        {/* container - start */}
        <div className="container">
          {/* innersection_wrap - start */}
          {Object.keys(orderDetail).length > 0 && (
            <div className="innersection_wrap tnk-you">
              <div className="mainacc_toptext tick">
                <img src={thnkyou_tick_img} />
                <h2>{language.thankyou}</h2>
                <p>{language.orderplacesuccess}</p>
                <Link to={"/products"} className="button">
                  {language.goshop}
                </Link>
              </div>

              {/* order-detail-maindiv - start */}
              <div className="thank-order-detaildiv">
                <div className="tnk-detail text-center">
                  <h2>{language.orderdetails}</h2>
                  <div className="tnk-order">
                    <h3>
                      {language.ordernumber} -{" "}
                      <strong>{orderDetail.order_local_no}</strong>
                    </h3>
                    <p>
                      {language.orderdate} :{" "}
                      {Moment(orderDetail.order_created_on).format(
                        "YYYY/MM/DD hh:mm A",
                        { locale: eoLocale }
                      )}
                      <br></br>
                      {language.paymentmethod} :{" "}
                      {orderDetail.order_method_name ===
                      "Credit card/Debit card"
                        ? language.criditordebitcard
                        : orderDetail.order_method_name}
                    </p>
                  </div>
                </div>

                <div className="tnk-delivery">
                  {orderDetail.order_availability_id === deliveryId ? (
                    <div className="delivery-cart-div">
                      <div className="cart-direction">
                        <img className="cart-direction-left" src={scootyImg} />
                        <img className="cart-direction-right" src={cartMpImg} />
                      </div>
                      <div className="cart_row tnkorder-first">
                        <div className="order-hlhs text-left">
                          <img src={scootyImg} />
                          <h5>{language.orderprocessing}</h5>
                          <p>{stripslashes(orderDetail.outlet_name)}</p>
                          <p>
                            {addressFormat(
                              orderDetail.outlet_unit_number1,
                              orderDetail.outlet_unit_number2,
                              orderDetail.outlet_address_line1,
                              orderDetail.outlet_address_line2,
                              orderDetail.outlet_postal_code
                            )}
                          </p>
                        </div>
                        <div className="order-hrhs text-right">
                          <img src={cartMpImg} />
                          <h5>{language.deliverylocation}</h5>
                          <p>
                            {addressFormat(
                              orderDetail.order_customer_unit_no1,
                              orderDetail.order_customer_unit_no2,
                              orderDetail.order_customer_address_line1,
                              orderDetail.order_customer_address_line2,
                              orderDetail.order_customer_postal_code
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="pickup-cart-div">
                      <div className="cart-direction"></div>
                      <div className="cart_row cart-header-first">
                        <div className="pickup-thankfull text-center">
                          <h4>{language.pickuplocation}</h4>
                          <p>{stripslashes(orderDetail.outlet_name)}</p>
                          <p>
                            {addressFormat(
                              orderDetail.outlet_unit_number1,
                              orderDetail.outlet_unit_number2,
                              orderDetail.outlet_address_line1,
                              orderDetail.outlet_address_line2,
                              orderDetail.outlet_postal_code
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="delivery_total delivery_total_number delivery_datetime_div">
                    <div className="delivery_total_left">
                      <h2>
                        {orderDetail.order_availability_id === deliveryId
                          ? language.deliverydate
                          : language.pickup}{" "}
                      </h2>
                      <h4 className="checkoutDate">
                        {Moment(orderDetail.order_date).format("YYYY/MM/DD")}
                      </h4>
                    </div>
                    <div className="delivery_total_left delivery_total_right">
                      <h2>
                        {orderDetail.order_availability_id === deliveryId
                          ? language.deadline
                          : language.pickup}{" "}
                      </h2>
                      {/* Advanced Slot */}
                      <h4 className="checkoutTime">
                        {this.showOrdTimeSlot(orderDetail)}
                      </h4>
                    </div>
                  </div>

                  <div className="order-details-with-clear">
                    <h5>{language.productdetail}</h5>
                  </div>
                  <div className="orderitem_body_div">
                    {this.loadItems(orderDetail)}
                    {orderDetail.order_remarks !== "" && (
                      <div className="remark_notesec text-left">
                        <h4>{language.remarks}</h4>
                        <p>{orderDetail.order_remarks}</p>
                      </div>
                    )}
                    {orderDetail.order_customer_send_gift === "Yes" && (
                      <>
                        {orderDetail.order_recipient_name !== "" &&
                          orderDetail.order_recipient_name !== null && (
                            <div className="remark_notesec text-left">
                              <h4>{language.recipientname}</h4>
                              <p>{orderDetail.order_recipient_name}</p>
                            </div>
                          )}
                        {orderDetail.order_recipient_contact_no !== "" &&
                          orderDetail.order_recipient_contact_no !== null && (
                            <div className="remark_notesec text-left">
                              <h4>{language.recipientnumber}</h4>
                              <p>{orderDetail.order_recipient_contact_no}</p>
                            </div>
                          )}
                        {orderDetail.order_gift_message !== "" &&
                          orderDetail.order_gift_message !== null && (
                            <div className="remark_notesec text-left">
                              <h4>{language.giftmessage}</h4>
                              <p>{orderDetail.order_gift_message}</p>
                            </div>
                          )}
                      </>
                    )}
                  </div>
                </div>

                <div className="cart_footer tnk_cart_footer">
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">{language.subtotal}</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>{showPrices(orderDetail.order_sub_total)}</span>
                      </div>
                    </div>
                  </div>
                  {parseFloat(orderDetail.order_tax_charge_inclusive) > 0 &&
                    parseFloat(
                      orderDetail.order_tax_calculate_amount_inclusive
                    ) > 0 && (
                      <p className="inclisive-gst">
                        {language.inclusivegst} (
                        {orderDetail.order_tax_charge_inclusive}%):
                        {showPrices(
                          parseFloat(
                            orderDetail.order_tax_calculate_amount_inclusive
                          ).toFixed(2)
                        )}
                      </p>
                    )}

                  {parseFloat(orderDetail.order_delivery_charge) > 0 && (
                    <div className="cart_row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-uppercase">
                            {language.delvieryfee}
                          </p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            {showPrices(
                              parseFloat(
                                orderDetail.order_delivery_charge
                              ).toFixed(2)
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                  {parseFloat(orderDetail.order_additional_delivery) > 0 && (
                    <div className="cart_row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-uppercase">
                            {language.additionaldelvieryfee}{" "}
                          </p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            {showPrices(
                              parseFloat(
                                orderDetail.order_additional_delivery
                              ).toFixed(2)
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {orderDetail.connect_platform_fee != 'undefined' && parseFloat(orderDetail.connect_platform_fee) > 0 && (
                  <div className="cart_row">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <p className="text-uppercase">
                        {language.platformfee}
                      </p>
                    </div>
                    <div className="col-sm-cls text-right">
                      <span>
                        {showPrices(
                            orderDetail.connect_platform_fee
                        )}
                      </span>
                    </div>
                  </div>
                  </div>
                  )}

                  {parseFloat(orderDetail.order_discount_amount) > 0 && (
                    <div className="cart_row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-uppercase">
                            {orderDetail.order_discount_type === "redeem"
                              ? language.dicount + " (-)"
                              : language.promocode + " (-)"}
                          </p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            {showPrices(
                              parseFloat(
                                orderDetail.order_discount_amount
                              ).toFixed(2)
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                  {parseFloat(orderDetail.order_redeemed_points) > 0 && (
                    <div className="cart_row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-uppercase">{language.dicount}</p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            {showPrices(
                              parseFloat(
                                orderDetail.order_redeemed_points
                              ).toFixed(2)
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                  {parseFloat(orderDetail.order_cpcustomer_discount_amount) >
                    0 && (
                    <div className="cart_row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-uppercase">
                            {language.corporatepromotion}(-)
                          </p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            {showPrices(
                              parseFloat(
                                orderDetail.order_cpcustomer_discount_amount
                              ).toFixed(2)
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                  {parseFloat(orderDetail.order_corporate_wallet_amount) >
                    0 && (
                    <div className="cart_row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-uppercase">
                            {language.corporatewallet}
                          </p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            {showPrices(
                              parseFloat(
                                orderDetail.order_corporate_wallet_amount
                              ).toFixed(2)
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                  {parseFloat(orderDetail.order_voucher_discount_amount) >
                    0 && (
                    <div className="cart_row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-uppercase">
                            {language.voucherdiscount1}(-)
                          </p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            {showPrices(
                              parseFloat(
                                orderDetail.order_voucher_discount_amount
                              ).toFixed(2)
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                  {parseFloat(orderDetail.order_tax_calculate_amount) > 0 &&
                    parseFloat(orderDetail.order_tax_charge_inclusive) ===
                      0 && (
                      <div className="cart_row gst-row">
                        <div className="row-replace">
                          <div className="col-sm-cls text-left">
                            <p className="text-uppercase">
                              {language.gst}
                              {/* parseFloat(orderDetail.order_tax_charge) > 0 && (
                                <>
                                  ({parseFloat(orderDetail.order_tax_charge)}%)
                                </>
                              ) */}
                            </p>
                          </div>
                          <div className="col-sm-cls text-right">
                            <span>
                              {showPrices(
                                parseFloat(
                                  orderDetail.order_tax_calculate_amount
                                ).toFixed(2)
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                  <div className="cart_row grant-total-cls">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">{language.total}</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(orderDetail.order_total_amount).toFixed(
                              2
                            ),
                            "Y"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* order-detail-maindiv - end */}
              <div className="tnk-chk-order">
                <div className="tnk-download">
                  <a
                    href={void 0}
                    onClick={this.printReceipt.bind(
                      this,
                      orderDetail.order_primary_id
                    )}
                  >
                    <img src={mm2} /> <span>{language.downalodinvoice}</span>
                  </a>
                </div>
                <Link to={"/myorders"} className="button">
                  {language.checkorderstatus}
                </Link>
              </div>
            </div>
          )}
          {/* innersection_wrap - start */}
        </div>
        {/* container - end */}
        <div id="dvLoading"></div>
        {/* Footer section */}
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var globalSettings = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      $("#dvLoading").fadeOut(2000);
      globalSettings = state.settings[0].result_set;
    }
  }

  return {
    settingsArr: globalSettings,
    orderdetail: state.orderdetail,
    printorder: state.printorder,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrderDetail: (orderId) => {
      dispatch({ type: GET_ORDER_DETAIL, orderId });
    },
    getPrintOrder: (orderprimaryId, availabilityId) => {
      dispatch({ type: GET_PRINTORDER, orderprimaryId, availabilityId });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Thankyou);
