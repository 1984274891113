/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { language } from "../Helpers/Language";
import { stripslashes, showAlert } from "../Helpers/SettingHelper";
import { appId } from "../Helpers/Config";
var Parser = require("html-react-parser");
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import validator from "validator";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Select from "react-select";
import { connect } from "react-redux";

import {
  GET_PICKUP_OUTLETS,
  GET_CONTACTDATA,
  GET_GLOBAL_SETTINGS,
} from "../../actions";
import banner from "../../common/images/cooc-japan.jpg";
var Parser = require("html-react-parser");

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      outlet_loaded: "no",
      outlets_result: [],
      outlets: [],
      outlet_id: "",
      fields: {
        first_name: "",
        last_name: "",
        mobile: "",
        subject: "",
        email: "",
        type: "",
        message: "",
      },
      pageTitle: "",
      pageDesc: "",
      status: "",
      windowHeight: 0,
    };
  }

  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };

  handleFormSubmit = () => {
    const formPayload = this.state.fields;
    console.log(formPayload);
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      customer_first_name: formPayload.first_name,
      customer_last_name: formPayload.last_name,
      customer_emailaddress: formPayload.email,
      customer_phonenumber: formPayload.mobile,
      subject: formPayload.subject.value,
      customer_message: formPayload.message,
    };

    this.props.getContactData(qs.stringify(postObject));
  };

  componentDidMount() {
    this.props.getGlobalSettings();
    /*this.props.getPickupOutlets();*/
    /* $("html, body").animate({ scrollTop: 0 }, 800); */
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.contactdata !== this.props.contactdata) {
      if (nextProps.contactdata[0].status === "ok") {
        this.handleShowAlertFun("success", nextProps.contactdata[0].message);

        /* setTimeout(function () {
          window.location.reload();
        }, 3000); */
      } else {
        this.handleShowAlertFun("success", nextProps.contactdata[0].message);

        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }
    }

    /* $("html, body").animate({ scrollTop: 0 }, 800); */
  }

  sateValChangefn(outlvalue) {
    this.setState({ outlet_id: outlvalue });
  }

  getOutletData = () => {
    var outLetLst = this.state.outlets;
    var outletId = this.state.outlet_id;
    if (Object.keys(outLetLst).length > 0) {
      var listhtml = outLetLst.map((item, index) => (
        <div
          key={index}
          onClick={this.sateValChangefn.bind(this, item.outlet_id)}
          className={
            outletId === item.outlet_id ? "locate-lirow active" : "locate-lirow"
          }
        >
          <h4>{stripslashes(item.outlet_name)}</h4>
          <p>{item.outlet_address_line1}</p>
          <p>
            Phone: <span className="phone-show-dskp">{item.outlet_phone}</span>
            <span className="phone-show-mbl">
              {" "}
              <a href={this.callPhoneOptn(item.outlet_phone)}>
                {item.outlet_phone}
              </a>
            </span>
          </p>
        </div>
      ));
      return listhtml;
    } else {
      return "";
    }
  };

  contMapHtml = () => {
    var outletId = this.state.outlet_id;
    /* jankosoft 'Dempsey' => 190 */
    if (outletId === "218") {
      return (
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.784116066201!2d103.80677851426552!3d1.30458871208381!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1a3cb9baec87%3A0x9afb0ed56701e15!2sMuthu&#39;s+Curry!5e0!3m2!1sen!2sin!4v1559310893750!5m2!1sen!2sin"
            width="600"
            height="450"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      );
    }
    /* jankosoft 'Race Course' => 192 */
    if (outletId === "216") {
      return (
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.775596869837!2d103.84998271832654!3d1.3099511611600971!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19c71f708091%3A0xcb1c05d04cdfa80f!2sMuthu&#39;s+Curry!5e0!3m2!1sen!2sin!4v1559311174739!5m2!1sen!2sin"
            width="600"
            height="450"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      );
    }
    /* jankosoft 'Suntec' => 191 */
    if (outletId === "217") {
      return (
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7996915113254!2d103.85716761426546!3d1.294727262111923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19a8a51c9f51%3A0x882bc15b9ef17943!2sMuthu&#39;s+Curry!5e0!3m2!1sen!2sin!4v1559311243416!5m2!1sen!2sin"
            width="600"
            height="450"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="contactusmain-top-div">
        <Header />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>{language.contactus}</p>
          </div>
        </div>

        <div className="contact_wrap">
          <div className="contactus_wrapper">
            <div className="container">
              <div className="contact-us-lhs">
                <Form
                  fields={this.state.fields}
                  onChange={this.fieldChange}
                  onValid={this.handleFormSubmit}
                  outletListData={this.getOutletData}
                  contMapFun={this.contMapHtml}
                  onInvalid={() => console.log("Form invalid!")}
                />
              </div>
              <div className="contact-us-rhs">
                <img src={banner} />
              </div>
            </div>
          </div>
        </div>

        <Footer />

        <div id="dvLoading1234"></div>
      </div>
    );
  }
}

const phonenumberPattern = /^[0-9]{6,14}$/;

const isMobile = (mobile) =>
  mobile.match(phonenumberPattern) ? null : language.validphone;

const isEmpty = (value) => (value === "" ? language.thisfieldrequired : null);

const isEmail = (email) =>
  validator.isEmail(email) ? null : language.invalidemail;

function validationConfig(props) {
  const { first_name, last_name, subject, mobile, email, message } =
    props.fields;

  return {
    fields: [
      "first_name",
      "last_name",
      "subject",
      "mobile",
      "email",
      "message",
    ],

    validations: {
      first_name: [[isEmpty, first_name]],
      last_name: [[isEmpty, last_name]],
      subject: [[isEmpty, subject]],
      mobile: [
        [isEmpty, mobile],
        [isMobile, mobile],
      ],
      email: [
        [isEmpty, email],
        [isEmail, email],
      ],
      message: [[isEmpty, message]],
    },
  };
}

class Form extends React.Component {
  state = {
    pageTitle: "",
    pageDesc: "",
  };

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    let errMsgFirstName,
      errMsgLastName,
      errMsgEmail,
      errMsgMobile,
      errMsgType,
      errMsgSubject,
      errMsgMessage;

    let contactcontent = "";

    if (this.props.contactContent != undefined) {
      contactcontent = Parser(
        this.props.contactContent.result_set[0].staticblocks_description
      );
    }

    if ($validation.first_name.error.reason !== undefined) {
      document.getElementsByClassName("first_name").className = "error";
      errMsgFirstName = $validation.first_name.show && (
        <span className="error">{$validation.first_name.error.reason}</span>
      );
    }

    if ($validation.last_name.error.reason !== undefined) {
      document.getElementsByClassName("last_name").className = "error";
      errMsgLastName = $validation.last_name.show && (
        <span className="error">{$validation.last_name.error.reason}</span>
      );
    }

    if ($validation.subject.error.reason !== undefined) {
      document.getElementsByClassName("subject").className = "error";
      errMsgSubject = $validation.subject.show && (
        <span className="error">{$validation.subject.error.reason}</span>
      );
    }

    if ($validation.mobile.error.reason !== undefined) {
      errMsgMobile = $validation.mobile.show && (
        <span className="error">{$validation.mobile.error.reason}</span>
      );
    }

    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show && (
        <span className="error">{$validation.email.error.reason}</span>
      );
    }

    if ($validation.message.error.reason !== undefined) {
      errMsgMessage = $validation.message.show && (
        <span className="error">{$validation.message.error.reason}</span>
      );
    }

    return (
      <div className="contact_form">
        <h3>{language.dropline}</h3>
        <form className="form_sec clear">
          <div className="form-group two-col">
            <div className="focus-out1">
              <label>{language.lastname}*</label>
              <input
                type="text"
                className="form-control input-focus"
                value={fields.last_name}
                maxLength="80"
                {...$field("last_name", (e) =>
                  onChange("last_name", e.target.value)
                )}
              />
              {errMsgLastName}
            </div>
            <div className="focus-out1">
              <label>{language.firstname}*</label>
              <input
                type="text"
                className="form-control input-focus"
                value={fields.first_name}
                maxLength="80"
                {...$field("first_name", (e) =>
                  onChange("first_name", e.target.value)
                )}
              />
              {errMsgFirstName}
            </div>
          </div>
          <div className="form-group">
            <div className="focus-out1">
              <label>{language.contactsubject}*</label>
              <Select
                onChange={onChange.bind(this, "subject")}
                id="subject"
                options={[
                  { label: "酒屋様", value: "酒屋様" },
                  {
                    label: "飲食店様",
                    value: "飲食店様",
                  },
                ]}
                placeholder="選択"
              />

              {errMsgSubject}
            </div>
          </div>

          <div className="form-group">
            <div className="focus-out1">
              <label>{language.contactnumber}*</label>
              <input
                type="text"
                className="form-control input-focus"
                value={fields.mobile}
                maxLength="11"
                {...$field("mobile", (e) => onChange("mobile", e.target.value))}
              />
              {errMsgMobile}
            </div>
          </div>
          <div className="form-group">
            <div className="focus-out1">
              <label>{language.email}*</label>
              <input
                type="text"
                className="form-control input-focus"
                value={fields.email}
                maxLength="85"
                {...$field("email", (e) => onChange("email", e.target.value))}
              />
              {errMsgEmail}
            </div>
          </div>

          <div className="form-group">
            <div className="focus-out1">
              <label>{language.message}*</label>

              <textarea
                className="form-control input-focus"
                id="feedback"
                {...$field("message", (e) =>
                  onChange("message", e.target.value)
                )}
              ></textarea>
              {errMsgMessage}
            </div>
          </div>
          <div className="btn_sec">
            <button
              type="button"
              className="btn btn_black btn-lg btn-block"
              onClick={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
              }}
            >
              {language.submit}
              <div className="ripple-container"></div>
            </button>
            <br />
          </div>
        </form>
      </div>
    );
  }
}
Form = validated(validationConfig)(Form);

const mapStateToProps = (state) => {
  return {
    outlets: state.outlets,
    globalsettings: state.settings,
    contactdata: state.contactdata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPickupOutlets: () => {
      dispatch({ type: GET_PICKUP_OUTLETS });
    },
    getContactData: (postObject) => {
      dispatch({ type: GET_CONTACTDATA, postObject });
    },
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
  };
};

Contact.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Contact)
);
